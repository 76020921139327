import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import * as moment from "moment";
import { MatDatepicker } from "@angular/material";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "layout-filter-box",
  templateUrl: "./layout-filter-box.component.html",
  styleUrls: ["./layout-filter-box.component.css"],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class LayoutFilterBoxComponent implements OnInit {
  // contains array of all things to be filtered
  // and passed back up to the main component to work
  // with table, objects in array should have value + column to work on (or 'all')
  filterArray = [];
  showReset = false;
  minDate = moment("2000-01-01");
  maxDate = moment();
  searchItem = "";
  mainSearchText = "";

  @HostBinding("class.selected-domain-id")
  @Input()
  bindDate = null;
  @HostBinding("class.my-domain-data")
  @Input()
  bindTitle = null;
  @HostBinding("class.my-domain-data")
  @Input()
  bindButton = null;
  @HostBinding("class.my-domain-data")
  @Input()
  bindButtonInclude = null;
  @HostBinding("class.my-domain-data")
  @Input()
  bindFeatures = null;
  @HostBinding("class.my-domain-data")
  @Input()
  bindFields = [];
  @HostBinding("class.my-domain-data")
  @Input()
  bindData = null;
  @HostBinding("class.my-domain-data")
  @Input()
  bindButtonAltText = null;
  @HostBinding("class.my-domain-data")
  @Input()
  bindRefreshButton = false;
  @HostBinding("class.my-domain-data")
  @Input()
  bindError = "";
  @HostBinding("class.my-domain-data")
  @Input()
  bindFilterUpdateStatus = "";

  @Input()
  bindMainSearch = false;
  @Input()
  mainSearchPlaceholder = "Search...";

  /*setting things higher up*/
  @Output() searchClick: EventEmitter<any> = new EventEmitter();
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
  @Output() setDate: EventEmitter<String> = new EventEmitter();
  @Output() refreshData: EventEmitter<any> = new EventEmitter();
  @Output() mainSearchEvent = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes) {
    // When the filter features change we need to empty out the existing filter
    // array.
    if (changes["bindFeatures"]) {
      this.resetFilters();
    }

    // this is a custom filter method to be able to target multiple specific columns with filtering
    // this ref main is a bad cheat and all this should be pulled out into an external method
    if (this.bindData !== null && typeof this.bindData !== "string") {
      let parent = this;
      this.bindData.filterPredicate = function (data, filter: any): boolean {
        //assumes by default this row will show
        let myCheck = true;
        // checks all the different listed filters, if one fails, my check is false and will not appear
        for (let i = 0; i < filter.length; i++) {
          // if target is a specific column
          if (filter[i].target !== "all") {
            const targetCol = filter[i].target;
            let itemToCheck = data[targetCol];
            if (itemToCheck === undefined) {
              return false;
            }

            //if itemToCheck is an array, makes a string
            if (itemToCheck.constructor === Array) {
              itemToCheck = itemToCheck.toString();
            }

            // checks against the string in this one column
            // if filter value is a single string
            if (filter[i].value.constructor === String) {
              if (filter[i].formType === "input") {
                // Partial match
                if (itemToCheck.indexOf(filter[i].value) === -1) {
                  myCheck = false;
                }
              } else {
                // Exact match
                if (itemToCheck !== filter[i].value) {
                  myCheck = false;
                }
              }
            }
            // if a filter value is an array of options
            else if (filter[i].value.constructor === Array) {
              // checks to make sure each value is in the big string
              for (let k = 0; k < filter[i].value.length; k++) {
                //as soon as a filter value doesn't hit, kills this row
                let myHit = String(itemToCheck)
                  .trim()
                  .toLowerCase()
                  .indexOf(String(filter[i].value[k].trim().toLowerCase()));
                if (myHit === -1) {
                  myCheck = false;
                } else {
                  myCheck = true;
                  break;
                }
              }
            }
          }

          //filtering text on any column
          // this should REALLY be able to be combined with the above
          if (filter[i].target === "all") {
            let myValueCheck = filter[i].value;
            let myTextCheck = false;
            // looks at an entire row of data
            // there's a problem in that it will keep rows in the data but not actually displaying
            // ideally pull this from the table data?
            for (let k = 0; k < parent.bindFields.length; k++) {
              let myItem = data[parent.bindFields[k]];

              // checks the value string against
              let myHit = String(myItem)
                .trim()
                .toLowerCase()
                .indexOf(String(myValueCheck.trim().toLowerCase()));
              if (myHit >= 0) {
                myTextCheck = true;
                break;
              }
            }

            // if the text string doesn't match anything, removes it
            if (!myTextCheck) {
              myCheck = false;
            }
          }
        }
        //returns a true or false
        return myCheck;
      };
    }

    // if something comes in with a preset run filter right away?????
    if (this.bindData !== null && typeof this.bindData !== "string") {
      this.filterTable();
    }

    if (this.bindFilterUpdateStatus !== "") {
      this.filterArrayUpdate({
        value: this.bindFilterUpdateStatus,
        target: "status",
        uniqueID: "status filter",
      });
    }
  }

  buttonAction() {
    this.buttonClick.emit(this.filterArray);
  }

  searchAction() {
    this.searchClick.emit({ searchItem: this.searchItem });
  }

  filterArrayUpdate($event) {
    // NEEDS TO TAKE OUT OF ARRAY IF VALUE IS EMPTY
    // DEFINITELY AN ISSUE IF ONE FILTER ENTERED
    // AND ANOTHER CLOSES UNENTERED THE FIRST
    // ONE RESETS ITSELF
    // Resets default to new value
    if (this.bindFeatures) {
      for (let i = 0; i < this.bindFeatures.length; i++) {
        for (let j = 0; j < this.bindFeatures[i].filterList.length; j++) {
          if (this.bindFeatures[i].filterList[j].uniqueID == $event.uniqueID) {
            this.bindFeatures[i].filterList[j].default = $event.value;
          }
        }
      }
    }

    // looks to see if this filter update exists in package
    const filterID = $event.uniqueID;
    const arrayIndex = this.filterArray.findIndex(
      (obj) => obj.uniqueID === filterID
    );
    // if not in the package, adds
    if (arrayIndex === -1) {
      // if value is empty (this can trigger accidentally on a backspace)
      if ($event.value !== "") {
        this.filterArray.push($event);
      }
    }
    // if already in the package
    else {
      // if there's value and it's already in there, replaces
      if (
        $event.value &&
        $event.value.constructor === String &&
        $event.value !== ""
      ) {
        this.filterArray[arrayIndex] = $event;
      } else if (
        $event.value &&
        $event.value.constructor === Array &&
        $event.value.length !== 0
      ) {
        this.filterArray[arrayIndex] = $event;
      }
      //if it's in there and value is empty removes entirely
      else {
        this.filterArray.splice(arrayIndex, 1);
      }
    }

    // handles filter
    this.showReset = this.filterArray.length > 0;

    // filters the data
    this.filterTable();
  }

  nilDefaults() {
    if (this.bindFeatures) {
      for (let i = 0; i < this.bindFeatures.length; i++) {
        for (let j = 0; j < this.bindFeatures[i].filterList.length; j++) {
          this.bindFeatures[i].filterList[j].default = "";
        }
      }
    }
  }

  // Empties the filterArray so that it can be populated with new filter values
  resetFilters() {
    // clears the filter array
    for (let i = this.filterArray.length - 1; i >= 0; i--) {
      // if filter has a form control resets itself
      if (this.filterArray[i].formControl !== undefined) {
        this.filterArray[i].formControl.setValue("");
      }
      this.filterArray.splice(i, 1);
    }
    this.searchItem = "";
    this.bindError = "";
    this.showReset = false;

    // filters the data
    this.filterTable();
  }

  infilter(target: string): boolean {
    return this.filterArray.findIndex((obj) => obj.target === target) === -1;
  }

  // this is for filtering the table by box results
  async filterTable() {
    while (true) {
      if (this.bindData) {
        this.bindData.filter = this.filterArray;

        // update filter features
        for (let i = 0; i < this.bindFeatures.length; i++) {
          for (let j = 0; j < this.bindFeatures[i]["filterList"].length; j++) {
            // if the feature is refreshable and it is not currently in the filter array
            if (
              this.bindFeatures[i]["filterList"][j].refreshable &&
              this.infilter(this.bindFeatures[i]["filterList"][j].filterTarget)
            ) {
              this.bindFeatures[i]["filterList"][
                j
              ].values = this.bindData.filteredData
                .map(
                  (data) =>
                    data[this.bindFeatures[i]["filterList"][j].filterTarget]
                )
                .filter(function (item, pos, self) {
                  return self.indexOf(item) == pos;
                });
            }
          }
        }
        break;
      }
      await new Promise((r) => setTimeout(r, 200)); // sleep .2 seconds
    }
  }

  // Emit main search to parent component
  onMainSearch() {
    this.mainSearchEvent.emit(this.mainSearchText);
  }

  // Handle enter pressed for main search
  onMainSearchKeyUp(event) {
    if (event.key === "Enter") {
      this.onMainSearch();
    }
  }

  // for the date things
  chosenYearHandler(normalizedYear: moment.Moment) {
    const ctrlValue = this.bindDate.value;
    ctrlValue.year(normalizedYear.year());
    this.bindDate.setValue(ctrlValue);
    // this.updateDate();
  }

  chosenMonthHandler(
    normlizedMonth: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    const ctrlValue = this.bindDate.value;
    ctrlValue.month(normlizedMonth.month());
    this.bindDate.setValue(ctrlValue);
    datepicker.close();
    this.updateDate();
  }

  updateDate() {
    this.setDate.emit();
  }

  refresh() {
    this.refreshData.emit();
  }
}
