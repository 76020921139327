<div
		fxLayout="column"
		fxFlex="100%;"
		class="panel-container"
		[ngClass]="{'panel-show': bindPanelShow == true, 'panel-hide': bindPanelShow == false, 'panel-load': bindPanelShow == null}"
>
	<div
			fxLayout="row"
			id='text-header'
			*ngIf='bindPanelShow'
	>
		<div id='text-id'>
			{{currentDomain}}
		</div>
		<div *ngIf="bindDomainRows[0]" id='text-date'
			 style='flex: 1'>{{domainDate}}</div>
		<mat-icon [inline]="true" id='icon-close' class="white" (click)="closePanel()">close</mat-icon>
	</div>
	<div
			class='panel-body'
			*ngIf='bindPanelShow'
	>
		<div class='monitor' *ngIf="soteriaUser">
			<div class='form-header'>
				<mat-checkbox [checked]=bindDomainRows[0].monitor (change)="monitor($event)">Monitor</mat-checkbox>
				<div class='messaging'>
					<span *ngIf="monitorStatus == 'saving'" class='messaging-saving'>saving</span>
					<span *ngIf="monitorStatus == 'saved'" class='messaging-saved'>saved</span>
					<span *ngIf="monitorStatus == 'fail'" class='messaging-nosave'>did not save</span>
				</div>
			</div>
		</div>
		<div class='form-item'>
			<div class='form-header'>
				<h3>Classification</h3>
				<div class='messaging'>
					<span *ngIf="classificationStatus == 'saving'" class='messaging-saving'>saving</span>
					<span *ngIf="classificationStatus == 'saved'" class='messaging-saved'>saved</span>
					<span *ngIf="classificationStatus == 'fail'" class='messaging-nosave'>did not save</span>
				</div>
			</div>

			<div *ngIf='conflictClassification' class='input-warning'>warning</div>

			<mat-form-field *ngIf='soteriaUser' class='form-width-half'>
				<mat-select [(ngModel)]="selectedClassification"
							(selectionChange)="selectionChangeClassification($event)">
					<mat-select-trigger>
						<mat-icon
								*ngIf="selectedClassification == 'Malicious' || selectedClassification == 'Confirmed Malicious'"
								svgIcon="icon-high"></mat-icon>
						<mat-icon
										*ngIf="selectedClassification == 'Suspicious'"
										svgIcon="icon-suspicious"></mat-icon>
						<mat-icon
								*ngIf="selectedClassification == 'New' || selectedClassification == 'Owner'"
								svgIcon="icon-medium"></mat-icon>
						<mat-icon
										*ngIf="selectedClassification == 'Unrelated'"
										svgIcon="icon-client"></mat-icon>
						<mat-icon
								*ngIf="selectedClassification == 'Infringement'"
								svgIcon="icon-infringe"></mat-icon>
						<mat-icon
								*ngIf="selectedClassification == 'Client Owned'"
								svgIcon="icon-client-owned"></mat-icon>
						<span class='mat-icon-follow'>
							{{selectedClassification}}
						</span>
					</mat-select-trigger>
					<mat-option *ngFor="let classification of classifications" [value]="classification.value">
						<mat-icon
								*ngIf="classification.viewValue == 'Malicious' || classification.viewValue == 'Confirmed Malicious'"
								svgIcon="icon-high"></mat-icon>
						<mat-icon
										*ngIf="classification.viewValue == 'Suspicious'"
										svgIcon="icon-suspicious"></mat-icon>
						<mat-icon
								*ngIf="classification.viewValue == 'New' || classification.viewValue == 'Owner'"
								svgIcon="icon-medium"></mat-icon>
						<mat-icon
										*ngIf="classification.viewValue == 'Unrelated'"
										svgIcon="icon-client"></mat-icon>
						<mat-icon
								*ngIf="classification.viewValue == 'Infringement'"
								svgIcon="icon-infringe"></mat-icon>
						<mat-icon
								*ngIf="classification.viewValue == 'Client Owned'"
								svgIcon="icon-client-owned"></mat-icon>
						<span>{{classification.viewValue}}</span>
					</mat-option>
				</mat-select>
			</mat-form-field>

			<div *ngIf='!soteriaUser' class='external-classification'>
				<mat-icon
						*ngIf="selectedClassification == 'Malicious' || selectedClassification == 'Confirmed Malicious'"
						svgIcon="icon-high"></mat-icon>
				<mat-icon
								*ngIf="selectedClassification == 'Suspicious'"
								svgIcon="icon-suspicious"></mat-icon>
				<mat-icon
								*ngIf="selectedClassification == 'New' || selectedClassification == 'Owner'"
								svgIcon="icon-medium"></mat-icon>
				<mat-icon
								*ngIf="selectedClassification == 'Unrelated'"
								svgIcon="icon-client"></mat-icon>
				<mat-icon
						*ngIf="selectedClassification == 'Infringement'"
						svgIcon="icon-infringe"></mat-icon>
				<mat-icon
						*ngIf="selectedClassification == 'Client Owned'"
						svgIcon="icon-client-owned"></mat-icon>
				<span class='mat-icon-follow'>
					{{selectedClassification}}
				</span>
			</div>
		</div>
		<div class='form-item'>
			<div class='form-header'>
				<h3>Takedown Status</h3>
				<div class='messaging'>
					<span *ngIf="takedownStatus == 'saving'" class='messaging-saving'>saving</span>
					<span *ngIf="takedownStatus == 'saved'" class='messaging-saved'>saved</span>
					<span *ngIf="takedownStatus == 'fail'" class='messaging-nosave'>did not save</span>
				</div>
			</div>

			<div *ngIf='conflictTakedown' class='input-warning'>warning</div>
			<div *ngIf='!soteriaUser' class='external-classification'>
				<mat-icon
					*ngIf="selectedTakedown == 'open'"
					svgIcon="icon-status-client-review"></mat-icon>
				<mat-icon
					*ngIf="selectedTakedown == 'uninitiated'"
					svgIcon="icon-status-open"></mat-icon>
				<mat-icon
					*ngIf="selectedTakedown == 'successful'"
					svgIcon="icon-status-in-progress"></mat-icon>
				<mat-icon
					*ngIf="selectedTakedown == 'unsuccessful'"
					svgIcon="icon-takedown-unsuccessful"></mat-icon>
				<mat-icon
					*ngIf="selectedTakedown == 'N/A'"
					svgIcon="icon-status-unassigned"></mat-icon>
				<span class='mat-icon-follow'>
					{{selectedTakedown}}
				</span>
				<div *ngIf="(selectedTakedown == 'uninitiated' || selectedTakedown == 'N/A')
					&& userPermissionTakedown" class='takedown'
				>
					<button mat-button (click)="takedownRequest($event)">Request Takedown</button>
				</div>
			</div>
			<mat-form-field *ngIf="soteriaUser" class='form-width-half'>

				<mat-select [(ngModel)]="selectedTakedown" (selectionChange)="selectionChangeTakedown($event)">

					<mat-select-trigger>
						<mat-icon
								*ngIf="selectedTakedown == 'open'"
								svgIcon="icon-status-client-review"></mat-icon>
						<mat-icon
								*ngIf="selectedTakedown == 'uninitiated'"
								svgIcon="icon-status-open"></mat-icon>
						<mat-icon
								*ngIf="selectedTakedown == 'successful'"
								svgIcon="icon-status-in-progress"></mat-icon>
						<mat-icon
								*ngIf="selectedTakedown == 'unsuccessful'"
								svgIcon="icon-takedown-unsuccessful"></mat-icon>
						<mat-icon
								*ngIf="selectedTakedown == 'N/A'"
								svgIcon="icon-status-unassigned"></mat-icon>

						<span class='mat-icon-follow'>
							{{selectedTakedown}}
						</span>
					</mat-select-trigger>



					<mat-option *ngFor="let takedown of takedowns" [value]="takedown.value">
						<mat-icon
								*ngIf="takedown.viewValue == 'Open'"
								svgIcon="icon-status-client-review"></mat-icon>
						<mat-icon
								*ngIf="takedown.viewValue == 'Uninitiated'"
								svgIcon="icon-status-open"></mat-icon>
						<mat-icon
								*ngIf="takedown.viewValue == 'Successful'"
								svgIcon="icon-status-in-progress"></mat-icon>
						<mat-icon
								*ngIf="takedown.viewValue == 'Unsuccessful'"
								svgIcon="icon-takedown-unsuccessful"></mat-icon>
						<mat-icon
								*ngIf="takedown.viewValue == 'N/A'"
								svgIcon="icon-status-unassigned"></mat-icon>

						<span class='mat-icon-follow'>
							{{takedown.viewValue}}
						</span>
					</mat-option>
			  </mat-select>
			</mat-form-field>
			<div *ngIf="selectedTakedown == 'uninitiated' && soteriaUser" class='takedown' >
				<button mat-button (click)="takedownRequest($event)">Request Takedown</button>
			</div>
		</div>

		<div class='form-item  form-width-threequarters' *ngIf='soteriaUser'>
			<div class='form-header'>
				<h3>Tags</h3>
				<div class='messaging'>
					<span *ngIf="tagStatus == 'saving'" class='messaging-saving'>saving</span>
					<span *ngIf="tagStatus == 'saved'" class='messaging-saved'>saved</span>
					<span *ngIf="tagStatus == 'fail'" class='messaging-nosave'>did not save</span>
				</div>
			</div>

			<div *ngIf='conflictTags' class='input-warning'>warning</div>
			<div *ngIf='soteriaAdmin' class='input-message'>{{tagMessage}}</div>
			<div *ngIf='!soteriaAdmin' class='input-message'>{{tagMessageLimited}}</div>
			<div *ngIf="tagpullError" class="input-warning">error pulling tags for domain</div>

			<ui-chips
					[bindAllTags]="allTags"
					[bindTags]="tags"
					[bindMakeOwnTags]="canMakeTags"
					[bindAdditionCallback]="addTag(_dnsenseService)"
					[bindRemovalCallback]="removeTag(_dnsenseService)"
			></ui-chips>
		</div>
		<div *ngIf='soteriaUser' class='form-item form-width-threequarters'>
			<div class='form-header'>
				<div class='form-header'>
					<h3>Internal Notes</h3>
					<div class='messaging'>
						<span *ngIf="internalStatus == 'saving'" class='messaging-saving'>saving</span>
						<span *ngIf="internalStatus == 'saved'" class='messaging-saved'>saved</span>
						<span *ngIf="internalStatus == 'fail'" class='messaging-nosave'>did not save</span>
						<span *ngIf="internalNotePullError" class='messaging-nosave'>error fetching domain notes</span>
					</div>
				</div>
				<button *ngIf="!internalAddNewNote" (click)="newInternalNote()" class="new">+ New Internal Note</button>
			</div>

			<div *ngIf='conflictInternal' class='input-warning'>warning</div>

			<div class='notes-list'>
				<div class='note-item' *ngFor="let note of internalNotes; let i = index">
					<div
							class='note-item-container'
							[ngClass]="{'item-delete': deleteInternalIndex === i}"
					>
						<div class='note-stats'>
							<span>{{note.username}}</span> | <span>{{note.time}}</span>
						</div>
						<div class='note-line'>
							<div class='note-text'>{{note.note}}</div>
							<mat-icon (click)="deleteInternalPrompt(i)" class="note-delete"
									  svgIcon="icon-delete"></mat-icon>
						</div>
						<div [attr.data-changestoapply]="internalChangesToApply" class='apply-delete-bar'>
							<span class='delete-note'>Are you sure you want to delete this note?</span>
							<button class="cancel" (click)="cancelInternalDelete()"><span>Cancel</span></button>
							<button class="delete" (click)="confirmInternalDelete()">Delete Note</button>
						</div>
					</div>
				</div>
			</div>

			<div *ngIf='internalAddNewNote'>
				<mat-form-field class="example-full-width">
					<textarea
							[(ngModel)]="inputInternal"
							matInput
							(ngModelChange)="inputChangeInternal()"
					></textarea>
				</mat-form-field>
				<div [attr.data-changestoapply]="internalChangesToApply" class='apply-cancel-bar'>
					<button class="cancel" (click)="cancelInternal()"><span>Cancel</span></button>
					<button class="apply" (click)="saveInternal()">Save Note</button>
				</div>
			</div>
		</div>
		<div *ngIf='soteriaUser' class='form-item form-width-threequarters'>
			<div class='form-header'>
				<h3>External Notes (customer facing)</h3>
				<div class='messaging'>
					<span *ngIf="externalStatus == 'saving'" class='messaging-saving'>saving</span>
					<span *ngIf="externalStatus == 'saved'" class='messaging-saved'>saved</span>
					<span *ngIf="externalStatus == 'fail'" class='messaging-nosave'>did not save</span>
				</div>
				<button *ngIf="!externalAddNewNote" (click)="newExternalNote()" class="new">+ New External Note</button>
			</div>

			<div *ngIf='conflictExternal' class='input-warning'>warning</div>

			<div class='notes-list'>
				<div class='note-item' *ngFor="let note of externalNotes; let i = index">
					<div
							class='note-item-container'
							[ngClass]="{'item-delete': deleteExternalIndex === i}"
					>
						<div class='note-stats'>
							<span>{{note.username}}</span> | <span>{{note.time}}</span>
						</div>
						<div class='note-line'>
							<div class='note-text'>{{note.note}}</div>
							<mat-icon (click)="deleteExternalPrompt(i)" class="note-delete"
									  svgIcon="icon-delete"></mat-icon>
						</div>
						<div [attr.data-changestoapply]="externalChangesToApply" class='apply-delete-bar'>
							<span class='delete-note'>Are you sure you want to delete this note?</span>
							<button class="cancel" (click)="cancelExternalDelete()"><span>Cancel</span></button>
							<button class="delete" (click)="confirmExternalDelete()">Delete Note</button>
						</div>
					</div>
				</div>
			</div>

			<div *ngIf='externalAddNewNote'>
				<mat-form-field class="example-full-width">
					<textarea
							[(ngModel)]="inputExternal"
							matInput
							(ngModelChange)="inputChangeExternal()"
					></textarea>
				</mat-form-field>
				<div [attr.data-changestoapply]="externalChangesToApply" class='apply-cancel-bar'>
					<button class="cancel" (click)="cancelExternal()"><span>Cancel</span></button>
					<button class="apply" (click)="saveExternal()">Save Note</button>
				</div>
			</div>
		</div>
		<div class='form-item form-width-threequarters'>
			<div class='form-header'>
				<h3>Comments</h3>
				<div class='messaging'>
					<span *ngIf="commentStatus == 'saving'" class='messaging-saving'>saving</span>
					<span *ngIf="commentStatus == 'saved'" class='messaging-saved'>saved</span>
					<span *ngIf="commentStatus == 'fail'" class='messaging-nosave'>did not save</span>
					<span *ngIf="commentPullError" class='messaging-nosave'>error pulling comments</span>
				</div>
				<button *ngIf="!addNewComment" (click)="newComment()" class="new">+ New Comment</button>
			</div>
			<div class='notes-list'>
				<div class='note-item' *ngFor="let comment of comments; let i = index">
					<div
							class='note-item-container'
							[ngClass]="{'item-delete': deleteCommentIndex === i}"
					>
						<div class='note-stats'>
							<span>{{comment.username}}</span> | <span>{{comment.time}}</span>
						</div>
						<div class='note-line'>
							<div class='note-text'>{{comment.comment}}</div>
							<mat-icon *ngIf='soteriaUser' (click)="deleteCommentPrompt(i)" class="note-delete"
									  svgIcon="icon-delete"></mat-icon>
						</div>
						<div [attr.data-changestoapply]="commentChangesToApply" class='apply-delete-bar'>
							<span class='delete-note'>Are you sure you want to delete this comment?</span>
							<button class="cancel" (click)="cancelCommentDelete()"><span>Cancel</span></button>
							<button class="delete" (click)="confirmCommentDelete()">Delete Comment</button>
						</div>
					</div>
				</div>
			</div>

			<div *ngIf='addNewComment'>
				<mat-form-field class="example-full-width">
					<textarea
							[(ngModel)]="inputComment"
							matInput
							(ngModelChange)="inputChangeComment()"
					></textarea>
				</mat-form-field>
				<div [attr.data-changestoapply]="commentChangesToApply" class='apply-cancel-bar'>
					<button class="cancel" (click)="cancelComment()"><span>Cancel</span></button>
					<button class="apply" (click)="saveComment()">Save Comment</button>
				</div>
			</div>
		</div>

		<div id='text-body' fxLayout="column" style="flex:1;">
			<div>{{bindDomainRows[0] ? bindDomainRows[0].summary : ""}}</div>
			<div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" style='position: relative'>
				<mat-tab-group style="width:100%;" selectedIndex="0" animationDuration="0ms" fxFlex="100%">
					<mat-tab label="Whois">
						<ng-template matTabContent>
							<div class='loading-container' *ngIf="loadingWhois">
								<mat-spinner mode="indeterminate"></mat-spinner>
							</div>
							<p *ngIf="!loadingWhois" class="whois" [innerHTML]="bindDomainRows[0] ? bindDomainRows[0].whois : ''"></p>
						</ng-template>
					</mat-tab>
					<mat-tab label="Screenshot">
						<ng-template  matTabContent>
							<div class='loading-container' *ngIf="loadingImage">
								<mat-spinner mode="indeterminate"></mat-spinner>
							</div>
							<img *ngIf="bindDomainRows[0].screenshot && !loadingImage" class="screenshot"
								 [src]="_sanitizer.bypassSecurityTrustResourceUrl(bindDomainRows[0].screenshot)"
								 matTooltip="Click to open in new tab"
								(click)="imageClick(bindDomainRows[0].screenshot, bindDomainRows[0].offending_dtld)"
							>
							<p *ngIf="bindDomainRows[0].screenshotError && !loadingImage">{{bindDomainRows[0].screenshotError}}</p>
						</ng-template>
					</mat-tab>
					<mat-tab label="Records">
						<div class='messaging'>
							<span *ngIf="dnsRecordPullError" class='messaging-nosave'>error fetching dns records</span>
						</div>
						<div class='loading-container' *ngIf="loadingDnsRecord">
							<mat-spinner mode="indeterminate"></mat-spinner>
						</div>
						<table class="table"
							*ngIf="!loadingDnsRecord || !dnsRecordEmpty">
							<thead class="thead">
								<th>Type</th>
								<th>Record</th>
								<th>TTL</th>
								<th>Priority</th>
								<th>Class</th>
							</thead>
							<tr class="tr"
								*ngFor="let record of dnsRecords">
								<td class="td">{{record.type}}</td>
								<td class="td">{{record.record}}</td>
								<td class="td">{{record.ttl}}</td>
								<td class="td">{{record.priority}}</td>
								<td class="td">{{record.class}}</td>
							</tr>
						</table>
						<p *ngIf='dnsRecordEmpty'>No records for domain</p>
					</mat-tab>
				</mat-tab-group>
			</div>
		</div>
	</div>
</div>
