import { Injectable, isDevMode } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Alias, Ticket, Tag } from "../_models";
import { Moment } from "moment";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class LexicoService {
  tickets: Ticket[];
  broadcasters: any;
  notifiers: any;
  aliases: Alias[];
  assignees: string[];
  tags: Tag[];
  startDate: Moment;
  stopDate: Moment;

  constructor(private http: HttpClient) {
    this.tickets = [];
    this.broadcasters = [];
    this.notifiers = [];
    this.aliases = [];
    this.assignees = [];
    this.tags = [];
    this.startDate = moment().startOf("month");
    this.stopDate = moment().endOf("month");
  }

  /*
		Tickets
	*/
  private async UpdateTickets(): Promise<any> {
    let params = new HttpParams()
      .set("start", this.startDate.format())
      .set("stop", this.stopDate.format());

    // If in development mode, return demo data
    if (isDevMode()) {
      return await this.http
        .get<Ticket[]>("/assets/data/lexico_tickets/list_tickets.json", {
          params,
        })
        .toPromise()
        .then(
          (result) => {
            this.tickets = result;
            for (let i = 0; i < this.tickets.length; i++) {
              this.tickets[i].created_at = this.startDate;
            }
            return { tickets: this.tickets };
          },
          (error) => {
            return { error: error };
          }
        );
    }

    // If in production make request to real API
    return await this.http
      .get<Ticket[]>("/ticket/ticket/list", { params })
      .toPromise()
      .then((results) => {
        this.tickets = results;
        return { tickets: this.tickets };
      })
      .catch((error) => {
        return { error: error };
      });
  }

  async GetTickets(): Promise<any> {
    if (this.tickets.length === 0) {
      return await this.UpdateTickets();
    }
    return { tickets: this.tickets };
  }

  async UpdateAlertRelevancy(
    ticket: string,
    alert: string,
    irrelevant: boolean
  ) {
    if (isDevMode()) {
      return null;
    }

    let params = new HttpParams()
      .set("ticket_id", ticket)
      .set("alert_id", alert)
      .set("relevancy", String(irrelevant));
    return await this.http
      .put("/ticket/ticket/alert/relevancy", {}, { params })
      .toPromise()
      .then(() => {
        return null;
      })
      .catch((error) => {
        return error;
      });
  }

  async TicketDaterange(month: Moment): Promise<any> {
    this.startDate = moment(month).startOf("month");
    this.stopDate = moment(month).endOf("month");

    return await this.UpdateTickets();
  }

  // Gets details for a ticket given a ticket id
  async GetTicketDetails(id: string): Promise<any> {
    if (isDevMode()) {
      const urlString = "/assets/data/lexico_tickets/ticket_" + id + ".json";
      return await this.http
        .get<Ticket>(urlString)
        .toPromise()
        .then(
          (result) => {
            let ticket = result;
            // Add notes and comments if they are empty
            if (ticket.notes == undefined) {
              ticket.notes = [];
            }
            if (ticket.comments == undefined) {
              ticket.comments = [];
            }
            ticket.created_at = this.startDate;
            return { ticket: ticket };
          },
          (error) => {
            return { error: error };
          }
        );
    } else {
      const urlString = "/ticket/ticket/" + id;
      return await this.http
        .get<Ticket>(urlString)
        .toPromise()
        .then(
          (result) => {
            let ticket = result;

            // tries to add notes nad comments
            if (ticket.notes == undefined) {
              ticket.notes = [];
            }
            if (ticket.comments == undefined) {
              ticket.comments = [];
            }
            return { ticket: ticket };
          },
          (error) => {
            return { error: error };
          }
        );
    }
  }

  /*
		Notifiers
	*/
  async UpdateNotifiersList(): Promise<any> {
    let params = new HttpParams()
      .set("start", this.startDate.format())
      .set("stop", this.stopDate.format());
    if (isDevMode()) {
      return await this.http
        .get("/assets/data/lexico_notifiers/notifiers_list.json", { params })
        .toPromise()
        .then(
          (result) => {
            this.notifiers = result;
            return { notifiers: this.notifiers };
          },
          (error) => {
            return { error: error };
          }
        );
    }

    return await this.http
      .get("/ticket/notifier/list", { params })
      .toPromise()
      .then((results) => {
        this.notifiers = results;
        return { notifiers: this.notifiers };
      })
      .catch((error) => {
        return { error: error };
      });
  }

  async GetNotifiers(): Promise<any> {
    if (this.notifiers.length === 0) {
      return await this.UpdateNotifiersList();
    }
    return { notifiers: this.notifiers };
  }

  async CreateNotifier(obj: any) {
    if (isDevMode()) {
      return null;
    }

    return await this.http
      .post("/ticket/notifier", obj)
      .toPromise()
      .then(() => {
        return null;
      })
      .catch((error) => {
        return error;
      });
  }

  async UpdateNotifier(obj: any) {
    if (isDevMode()) {
      return null;
    }

    return await this.http
      .put("/ticket/notifier", obj)
      .toPromise()
      .then(() => {
        return null;
      })
      .catch((error) => {
        return error;
      });
  }

  async DeleteNotifier(notifier: string) {
    if (isDevMode()) {
      return null;
    }

    let promise = this.http
      .delete("/ticket/notifier/" + notifier, {})
      .toPromise();
    return await promise
      .then(() => {
        return null;
      })
      .catch(() => {
        return "failed to delete notifier";
      });
  }

  /*
		Broadcasters
	*/
  async UpdateBroadcastersList(): Promise<any> {
    let params = new HttpParams()
      .set("start", this.startDate.format())
      .set("stop", this.stopDate.format());
    if (isDevMode()) {
      return await this.http
        .get("/assets/data/lexico_broadcast/list_broadcasters.json", { params })
        .toPromise()
        .then(
          (result) => {
            this.broadcasters = result;
            return { broadcasters: this.broadcasters };
          },
          (error) => {
            return { error: error };
          }
        );
    }

    return await this.http
      .get("/ticket/broadcaster/list", { params })
      .toPromise()
      .then((results) => {
        this.broadcasters = results;
        return { broadcasters: this.broadcasters };
      })
      .catch((error) => {
        return { error: error };
      });
  }

  async GetBroadcasters(): Promise<any> {
    if (this.broadcasters.length === 0) {
      return await this.UpdateBroadcastersList();
    }
    return { broadcasters: this.broadcasters };
  }

  async CreateBroadcaster(obj: any) {
    if (isDevMode()) {
      return null;
    }

    return await this.http
      .post("/ticket/broadcaster", obj)
      .toPromise()
      .then(() => {
        return null;
      })
      .catch((error) => {
        return error;
      });
  }

  async UpdateBroadcaster(obj: any) {
    if (isDevMode()) {
      return null;
    }

    return await this.http
      .put("/ticket/broadcaster", obj)
      .toPromise()
      .then(() => {
        return null;
      })
      .catch((error) => {
        return error;
      });
  }

  async DeleteBroadcaster(broadcaster: string) {
    if (isDevMode()) {
      return null;
    }

    let promise = this.http
      .delete("/ticket/broadcaster/" + broadcaster, {})
      .toPromise();
    return await promise
      .then(() => {
        return null;
      })
      .catch(() => {
        return "failed to delete broadcaster";
      });
  }

  /*
		Aliases
	*/
  private async UpdateAliases(): Promise<any> {
    let params = new HttpParams()
      .set("start", this.startDate.format())
      .set("stop", this.stopDate.format());
    if (isDevMode()) {
      return await this.http
        .get<Alias[]>("/assets/data/lexico_aliases/list_aliases.json", {
          params,
        })
        .toPromise()
        .then(
          (result) => {
            this.aliases = result;
            return { aliases: this.aliases };
          },
          (error) => {
            return { error: error };
          }
        );
    }

    return await this.http
      .get<Alias[]>("/ticket/notifier/alias/list", { params })
      .toPromise()
      .then((results) => {
        this.aliases = results;

        // checks for empty aliases
        for (let i = 0; i < this.aliases.length; i++) {
          if (this.aliases[i].name == undefined) {
            this.aliases[i].name = "";
          }
          if (this.aliases[i].to == undefined) {
            this.aliases[i].to = [];
          }
        }
        return { aliases: this.aliases };
      })
      .catch((error) => {
        return { error: error };
      });
  }

  async GetAliases(): Promise<any> {
    if (this.aliases.length === 0) {
      return await this.UpdateAliases();
    }
    return { aliases: this.aliases };
  }

  /*
		Assignees
	*/
  async UpdateAssignees(): Promise<any> {
    let params = new HttpParams()
      .set("start", this.startDate.format())
      .set("stop", this.stopDate.format());
    if (isDevMode()) {
      return await this.http
        .get<string[]>("/assets/data/lexico_tickets/assignees.json", { params })
        .toPromise()
        .then(
          (result) => {
            this.assignees = result;
            return { assignees: this.assignees };
          },
          (error) => {
            return { error: error };
          }
        );
    }

    return await this.http
      .get<string[]>("/ticket/assignee/list", { params })
      .toPromise()
      .then(
        (result) => {
          this.assignees = result;
          return { assignees: this.assignees };
        },
        (error) => {
          return { error: error };
        }
      );
  }

  async GetAssignees(): Promise<any> {
    if (this.assignees.length === 0) {
      return await this.UpdateAssignees();
    }
    return { assignees: this.assignees };
  }

  async CreateAssignee(email: string) {
    if (isDevMode()) {
      return null;
    }

    let params = new HttpParams().set("email", email);
    return await this.http
      .post("/ticket/assignee", {}, { params: params })
      .toPromise()
      .then(
        () => {
          return null;
        },
        (error) => {
          return error;
        }
      );
  }

  async DeleteAssignee(email: string) {
    if (isDevMode()) {
      return null;
    }

    let params = new HttpParams().set("email", email);
    return await this.http
      .delete("/ticket/assignee", { params: params })
      .toPromise()
      .then(
        () => {
          return null;
        },
        (error) => {
          return error;
        }
      );
  }

  /*
		Tags
	*/
  async UpdateTags(): Promise<any> {
    let params = new HttpParams()
      .set("start", this.startDate.format())
      .set("stop", this.stopDate.format());
    if (isDevMode()) {
      return await this.http
        .get<Tag[]>("/assets/data/lexico_tags/list_tags.json", { params })
        .toPromise()
        .then(
          (result) => {
            this.tags = result;
            return { tags: this.tags };
          },
          (error) => {
            return { error: error };
          }
        );
    }

    // Call real API if not in dev mode
    return await this.http
      .get<Tag[]>("/ticket/tag/list", { params })
      .toPromise()
      .then((results) => {
        this.tags = results;
        return { tags: this.tags };
      })
      .catch((error) => {
        return { error: error };
      });
  }

  async GetTags(): Promise<any> {
    if (this.tags.length === 0) {
      return this.UpdateTags();
    }
    return { tags: this.tags };
  }

  async DeleteTag(tag: string) {
    if (isDevMode()) {
      return null;
    }

    let promise = this.http.delete("/ticket/tag/" + tag, {}).toPromise();
    return await promise
      .then(() => {
        return null;
      })
      .catch(() => {
        return "failed to delete tag";
      });
  }

  async CreateTag(obj: any) {
    if (isDevMode()) {
      return null;
    }

    let params = new HttpParams();
    for (let prop of Object.keys(obj)) {
      params = params.append(prop, obj[prop]);
    }

    return await this.http
      .post("/ticket/tag", {}, { params })
      .toPromise()
      .then(() => {
        return null;
      })
      .catch((error) => {
        return error;
      });
  }

  async UpdateTag(obj: any) {
    if (isDevMode()) {
      return null;
    }

    return await this.http
      .put("/ticket/tag", obj)
      .toPromise()
      .then(() => {
        return null;
      })
      .catch((error) => {
        return error;
      });
  }

  async DeleteAlias(alias: string) {
    if (isDevMode()) {
      return null;
    }

    let promise = this.http
      .delete("/ticket/notifier/alias/" + alias, {})
      .toPromise();
    return await promise
      .then(() => {
        return null;
      })
      .catch(() => {
        return "failed to delete alias";
      });
  }

  async CreateAlias(obj: any) {
    if (isDevMode()) {
      return null;
    }

    return await this.http
      .post("/ticket/notifier/alias", obj)
      .toPromise()
      .then(() => {
        return null;
      })
      .catch((error) => {
        return error;
      });
  }

  async UpdateAlias(obj: any) {
    if (isDevMode()) {
      return null;
    }

    return await this.http
      .put("/ticket/notifier/alias", obj)
      .toPromise()
      .then(() => {
        return null;
      })
      .catch((error) => {
        return error;
      });
  }

  GetPrioritySummary() {
    if (isDevMode()) {
      return this.http
        .get("/assets/data/lexico_stats/priority.json")
        .toPromise();
    } else {
      return this.http.get("/ticket/summary/priority").toPromise();
    }
  }

  GetStatusSummary() {
    if (isDevMode()) {
      return this.http.get("/assets/data/lexico_stats/status.json").toPromise();
    } else {
      return this.http.get("/ticket/summary/status").toPromise();
    }
  }

  GetRiskSummary() {
    if (isDevMode()) {
      return this.http.get("/assets/data/lexico_stats/risk.json").toPromise();
    } else {
      return this.http.get("/ticket/summary/risk").toPromise();
    }
  }

  GetTrafficSummary() {
    if (isDevMode()) {
      return this.http
        .get("/assets/data/lexico_stats/sensor_traffic.json")
        .toPromise();
    } else {
      return this.http.get("/lexico/traffic").toPromise();
    }
  }

  GetSensorsOverTime() {
    if (isDevMode()) {
      return this.http
        .get("/assets/data/lexico_stats/sensors_over_time.json")
        .toPromise();
    } else {
      return this.http.get("/lexico/stat/sensor").toPromise();
    }
  }

  GetEventsOverTime() {
    if (isDevMode()) {
      return this.http
        .get("/assets/data/lexico_stats/events_over_time.json")
        .toPromise();
    } else {
      return this.http.get("/lexico/stat/event").toPromise();
    }
  }

  async ListSensors(): Promise<any[]> {
    let sensorResponse;
    if (isDevMode()) {
      sensorResponse = this.http
        .get("/assets/data/lexico_tickets/sensor_list.json")
        .toPromise();
    } else {
      sensorResponse = this.http.get("/lexico/sensor").toPromise();
    }

    let sensorlist = [];
    await sensorResponse.then(
      (results) => {
        sensorlist = results;
      },
      () => {}
    );
    return sensorlist;
  }

  async SetStatus(id: string, status: string) {
    let params = new HttpParams().set("id", id).set("status", status);
    return await this.http
      .put("/ticket/ticket/status", null, { params: params })
      .toPromise()
      .then(
        () => {
          return null;
        },
        (error) => {
          return error;
        }
      );
  }

  async CloseIrrelevant(id: string) {
    if (isDevMode()) {
      return null;
    }

    let params = new HttpParams().set("id", id);
    return await this.http
      .put("/ticket/ticket/irrelevant", null, { params: params })
      .toPromise()
      .then(
        () => {
          return null;
        },
        (error) => {
          return error;
        }
      );
  }

  async AddTag(ticket: string, tag: string) {
    if (isDevMode()) {
      return null;
    }

    let params = new HttpParams().set("ticket_id", ticket).set("tag_id", tag);
    return await this.http
      .post("/ticket/ticket/tag", null, { params: params })
      .toPromise()
      .then(
        () => {
          return null;
        },
        (error) => {
          return error;
        }
      );
  }

  async RemoveTag(ticket: string, tag: string) {
    if (isDevMode()) {
      return null;
    }

    let params = new HttpParams().set("ticket_id", ticket).set("tag_id", tag);
    return await this.http
      .delete("/ticket/ticket/tag", { params: params })
      .toPromise()
      .then(
        () => {
          return null;
        },
        (error) => {
          return error;
        }
      );
  }

  async SetTicketRisk(id: string, value: string) {
    if (isDevMode()) {
      return null;
    }

    let params = new HttpParams().set("id", id).set("suspicion", value);
    return await this.http
      .put("/ticket/ticket/suspicion", null, { params, responseType: "text" })
      .toPromise()
      .then(() => {
        return null;
      })
      .catch((error) => {
        return error;
      });
  }

  async SetTicketPriority(id: string, value: string) {
    if (isDevMode()) {
      return null;
    }

    let params = new HttpParams().set("id", id).set("priority", value);
    return await this.http
      .put("/ticket/ticket/priority", null, { params, responseType: "text" })
      .toPromise()
      .then(() => {
        return null;
      })
      .catch((error) => {
        return error;
      });
  }

  async SetTicketPositivity(id: string, value: string) {
    if (isDevMode()) {
      return null;
    }

    let params = new HttpParams().set("id", id).set("positivity", value);
    return await this.http
      .put("/ticket/ticket/positivity", null, { params, responseType: "text" })
      .toPromise()
      .then(() => {
        return null;
      })
      .catch((error) => {
        return error;
      });
  }

  async SetTicketDescription(id: string, value: string) {
    if (isDevMode()) {
      return null;
    }

    let params = new HttpParams().set("id", id).set("description", value);
    return await this.http
      .put("/ticket/ticket/description", null, { params, responseType: "text" })
      .toPromise()
      .then(() => {
        return null;
      })
      .catch((error) => {
        return error;
      });
  }

  /**
   * Adds a comment to an existing ticket
   * @param id for the ticket
   * @param comment text to add
   */
  AddTicketComment(id: string, comment: string) {
    if (isDevMode()) {
      return null;
    }

    const params = new HttpParams()
      .set("ticket_id", id)
      .set("comment", comment);
    return this.http
      .post("/ticket/ticket/comment", null, { params, responseType: "text" })
      .toPromise();
  }
}
