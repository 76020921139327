import {Component, ElementRef, HostBinding, Input, OnInit, ViewChild} from '@angular/core';
import {Chart} from 'chart.js';

@Component({
	selector: 'content-line-graph',
	templateUrl: './content-line-graph.component.html',
	styleUrls: ['./content-line-graph.component.css']
})
export class ContentLineGraphComponent implements OnInit {
	@ViewChild("tref", {read: ElementRef}) tref: ElementRef;
	// @ViewChild("legendref", {read: ElementRef}) legendref: ElementRef;

	@HostBinding('class.my-domain-data') @Input()
	bindID = null;
	@HostBinding('class.my-domain-data') @Input()
	bindData = null;
	@HostBinding('class.my-domain-data') @Input()
	bindTitle = "";

	chartProps = {};
	chart = null;
	loading = true;
	error = false;

	constructor() {
	}

	ngOnInit() {
	}

	ngOnChanges() {
		if (this.bindData !== null) {
			if (this.tref !== undefined) {
				this.tref.nativeElement.width = this.tref.nativeElement.parentNode.offsetWidth * 2;
				this.tref.nativeElement.height = this.tref.nativeElement.parentNode.offsetHeight * 2;
				this.tref.nativeElement.style.width = this.tref.nativeElement.parentNode.offsetWidth + "px";
				this.tref.nativeElement.style.height = this.tref.nativeElement.parentNode.offsetHeight + "px";
			}
			this.loading = false;

			this.createGraph();
		}
	}

	createGraph() {
		// creates chart props based on what came in
		if (typeof this.bindData === 'string') {
			this.error = true;
		} else {
			this.chartProps = {
				type: 'line',
				maintainAspectRatio: false,
				data: this.bindData,
				options: {
					//legend call back allows for customzing the legends
					//https://stackoverflow.com/questions/46381231/chart-js-legend-customization
					// legendCallback: function (chart) {
					// 	let legendSet = [];
					// 	for (let i = 0; i < chart.data.datasets.length; i++) {
					// 		legendSet.push('<div class="legend-item" style="width:100%; margin-bottom:2px;">');
					// 		legendSet.push('<div class="legendBox" style="float:left; margin-top:6px; margin-right:4px; width:13px; height:2px; background-color:' + chart.data.datasets[i].backgroundColor + '"></div>');
					// 		legendSet.push('<span style="display:inline-block; font-size:11px;">' + chart.data.datasets[i].label.slice(0, 5) + '</span>');
					// 		legendSet.push('</div>');
					// 	}
					// 	return legendSet.join("");
					// },
					responsive: true,
					title: {
						display: false,
						text: 'Chart.js Line Chart'
					},
					legend: {
						display: false,
					},
					tooltips: {
						mode: 'index',
						intersect: false,
						callbacks: {
							label: function(tooltipItems, data) {
								var label = data.datasets[tooltipItems.datasetIndex].label.slice(0,5) || '';
								if (label) {
									label += ': ';
								}
								label += tooltipItems.yLabel;
								return label;
							}
						}
					},
					animation: false,
					hover: {
						mode: 'point',
						intersect: true
					},
					scales: {
						xAxes: [{
							display: true,
							scaleLabel: {
								display: false,
								labelString: 'Month'
							}
						}],
						yAxes: [{
							display: true,
							ticks: {
								callback: function(value) {if (value % 1 === 0) {return value;}},
								beginAtZero: true,
								precision: 0,
							},
							scaleLabel: {
								display: false,
								labelString: 'Value'
							}
						}]
					}
				}
			};
			if (this.chart !== null) {
				// destroys the chart to rebuild
				this.chart.destroy();
			}
			this.chart = new Chart(this.bindID, this.chartProps);
			// if (this.chart.data.datasets.length > 1){
			// 	if (this.legendref !== undefined) {
			// 		this.legendref.nativeElement.innerHTML = this.chart.generateLegend();
			// 		;			}
			// }
		}
	}

	onResize() {
		// destroys the chart to rebuild
		if (this.chart !== null) {
			// destroys the chart to rebuild
			this.chart.destroy();
		}
		// gets new width and height
		this.tref.nativeElement.width = 0;
		this.tref.nativeElement.height = 0;
		this.tref.nativeElement.style.width = 0 + "px";
		this.tref.nativeElement.style.height = 0 + "px";

		this.tref.nativeElement.width = this.tref.nativeElement.parentNode.offsetWidth * 2;
		this.tref.nativeElement.height = this.tref.nativeElement.parentNode.offsetHeight * 2;
		this.tref.nativeElement.style.width = this.tref.nativeElement.parentNode.offsetWidth + "px";
		this.tref.nativeElement.style.height = this.tref.nativeElement.parentNode.offsetHeight + "px";

		// makes chart again
		this.chart = new Chart(this.bindID, this.chartProps);
	}
}
