<div class="event-summary">
  <div class="main-header">Event Overview</div>

  <div class="main-body">
    <div class="event-summary-row">
      <div class="header-box">
        <div class="val-group-alt">
          <mat-icon
            #tooltip="matTooltip"
            matTooltip="{{ getSeverityText(bindTicket.suspicion) }}"
            matTooltipPosition="above"
            svgIcon="{{ bindTicket.suspicion_icon }}"
            class="cell-icon"
          ></mat-icon>
          <div
            class="{{ 'value ' + mapSeverityToColor(bindTicket.suspicion) }}"
          >
            {{ bindTicket.suspicion }}
          </div>
          <div class="label">Severity</div>
        </div>
      </div>
      <div class="header-box">
        <div class="val-group-alt">
          <mat-icon
            #tooltip="matTooltip"
            matTooltip="{{ getClassificationText(bindTicket.priority) }}"
            matTooltipPosition="above"
            svgIcon="{{ bindTicket.priority_icon }}"
            class="cell-icon"
          ></mat-icon>
          <div
            class="{{
              'value ' + mapClassificationToColor(bindTicket.priority)
            }} "
          >
            {{ bindTicket.priority }}
          </div>
          <div class="label">Classification</div>
        </div>
      </div>
    </div>

    <div class="summary-body">
      <div class="val-group" *ngIf="ticketDetails && ticketDetails.name">
        <div class="label">Name</div>
        <div class="value">
          {{ ticketDetails.name }}
        </div>
      </div>

      <div class="val-group" *ngIf="bindTicket && bindTicket.description">
        <div class="label">Description</div>
        <div class="value">
          {{ bindTicket.description }}
        </div>
      </div>

      <div class="val-group" *ngIf="ticketDetails && ticketDetails.hostname">
        <div class="label">Hostname</div>
        <div class="value">
          {{ ticketDetails.hostname }}
        </div>
      </div>

      <div class="val-group" *ngIf="ticketDetails && ticketDetails.username">
        <div class="label">Username</div>
        <div class="value">
          {{ ticketDetails.username }}
        </div>
      </div>

      <div class="remediation-row">
        <button
          *ngIf="!this.remediationEnabled"
          class="btn"
          (click)="this.remediationEvent.emit(true)"
        >
          Acknowledge
        </button>

        <ui-dropdown
          [disabled]="!this.remediationEnabled"
          *ngIf="this.remediationEnabled"
          [bindData]="bindTicket"
          [bindDataField]="'positivity'"
          [bindPlaceholder]="'Remediation Status'"
          [bindOptions]="positivityStatus"
          [bindIdentifier]="bindTicket.id"
          [bindPresetValue]="
            ticketDetails ? ticketDetails.positivity : undefined
          "
          [bindAPI]="apiPositivity"
        ></ui-dropdown>

        <mat-icon
          #tooltip="matTooltip"
          matTooltip="Please review the entire event timeline before selecting a remediation status"
          matTooltipPosition="above"
          svgIcon="icon-help-outline"
          class="cell-icon"
        ></mat-icon>
      </div>

      <div class="tags" *ngIf="ticketDetails">
        <div class="tag" *ngFor="let tag of ticketDetails.tags">
          {{ tag }}
        </div>
      </div>
    </div>

    <div class="new-comment-container">
      <div class="header-bar">
        <div class="main-header">New Comment</div>

        <div class="notification-area">
          <div
            *ngIf="commentError"
            class="error"
            (click)="commentError = false"
          >
            <mat-icon [inline]="true">error</mat-icon>
            <div class="error-text">Failed to Save</div>
          </div>
        </div>
      </div>
      <div class="textarea-container">
        <mat-form-field>
          <textarea
            matInput
            #textName="matInput"
            value="{{ commentText }}"
            (keyup)="onCommentTextKeyup($event.target.value)"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="button-row">
        <button (click)="onCommentSave()" class="btn">Save</button>
      </div>
    </div>
  </div>
</div>
