import {Component, ElementRef, HostBinding, Input, OnInit, ViewChild} from '@angular/core';
import {Chart} from 'chart.js';

@Component({
	selector: 'content-donut',
	templateUrl: './content-donut.component.html',
	styleUrls: ['./content-donut.component.css']
})
export class ContentDonutComponent implements OnInit {
	@ViewChild("tref", {read: ElementRef}) tref: ElementRef;
	@ViewChild("legendref", {read: ElementRef}) legendref: ElementRef;

	@HostBinding('class.my-domain-data') @Input()
	bindID = null;
	@HostBinding('class.my-domain-data') @Input()
	bindData = null;
	@HostBinding('class.my-domain-data') @Input()
	bindTitle = "";

	chartProps = {};
	chart = null;
	loading = true;
	error = false;
	showLegend = false;

	constructor() {
	}

	ngOnInit() {
	}

	ngOnChanges() {
		if (this.bindData !== null) {
			if (this.tref !== undefined) {
				this.tref.nativeElement.style.width = this.tref.nativeElement.parentNode.offsetWidth + "px";
				this.tref.nativeElement.style.height = this.tref.nativeElement.parentNode.offsetHeight + "px";
				this.tref.nativeElement.width = this.tref.nativeElement.parentNode.offsetWidth;
				this.tref.nativeElement.height = this.tref.nativeElement.parentNode.offsetHeight;
			}
			this.loading = false;
			this.createGraph();
		}
	}

	createGraph() {
		if (typeof this.bindData === 'string') {
			this.error = true;
		} else {
			this.chartProps = {
				type: 'doughnut',
				maintainAspectRatio: false,
				data: this.bindData,
				options: {
					legendCallback: function (chart) {
						let legendSet = [];

						legendSet.push('<div class="outer1" style="display:flex; flex-direction:row;">');
						legendSet.push('<div class="outer2" style="display:flex; flex:1; flex-direction:column; max-width:100px;">');

						for (let i = 0; i < chart.data.labels.length; i++) {
							if (chart.data.icons !== undefined) {
								legendSet.push('<div class="legend-item" style="width:100%; display:flex; flex-direction:row; margin-top:-4px;">');
								legendSet.push('<img style="margin-right:3px; display:inline-block;" src="/assets/img/' + chart.data.icons[i] + '.svg">');
								legendSet.push('<span style="display:inline-block; align-self:center; font-size:11px;">' + chart.data.labels[i] + '</span>');
								legendSet.push('</div>');
							} else {
								legendSet.push('<div class="legend-item" style="width:100%; margin-bottom:2px;">');
								legendSet.push('<div class="legendBox" style="float:left; margin-right:4px; width:13px; height:13px; background-color:' + chart.data.datasets[0].backgroundColor[i] + '"></div>');
								legendSet.push('<span style="display:inline-block; font-size:11px;">' + chart.data.labels[i] + '</span>');
								legendSet.push('</div>');
							}
							if (i == 2 && chart.data.labels.length > 4) {
								legendSet.push('</div><div class="outer2" style="display:flex; flex:1; flex-direction:column; max-width:100px;">');
							}
						}

						legendSet.push('</div>');
						legendSet.push('</div>');

						this.showLegend = true;
						return legendSet.join("");
					},
					responsive: false,
					legend: {
						display: false,
					},
					title: {
						display: false,
					},
					animation: {
						animateScale: false,
						animateRotate: false
					}
				}
			};
			if (this.chart !== null) {
				// destroys the chart to rebuild
				this.chart.destroy();
			}
			this.chart = new Chart(this.bindID, this.chartProps);
			if (this.legendref !== undefined) {
				this.legendref.nativeElement.innerHTML = this.chart.generateLegend();
			}
		}
	}

	onResize() {
		if (this.chart !== null) {
			// destroys the chart to rebuild
			this.chart.destroy();
		}

		this.tref.nativeElement.width = 0;
		this.tref.nativeElement.height = 0;
		this.tref.nativeElement.style.width = 0 + "px";
		this.tref.nativeElement.style.height = 0 + "px";

		this.tref.nativeElement.style.width = this.tref.nativeElement.parentNode.offsetWidth + "px";
		this.tref.nativeElement.style.height = this.tref.nativeElement.parentNode.offsetHeight + "px";
		this.tref.nativeElement.width = this.tref.nativeElement.parentNode.offsetWidth;
		this.tref.nativeElement.height = this.tref.nativeElement.parentNode.offsetHeight;

		this.chart = new Chart(this.bindID, this.chartProps);
	}
}
