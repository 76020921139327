<div class="management-container">
	<div class="management-header">
		<div class="management-header-inner">
			<h3 class="title">User List</h3>
			<button
				class="create-user-button"
				(click)="confirmCreateUser()"
				[hidden]='showCreateUser'
			>+ New User</button>
			<mat-form-field class="filter">
				<textarea cdkTextareaAutosize
					matInput
					#inputName="matInput"
					placeholder="Filter..."
					[formControl]="filterForm"
					autocomplete="off"
					[(ngModel)]="filterStr"
					(ngModelChange)="updateFilter(filterStr)"
				></textarea>
			</mat-form-field>
		</div>
	</div>
	<div class="management-body-container">
		<div class="management-body">

			<div *ngIf="showCreateUser">
				<div class="management-user-create">
					<mat-form-field *ngIf="soteriaUser">
						<mat-select
								[(ngModel)]="create_client"
								[formControl]="createClientForm"
								placeholder="Client"
						>
							<mat-option
									*ngFor="let client of clientList"
									[value]="client"
							>
								{{client.client_name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field>
						<textarea cdkTextareaAutosize
							matInput
							#inputName="matInput"
							placeholder="First Name"
							[formControl]="createFNForm"
							autocomplete="off"
							[(ngModel)]="create_first"
						></textarea>
					</mat-form-field>
					<mat-form-field>
						<textarea cdkTextareaAutosize
								matInput
								#inputName="matInput"
								placeholder="Last Name"
								[formControl]="createLNForm"
								autocomplete="off"
								[(ngModel)]="create_last"
						></textarea>
					</mat-form-field>
					<mat-form-field>
						<textarea cdkTextareaAutosize
								matInput
								#inputName="matInput"
								placeholder="Email"
								[formControl]="createEmailForm"
								autocomplete="off"
								[(ngModel)]="create_email"
						></textarea>
					</mat-form-field>
					<mat-form-field>
						<mat-select
							multiple
							[(ngModel)]="create_roles"
							[formControl]="createRoleForm"
							placeholder="Role"
						>
							<mat-option
								*ngFor="let role of role_list"
								[value]="role"
							>
								{{role.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<div class="management-confirm-create">
						<p *ngIf="cuserMessage != ''"
							 class='cuser-error error messaging'
						>
							{{cuserMessage}}
						</p>
						<button
									class="cancel"
									(click)="cancelCreateUser()"
									>
									Cancel
						</button>
						<button *ngIf="createFieldsEmpty()"
							class="create-user-empty"
							(click)="submitCreateUser()">Create</button>
						<button *ngIf="!createFieldsEmpty()"
							class="create-user-ready"
							(click)="submitCreateUser()">Create</button>
					</div>
				</div>
				<hr>
			</div>

			<div *ngFor="let user of filtered_user_list" class="management-user-item row">
				<div class="management-user">
					<p class="user-item name cell"> {{user.first_name}} {{user.last_name}}</p>
					<p class="user-item email cell">{{user.email}}</p>
					<p class="user-item roles cell">{{
						user.roles.length === 1 ?
							user.roles[0].name : '(' + user.roles.length.toString() + ' roles)'
					}}</p>
					<mat-icon
						class="cell"
						*ngIf="!(selectedUser === user)"
						(click)="selectUser(user)"
					>keyboard_arrow_down</mat-icon>
					<mat-icon
						class="cell"
						*ngIf="(selectedUser === user)"
						(click)="deselectUser()"
					>keyboard_arrow_right</mat-icon>
					<mat-icon
						class="cell"
						(click)="confirmDeleteUser(user)"
					>delete</mat-icon>
				</div>
				<div *ngIf="showDeleteUser && user === selectedUser"
					class="management-confirm-delete">
					<p class="messaging error delete-message">Are you sure you want to delete the user?</p>
					<button class="cancel" (click)="cancelDeleteUser()">Cancel</button>
					<button class="delete" (click)="submitDeleteUser()">Delete User</button>
				</div>
				<div *ngIf="!showDeleteUser && user === selectedUser" class="management-user-edit">
					<mat-form-field>
						<textarea cdkTextareaAutosize
							matInput
							#inputName="matInput"
							placeholder="First Name"
							[formControl]="editFNForm"
							autocomplete="off"
							[(ngModel)]="user.first_name"
							readonly
						></textarea>
					</mat-form-field>
					<mat-form-field>
						<textarea cdkTextareaAutosize
							matInput
							#inputName="matInput"
							placeholder="Last Name"
							[formControl]="editLNForm"
							autocomplete="off"
							[(ngModel)]="user.last_name"
							readonly
						></textarea>
					</mat-form-field>
					<mat-form-field>
						<textarea cdkTextareaAutosize
							matInput
							#inputName="matInput"
							placeholder="Email"
							[formControl]="editEmailForm"
							autocomplete="off"
							[(ngModel)]="user.email"
							readonly
						></textarea>
					</mat-form-field>

					<div class="role-selector">
						<mat-form-field class="role">
							<mat-label>Roles</mat-label>
							<mat-select
								multiple
								[(ngModel)]="selectedUserRoles"
								(selectionChange)="UpdateUserRoles($event)"
								[formControl]="editRoleForm"
								[compareWith]="compareFn"
							>
								<mat-option
									*ngFor="let role of role_list"
									[value]="role"
								>
									{{role.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<p
							[hidden]="euserMessage === ''"
							class="messaging role-message"
							[ngClass]="{
								'error': euserMessageStyle === 'error',
								'success': euserMessageStyle === 'success'
							}"
							>
							{{euserMessage}}
						</p>
					</div>
				</div>
				<hr>
			</div>
		</div>
	</div>

	<div class="client-management" *ngIf="soteriaUser">
		<div class="client-management-header">
			<div class="client-management-inner">
				<h3 class="title">Client</h3>
				<button
						class="create-client-button"
						(click)="confirmCreateClient()"
						[hidden]='showCreateClient'
				>+ New Client</button>
				<p *ngIf="clientError !== ''"
				   class='client-error error messaging'
				>
					{{clientError}}
				</p>
			</div>
			<div *ngIf="showCreateClient">
				<div class="client-management-create">
					<mat-form-field>
						<textarea cdkTextareaAutosize
								  matInput
								  #inputName="matInput"
								  placeholder="New Client Name"
								  [formControl]="createClientForm"
								  autocomplete="off"
								  [(ngModel)]="createClientName"
						></textarea>
					</mat-form-field>
					<div class="client-management-confirm-create">
						<p *ngIf="cClientError != ''"
						   class='cuser-error error messaging'
						>
							{{cClientError}}
						</p>
						<button
								class="cancel client-cancel"
								(click)="cancelCreateClient()"
						>
							Cancel
						</button>
						<button *ngIf="createClientFieldEmpty()"
								class="create-user-empty"
								(click)="submitCreateClient()">Create</button>
						<button *ngIf="!createClientFieldEmpty()"
								class="create-user-ready"
								(click)="submitCreateClient()">Create</button>
					</div>
				</div>
			</div>
			<div class="select-client">
				<mat-form-field>
					<mat-select  [(ngModel)]="selectClient"
								 (selectionChange)="GetClient()"
								 placeholder="Client"
					>
						<mat-option
								*ngFor="let client of clientList"
								[value]="client"
						>
							{{client.client_name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="show-client" *ngIf="selectClient">
				<div class="client-info">
					<div class="client-name">
						<mat-form-field>
						<textarea cdkTextareaAutosize
								  matInput
								  #inputName="matInput"
								  placeholder="Client Name"
								  autocomplete="off"
								  [(ngModel)]="selectClient.client_name"
								  readonly
						></textarea>
						</mat-form-field>

						<mat-icon
								(click)="showUpdateClientName()"
						>edit</mat-icon>
					</div>

					<div *ngIf="clientUpdateBool">
						<mat-form-field>
						<textarea cdkTextareaAutosize
								  matInput
								  #inputName="matInput"
								  placeholder="New Client Name"
								  autocomplete="off"
								  [(ngModel)]="clientUpdateName"
						></textarea>
						</mat-form-field>


						<div class="client-update-confirm">
							<p *ngIf="cClientUpdateError != ''"
							   class='update-client-error error messaging'
							>
								{{cClientUpdateError}}
							</p>
							<button
									class="cancel client-cancel"
									(click)="cancelClientUpdate()"
							>
								Cancel
							</button>
							<button
									class="create-user-ready"
									(click)="submitClientNameUpdate()">Update</button>
						</div>

					</div>

					<mat-form-field>
						<textarea cdkTextareaAutosize
								  matInput
								  #inputName="matInput"
								  placeholder="Client UUID"
								  autocomplete="off"
								  [(ngModel)]="selectClient.client_uuid"
								  readonly
						></textarea>
					</mat-form-field>
				</div>

				<div>
					<div>
						<h3>Client Products</h3>
						<button
								class="create-client-product-button"
								(click)="confirmCreateClientProduct()"
								[hidden]='showCreateClientProduct'
						>+ New Client Product</button>
					</div>
					<div class='create-client-product' *ngIf="showCreateClientProduct">
						<mat-form-field>
							<mat-select  [(ngModel)]="selectClientProduct"
										 [formControl]="selectClientProductForm"
										 placeholder="Client Product"
							>
								<mat-option
										*ngFor="let product of clientProducts"
										[value]="product"
								>
									{{product.product_name}}
								</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field>
							<mat-label>Expiration Date</mat-label>
							<input matInput [matDatepicker]="picker"
								   [formControl]="createProductExpireForm"
								   [(ngModel)]="createProductExpire"
							>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
						</mat-form-field>

						<div class="client-management-confirm-create">
							<p *ngIf="cClientError != ''"
							   class='cuser-error error messaging'
							>
								{{cClientError}}
							</p>
							<button
									class="cancel client-cancel"
									(click)="cancelCreateClientProduct()"
							>
								Cancel
							</button>
							<button *ngIf="createClientProductFieldEmpty()"
									class="create-user-empty"
									>Create</button>
							<button *ngIf="!createClientProductFieldEmpty()"
									class="create-user-ready"
									(click)="submitCreateClientProduct()">Create</button>
						</div>

					</div>

					<div class="client-product-table" *ngFor="let product of selectClient.ClientProducts">
						<div class="client-row">
							<div class="cell-product-name">
								{{product.Product.product_name}}
							</div>
							<div class="cell-product-expire">
								{{product.Expires}}
							</div>
							<mat-icon
									class="cell-product-delete"
									(click)="deleteClientProduct(product)"
							>delete</mat-icon>
						</div>
						<hr>
					</div>
				</div>

			</div>
		</div>

	</div>

	<div class="role-management" *ngIf="soteriaUser">
		<div class="management-header">
			<div class="management-header-inner">
				<h3 class="title">Role</h3>
				<button
						class="create-user-button"
						(click)="confirmCreateRole()"
						[hidden]='showCreateRole'
				>+ New Role</button>
				<p
						class="role-error error messaging"
						*ngIf="roleError !== ''"
				>
					{{roleError}}
				</p>
			</div>
			<div class="role-create" *ngIf="showCreateRole">
				<div class="management-role-create">
					<mat-form-field>
						<textarea cdkTextareaAutosize
								  matInput
								  #inputName="matInput"
								  placeholder="Role Name"
								  [formControl]="createRoleForm"
								  autocomplete="off"
								  [(ngModel)]="newRole"
						></textarea>
					</mat-form-field>
					<div class="management-confirm-create">
						<p *ngIf="cuserMessage != ''"
						   class='cuser-error error messaging'
						>
							{{cuserMessage}}
						</p>
						<button
								class="cancel"
								(click)="cancelCreateRole()"
						>
							Cancel
						</button>
						<button *ngIf="!createRoleFieldEmpty()"
								class="create-user-empty"
								(click)="submitCreateRole()">Create</button>
						<button *ngIf="createRoleFieldEmpty()"
								class="create-user-ready"
								(click)="submitCreateRole()">Create</button>
					</div>
				</div>
			</div>
			<div class="select-role">
				<mat-form-field>
					<mat-select
							[(ngModel)]="selectedRoleToView"
							(selectionChange)="getRolePerms()"
							placeholder="Role"
					>
						<mat-option
								*ngFor="let role of role_list"
								[value]="role"
						>
							{{role.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>


			<div class="show-role" *ngIf="selectedRoleToView">
				<div>
					<h3>Role Permissions</h3>
					<button class="create-perm-button"
							(click)="confirmCreatePerm()"
							[hidden]='showCreatePerm'
					>+ New Perm</button>
				</div>
				<div class='create-role-perm' *ngIf="showCreatePerm">
					<mat-form-field>
						<textarea cdkTextareaAutosize
								  matInput
								  #inputName="matInput"
								  placeholder="Object"
								  [formControl]="newPermObjectForm"
								  autocomplete="off"
								  [(ngModel)]="newPermObject"
						></textarea>
					</mat-form-field>
					<mat-form-field>
						<textarea cdkTextareaAutosize
								  matInput
								  #inputName="matInput"
								  placeholder="Verb"
								  [formControl]="newPermVerbForm"
								  autocomplete="off"
								  [(ngModel)]="newPermVerb"
						></textarea>
					</mat-form-field>
					<mat-form-field>
						<mat-label>Action</mat-label>
						<mat-select
								[(ngModel)]="newPermAction"
								[formControl]="newPermActionForm"
						>
							<mat-option
									*ngFor="let action of permActions"
									[value]="action"
							>
								{{action}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<div class="role-confirm-create">
						<button
								class="cancel"
								(click)="cancelCreatePerm()"
						>
							Cancel
						</button>
						<button *ngIf="!createPermFieldEmpty()"
								class="create-user-empty"
						>Create</button>
						<button *ngIf="createPermFieldEmpty()"
								class="create-user-ready"
								(click)="submitCreatePerm()">Create</button>
					</div>

				</div>



				<div class='perm-table '*ngFor="let perm of selectedRolePermList">
						<div class="perm-row">
							<p class="cell-perm-list">{{perm.perm}}</p>
							<mat-icon
									class="cell-perm-delete"
									(click)="deletePerm(perm)"
							>delete</mat-icon>
						</div>
						<hr>

				</div>

				<div>
					<p class='delete-role-text' *ngIf="deleteRole">
						Are you sure you want to delete this role? This will permentaly delete it.
					</p>
					<button *ngIf='deleteRole' class="delete role-delete" (click)="submitDeleteRole()">Delete Role</button>
					<button *ngIf='deleteRole' class="cancel role-delete" (click)="cancelDeleteRole()">Cancel</button>
					<button *ngIf='!deleteRole'class="delete role-delete" (click)="confirmDeleteRole()">Delete Role</button>
				</div>
			</div>
		</div>
	</div>
</div>
