import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';

@Component({
	selector: 'layout-panel-broadcasters',
	templateUrl: './layout-panel-broadcasters.component.html',
	styleUrls: ['./layout-panel-broadcasters.component.css'],
})
export class LayoutPanelBroadcastersComponent implements OnInit {
	@HostBinding('class.my-clients-data') @Input()
	bindPanelShow = null;
	@HostBinding('class.my-clients-data') @Input()
	bindCreate = false;
	@Output() closePanel: EventEmitter<any> = new EventEmitter();
	@HostBinding('class.my-clients-data') @Input()
	bindData = null;

	presaveData = null;
	changesToApply = false;
	saveStatus = '';

	constructor() {
	}

	@HostBinding('class.my-clients-data') @Input()
	bindCreateCallback = async (obj) => {
		return obj
	};

	@HostBinding('class.my-clients-data') @Input()
	bindUpdateCallback = async (obj) => {
		return obj
	};

	ngOnInit() {
	}

	async ngOnChanges() {
		if (this.bindData) {
			this.presaveData = {...this.bindData};
		}
	}

	clog($event) {
		console.log($event);
	}

	checkChanges() {
		this.changesToApply = JSON.stringify(this.presaveData) != JSON.stringify(this.bindData) // this.presaveData != this.bindData;
	}

	updatePresaved(key, $event) {
		this.presaveData[key] = $event.value;
		if (this.presaveData[key] == '') {
			delete this.presaveData[key];
		}
		this.checkChanges()
	}

	// Closes panel
	onPanelClose() {
		this.changesToApply = false;
		this.saveStatus = '';
		this.closePanel.emit({nochange: true})
	}

	cancelPanel() {
		this.changesToApply = false;
		this.saveStatus = '';
		this.closePanel.emit({nochange: true});
	}

	async applyPanel() {
		let err;
		if (this.bindCreate) {
			err = await this.bindCreateCallback(this.presaveData);
		} else {
			err = await this.bindUpdateCallback(this.presaveData);
		}
		if (err) {
			this.saveStatus = 'nosave';
		} else {
			this.changesToApply = false;
			this.bindData = this.presaveData;
			this.saveStatus = '';
			this.closePanel.emit(
				{
					action: 'apply',
					newData: this.bindData
				}
			);
		}
	}
}
