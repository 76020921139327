import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import {MatTableDataSource} from '@angular/material';
import {ClientService, LexicoService, UserService} from '../../_services';
import {MediaObserver} from '@angular/flex-layout';
import {Ticket} from '../../_models';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'lexico-tickets',
	templateUrl: './lexico-tickets.component.html',
	styleUrls: ['./lexico-tickets.component.css']
})
export class LexicoTicketsComponent implements OnInit {
	soteriaUser = false;

	// basic static vars that would appear in multiple views
	alertShow = null;
	selectedItemData = null;
	selectedItemIndex = null;

	minDate = moment('2000-01-01');
	maxDate = moment();

	date = new FormControl(moment());
	data = null;
	tagsList = [];
	tagsListID = [];
	tableMessage = null;

	optionsRisk = [
		'Malicious', 'Suspicious', 'Not Suspicious'
	];
	optionsPriority = [
		'High', 'Medium', 'Low', 'Informational'
	];
	iconsRisk = [
		'icon-risk-high', 'icon-risk-medium', 'icon-risk-informational'
	];
	iconsPriority = [
		'icon-priority-high', 'icon-priority-medium', 'icon-priority-low', 'icon-priority-informational'
	];

	/* stuff for filter box */
	filterListStatus = ['In Progress', 'Open', 'In Review', 'Client Review', 'Closed', 'Unassigned'];
	iconListStatus = ['icon-status-in-progress', 'icon-status-open', 'icon-status-in-review', 'icon-status-client-review', 'icon-status-closed', 'icon-status-unassigned'];
	filterListPriority = ['High', 'Medium', 'Low', 'Informational'];
	iconListPriority = ['icon-priority-high', 'icon-priority-medium', 'icon-priority-low', 'icon-priority-informational'];
	filterListRisk = ['Malicious', 'Suspicious', 'Potentially Unwanted Program (PUP)', 'Not Suspicious'];
	iconListRisk = ['icon-risk-high', 'icon-risk-medium', 'icon-risk-suspicious', 'icon-risk-informational'];
	filterListAssignee = [];
	filterBoxTitle = 'Ticket List';
	filterBoxButton = 'Open Next New Ticket';
	filterBoxButtonInclude = true;
	filterBoxAltMessage = 'No New Tickets';
	filterBoxAllFields = ['suspicion', 'id', 'priority', 'status', 'assignee', 'description', 'client_name'];
	filterBoxFeatures = [
		{
			'filterList': [
				{
					type: 'input',
					filterTarget: 'all',
					default: '',
					placeholder: 'Text Filter (on any column)',
					uniqueID: 'text filter'
				},
				{
					type: 'input',
					filterTarget: 'id',
					default: '',
					placeholder: 'Ticket Id',
					uniqueID: 'id filter'
				},
				{
					type: 'ui-dropdown',
					filterTarget: 'suspicion',
					default: '',
					placeholder: 'Risk',
					uniqueID: 'risk filter',
					values: this.filterListRisk,
					icons: this.iconListRisk
				}
			]
		},
		{
			'filterList': [
				{
					type: 'ui-multiselect',
					filterTarget: 'status',
					default: 'Open',
					placeholder: 'Status',
					uniqueID: 'status filter',
					values: this.filterListStatus,
					icons: this.iconListStatus,
				},
				{
					type: 'ui-dropdown',
					filterTarget: 'priority',
					default: '',
					placeholder: 'Priority',
					uniqueID: 'priority filter',
					values: this.filterListPriority,
					icons: this.iconListPriority
				}

			]
		},
		{
			'filterList': [
				{
					type: 'ui-dropdown',
					filterTarget: 'assignee',
					default: '',
					placeholder: 'Assignee',
					uniqueID: 'assignee filter',
					values: this.filterListAssignee
				},
				{
					type: 'date',
					filterTarget: 'created_at',
					default: '',
					placeholder: 'Date Range',
					uniqueID: 'date filter'
				}
			]
		}
	];

	/* stuff for table */
	tableConfig = {
		'columns': [

			{
				'header': 'Risk',
				'field': 'suspicion',
				'maxWidth': 115
			},
			{
				'header': 'Client',
				'field': 'client_name',
				'maxWidth': 100
			},
			{
				'header': 'Created',
				'field': 'created_at',
				'maxWidth': 85
			},
			{
				'header': 'Priority',
				'field': 'priority',
				'maxWidth': 115
			},
			{
				'header': 'Status',
				'field': 'status',
				'maxWidth': 115
			},
			{
				'header': 'Assignee',
				'field': 'assignee',
				'maxWidth': 125
			},
			{
				'header': 'Description',
				'field': 'description',
				'maxWidth': null
			}
		],
		'delete': false,
		'testing': false
	};

	/* stuff for visualizations */
	idDonut1 = 'lexico_tickets_donut1';
	idDonut2 = 'lexico_tickets_donut2';
	idLine1 = 'lexico_tickets_line1';
	idLine2 = 'lexico_tickets_line2';
	idBar1 = 'lexico_tickets_bar1';
	lineColors = [
		'#4290E8',
		'#44D7CB',
		'#D975FD',
		'#8CDC57',
		'#FD9C36',
		'#9F87FF',
		'#B77B4A',
		'#FF9696',
		'#FED535',
		'#D3D3D3'
	];
	donutDataPriorityPrep = null;
	donutDataStatusPrep = null;
	lineDataEventsOverTime = null;
	lineDataSensorsOverTime = null;
	clientlist = [];
	bulkSelectedTickets = [];
	bulkSaveStatus = '';
	TicketLoadStatus = '';

	// icon lists
	iconPriorityMatch = {
		High: 'icon-priority-high',
		Medium: 'icon-priority-medium',
		Low: 'icon-priority-low',
		Informational: 'icon-priority-informational',
	};
	iconStatusMatch = {
		Open: 'icon-status-open',
		In_Review: 'icon-status-in-review',
		In_Progress: 'icon-status-in-progress',
		Client_Review: 'icon-status-client-review',
		Closed: 'icon-status-closed',
		Unassigned: 'icon-status-unassigned'
	};
	iconRiskMatch = {
		Malicious: 'icon-risk-high',
		Suspicious: 'icon-risk-medium',
		Potentially_Unwanted_Program_PUP: 'icon-risk-suspicious',
		Not_Suspicious: 'icon-risk-informational'
	};

	constructor(
		private http: HttpClient,
		private _lexicoService: LexicoService,
		private _userservice: UserService,
		public media: MediaObserver,
		private _clientService: ClientService,
		private route: ActivatedRoute,
		private router: Router,
	) {
	}

	updateTickets(data: Ticket[]) {
		if (data !== null && data.length == 0) {
			this.tableMessage = 'no data in this time range';
		} else if (data !== null) {
			this.tableMessage = null;
			this.data = new MatTableDataSource(data);

			// matches up columns with icons
			for (let i = 0; i < this.data.data.length; i++) {
				if (!this.data.data[i].status) {
					this.data.data[i].status = '';
				}
				if (!this.data.data[i].priority) {
					this.data.data[i].priority = '';
				}
				if (!this.data.data[i].suspicion) {
					this.data.data[i].suspicion = '';
				}

				for (let j = 0; j < this.clientlist.length; j++) {
					if (this.clientlist[j]['client_uuid'] == this.data.data[i].client) {
						this.data.data[i].client_name = this.clientlist[j]['client_name'];
					}
				}

				const statusText = this.data.data[i].status.split(' ').join('_');
				const priorityText = this.data.data[i].priority.split(' ').join('_');
				let suspiciousText = this.data.data[i].suspicion.split(' ').join('_');
				suspiciousText = suspiciousText.replace('(', '');
				suspiciousText = suspiciousText.replace(')', '');


				this.data.data[i].priority_icon = this.iconPriorityMatch[priorityText];
				this.data.data[i].status_icon = this.iconStatusMatch[statusText];
				this.data.data[i].suspicion_icon = this.iconRiskMatch[suspiciousText];
			}
		}
	}

	async bulkRisk($event) {
		this.bulkSaveStatus = 'saving';
		for (let i = 0; i < this.bulkSelectedTickets.length; i++) {
			const err = await this._lexicoService.SetTicketRisk(this.bulkSelectedTickets[i], $event.value);
			if (err) {
				this.bulkSaveStatus = 'nosave';
				return;
			}


			let suspiciousText = $event.value.split(' ').join('_');
			suspiciousText = suspiciousText.replace('(', '');
			suspiciousText = suspiciousText.replace(')', '');

			for (let j = 0; j < this.data.filteredData.length; j++) {
				if (this.data.filteredData[j].id == this.bulkSelectedTickets[i]) {
					this.data.filteredData[j].suspicion = $event.value;
					this.data.filteredData[j].suspicion_icon = this.iconRiskMatch[suspiciousText];
				}
			}
		}
		this.bulkSaveStatus = 'saved';
	}

	async bulkIrrelevant($event) {
		// console.log('status:', $event);
		// console.log(this._userservice.user);
		// this.bulkSaveStatus = 'saved';
		this.bulkSaveStatus = 'saving';
		for (let i = 0; i < this.bulkSelectedTickets.length; i++) {
			const err = await this._lexicoService.CloseIrrelevant(this.bulkSelectedTickets[i]);
			if (err) {
				this.bulkSaveStatus = 'nosave';
				return;
			}

			for (let j = 0; j < this.data.filteredData.length; j++) {
				if (this.data.filteredData[j].id == this.bulkSelectedTickets[i]) {
					this.data.filteredData[j].status = 'Closed';
					this.data.filteredData[j].status_icon = this.iconStatusMatch['Closed'];
					this.data.filteredData[j].assignee = this._userservice.user.Email;
					this.data.filteredData[j].suspicion = 'Not Suspicious';
					this.data.filteredData[j].suspicion_icon = this.iconRiskMatch['Not_Suspicious'];
				}
			}
		}
		this.bulkSaveStatus = 'saved';
	}

	async bulkPriority($event) {
		this.bulkSaveStatus = 'saving';
		for (let i = 0; i < this.bulkSelectedTickets.length; i++) {
			const err = await this._lexicoService.SetTicketPriority(this.bulkSelectedTickets[i], $event.value);
			if (err) {
				this.bulkSaveStatus = 'nosave';
				return;
			}

			const priorityText = $event.valuesplit(' ').join('_');

			for (let j = 0; j < this.data.filteredData.length; j++) {
				if (this.data.filteredData[j].id == this.bulkSelectedTickets[i]) {
					this.data.filteredData[j].priority = $event.value;
					this.data.filteredData[j].priority_icon = this.iconPriorityMatch[priorityText];
				}
			}
		}
		this.bulkSaveStatus = 'saved';
	}

	async bulkDescription($event) {
		if ($event.save) {
			this.bulkSaveStatus = 'saving';
			for (let i = 0; i < this.bulkSelectedTickets.length; i++) {
				const err = await this._lexicoService.SetTicketDescription(this.bulkSelectedTickets[i], $event.value);
				if (err) {
					this.bulkSaveStatus = 'nosave';
					return;
				}

				for (let j = 0; j < this.data.filteredData.length; j++) {
					if (this.data.filteredData[j].id == this.bulkSelectedTickets[i]) {
						this.data.filteredData[j].description = $event.value;
					}
				}
			}
			this.bulkSaveStatus = 'saved';
		}
	}

	clearBulk() {
		this.bulkSelectedTickets = [];
	}

	async ngOnInit() {
		// determines if this is an internal user or not
		this.soteriaUser = await this._userservice.UserIsSoterian();

		// If the user isn't a soterian, then we need to limit what filters they can use and what fields they can see
		if (!this.soteriaUser) {
			this.bulkSelectedTickets = null;
			this.tableConfig = {
				'columns': [
					{
						'header': 'Risk',
						'field': 'suspicion',
						'maxWidth': 115
					},
					{
						'header': 'Id',
						'field': 'id',
						'maxWidth': 50
					},
					{
						'header': 'Created',
						'field': 'created_at',
						'maxWidth': 85
					},
					{
						'header': 'Priority',
						'field': 'priority',
						'maxWidth': 115
					},
					{
						'header': 'Description',
						'field': 'description',
						'maxWidth': null
					}
				],
				'delete': false,
				'testing': false
			};

			this.filterBoxFeatures = [
				{
					'filterList': [
						{
							type: 'input',
							filterTarget: 'all',
							default: '',
							placeholder: 'Text Filter (on any column)',
							uniqueID: 'text filter'
						},
						{
							type: 'ui-dropdown',
							filterTarget: 'suspicion',
							default: '',
							placeholder: 'Risk',
							uniqueID: 'risk filter',
							values: this.filterListRisk,
							icons: this.iconListRisk
						}
					]
				},
				{
					'filterList': [
						{
							type: 'ui-dropdown',
							filterTarget: 'priority',
							default: '',
							placeholder: 'Priority',
							uniqueID: 'priority filter',
							values: this.filterListPriority,
							icons: this.iconListPriority
						},
						{
							type: 'date',
							filterTarget: 'created_at',
							default: '',
							placeholder: 'Date Range',
							uniqueID: 'date filter'
						}
					]
				}
			];
			this.filterBoxAllFields = ['suspicion', 'id', 'priority', 'description'];
			this.filterBoxButtonInclude = false;
			this.filterBoxAltMessage = '';
		}

		// If the user is a soterian, get a list of clients, a list of assignees, and show the 'next ticket' button
		if (this.soteriaUser) {
			const data = await this._clientService.LoadClientList();
			if (data['error']) {
				// TODO: handle error if clients fail to load
			} else if (!data['clients']) {
				this.clientlist = [];
			} else {
				this.clientlist = data['clients'];
			}

			this.filterBoxButtonInclude = true;

			const assignees = await this._lexicoService.GetAssignees();
			if (assignees['error']) {
				// console.log(assignees['error']);
			} else if (!assignees['assignees']) {
				// console.log('assignees not returned');
			} else {
				assignees['assignees'].sort();
				for (let i = 0; i < assignees['assignees'].length; i++) {
					this.filterListAssignee.push(assignees['assignees'][i]);
				}
			}
		}

		// Pull the ticket data for the table, show error if needed
		const data = await this._lexicoService.GetTickets();
		if (data['error']) {
			this.tableMessage = 'error loading data';
		} else if (!data['tickets']) {
			this.tableMessage = 'no data in this time range';
		} else {
			this.updateTickets(<Ticket[]>data['tickets']);
		}

		// Pull the tag data for the tickets
		const tags = await this._lexicoService.GetTags();
		if (tags['error']) {
		} else if (!tags['tags'] || tags['tags'].length == 0) {
		} else {
			tags['tags'].sort((a, b) => (a.name > b.name) ? 1 : -1);
			for (let i = 0; i < tags['tags'].length; i++) {
				this.tagsList.push(tags['tags'][i].name);
				this.tagsListID.push(tags['tags'][i].id);
			}
		}

		// Set up graphs
		this.setupDonutPriority();
		this.setupDonutStatus();
		this.setupEventsOverTime();
		this.setupSensorsOverTime();

		// If the route has a query parameter for the ticket id, route them to that
		// ticket
		 this.route
      .queryParams
      .subscribe(async params => {
				if (params['id']) {
					let index = -1;

					// a case to where this.data could be null due to new month with no data
					if (this.data != null) {
						for (let i = 0; i < this.data.data.length; i++) {
							if (this.data.data[i].id == params['id']) {
								index = i;
							}
						}
					}

					// handle the case where the ticket isn't currently in the stored data
					if (index == -1) {
						const tdata = await this._lexicoService.GetTicketDetails(params['id']);
						if (tdata['error']) {
							this.TicketLoadStatus = 'fail';
							return;
						}

						// special case for if there are no tickets in a list. this might occur
						// if a new month happens over the weekend.
						if (this.data == null) {
							this.TicketLoadStatus = '';
							const list = <Ticket[]>[];
							list.push(tdata['ticket']);
							this.updateTickets(list);
							index = this.data.data.length - 1;
						} else {
							this.TicketLoadStatus = '';
							this.data.data.push(tdata['ticket']);
							index = this.data.data.length - 1;
						}
					}

					this.TicketLoadStatus = '';
					this.selectedItemData = this.data.data[index];
					this.selectedItemIndex = index;
					this.alertShow = true;
				} else {
					this.TicketLoadStatus = '';
					this.selectedItemData = null;
					this.selectedItemIndex = null;
					this.alertShow = false;
				}
      });
	}

	async setupDonutPriority() {
		// takes imported donut priority data and processes for chartjs
		this._lexicoService.GetPrioritySummary().then((results) => {
			this.donutDataPriorityPrep = {
				datasets: [{
					data: [],
					backgroundColor: [
						'#FA6262',
						'#FED535',
						'#60CB73',
						'#C9C9C9'
					]
				}],
				labels: [
					'High',
					'Medium',
					'Low',
					'Informational'
				]
			};
			this.donutDataPriorityPrep.datasets[0].data.push(results['high']);
			this.donutDataPriorityPrep.datasets[0].data.push(results['medium']);
			this.donutDataPriorityPrep.datasets[0].data.push(results['low']);
			this.donutDataPriorityPrep.datasets[0].data.push(results['informational']);

			this.donutDataPriorityPrep.icons = [
				'icon_priority_high',
				'icon_priority_medium',
				'icon_priority_low',
				'icon_priority_informational'
			];
		}).catch(() => {
			this.donutDataPriorityPrep = 'error';
		});
	}

	async setupDonutStatus() {
		// takes imported donut status data and processes for chartjs
		this._lexicoService.GetStatusSummary().then((results) => {
			this.donutDataStatusPrep = {
				datasets: [{
					data: [],
					backgroundColor: [
						'#60CB73',
						'#FED535',
						'#44D7CB',
						'#4290E8',
						'#9E7FFF',
						'#C9C9C9',
					]
				}],
				labels: [
					'In Progress',
					'Open',
					'In Review',
					'Client Review',
					'Closed',
					'Unassigned'
				]
			};
			this.donutDataStatusPrep.datasets[0].data.push(results['in_progress']);
			this.donutDataStatusPrep.datasets[0].data.push(results['open']);
			this.donutDataStatusPrep.datasets[0].data.push(results['in_review']);
			this.donutDataStatusPrep.datasets[0].data.push(results['client_review']);
			this.donutDataStatusPrep.datasets[0].data.push(results['closed']);
			this.donutDataStatusPrep.datasets[0].data.push(results['unassigned']);

			this.donutDataStatusPrep.icons = [
				'icon_status_in_progress',
				'icon_status_open',
				'icon_status_in_review',
				'icon_status_client_review',
				'icon_status_closed',
				'icon_status_unassigned'
			];
		}).catch(() => {
			this.donutDataStatusPrep = 'error';
		});
	}

	async setupSensorsOverTime() {
		// brings in line chart data and processes for chartjs
		this._lexicoService.GetSensorsOverTime().then((results) => {
			// iterates over all timestamps and creates a label for them
			let labels = [];
			Object.keys(results).forEach((key, index) => {
				Object.keys(results[key]).forEach((timestamp, index) => {
					const ts = moment(timestamp).format('MM/DD');
					if (labels.indexOf(ts) === -1) {
						labels.push(ts);
					}
				});
			});
			labels = labels.sort();

			// Generate dataset array for chart
			const datasets = [];
			Object.keys(results).forEach((key, index) => {
				// sets color index, if color index too high repeats colors a second time
				// this gives you two loops, if there are seriously more than that
				// you need a different solution, this graph is too small for 18 lines
				let colorIndex = index;
				if (index > 9) {
					colorIndex = index - 10;
				}

				const dataset = {};
				dataset['label'] = key;
				dataset['borderColor'] = this.lineColors[colorIndex];
				dataset['borderWidth'] = 2;
				dataset['pointRadius'] = 2;
				dataset['backgroundColor'] = this.lineColors[colorIndex];
				dataset['fill'] = false;

				// iterates over all of the data and finds the max
				const mapper = {};
				Object.keys(results[key]).forEach((timestamp, index) => {
					const ts = moment(timestamp).format('LL');
					if (!mapper[ts]) {
						mapper[ts] = 0;
					}
					mapper[ts] = Math.max(mapper[ts], results[key][timestamp]);
				});

				const data = [];
				Object.keys(mapper).forEach((timestamp, index) => {
					data.push({x: labels.indexOf(timestamp), y: mapper[timestamp]});
				});

				dataset['data'] = data;
				datasets.push(dataset);
			});

			this.lineDataSensorsOverTime = {
				labels: labels,
				datasets: datasets,
			};
		}).catch((error) => {
			console.log('error', error);
			this.lineDataSensorsOverTime = 'error';
		});
	}

	async setupEventsOverTime() {
		// brings in line chart data and processes for chartjs
		this._lexicoService.GetEventsOverTime().then((results) => {
			// iterates over all timestamps and creates a lable for them
			let labels = [];
			Object.keys(results).forEach((key, index) => {
				Object.keys(results[key]).forEach((timestamp, index) => {
					const ts = moment(timestamp).format('MM/DD');
					if (labels.indexOf(ts) === -1) {
						labels.push(ts);
					}
				});
			});
			labels = labels.sort();

			// Generate dataset array for chart
			const datasets = [];
			Object.keys(results).forEach((key, index) => {
				// sets color index, if color index too high repeats colors a second time
				// this gives you two loops, if there are seriously more than that
				// you need a different solution, this graph is too small for 18 lines
				let colorIndex = index;
				if (index > 9) {
					colorIndex = index - 10;
				}

				const dataset = {};
				dataset['label'] = key;
				dataset['borderColor'] = this.lineColors[colorIndex];
				dataset['borderWidth'] = 2;
				dataset['pointRadius'] = 2;
				dataset['backgroundColor'] = this.lineColors[colorIndex];
				dataset['fill'] = false;

				// iterates over all of the data and finds the sum
				const mapper = {};
				Object.keys(results[key]).forEach((timestamp, index) => {
					const ts = moment(timestamp).format('LL');
					if (!mapper[ts]) {
						mapper[ts] = 0;
					}
					mapper[ts] = mapper[ts] + results[key][timestamp];
				});

				const data = [];
				Object.keys(mapper).forEach((timestamp, index) => {
					data.push({x: labels.indexOf(timestamp), y: mapper[timestamp]});
				});

				dataset['data'] = data;
				datasets.push(dataset);
			});

			this.lineDataEventsOverTime = {
				labels: labels,
				datasets: datasets,
			};
		}).catch((error) => {
			console.log('error', error);
			this.lineDataEventsOverTime = 'error';
		});
	}

	/* interactions */
	nextTicket() {
		this.http.get('/ticket/ticket/dequeue', {}).subscribe((result) => {
			const resultParse = JSON.parse(JSON.stringify(result));
			const recentID = resultParse.id;
			// let index = -1;
			// for (let i = 0; i < this.data.data.length; i++) {
			// 	if (this.data.data[i].id == recentID) {
			// 		index = i;
			// 	}
			// }
			//
			// if (index == -1) {
			// 	this.data.data.push(resultParse);
			// 	index = this.data.data.length - 1;
			// }
			//
			// this.selectedItemData = this.data.data[index];
			// this.selectedItemIndex = index;
			this.router.navigate(['/home/lexico/tickets'], { queryParams: { id: recentID } });
		}, (error) => {
			if (error.error.error === 'no new tickets') {
				this.router.navigate(['/home/lexico/tickets']);
				this.filterBoxAltMessage = 'No new tickets';
				this.filterBoxButtonInclude = false;
				this.sleep(4000).then(() => {
					this.filterBoxAltMessage = '';
					this.filterBoxButtonInclude = true;
				});
			} else {
				// On error, set error message and remove button. After 2 seconds, reset button
				this.filterBoxAltMessage = 'Failed to load ticket';
				this.filterBoxButtonInclude = false;
				this.sleep(4000).then(() => {
					this.filterBoxAltMessage = '';
					this.filterBoxButtonInclude = true;
				});
			}
		});
	}

	// sleep time expects milliseconds
	sleep(time) {
		return new Promise((resolve) => setTimeout(resolve, time));
	}

	selectTableRow($event) {
		// if row already selected, closes it
		if ($event.emitIndex == this.selectedItemIndex) {
			this.router.navigate(['/home/lexico/tickets']);
		} else {
			this.router.navigate(['/home/lexico/tickets'], {queryParams: {id: $event.emitMatch.id}});
		}
	}

	closeAlertSaveData() {
		this.router.navigate(['/home/lexico/tickets']);
	}

	async updateDate() {
		if (this.date.value.isAfter(this.minDate) && this.date.value.startOf('month').isBefore(this.maxDate)) {
			this.data = new MatTableDataSource([]);
			const data = await this._lexicoService.TicketDaterange(moment(this.date.value).startOf('month'));
			if (data['error']) {
				this.tableMessage = 'error loading data';
			} else if (!data['tickets']) {
				this.tableMessage = 'no data in this time range';
			} else {
				this.updateTickets(<Ticket[]>data['tickets']);
			}
		}
	}
}
