<div class="dnsense_domains_container" [attr.user-view]="soteriaUser">
  <layout-panel-domain
    (hidePanel)="hidePanel()"
    [bindDomainRows]="selectedDataRows"
    [bindPanelShow]="panelShow"
  >
  </layout-panel-domain>
  <layout-panel-domain-add
    [bindData]="data"
    [bindPanelShow]="panelShowAdd"
    (hidePanel)="hidePanelAdd()"
    (sendDomain)="selectTableRow($event)"
  >
  </layout-panel-domain-add>
  <div *ngIf="!soteriaUser" class="layout-viz">
    <div id="bar-chart-1">
      <content-bar-chart
        [bindID]="'idBarDNSense1'"
        [bindData]="barDataMalicious"
        [bindTitle]="'High Risk Domains'"
        [bindLegend]="false"
        (sendDataUp)="filterMalicious($event)"
      ></content-bar-chart>
    </div>
    <div id="bar-chart-2">
      <content-bar-chart
        [bindID]="'idBarDNSense2'"
        [bindData]="barDataSuspicious"
        [bindTitle]="'Low Risk Domains'"
        [bindLegend]="false"
        (sendDataUp)="filterSuspicious($event)"
      ></content-bar-chart>
    </div>
  </div>
  <div class="layout-table">
    <layout-filter-box
      *ngIf="true"
      [bindDate]="date"
      (setDate)="updateDate()"
      [bindTitle]="filterBoxTitle"
      [bindButton]="filterBoxButton"
      [bindFeatures]="filterBoxFeatures"
      [bindFields]="filterBoxAllFields"
      (searchClick)="searchDomain($event)"
      (buttonClick)="downloadReport($event)"
      [bindData]="data"
      [bindButtonInclude]="filterBoxButtonInclude"
      [bindButtonAltText]="filterBoxAltMessage"
      [bindError]="bindError"
      [bindFilterUpdateStatus]="bindFilterStatus"
      [bindRefreshButton]="true"
      (refreshData)="updateDate()"
      [bindMainSearch]="true"
      mainSearchPlaceholder="Search for a Domain..."
      (mainSearchEvent)="searchDomain($event)"
    >
    </layout-filter-box>
    <content-table
      [bindSort]="'found_on'"
      [bindSortDirection]="'desc'"
      [bindData]="data"
      [bindTableConfig]="tableConfig"
      [bindSelectedItemIndex]="selectedItemIndex"
      [bindMessage]="tableMessage"
      (setRow)="selectTableRow($event)"
    >
    </content-table>
  </div>
</div>
