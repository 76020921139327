import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
	selector: 'app-feedback',
	templateUrl: './feedback.component.html',
	styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<FeedbackComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onEnter() {
		let element: HTMLElement = document.getElementById('submit') as HTMLElement;
		element.click()
	}

	ngOnInit() {
	}
}
