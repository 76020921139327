import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material";
import {LexicoService} from "../../_services";
import {MediaObserver} from '@angular/flex-layout';

@Component({
	selector: 'lexico-assignees',
	templateUrl: './lexico-assignees.component.html',
	styleUrls: ['./lexico-assignees.component.css']
})
export class LexicoAssigneesComponent implements OnInit {
	// basic static vars that would appear in multiple views
	panelShow = null;
	selectedItemData = null;
	selectedItemIndex = null;
	tableMessage = null;
	// first array is columns
	// feature array inside is individual filter
	// that go down the column
	filterBoxFeatures = [
		{
			"filterList": [
				{
					type: "input",
					filterTarget: "all",
					default: "",
					placeholder: "Text Filter (on any column)",
					uniqueID: 'text filter'
				}
			]
		},
		{
			"filterList": []
		},
		{
			"filterList": []
		}
	];
	filterBoxTitle = "Assignee List";
	filterBoxButton = "+ New Assignee"
	filterBoxAllFields = ["email"];
	// for the table, config is how it's arranged
	// sample data is temp until we can get some
	tableConfig = {
		"columns": [
			{
				"header": "Email",
				"field": "email",
				"maxWidth": null
			}
		],
		"delete": true,
		"testing": false
	};
	data = null;
	//for the panel that comes out
	// array of rows, each feature is a row
	// this is like filter box except these are rows of inputs instead of columns
	panelTitle = "";
	panelSaveWording = "";
	panelFeatures = [
		{
			"panelList": [
				{
					type: "input",
					dataProp: "email",
					placeholder: "Assignee"
				},
				{}
			]
		},
	];
	identifierProp = 'email';

	constructor(public _lexicoService: LexicoService, public media: MediaObserver) {
	}

	updateAssignees(data: string[]) {
		if (data !== null && data.length == 0) {
			this.tableMessage = 'no data in this time range';
		} else if (data !== null) {
			this.tableMessage = null;

			let result = [];
			for (let i = 0; i < data.length; i++) {
				result.push({email: data[i]})
			}

			this.data = new MatTableDataSource(result);
		}
	}

	async ngOnInit() {
		let data = await this._lexicoService.GetAssignees();
		if (data['error']) {
			this.tableMessage = 'error loading data';
		} else if (!data['assignees']) {
			this.tableMessage = 'no data in this time range';
		} else {
			this.updateAssignees(<string[]>data['assignees']);
		}
	}

	// for closing the panel, always closes but does API things
	// this would appear in my different views
	closePanelSaveData($event) {
		const closeAction = $event.action;
		if (closeAction === "apply") {
			//if this is a new one pushes into array
			if (this.selectedItemIndex == null) {
				if (this.data == null) {
					const emptyArray = [];
					this.data = new MatTableDataSource(emptyArray);
					this.tableMessage = null;
				}
				this.data.data.push($event.newData);
				this.data.data = this.data.data.slice();
			}
			//if this is an old one updates
			else {
				const selectedIndex = this.data.data.indexOf(this.selectedItemData);
				this.data.data[selectedIndex] = $event.newData;
			}
		}

		// does the closing action and resets things
		this.selectedItemData = null;
		this.selectedItemIndex = null;
		this.panelShow = false;
	}

	// opens panel to make a new item
	// this would appear in many different views
	newItem() {
		this.selectedItemIndex = null;
		this.panelShow = true;
		this.panelTitle = "New Assignee";
		this.panelSaveWording = "Save New Assignee";

		// temp tries making new alias
		this.selectedItemData = {
		};
	}

	// for getting a selected table row
	// this would appear in many different views
	selectTableRow($event) {
/*
		const match = $event.emitMatch;
		const index = $event.emitIndex;

		// if row already selected, closes it
		if (index == this.selectedItemIndex) {
			this.selectedItemData = null;
			this.selectedItemIndex = null;
			this.panelShow = false;
		} else {
			this.selectedItemData = match;
			this.selectedItemIndex = index;
			this.panelShow = true;
			this.panelTitle = "Edit Alias";
			this.panelSaveWording = "Apply Changes";
		}
*/
	}

	deleteTableRow($event) {
		if ($event.emitLength == 0) {
			this.tableMessage = 'no data in this time range';
			this.data = null;
		}
	}

	rAssignee = this.removeAssignee(this._lexicoService);
	removeAssignee(lexicoService) {
		return async (identifier) => {
			return await lexicoService.DeleteAssignee(identifier);
		}
	}

	// Hacky work-around to get the update to stop breaking within the panel.
	// For some reason, if you pass a function call rather than a function reference
	// the panel update craps out.
	cassigneeCallback = this.createAssignee(this._lexicoService);
	createAssignee(lexicoService) {
		return async (obj) => {
			return await lexicoService.CreateAssignee(obj['email']);
		}
	}
}
