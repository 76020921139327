<div
		fxLayout="column" fxFlex="100%;" class="panel-container"
		[ngClass]="{'panel-show': bindPanelShow == true, 'panel-hide': bindPanelShow == false, 'panel-load': bindPanelShow == null}"
>
	<div fxLayout="row" id='panel-header' *ngIf='bindPanelShow'>
		<div id='text-id'>
			{{ bindCreate ? 'New Client' : 'Edit ' + presaveData.owner }}
		</div>

		<div class='saving-status'>
			<div *ngIf='saveStatus == "unsaved"' id='status-unsaved'>unsaved</div>
			<div *ngIf='saveStatus == "saving"' id='status-saving'>saving</div>
			<div *ngIf='saveStatus == "saved"' id='status-saved'>saved</div>
			<div *ngIf='saveStatus == "nosave"' id='status-nosave'>did not save</div>
		</div>

		<div *ngIf='bindCreate && changesToApply' id='apply-cancel-combo'>
			<button id='cancel' (click)="cancelPanel()">Cancel</button>
			<button id='apply' (click)="applyPanel()">Apply Changes</button>
		</div>

		<mat-icon [inline]="true" id='icon-close' class="white" (click)="onPanelClose()">close</mat-icon>
	</div>

	<div class='panel-body' *ngIf='bindPanelShow'>
		<div class='input-row'>
			<div class='form-item form-width-half' *ngIf="bindCreate">
				<ui-input
						[bindTarget]="'owner'"
						[bindPresetValue]='presaveData.owner'
						[bindPlaceholder]="'Client Name'"
						[bindSaveAppear]='false'
						(newValue)="updateName($event)"
				></ui-input>
			</div>

			<div id='checkbox-item' class='form-item'>
				<mat-checkbox
                    #myCheckboxPaying
					[checked]="presaveData.paying"
					(change)="updatePaying($event)"
				>
					Paying Customer
				</mat-checkbox>
			</div>
		</div>



		<!--KEYWORD PIECE-->
		<div
			*ngIf="!bindCreate"
			class='keyword-container
			form-item form-width-threequarters'
		>
			<div class='form-header'>
				<div class='form-header'>
					<h3>Keyword</h3>
					<div class='messaging'>
						<span *ngIf="addKeywordStatus == 'saving'" class='messaging-saving'>saving</span>
						<span *ngIf="addKeywordStatus == 'saved'" class='messaging-saved'>saved</span>
						<span *ngIf="addkeywordStatus == 'fail'" class='messaging-nosave'>did not save</span>
					</div>
				</div>
				<button  *ngIf='!addingNewKeyword'
						 class='new'
						 (click)="addNewKeywordPrompt()">+ New Keyword</button>
			</div>

			<!--INPUT FOR NEW KEYWORD-->
			<div *ngIf='addingNewKeyword'>
				<mat-form-field class="example-full-width">
					<input
						matInput
						[(ngModel)]="inputKeyword"
						(ngModelChange)="changeKeyword()"
						placeholder="Enter Keyword"
					>
				</mat-form-field>
				<div [attr.data-changestoapply]="keywordChangesToApply" class='apply-cancel-bar'>
					<button class="cancel" (click)="cancelKeyword()"><span>Cancel</span></button>
					<button class="apply" (click)="saveKeyword()">Save Keyword</button>
				</div>
			</div>
			<div class='notes-list'>
				<div *ngFor="let keyword of keywordsData; let j = index">
					<mat-expansion-panel>
						<mat-expansion-panel-header>
							<mat-panel-title>
								{{keyword.keyword}}
							</mat-panel-title>
							<mat-panel-description>
								Created: {{keyword.created_on}}
							</mat-panel-description>
						</mat-expansion-panel-header>

						<div class='expander-top'>
							<div class='expander-top-line'>
								<!-- Checkbox for grep keyword toggle -->
								<mat-checkbox
										style="margin-right: 20px;"
										[checked]="keyword.grep"
										(change)="updateKeyword(keyword, 'grep')"
								>
									Grep
								</mat-checkbox>

								<!-- Checkbox for similarity keyword toggle -->
								<mat-checkbox
										style="margin-right: 20px;"
										[checked]="keyword.similarity"
										(change)="updateKeyword(keyword, 'similarity')"
								>
									Similarity
								</mat-checkbox>

								<!-- Button to delete keyword -->
								<button (click)="deleteKeyword(j)"
										class='button_delete'>Delete Keyword
								</button>
							</div>
							<div *ngIf="keywordDeleteIndex == j" class="keyword-delete apply-delete-bar" >
								<span>Are you sure you want to delete this keyword?</span>
								<div class='keyword-exclusion-delete-pair'>
									<button class="cancel" (click)="cancelKeywordDelete()"><span>Cancel</span></button>
									<button class="delete" (click)="confirmKeywordDelete(keyword)">Delete</button>
								</div>
							</div>
						</div>

						<!-- Keyword exclusion section -->
						<div class='expander-body'>

							<div class='form-header'>
								<h3>Exclusions</h3>
								<button
									class='new'
									*ngIf="keywordForExclusion !== keyword"
									(click)="showKeywordExclusion(keyword)"
								>
								+ New Exclusion
								</button>
							</div>

							<!-- TODO: Keyword addition -->
							<div class='keyword-input-container' *ngIf="keywordForExclusion === keyword">
								<!-- <input
									type="text"
									placeholder="Enter exclusion"
									[value]="newExclusion"
									(input)="newExclusion = $event.target.value"
								> -->
								<mat-form-field class="example-full-width">
									<input
										matInput
										[(ngModel)]="newExclusion"
										(ngModelChange)="exclusionChange()"
										placeholder="Enter exclusion"
									>
								</mat-form-field>
								<div [attr.data-changestoapply]="exclusionChangesToApply" class='keyword-input-save-container apply-cancel-bar'>
									<div class='keyword-input-save-pair'>
										<button class='cancel' (click)="cancelKeywordExclusion()"><span>Cancel</span></button>
										<button class='apply' (click)="saveKeywordExclusion(keyword)"><span>Save Exclusion</span></button>
									</div>
								</div>
							</div>

							<div class="keyword-exclusion-line" *ngFor="let exclusion of keyword.exclusions; let i = index">
								<div class="keyword-exclusion-text">
									<span>{{exclusion.word}}</span>
									<mat-icon
										(click)="deleteExclusion(i)"
										class='exclusion-delete'
										svgIcon="icon-delete"
									></mat-icon>
								</div>
								<div *ngIf="exclusionDeleteIndex == i" class="keyword-exclusion-delete apply-delete-bar" >
									<span>Are you sure you want to delete this exclusion?</span>
									<div class='keyword-exclusion-delete-pair'>
										<button class="cancel" (click)="cancelExclusionDelete()"><span>Cancel</span></button>
										<button class="delete" (click)="confirmExclusionDelete(keyword, exclusion)">Delete</button>
									</div>
								</div>
							</div>
						</div>

					</mat-expansion-panel>
				</div>
			</div>
		</div>






		<!--DOMAIN PIECE-->
		<div *ngIf='!bindCreate' class='domain-container form-item form-width-threequarters'>

			<div class='form-header'>
				<div class='form-header'>
					<h3>Domains</h3>
					<div class='messaging'>
						<span *ngIf="domainStatus == 'saving'" class='messaging-saving'>saving</span>
						<span *ngIf="domainStatus == 'saved'" class='messaging-saved'>saved</span>
						<span *ngIf="domainStatus == 'fail'" class='messaging-nosave'>did not save</span>
					</div>
				</div>
				<button *ngIf="!addNewDomain" (click)="newDomain()" class="new">+ New Domain</button>
			</div>

			<!--WHAT IS THIS?-->
			<div *ngIf='conflictDomain' class='domain-warning'>warning</div>

			<!--INPUT FOR NEW DOMAIN-->
			<div *ngIf='addNewDomain'>
				<mat-form-field class="example-full-width">
					<input
						matInput
						[(ngModel)]="inputDomain"
						(ngModelChange)="domainChange()"
						placeholder="Enter domain"
					>
				</mat-form-field>
				<div [attr.data-changestoapply]="domainChangesToApply" class='apply-cancel-bar'>
					<button class="cancel" (click)="cancelDomain()"><span>Cancel</span></button>
					<button class="apply" (click)="saveDomain()">Save Domain</button>
				</div>
			</div>

			<!--LIST OF DOMAINS-->
			<div class='domain-list'>
				<div class='domain-item' *ngFor="let domain of domainsData; let i = index">
					<div
							class='domain-item-container'
							[ngClass]="{'item-delete': deleteDomainIndex === i}"
					>
						<!--<div class='domain-stats'>
							<span>{{note.username}}</span> | <span>{{note.time}}</span>
						</div>-->
						<div class='domain-line'>
							<div class='domain-text'>{{domain.domaintld}}</div>
							<mat-icon
								(click)="deleteDomainPrompt(i)"
								class="domain-delete"
								svgIcon="icon-delete"
							></mat-icon>
						</div>
						<div [attr.data-changestoapply]="domainChangesToApply" class='apply-delete-bar'>
							<span class='delete-domain'>Are you sure you want to delete this domain?</span>
							<button class="cancel" (click)="cancelDomainDelete()"><span>Cancel</span></button>
							<button class="delete" (click)="confirmDomainDelete()">Delete Domain</button>
						</div>
					</div>
				</div>
			</div>



		</div>







	</div>
</div>
