import {NgModule, Injectable} from '@angular/core';
import {RouterModule, Routes, Router, CanActivate, ActivatedRouteSnapshot} from '@angular/router';
import {NotificationsComponent} from './dashboard/notifications/notifications.component';
import {LexicoTicketsComponent} from './views/lexico-tickets/lexico-tickets.component';
import {LexicoAliasesComponent} from './views/lexico-aliases/lexico-aliases.component';
import {LexicoAssigneesComponent} from './views/lexico-assignees/lexico-assignees.component';
import {LexicoBroadcastersComponent} from './views/lexico-broadcasters/lexico-broadcasters.component';
import {LexicoNotifiersComponent} from './views/lexico-notifiers/lexico-notifiers.component';
import {LexicoTagsComponent} from './views/lexico-tags/lexico-tags.component';
import {DnsenseDomainsComponent} from './views/dnsense-domains/dnsense-domains.component';
import {DnsenseClientsComponent} from './views/dnsense-clients/dnsense-clients.component';
import {AdministrationComponent} from './dashboard/administration/administration.component';
import { SettingsComponent }      from './dashboard/settings/settings.component';
import {DashboardComponent} from "./dashboard/dashboard.component";
import {DnsenseTakedownComponent} from "./views/dnsense-takedowns/dnsense-takedown.component";
import {ClientService, UserService} from "./_services";
import {LandingPageComponent} from "./views/landing-page/landing-page.component";

@Injectable({
	providedIn: 'root'
})
export class SoteriaGuard implements CanActivate {

	constructor(public router: Router, private _userservice: UserService) {}

	canActivate(): Promise<boolean> {
		return this._userservice.UserIsSoterian();
	}
}

@Injectable({
	providedIn: 'root'
})
export class ClientGuard implements CanActivate {
	constructor(private router: Router, private _clientService: ClientService, public _userservice: UserService) {}

	async canActivate(route: ActivatedRouteSnapshot) {
		if (route.routeConfig && route.routeConfig.path == 'home/lexico/tickets') {
			if (await this.doesClientHaveProduct('lexico')){
				return true
			}

			return this.router.parseUrl('/home/dnsense/domains')
		}

		if (route.routeConfig && route.routeConfig.path == 'home/dnsense/domains') {
			if (await this.doesClientHaveProduct('dnsense')){
				return true
			}

			return this.router.parseUrl('/home/landing')
		}

		return this.router.parseUrl('/home/landing')
	}

	private async doesClientHaveProduct(product: string): Promise<boolean> {
		let client = await this._clientService.GetClient()
		for (let i = 0; i < client.ClientProducts.length; i++) {
			if (product.toLowerCase() === client.ClientProducts[i]["Product"]["product_name"].toLowerCase()){
				return true
			}
		}
		return false
	}

}


const routes: Routes = [
	{
		path: '',
		component: DashboardComponent,
		children: [
			{path: 'home/lexico/tickets', component: LexicoTicketsComponent, canActivate: [ClientGuard]},
			{path: 'home/lexico/aliases', component: LexicoAliasesComponent, canActivate: [SoteriaGuard]},
			{path: 'home/lexico/assignees', component: LexicoAssigneesComponent, canActivate: [SoteriaGuard]},
			{path: 'home/lexico/broadcasters', component: LexicoBroadcastersComponent, canActivate: [SoteriaGuard]},
			{path: 'home/lexico/notifiers', component: LexicoNotifiersComponent, canActivate: [SoteriaGuard]},
			{path: 'home/lexico/tags', component: LexicoTagsComponent, canActivate: [SoteriaGuard]},

			{path: 'home/dnsense/domains', component: DnsenseDomainsComponent, canActivate: [ClientGuard]},
			{path: 'home/dnsense/clients', component: DnsenseClientsComponent, canActivate: [SoteriaGuard]},
			{path: 'home/dnsense/takedowns', component: DnsenseTakedownComponent},
			{path: 'home/dnsense', redirectTo: 'home/dnsense/domains', pathMatch: 'full'},

			{path: 'notifications', component: NotificationsComponent},
			{path: 'administration', component: AdministrationComponent},
			{path: 'settings', component: SettingsComponent},
			{path: 'home/landing', component: LandingPageComponent},
			{path: '**', redirectTo: 'home/lexico/tickets', pathMatch: 'full'},

		]
	},
	{path: '**', redirectTo: ''}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {useHash: true})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
