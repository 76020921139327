<div
		class='dnsense_clients_container'
>
	<layout-panel-clients
			[bindPanelShow]="panelShow"
			[bindData]="selectedItemData"
			[bindCreate]="newClient"
			[bindCreateCallback]="cclientCallback"
			[bindUpdateCallback]="uclientCallback"
			(closePanel)="closePanelSaveData($event)"
	>
	</layout-panel-clients>
	<div class='layout-table'>
		<layout-filter-box
				[bindTitle]="filterBoxTitle"
				[bindButton]="filterBoxButton"
				[bindFeatures]="filterBoxFeatures"
				[bindButtonInclude]=true
				[bindFields]="filterBoxAllFields"
				(buttonClick)="newItem()"
				[bindData]="data"
		>
		</layout-filter-box>
		<content-table
				[bindSort]='"owner"'
				[bindSortDirection]='"asc"'
				[bindData]="data"
				[bindTableConfig]="tableConfig"
				[bindSelectedItemIndex]="selectedItemIndex"
				[bindMessage]="tableMessage"
				(setRow)="selectTableRow($event)"
				(deletedRow)="deleteTableRow($event)"
				[bindIdentifierProp]="'owner_id'"
				[bindRemovalCallback]="rClient"
		>
		</content-table>
	</div>
</div>
