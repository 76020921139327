import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http'
import {Match} from '../_models'
import * as moment from "moment";
import {Moment} from "moment";

// @ts-ignore
const LosslessJSON = require('lossless-json');

@Injectable({
	providedIn: 'root'
})
export class DNSenseService {
	matches: Match[];
	tags: any[];
	startDate: Moment;
	stopDate: Moment;

	constructor(private http: HttpClient) {
		this.matches = [];
		this.tags = [];
		this.startDate = moment().startOf('month');
		this.stopDate = moment().endOf('month');
	}

	async LoadClientList() {
		if (isDevMode()) {
			return await this.http.get('/assets/data/dnsense/dnsense_clients.json', {responseType: 'text'}).toPromise().then((result) => {
				result = LosslessJSON.parse(result);
				return {'clients': result};
			});
		} else {
			return await this.http.get('/dnsense/owner/list', {responseType: 'text'}).toPromise().then((result) => {
				result = LosslessJSON.parse(result);
				return {'clients': result};
			}, (error) => {
				return {error: error};
			});
		}
	}

	async LoadClientInfo(owner_id: string) {
		let params = new HttpParams().set('owner', owner_id);
		if (isDevMode()) {
			return await this.http.get('/assets/data/dnsense/client_info.json', {
				params,
				responseType: 'text'
			}).toPromise().then((result) => {
				result = LosslessJSON.parse(result);
				return {'info': result};
			});
		} else {
			return await this.http.get('/dnsense/owner/info', {
				params,
				responseType: 'text'
			}).toPromise().then((result) => {
				result = LosslessJSON.parse(result);
				return {'info': result};
			}, (error) => {
				return {error: error};
			});
		}
	}

	async CreateClientDomain(owner_id: string, domain: string) {
		let params = new HttpParams().set('owner', owner_id).set('domain', domain);
		if (isDevMode()) {return null;} else {
			return await this.http.post('/dnsense/domain', {}, {
				params,
			}).toPromise().then(() => {
				return null;
			}, (error) => {
				return error;
			});
		}
	}

	async DeleteClientDomain(domain_id: string) {
		let params = new HttpParams().set('domain', domain_id);
		if (isDevMode()) {return null;} else {
			return await this.http.delete('/dnsense/domain', {
				params,
			}).toPromise().then(() => {
				return null;
			}, (error) => {
				return error;
			});
		}
	}

	async CreateClientKeyword(owner_id: string, keyword: string) {
		let params = new HttpParams().set('owner', owner_id).set('keyword', keyword);
		if (isDevMode()) {return null;} else {
			return await this.http.post('/dnsense/keyword', {}, {
				params,
			}).toPromise().then(() => {
				return null;
			}, (error) => {
				return error;
			});
		}
	}

	async UpdateClientKeyword(keyword: string, keytype: string, value: boolean) {
		let params = new HttpParams().set('keyword', keyword).set('keytype', keytype).set('value', value.toString());
		if (isDevMode()) {return null;} else {
			return await this.http.put('/dnsense/keyword', {}, {
				params,
			}).toPromise().then(() => {
				return null;
			}, (error) => {
				return error;
			});
		}
	}

	async DeleteClientKeyword(keyword: string) {
		let params = new HttpParams().set('keyword', keyword);
		if (isDevMode()) {return null;} else {
			return await this.http.delete('/dnsense/keyword', {
				params,
			}).toPromise().then(() => {
				return null;
			}, (error) => {
				return error;
			});
		}
	}

	async CreateKeywordExclusion(exclusion: string, keyword: string) {
		let params = new HttpParams().set('exclusion', exclusion).set('keyword', keyword);
		if (isDevMode()) {return null;} else {
			return await this.http.post('/dnsense/exclusion', {}, {
				params,
			}).toPromise().then(() => {
				return null;
			}, (error) => {
				return error;
			});
		}
	}

	async DeleteKeywordExclusion(exclusion: string) {
		let params = new HttpParams().set('exclusion', exclusion);
		if (isDevMode()) {return null;} else {
			return await this.http.delete('/dnsense/exclusion', {
				params,
			}).toPromise().then(() => {
				return null;
			}, (error) => {
				return error;
			});
		}
	}

	async GetMatches(): Promise<any> {
		if (this.matches.length === 0) {
			return this.UpdateMatches();
		}
		return {matches: this.matches};
	}

	async SetDaterange(month: Moment): Promise<any> {
		this.startDate = moment(month).startOf('month');
		this.stopDate = moment(month).endOf('month');
		return this.UpdateMatches()
	}

	//allows you to get matches for a specific day
	async SetDay(day: Moment, malicious: string): Promise<any>{
		this.startDate = moment(day).startOf('day');
		this.stopDate = moment(day).endOf('day');
		return this.FilterMatches(malicious)
	}

	expandDevSampleData(result) {

		//this takes the sample dates, makes the most recent yesterday, and then works it all backwards
		// gets today's date and timestamp
		var todayDate = new Date();
		var todayStamp = todayDate.getTime()

		//looks at the last in the series and gets timestamp
		var lastDateString = result[result.length - 1].found_on
		var dateTimeParts = lastDateString.split('-');
		dateTimeParts[1] = dateTimeParts[1] - 1
		dateTimeParts[2] = Number(dateTimeParts[2].substr(0, 2))
		var lastDate = new Date(dateTimeParts[0], dateTimeParts[1], dateTimeParts[2]);
		var lastDateStamp = lastDate.getTime()

		//gets difference in timestamp, amount to add to every day in the series
		var diffTimeStamp = todayStamp - lastDateStamp

		// goes through all dates and moves them all up to being current from today I'm testing with
		var originalLength = result.length;
		for (var i = result.length - 1; i >= 0; i--) {
			var resultDate = result[i].found_on
			var resultTimeParts = resultDate.split('-');
			resultTimeParts[1] = resultTimeParts[1] - 1
			resultTimeParts[2] = Number(resultTimeParts[2].substr(0, 2))
			var resultNewDate = new Date(resultTimeParts[0], resultTimeParts[1], resultTimeParts[2]);
			var resultDateStamp = resultNewDate.getTime()
			result[i].timestamp = resultDateStamp + diffTimeStamp
			var reconvertedTimestamp = new Date(resultDateStamp + diffTimeStamp)
			result[i].day = reconvertedTimestamp
			result[i].found_on = reconvertedTimestamp
		}

		// this takes the list of sample dates and duplicates the list to get 100 days worth of data
		// takes the dates and their original timestamps and subtracts 10 days, repeats a bunch of times to get to 100 days
		var timestampSubtract = 864000000
		for (var z = 1; z < 10; z++) {
			var newSet = []
			for (var i = 0; i < originalLength; i++) {
				var newEntry = {
					"timestamp": 0,
					"found_on": null,
					"day": null
				}
				for (var k in result[i]) newEntry[k] = result[i][k];
				newEntry.timestamp = newEntry.timestamp - (timestampSubtract)// add this "*z" to really send to back over a year //*z)
				var newDate = new Date(newEntry.timestamp)
				newEntry.found_on = newDate
				newEntry.day = newDate
				//newEntry.day = newDate
				newSet.push(newEntry)
			}
			for (var j = newSet.length - 1; j >= 0; j--) {
				result.unshift(newSet[j])
			}
		}

	}

	// this gets malicious and suspicious stats
	GetMaliciousStats() {
		let start = moment().subtract(15, 'day').startOf('day');
		let stop = moment().endOf('day');
		let params = new HttpParams().set('start', start.zone("-05:00").toISOString()).set('stop', stop.zone("-05:00").toISOString());
		if (isDevMode()) {
			return this.http.get('/assets/data/dnsense/mal_match_stats.json', {params}).toPromise();
		}
		return this.http.get('/dnsense/stats/malicious', {params}).toPromise();
	}

	// actually nonsuspicous stats. method named wrong
	GetSuspiciousStats() {
		let start = moment().subtract(15, 'day').startOf('day');
		let stop = moment().endOf('day');
		let params = new HttpParams().set('start', start.zone("-05:00").toISOString()).set('stop', stop.zone("-05:00").toISOString());
		if (isDevMode()) {
			return this.http.get('/assets/data/dnsense/nonmal_match_stats.json', {params}).toPromise();
		}
		return this.http.get('/dnsense/stats/nonmalicious', {params}).toPromise();
	}

	async GetMatchSummary(match: Match): Promise<string> {
		if (isDevMode()) {
			let params = new HttpParams().set('domain', match.offending_dtld);
			return await this.http.get<Match>('/assets/data/dnsense/match_info.json', {params}).toPromise().then((result) => {
				if (result.summary) {
					return result.summary;
				} else {
					return "This domain has no analyst summary.";
				}
			}, (error) => {
				return "There was an error fetching the analyst summary for this domain.";
			});
		}

		let params = new HttpParams().set('domain', match.offending_dtld);
		return await this.http.get<Match>('/dnsense/matches/info', {params}).toPromise().then((result) => {
			if (result.summary) {
				return result.summary;
			} else {
				return "This domain has no analyst summary.";
			}
		}, (error) => {
			return "There was an error fetching the analyst summary for this domain.";
		});
	}

	async GetWhois(match: Match): Promise<any> {
		if (isDevMode()) {
			let params = new HttpParams().set('domain', match.offending_dtld);
			return await this.http.get('/assets/data/dnsense/match_whois.json', {params}).toPromise().then( (result) => {
				if (result['raw']){
					return {"result" : result['raw'].replace(/\n/g, '<br>')};
				} else {
					return {"result": "Sorry, we couldn't find whois information for this domain..."};
				}
			},  (error) => {
				return {"error" : "Sorry, there was an error fetching whois information for this domain..."};
			});
		}

		let params = new HttpParams().set('domain', match.offending_dtld);
		return await this.http.get('/webdb/domain/whois', {params}).toPromise().then((result) => {
			if (result['raw']) {
				return {"result" : result['raw'].replace(/\n/g, '<br>')};
			} else {
				return {"result": "Sorry, we couldn't find whois information for this domain..."};
			}
		}, (error) => {
			return {"error" : "Sorry, there was an error fetching whois information for this domain..."};
		});
	}

	async GetScreenshot(domain: string): Promise<any> {
		if (isDevMode()) {
			let params = new HttpParams().set('domain', domain);
			return await this.http.get('/assets/data/dnsense/match_screenshot.txt', {
				params,
				responseType: 'text'
			}).toPromise().then((result) => {
				if (result) {
					return {screenshot: 'data:image/png;base64,' + result};
				} else {
					return {error: "This domain has no screenshot."};
				}
			}, (error) => {
				return {error: JSON.parse(error.error).error};
			});
		}

		let params = new HttpParams().set('domain', domain);
		return await this.http.get('/webdb/domain/screenshot', {
			params,
			responseType: 'text'
		}).toPromise().then((result) => {
			if (result) {
				return {screenshot: 'data:image/png;base64,' + result};
			} else {
				return {error: "This domain has no screenshot."};
			}
		}, (error) => {
				return {error: JSON.parse(error.error).error};
		});
	}

	async GetDNSRecords(match: string): Promise<any> {
		if (isDevMode()) {
			let params = new HttpParams().set('domain', match);
			return await this.http.get('/assets/data/dnsense/dnsrecords.json', {params}).toPromise().then((result) => {
				return {records: result};
			}, (error) => {
				return {error: error};
			});
		}

		let params = new HttpParams().set('domain', match);
		return await this.http.get('/webdb/domain/dnsrecords', {params}).toPromise().then((result) => {
			return {records: result};
		}, (error) => {
			return {error: error};
		});
	}

	async GetTagList() {
		if (isDevMode()) {
			return ['alligator', 'berm', 'cat', 'debug', 'eagle', 'elegant', 'malestorm', 'none', 'winter', 'yak', 'zoo'];
		} else {
			let promise = this.http.get('/dnsense/tag/list', {}).toPromise();
			return await promise.then((result) => {
				let tagResults = <any[]>result;
				let output = [];
				for (let i = 0; i < tagResults.length; i++) {
					output.push(tagResults[i]['tag']);
				}
				this.tags = Array.from(output);
				return this.tags;
			}).catch(() => {
				return null;
			});
		}
	}

	async AddTag(domain: string, tag: string, canCreate = false) {
		if (isDevMode()) {
			return null;
		}

		// Create tag if it doesn't exist and the user has the correct permission
		if (!this.tags.includes(tag) && !canCreate) {
			return 'cannot create tag';
		} else if (!this.tags.includes(tag) && canCreate) {
			let params = new HttpParams().set('tag', tag);
			let promise = this.http.post('/dnsense/tag', null, {params}).toPromise();

			let err = await promise.then(() => {
				this.tags.push(tag);
				return null;
			}).catch(() => {
				return 'error creating tag';
			});

			if (err) {
				return err;
			}
		}

		// Add tag to domain
		let params = new HttpParams().set('tag', tag).set('domain', domain);
		let promise = this.http.post('/dnsense/matches/tag', null, {params}).toPromise();
		return await promise.then(() => {
			return null;
		}).catch(() => {
			return 'failed to add tag to domain';
		});
	}

	async RemoveTag(domain: string, tag: string) {
		if (isDevMode()) {
			return null;
		}

		let params = new HttpParams().set('tag', tag).set('domain', domain);
		let promise = this.http.delete('/dnsense/matches/tag', {params}).toPromise();
		return await promise.then(() => {
			return null;
		}).catch(() => {
			return 'failed to remove tag from domain';
		});
	}

	async CreateClient(client: string) {
		if (isDevMode()) {
			return {client: {owner_id: {value: 'testownerid'}}};
		}

		let params = new HttpParams().set('owner', client);
		let promise = this.http.post('/dnsense/owner', null, {params, responseType: 'text'}).toPromise();
		return await promise.then((result) => {
			result = LosslessJSON.parse(result);
			return {client: result};
		}).catch(() => {
			return {error: 'failed to update client'};
		});
	}

	async UpdateClientPayment(client: string, payment: boolean) {
		if (isDevMode()) {
			return null;
		}

		let params = new HttpParams().set('owner', client).set('paying', payment.toString());
		let promise = this.http.put('/dnsense/owner/payment', null, {params}).toPromise();
		return await promise.then(() => {
			return null;
		}).catch(() => {
			return 'failed to update client';
		});
	}

	async DeleteClient(client: string) {
		if (isDevMode()) {
			return null;
		}

		let params = new HttpParams().set('owner', client);
		let promise = this.http.delete('/dnsense/owner', {params}).toPromise();
		return await promise.then(() => {
			return null;
		}).catch(() => {
			return 'failed to delete client';
		});
	}

	async DomainTags(domain: string) {
		if (isDevMode()) {
			return {tags: Array.from(['eagle', 'winter', 'yak'])};
		} else {
			let params = new HttpParams().set('domain', domain);
			let promise = this.http.get('/dnsense/matches/tag/list', {params}).toPromise();
			return await promise.then((result) => {
				let tagResults = <string[]>result;
				let tags = [];
				for (let i = 0; i < tagResults.length; i++) {
					tags.push(tagResults[i]);
				}
				return {tags: Array.from(tags)};
			}).catch((error) => {
				return {error: error};
			});
		}
	}

	async DomainInternalNotes(domain: string) {
		if (isDevMode()) {
			return {
				notes: Array.from([
					{
						note_id: "312515170237546493",
						username: 'username 1',
						time: 'xx/xx/xxxx',
						note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
					},
					{
						note_id: "312515170237546492",
						username: 'username 2',
						time: 'xx/xx/xxxx',
						note: 'Lorem ipsum'
					},
				])
			};
		} else {
			let params = new HttpParams().set('offending_dtld', domain);
			let promise = this.http.get('/dnsense/notes', {params}).toPromise();
			return await promise.then((result) => {
				return {notes: Array.from(<any[]>result)};
			}).catch((error) => {
				return {error: error};
			});
		}
	}

	async CreateDomainInternalNote(note: string, domain: string) {
		let params = new HttpParams().set('note', note).set('offending_dtld', domain);
		let promise = this.http.post('/dnsense/notes', {}, {params}).toPromise();
		return await promise.then(() => {
			return null;
		}).catch(() => {
			return 'failed to create note';
		});
	}

	async DeleteDomainInternalNote(note: string) {
		let params = new HttpParams().set('note', note);
		let promise = this.http.delete('/dnsense/notes', {params}).toPromise();
		return await promise.then(() => {
			return null;
		}).catch(() => {
			return 'failed to delete note';
		});
	}

	async DomainComment(domain: string) {
		if (isDevMode()) {
			return {
				comments: Array.from([
					{
						comment_id: "312515170237546493",
						username: 'username 1',
						time: 'xx/xx/xxxx',
						comment: 'comment 1'
					},
					{
						comment_id: "312515170237546492",
						username: 'username 2',
						time: 'xx/xx/xxxx',
						comment: 'comment 2'
					},
				])
			};
		} else {
			let params = new HttpParams().set('offending_dtld', domain);
			let promise = this.http.get('/dnsense/comments', {params}).toPromise();
			return await promise.then((result) => {
				return {comments: Array.from(<any[]>result)};
			}).catch((error) => {
				return {error: error};
			});
		}
	}

	async CreateDomainComment(comment: string, domain: string) {
		let params = new HttpParams().set('comment', comment).set('offending_dtld', domain);
		let promise = this.http.post('/dnsense/comments', {}, {params}).toPromise();
		return await promise.then(() => {
			return null;
		}).catch(() => {
			return 'failed to create comment';
		});
	}

	async DeleteDomainComment(comment: string) {
		let params = new HttpParams().set('comment', comment);
		let promise = this.http.delete('/dnsense/comments', {params}).toPromise();
		return await promise.then(() => {
			return null;
		}).catch(() => {
			return 'failed to delete comment';
		});
	}


	async DomainExternalNotes(domain: string) {
		if (isDevMode()) {
			return {
				notes: Array.from([
					{
						note_id: "312515170237546493",
						username: 'username 1',
						time: 'xx/xx/xxxx',
						note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
					},
					{
						note_id: "312515170237546492",
						username: 'username 2',
						time: 'xx/xx/xxxx',
						note: 'Lorem ipsum'
					},
				])
			};
		} else {
			let params = new HttpParams().set('offending_dtld', domain);
			let promise = this.http.get('/dnsense/notes/client', {params}).toPromise();
			return await promise.then((result) => {
				return {notes: Array.from(<any[]>result)};
			}).catch((error) => {
				return {error: error};
			});
		}
	}

	async CreateDomainExternalNote(note: string, domain: string) {
		let params = new HttpParams().set('note', note).set('offending_dtld', domain);
		let promise = this.http.post('/dnsense/notes/client', {}, {params}).toPromise();
		return await promise.then(() => {
			return null;
		}).catch(() => {
			return 'failed to create note';
		});
	}

	async DeleteDomainExternalNote(note: string) {
		let params = new HttpParams().set('note', note);
		let promise = this.http.delete('/dnsense/notes/client', {params}).toPromise();
		return await promise.then(() => {
			return null;
		}).catch(() => {
			return 'failed to delete note';
		});
	}

	async UpdateDomainStatus(domain: string, status: string) {
		if (isDevMode()) {
			return null;
		}

		let params = new HttpParams().set('offending_dtld', domain).set('status', status);
		return await this.http.put('/dnsense/matches/status', null, {
			params,
			responseType: 'text'
		}).toPromise().then(() => {
			return null;
		}).catch((error) => {
			return error;
		});
	}

	async UpdateDomainTakedown(domain: string, takedown: string) {
		if (isDevMode()) {
			return null;
		}

		let params = new HttpParams().set('domain', domain).set('tstatus', takedown);
		return await this.http.post('/dnsense/matches/takedown', null, {
			params,
			responseType: 'text'
		}).toPromise().then(() => {
			return null;
		}).catch((error) => {
			return error;
		});
	}

	async DomainTakedown(domain: string) {
		let params = new HttpParams().set('domain', domain);
		return await this.http.post('/dnsense/takedown', null, {
			params,
			responseType: 'text'
		}).toPromise().then(() => {
			return null;
		}).catch((error) => {
			return error;
		});
	}

	private async FilterMatches(malicious): Promise<any> {
		let params = new HttpParams().set('start', this.startDate.format()).set('stop', this.stopDate.format()).
		set('malicious', malicious);
		if (isDevMode()) {
			return await this.http.get<Match[]>('/assets/data/dnsense/matches.json', {params}).toPromise().then((result) => {
				this.expandDevSampleData(result);
				for (let i = 0; i < result.length; i++) {
					//if not malicious/suspicious, delete malicous or suspicious domain
					if (malicious === 'false' && (result[i].takedown === 'Confirmed Malicious' ||
						result[i].takedown === 'Malicious' || result[i].takedown === 'Suspicious'
					)) {
						result.splice(i, 1);
					}
					// if malicious, delete non malicious/suspicious domain
					if(malicious === 'true' && (result[i].takedown !== 'Confirmed Malicious' ||
						result[i].takedown !== 'Malicious' || result[i].takedown !== 'Suspicious'
					)){
						result.splice(i, 1);
					}
					if (!result[i].takedown) {
						result[i].takedown = 'uninitiated'
					}
				}
				this.matches = result;
				return {matches: this.matches};
			}, (error) => {
				return {error: error}
			});
			return
		}

		// for real API call
		return await this.http.get<Match[]>('/dnsense/matches/filter', {params}).toPromise().then((result) => {
			for (let i = 0; i < result.length; i++) {
				if (!result[i].takedown) {
					result[i].takedown = 'uninitiated'
				}
			}
			this.matches = result;
			return {matches: this.matches};
		}, (error) => {
			return {error: error}
		});
	}

	private async UpdateMatches(): Promise<any> {
		let params = new HttpParams().set('start', this.startDate.format()).set('stop', this.stopDate.format());
		if (isDevMode()) {
			return await this.http.get<Match[]>('/assets/data/dnsense/matches.json', {params}).toPromise().then((result) => {
				this.expandDevSampleData(result);
				for (let i = 0; i < result.length; i++) {
					if (!result[i].takedown) {
						result[i].takedown = 'uninitiated'
					}
				}
				this.matches = result;
				return {matches: this.matches};
			}, (error) => {
				return {error: error}
			});
			return
		}

		// for real API call
		return await this.http.get<Match[]>('/dnsense/matches', {params}).toPromise().then((result) => {
			for (let i = 0; i < result.length; i++) {
				if (!result[i].takedown) {
					result[i].takedown = 'uninitiated'
				}
			}
			this.matches = result;
			return {matches: this.matches};
		}, (error) => {
			return {error: error}
		});
	}

	async Monitoring(domain: string, monitor: boolean) {
		if (isDevMode()) {
			return null;
		}

		let params = new HttpParams().set('offending_dtld', domain).set('monitor', monitor.toString());
		return await this.http.post('/dnsense/monitor', null, {
			params,
			responseType: 'text'
		}).toPromise().then(() => {
			return null;
		}).catch((error) => {
			return error;
		});
	}

	async SearchMatch(domain: string){
		if (isDevMode()){
			return {match: [{
					"day": "2020-03-26T12:57:40.891439Z",
					"final_score": "MC4zOTMzNTQyODY5ODU2ODI2",
					"flags": "{}",
					"found_on": "2020-07-08T00:00:00Z",
					"keyword": "test234234",
					"offending_dtld": domain.toUpperCase(),
					"owner": "",
					"paying": true,
					"priority": "MC40NTE2MzgzMzAxMjY0NzE2",
					"status": "Malicious",
					"takedown": "uninitiated"
				}]}
		}

		let params = new HttpParams().set('offending_dtld', domain.toUpperCase());
		return await this.http.get('/dnsense/match', {params}).toPromise().then((result) => {
			return {match: result};
		}, (error) => {
			return {error: error}
		});
	}

	async AddMatch(domain: string, keywordId: Number){
		if (isDevMode()){
			return {match: [{
					"day": "2020-03-26T12:57:40.891439Z",
					"final_score": "MC4zOTMzNTQyODY5ODU2ODI2",
					"flags": "{}",
					"found_on": "2020-07-08T00:00:00Z",
					"keyword": "test234234",
					"offending_dtld": domain.toUpperCase(),
					"owner": "",
					"paying": true,
					"priority": "MC40NTE2MzgzMzAxMjY0NzE2",
					"status": "New",
					"takedown": "uninitiated"
				}]};
		}

		let params = new HttpParams().set('offending_dtld', domain.toUpperCase()).set('keyword', keywordId.toString());
		return await this.http.post('/dnsense/match', {}, {
			params,
		}).toPromise().then((result) => {
			return {match: result};
		}, (error) => {
			return {error: error}
		});

	}

	async ListKeywords() {
		if (isDevMode()) {
			return await this.http.get('/assets/data/dnsense/keywords.json').
			toPromise().then((result) => {
				return {'keywords': result};
			});
		} else {
			return await this.http.get('/dnsense/keyword/list', {responseType: 'text'}).
			toPromise().then((result) => {
				result = LosslessJSON.parse(result);
				return {'keywords': result};
			}, (error) => {
				return {error: error};
			});
		}
	}

	async LoadTakedownList() {
		if (isDevMode()) {
			return await this.http.get('/assets/data/dnsense/dnsense_takedowns.json', {responseType: 'text'}).toPromise().then((result) => {
				result = LosslessJSON.parse(result);
				return {'takedowns': result};
			});
		} else {
			return await this.http.get('/dnsense/takedown/list', {responseType: 'text'}).toPromise().then((result) => {
				result = LosslessJSON.parse(result);
				return {'takedowns': result};
			}, (error) => {
				return {error: error};
			});
		}
	}

}
