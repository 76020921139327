<div class='table-container'>
	<div class='error-container' *ngIf="bindMessage">
		<div>{{bindMessage}}</div>
	</div>
	<mde-popover
        #appPopover="mdePopover"
		[mdePopoverOverlapTrigger]="false"
		[mdePopoverCloseOnClick]
		(close)="deselectRowDelete()"
	>
		<mat-card style="max-width: 300px">
			<mat-card-content>
				<div class='popover-main'>
					Are you sure you want to delete this item?
				</div>
				<div class='popover-button-row'>
					<button id='popover-cancel'>Cancel</button>
					<button (click)="applyDelete()" id='popover-delete'>Delete</button>
				</div>
			</mat-card-content>
		</mat-card>
	</mde-popover>
	<mat-table
			[hidden]="bindMessage !== null || !bindData"
			matSort
			multiTemplateDataRows
			matSortActive={{bindSort}}
			matSortDirection={{bindSortDirection}}
			(matSortChange)="table.renderRows(); updateSort()"
			[dataSource]="bindData"
	>

		<ng-container matColumnDef="bulk-select">
			<mat-header-cell style="max-width: 50px;" *matHeaderCellDef>Select</mat-header-cell>
			<mat-cell style="max-width: 50px;" *matCellDef="let cellData; let i = dataIndex;">
				<mat-checkbox [checked]="bindBulkSelected.indexOf(cellData[bindBulkIdentifier]) >= 0" (change)="toggleSelected($event, cellData[bindBulkIdentifier])"></mat-checkbox>
			</mat-cell>
		</ng-container>

		<div *ngFor="let column of bindTableConfig.columns">
			<ng-container matColumnDef={{column.field}}>
				<mat-header-cell [ngStyle]="{'max-width.px': column.maxWidth}" *matHeaderCellDef mat-sort-header>
					{{column.header}}
				</mat-header-cell>
				<mat-cell [ngStyle]="{'max-width.px': column.maxWidth}" *matCellDef="let cellData, let i = dataIndex" (click)="bindEditable === true && selectRow($event, cellData, i);">
					<span class="mobile-label">{{column.header}}</span>
					<mat-icon *ngIf="cellData[column.field+'_icon']" [inline]="true"
							  svgIcon="{{cellData[column.field+'_icon']}}" class="cell-icon"></mat-icon>
					<span>{{  formatValue(cellData[column.field], column.field)  }}</span>
				</mat-cell>
			</ng-container>
		</div>

		<ng-container matColumnDef="delete">
			<mat-header-cell class='icon-cell' *matHeaderCellDef mat-sort-header>Delete</mat-header-cell>
			<mat-cell
					*matCellDef="let cellData; let i = dataIndex;"
					class='icon-cell'
			>
				<div *ngIf='deleteFailed == i' class='icon-cell-warning'>did not delete</div>
				<mat-icon
						[mdePopoverTriggerFor]="appPopover"
						mdePopoverTriggerOn="click"
                        #popoverTrigger="mdePopoverTrigger"
						(click)="deleteItem($event, cellData, i)"
						class="icon-delete" svgIcon="icon-delete"
				></mat-icon>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="arrow">
			<mat-header-cell fxFlex="20px;" *matHeaderCellDef mat-sort-header></mat-header-cell>
			<mat-cell fxFlex="20px;" *matCellDef="let cellData, let i = dataIndex" (click)="bindEditable === true && selectRow($event, cellData, i);">
				<mat-icon [inline]="true" class="right-arrow">arrow_forward_ios</mat-icon>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row
			*matRowDef="let match; let i = dataIndex; columns: displayedColumns;"
			class='data-row'
			[ngClass]="{'match-row-selected': i === bindSelectedItemIndex, 'match-row-delete': i === deleteIndex, 'match-row-disabled': bindEditable === false}"
		>
		</mat-row>
	</mat-table>
	<mat-paginator [hidden]="!(bindData && bindData.filteredData.length > 0)" showFirstLastButtons [pageSize]="20" [hidePageSize]="true"></mat-paginator>
	<div class='loading-container' *ngIf="loading && bindMessage==null">
		<mat-spinner mode="indeterminate"></mat-spinner>
	</div>
</div>
