import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';

@Component({
	selector: 'layout-panel',
	templateUrl: './layout-panel.component.html',
	styleUrls: ['./layout-panel.component.css']
})
export class LayoutPanelComponent implements OnInit {
	changesToApply = false;
	preApplyState = null;
	error = false;
	newItem = false;

	@HostBinding('class.my-domain-data') @Input()
	bindTitle = null;
	@HostBinding('class.my-domain-data') @Input()
	bindFeatures = null;
	@HostBinding('class.my-domain-data') @Input()
	bindPanelShow = null;
	@HostBinding('class.my-domain-data') @Input()
	bindSaveWording = null;
	@HostBinding('class.my-domain-data') @Input()
	bindSelectedData = null;
	@HostBinding('class.my-domain-data') @Input()
	bindIdentifierProp = null;

	// bindCreateCallback is a function that takes the object that is to be created and creates the object on the
	// backend. If this succeeds, it should return a null value. Any non-null value is a failure condition.
	@HostBinding('class.my-domain-data') @Input()
	bindCreateCallback = async (obj) => { return obj };

	// bindUpdateCallback is a function that takes an object anf updates the backend with all fields on that object.
	// If this succeeds, it should return a null value. Any non-null value is a failure condition.
	@HostBinding('class.my-domain-data') @Input()
	bindUpdateCallback = async (obj) => { return obj };

	@Output() closePanel: EventEmitter<any> = new EventEmitter();
	// @Output() bindCreateCallback: EventEmitter<any> = new EventEmitter();

	constructor() {
	}

	ngOnInit() {
	}

	ngOnChanges() {
		// resets the pre-apply preApplyState
		this.preApplyState = JSON.parse(JSON.stringify(this.bindSelectedData));
		this.bindSelectedData = JSON.parse(JSON.stringify(this.bindSelectedData));
		this.changesToApply = false;
		this.error = false;

		// needs to know if this is new or existing for later,
		// swap out ID with a passed in property
		this.newItem = this.bindSelectedData && this.bindSelectedData[this.bindIdentifierProp] === undefined;
	}

	panelArrayUpdate($event) {
		this.preApplyState[$event.target] = $event.value;
		this.checkForApply();
	}

	checkForApply() {
		this.changesToApply = (JSON.stringify(this.preApplyState) != JSON.stringify(this.bindSelectedData));
	}



	// this will close the panel but not reset anything so that the guts
	// don't get jarring as you're fading out
	// reset is done on the panel getting new data
	cancelPanel() {
		this.closePanel.emit(
			{
				action: 'cancel'
			}
		);
	}

	async applyPanel() {
		//take out any spaces for underscores
		if (this.preApplyState.name !== undefined) {
			this.preApplyState.name = this.preApplyState.name.replace(" ", "_");
		}

		// If the item is new, handle it accordingly
		let err;
		if (this.newItem) {
			// err = await this.bindCreateCallback(this.preApplyState);
			err = await this.bindCreateCallback(this.preApplyState);
		} else {
			err = await this.bindUpdateCallback(this.preApplyState);
		}

		// handle error gracefully
		if (err) {
			this.error = true;
		} else {
			this.bindSelectedData = this.preApplyState;
			this.closePanel.emit(
				{
					action: 'apply',
					newData: this.bindSelectedData
				}
			);
		}
	}
}
