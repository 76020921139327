<div class='container-list'>
	<div class='list-header-row'>
		<div class='list-title'>{{bindTitle}}</div>
		<button *ngIf='showNewButton && bindNewInclude' (click)="addNew()">{{bindNewText}}</button>
	</div>
	<div *ngIf='showInstructions' class='list-empty'>
		<p>{{bindEmptyText}}</p>
	</div>
	<div class='saving-status'>
		<div *ngIf='saveStatus == "unsaved"' id='status-unsaved'>unsaved</div>
		<div *ngIf='saveStatus == "saving"' id='status-saving'>saving</div>
		<div *ngIf='saveStatus == "saved"' id='status-saved'>saved</div>
		<div *ngIf='saveStatus == "nosave"' id='status-nosave'>did not save</div>
	</div>
	<div *ngIf='showNew'
		 class='list-item-row'
		 [ngClass]="{'email-invalid': indexInvalid}"
	>
		<div
				*ngIf="bindType == 'screenshot'"
				class='screenshot-input'>
			<div
					class='screenshot-drop-area'
					(dragenter)="dragEnter($event)"
					(dragover)="dragEnter($event)"
					(dragleave)="dragLeave($event)"
					(drop)="dragDrop($event)"
					[attr.hover-over]='hoverOver'
			>
				<div>
					{{screenshotMessage}}
				</div>
			</div>
		</div>
		<ui-input
				*ngIf="bindType == 'input'"
				bindPlaceholder="Email Address"
				bindFocusOnInit=true
				(newValue)="editItem($event)"
		></ui-input>
		<ui-textarea
				*ngIf="bindType == 'textArea'"
				bindPresetValue=''
				bindFocusOnInit=true
				bindPlaceholder={{bindPlaceholder}}
				(newValue)="editItem($event)"
		></ui-textarea>
		<div
				[attr.input-type]='bindType'
				class='list-item-applysave'
		>
			<div class='list-validataion-message'>{{errorMessage}}</div>
			<div
					class="button-row"
					[ngClass]="{'cancel-save': newItemSave=='true', 'cancel-save-disabled' : newItemSave=='false', 'cancel-save-disabled-all' : newItemSave=='both'}"
			>
				<button (click)="cancelItem()" class='button-cancel'>Cancel</button>
				<button *ngIf="bindType !== 'screenshot'" (click)="saveItem()" class='button-save'>Save</button>
			</div>
		</div>
	</div>
	<div *ngIf='showList' class='list-body'>
		<div class='list-item-row' *ngFor="let item of itemList; let i = index">
			<div
					*ngIf='bindDisplayFields'
					class='list-extra-fields'
			>
        <span *ngFor="let field of bindDisplayFields; let j = index">
          {{     formatValue(bindPresetValue[i][field])     }}
			<span *ngIf='j !== bindDisplayFields.length-1'> |</span>
        </span>
			</div>
			<div
					class='list-item-container'
					[ngClass]="{'item-delete': indexDelete === i}"
			>
				<div class='list-item-text'>{{item}}</div>
				<mat-icon
						*ngIf='bindDelete'
						(click)="deleteItem(i)"
						class="icon-delete"
						svgIcon="icon-delete"
				></mat-icon>
			</div>
			<div *ngIf='indexDelete === i'
				 class='list-item-delete'
				 [ngClass]="{'delete-disable': deleteDisable}"
			>
				<div class='list-delete-message'>Are you sure you want to delete this item?</div>
				<div class="button-row">
					<button (click)="cancelDelete()" class='button-cancel'>Cancel</button>
					<button (click)="applyDelete()" class='button-delete'>Delete</button>
				</div>
			</div>
		</div>
	</div>
</div>
