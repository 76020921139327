import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Component({
	selector: 'layout-tickets-alerts',
	templateUrl: './layout-tickets-alerts.component.html',
	styleUrls: ['./layout-tickets-alerts.component.css']
})
export class LayoutTicketsAlertsComponent implements OnInit, OnDestroy {
	combinedEventsAlerts = [];
	mixEventAlert = false;

	@HostBinding('class.my-domain-data') @Input()
	bindPanelAlerts = [];
	@HostBinding('class.my-domain-data') @Input()
	bindPanelEvents = [];
	@HostBinding('class.my-domain-data') @Input()
	bindPanelSource = null;
	@HostBinding('class.my-domain-data') @Input()
	bindSoteriaUser = null;
	@HostBinding('class.my-domain-data') @Input()
	bindTicketID = '';

	@Output() setRow: EventEmitter<any> = new EventEmitter();

	constructor() {
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		this.bindPanelAlerts = [];
		this.bindPanelEvents = [];
	}

	ngOnChanges() {
		let alertsPresent = false;
		let eventsPresent = false;

		//clears out existing array
		for (let i = this.combinedEventsAlerts.length - 1; i >= 0; i--) {
			this.combinedEventsAlerts.splice(i, 1);
		}

		// combines into one single array
		if (this.bindPanelAlerts !== undefined) {
			alertsPresent = true;
			for (let i = 0; i < this.bindPanelAlerts.length; i++) {
				this.bindPanelAlerts[i].rowType = "alert";
				this.combinedEventsAlerts.push(this.bindPanelAlerts[i]);
			}
		}

		this.mixEventAlert = alertsPresent && eventsPresent;
	}

	selectTableRow($event) {
		//emits details up to parent
		this.setRow.emit($event);
	}
}
