import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material";
import {HttpClient} from '@angular/common/http'
import {LexicoService} from "../../_services";
import {MediaObserver} from '@angular/flex-layout';
import {Alias} from "../../_models";

@Component({
	selector: 'lexico-aliases',
	templateUrl: './lexico-aliases.component.html',
	styleUrls: ['./lexico-aliases.component.css']
})
export class LexicoAliasesComponent implements OnInit {
	// basic static vars that would appear in multiple views
	panelShow = null;
	selectedItemData = null;
	selectedItemIndex = null;
	tableMessage = null;
	// array of unique emails for the multi-select
	// decided to NOT do this in the component itself since it meant sending the whole table in
	// possibly can refactor in the future to let the component just do this on it's own
	// also a possible issue that the table filters, does this list change as well if we do that?
	emailMultiSelectUnique = [];
	// first array is columns
	// feature array inside is individual filter
	// that go down the column
	filterBoxFeatures = [
		{
			"filterList": [
				{
					type: "input",
					filterTarget: "all",
					default: "",
					placeholder: "Text Filter (on any column)",
					uniqueID: 'text filter'
				}
			]
		},
		{
			"filterList": [
				{
					type: "ui-multiselect",
					filterTarget: "to",
					default: [],
					placeholder: "By Emails",
					uniqueID: 'email list filter',
					values: this.emailMultiSelectUnique
				}
			]
		},
		{
			"filterList": []
		}
	];
	filterBoxTitle = "Aliases List";
	filterBoxButton = "+ New Alias";
	filterBoxAllFields = ["name", "to"];
	// for the table, config is how it's arranged
	// sample data is temp until we can get some
	tableConfig = {
		"columns": [
			{
				"header": "Name",
				"field": "name",
				"maxWidth": 200
			},
			{
				"header": "To",
				"field": "to",
				"maxWidth": null
			}
		],
		"delete": true,
		"testing": false
	};
	data = null;
	//for the panel that comes out
	// array of rows, each feature is a row
	// this is like filter box except these are rows of inputs instead of columns
	panelTitle = "";
	panelSaveWording = "";
	panelFeatures = [
		{
			"panelList": [
				{
					type: "input",
					dataProp: "name",
					placeholder: "Alias Name"
				},
				{}
			]
		},
		{
			"panelList": [
				{
					type: "list",
					dataProp: "to",
					listTitle: "Email List (to)",
					placeholder: "Email",
					buttonName: "+ New Email"
				},
				{}
			]
		}
	];
	identifierProp = 'id';

	constructor(private http: HttpClient, public _lexicoService: LexicoService, public media: MediaObserver) {
	}

	updateAliases(data: Alias[]) {
		if (data !== null && data.length == 0) {
			this.tableMessage = 'no data in this time range';
		} else if (data !== null) {
			this.tableMessage = null;
			this.data = new MatTableDataSource(data);

			// makes the multi-select email list
			for (let i = 0; i < this.data.data.length; i++) {
				for (let k = 0; k < this.data.data[i]["to"].length; k++) {
					const myItem = this.data.data[i]["to"][k];
					if (this.emailMultiSelectUnique.indexOf(myItem) == -1) {
						this.emailMultiSelectUnique.push(myItem);
					}
				}
				//this.filterListAssignee.sort()
				this.emailMultiSelectUnique.sort(function (a, b) {
					return a.toLowerCase().localeCompare(b.toLowerCase());
				});
			}
		}
	}

	async ngOnInit() {
		let data = await this._lexicoService.GetAliases();
		if (data['error']) {
			this.tableMessage = 'error loading data';
		} else if (!data['aliases']) {
			this.tableMessage = 'no data in this time range';
		} else {
			this.updateAliases(<Alias[]>data['aliases']);
		}
	}

	// for closing the panel, always closes but does API things
	// this would appear in my different views
	closePanelSaveData($event) {
		const closeAction = $event.action;
		if (closeAction === "apply") {
			//if this is a new one pushes into array
			if (this.selectedItemIndex == null) {
				if (this.data == null) {
					const emptyArray = [];
					this.data = new MatTableDataSource(emptyArray);
					this.tableMessage = null;
				}
				this.data.data.push($event.newData);
				this.data.data = this.data.data.slice();
			}
			//if this is an old one updates
			else {
				const selectedIndex = this.data.data.indexOf(this.selectedItemData);
				this.data.data[selectedIndex] = $event.newData;
			}
		}

		// does the closing action and resets things
		this.selectedItemData = null;
		this.selectedItemIndex = null;
		this.panelShow = false;

		// checks emails again and adds any new ones
		if (this.data !== null) {
			this.emailMultiSelectUnique.splice(0, this.emailMultiSelectUnique.length);
			for (let i = 0; i < this.data.data.length; i++) {
				for (let k = 0; k < this.data.data[i]["to"].length; k++) {
					const myItem = this.data.data[i]["to"][k];
					if (this.emailMultiSelectUnique.indexOf(myItem) == -1) {
						this.emailMultiSelectUnique.push(myItem);
					}
				}
				this.emailMultiSelectUnique.sort(function (a, b) {
					return a.toLowerCase().localeCompare(b.toLowerCase());
				});
			}
		}
	}

	// opens panel to make a new item
	// this would appear in many different views
	newItem() {
		this.selectedItemIndex = null;
		this.panelShow = true;
		this.panelTitle = "New Alias";
		this.panelSaveWording = "Save New Alias";

		// temp tries making new alias
		this.selectedItemData = {
			'name': '',
			'to': []
		};
	}

	// for getting a selected table row
	// this would appear in many different views
	selectTableRow($event) {
		const match = $event.emitMatch;
		const index = $event.emitIndex;

		// if row already selected, closes it
		if (index == this.selectedItemIndex) {
			this.selectedItemData = null;
			this.selectedItemIndex = null;
			this.panelShow = false;
		} else {
			this.selectedItemData = match;
			this.selectedItemIndex = index;
			this.panelShow = true;
			this.panelTitle = "Edit Alias";
			this.panelSaveWording = "Apply Changes";
		}
	}

	deleteTableRow($event) {
		if ($event.emitLength == 0) {
			this.tableMessage = 'no data in this time range';
			this.data = null;
		}
	}

	rAlias = this.removeAlias(this._lexicoService);
	removeAlias(lexicoService) {
		return async (identifier) => {
			return await lexicoService.DeleteAlias(identifier);
		}
	}

	// Hacky work-around to get the update to stop breaking within the panel.
	// For some reason, if you pass a function call rather than a function reference
	// the panel update craps out.
	caliasCallback = this.createAlias(this._lexicoService);
	createAlias(lexicoService) {
		return async (obj) => {
			return await lexicoService.CreateAlias(obj);
		}
	}

	// Hacky work-around to get the update to stop breaking within the panel.
	// For some reason, if you pass a function call rather than a function reference
	// the panel update craps out.
	ualiasCallback = this.updateAlias(this._lexicoService);
	updateAlias(lexicoService) {
		return async (obj) => {
			return await lexicoService.UpdateAlias(obj);
		}
	}
}
