import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { HttpClient, HttpParams } from "@angular/common/http";

@Component({
  selector: "ui-dropdown",
  templateUrl: "./ui-dropdown.component.html",
  styleUrls: ["./ui-dropdown.component.css"],
})
export class UiDropdownComponent implements OnInit {
  controlAutocomplete = new FormControl();
  selectedValue = null;
  savedValue = null;
  saveForAPIErrorValue = null;
  saveStatus = null;
  apiConnect = "something";

  @HostBinding("class.selected-domain-id")
  @Input()
  bindData = null;
  @HostBinding("class.selected-domain-id")
  @Input()
  bindDataField = null;
  @HostBinding("class.selected-domain-id")
  @Input()
  bindPlaceholder = null;
  @HostBinding("class.selected-domain-id")
  @Input()
  bindTarget = "";
  @HostBinding("class.selected-domain-id")
  @Input()
  bindOptions = null;
  @HostBinding("class.selected-domain-id")
  @Input()
  bindUniqueID = "";
  @HostBinding("class.selected-domain-id")
  @Input()
  bindDefaultValue = "";
  @HostBinding("class.selected-domain-id")
  @Input()
  bindIcons = null;
  @HostBinding("class.selected-domain-id")
  @Input()
  bindPresetValue = null;
  @HostBinding("class.selected-domain-id")
  @Input()
  bindAPI = null;
  @HostBinding("class.selected-domain-id")
  @Input()
  bindIdentifier = null;
  @Input() disabled = null;

  @Output() sendValue: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient) {
    let refMain = this;

    this.controlAutocomplete.valueChanges.subscribe((val) => {
      if (val == "") {
        refMain.selectedValue = null;
        refMain.savedValue = null;
      }
    });
  }

  ngOnInit() {
    if (this.bindDefaultValue) {
      this.selectedValue = this.bindDefaultValue;
      this.savedValue = this.bindDefaultValue;
      this.saveForAPIErrorValue = this.bindDefaultValue;
      // emits value up for filter box and other things
      this.sendValue.emit({
        value: this.savedValue,
        target: this.bindTarget,
        uniqueID: this.bindUniqueID,
        formControl: this.controlAutocomplete,
      });
    }
  }

  ngOnChanges() {
    // if any preset value applies it now
    if (this.bindData !== null) {
      this.selectedValue = this.bindData[this.bindDataField];
      this.savedValue = this.bindData[this.bindDataField];
      this.saveForAPIErrorValue = this.bindData[this.bindDataField];
    }
    if (this.bindPresetValue !== null && this.bindPresetValue !== "") {
      this.selectedValue = this.bindPresetValue;
      this.savedValue = this.bindPresetValue;
      this.saveForAPIErrorValue = this.bindPresetValue;
      this.sendValue.emit({
        value: this.savedValue,
        target: this.bindTarget,
        uniqueID: this.bindUniqueID,
        formControl: this.controlAutocomplete,
      });
    }
  }

  newValue($event) {
    if (this.savedValue !== $event.value) {
      this.saveForAPIErrorValue = this.savedValue;
      this.savedValue = $event.value;

      // emits value up for filter box and other things
      this.sendValue.emit({
        value: this.savedValue,
        target: this.bindTarget,
        uniqueID: this.bindUniqueID,
        formControl: this.controlAutocomplete,
      });

      if (this.bindAPI !== null) {
        this.saveStatus = "saving";
        this.apiValue($event.value);
      }
    } else if (this.apiConnect !== null) {
      this.saveStatus = null;
    }
  }

  apiValue(value) {
    let params = new HttpParams()
      .set(this.bindAPI.identifierProp, this.bindIdentifier)
      .set(this.bindAPI.valueProp, value);
    this.http
      .put(this.bindAPI.link, null, { params, responseType: "text" })
      .toPromise()
      .then(() => {
        this.saveStatus = "saved";
        this.bindData[this.bindDataField] = value;
        if (this.bindIcons) {
          this.bindData[this.bindDataField + "_icon"] = this.bindIcons[
            this.bindOptions.indexOf(value)
          ];
        }
        this.selectedValue = value;
        this.savedValue = value;
        this.saveForAPIErrorValue = null;
      })
      .catch(() => {
        this.saveStatus = "nosave";
        this.savedValue = this.saveForAPIErrorValue;
        this.selectedValue = this.saveForAPIErrorValue;
        this.controlAutocomplete.setValue(this.saveForAPIErrorValue);
        this.saveForAPIErrorValue = null;
      });
  }
}
