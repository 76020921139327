<div class='donut-container'>
	<div class='donut-title'>{{bindTitle}}</div>
	<div class='loading-container' *ngIf="loading && !error">
		<mat-spinner mode="indeterminate"></mat-spinner>
	</div>
	<div class='error-container' *ngIf="error">
		<div>There was an error loading the data.</div>
	</div>
	<div *ngIf="!error" (window:resize)="onResize()" class='donut-viz'>
		<canvas #tref id="{{bindID}}"></canvas>
	</div>
	<div
			*ngIf="!error"
            #legendref
			class='donut-legend'
			data-loading='showLegend'
	>
	</div>
</div>
