import { Component, OnInit } from '@angular/core';
import {DNSenseService, UserService} from "../../_services";
import {Alias} from "../../_models";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-dnsense-takedown',
  templateUrl: './dnsense-takedown.component.html',
  styleUrls: ['./dnsense-takedown.component.css']
})
export class DnsenseTakedownComponent implements OnInit {
  soteriaUser = false;
  data = new MatTableDataSource([]);
  tableMessage = null;
  tableConfig = {
    "columns": [
      {
        "header": "Domain",
        "field": "offending_dtld",
        "maxWidth": 400
      },
      {
        "header": "Status",
        "field": "status",
        "maxWidth": 400
      },
      {
        "header": "Time",
        "field": "time",
        "maxWidth": 400
      },
      {
        "header": "User",
        "field": "username",
        "maxWidth": 400
      }
    ],
    "delete": false,
    "testing": false
  };
  selectedItemIndex = null;
  selectedItemData = null;
  filterBoxTitle = "Takedown List";
  filterBoxButton = "";
  filterBoxFeatures = [
    {
      "filterList": [
        {
          type: "input",
          filterTarget: "all",
          default: "",
          placeholder: "Text Filter (on any column)",
          uniqueID: 'text filter'
        }
      ]
    },
    {
      "filterList": []
    },
    {
      "filterList": []
    }
  ];
  filterBoxAllFields = ["offening_dtld", "time", "username", "status"];
  panelTitle = "";
  panelSaveWording = "";
  panelFeatures = [
    {
      "panelList": [
        {
          type: "input",
          dataProp: "owner",
          placeholder: "Client Name"
        },
        {}
      ]
    },
    {
      "panelList": [
        {
          type: "dropdown",
          dataProp: "paying",
          placeholder: "Paying",
          values: [true, false],
        },
        {}
      ]
    }
  ];
  panelShow = false;
  identifierProp = 'offenind_dtld';
  newClient = false;

  constructor(private _userservice: UserService, public _dnsenseservice: DNSenseService) {
  }

  updateTakedowns(data: Alias[]) {
    if (data !== null && data.length == 0) {
      this.tableMessage = 'no data found';
    } else if (data !== null) {
      this.tableMessage = null;
      this.data = new MatTableDataSource(data);
    }
  }

  async ngOnInit() {
    // determines if this is an internal user or not
    this.soteriaUser = await this._userservice.UserIsSoterian();
    let data = await this._dnsenseservice.LoadTakedownList();
    if (data['error']) {
      this.tableMessage = 'error loading data';
    } else if (!data['takedowns']) {
      this.tableMessage = 'no data found';
    } else {
      this.updateTakedowns(<any[]>data['takedowns']);
    }
  }

}
