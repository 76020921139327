import {Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatInput} from '@angular/material';
import {HttpClient, HttpParams} from '@angular/common/http'

@Component({
	selector: 'ui-textarea',
	templateUrl: './ui-textarea.component.html',
	styleUrls: ['./ui-textarea.component.css']
})
export class UiTextareaComponent implements OnInit {
	controlTextArea = new FormControl();
	textDifferent = false;
	textCurrent = '';
	savedSelection = '';
	saveForAPIErrorValue = null;
	saveStatus = null;

	@HostBinding('class.selected-domain-id') @Input()
	bindData = '';
	@HostBinding('class.selected-domain-id') @Input()
	bindDataField = '';
	@HostBinding('class.selected-domain-id') @Input()
	bindPlaceholder = '';
	@HostBinding('class.selected-domain-id') @Input()
	bindTarget = '';
	@HostBinding('class.selected-domain-id') @Input()
	bindPresetValue = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindAPI = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindSaveAppear = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindFocusOnInit = false;
	@HostBinding('class.selected-domain-id') @Input()
	bindIdentifier = null;

	// passing values up
	@Output() newValue: EventEmitter<any> = new EventEmitter();

	@ViewChild('textName') nameInput: MatInput;

	constructor(private http: HttpClient) {
	}

	ngOnInit() {
		if (this.bindFocusOnInit) {
			this.nameInput.focus();
		}
	}

	ngOnChanges() {
		// makes some default changes
		if (this.bindData[this.bindDataField] !== null) {
			this.controlTextArea.setValue(this.bindData[this.bindDataField]);
			this.savedSelection = this.bindData[this.bindDataField];
		}
	}

	changeTextareaText($event) {
		const myValue = $event.target.value;
		this.textDifferent = myValue !== this.savedSelection;
		this.textCurrent = myValue;
		this.newValue.emit(
			{
				value: myValue,
				target: this.bindTarget,
				formControl: this.controlTextArea,
			}
		);
	}

	cancelItem() {
		this.controlTextArea.setValue(this.savedSelection);
		this.textCurrent = this.savedSelection;
		this.textDifferent = false;
	}

	saveItem() {
		this.saveForAPIErrorValue = this.savedSelection;
		this.savedSelection = this.textCurrent;
		this.textDifferent = false;

		if (this.bindAPI !== null) {
			this.saveStatus = 'saving';
			this.apiValue(this.textCurrent);
		} else {
			this.newValue.emit(
				{
					value: this.textCurrent,
					target: this.bindTarget,
					formControl: this.controlTextArea,
					save: true,
				}
			);
		}
	}

	apiValue(value) {
		let params = new HttpParams().set(this.bindAPI.identifierProp, this.bindIdentifier).set(this.bindAPI.valueProp, value);
		this.http.put(this.bindAPI.link, null, {params, responseType: 'text'}).toPromise().then(() => {
			this.saveStatus = 'saved';
			this.bindData[this.bindDataField] = value;
			this.textCurrent = value;
		}).catch(() => {
			this.saveStatus = 'nosave';
		});
	}
}
