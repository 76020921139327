<div class='bar-container'>
	<div class='bar-title'>{{bindTitle}}</div>
	<div class='loading-container' *ngIf="loading && !error">
		<mat-spinner mode="indeterminate"></mat-spinner>
	</div>
	<div class='error-container' *ngIf="error">
		<div>There was an error loading the data.</div>
	</div>
	<div *ngIf="!error" class='viz-container-row'>
		<div [attr.data-legend]="bindLegend" (window:resize)="onResize()" class='bar-viz'>
			<canvas #tref id="{{bindID}}"></canvas>
		</div>
		<div #legendref class='bar-legend' [attr.data-legend]="bindLegend"></div>
	</div>
</div>
