import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
	selector: 'ui-multiselect',
	templateUrl: './ui-multiselect.component.html',
	styleUrls: ['./ui-multiselect.component.css']
})
export class UiMultiselectComponent implements OnInit {
	controlMultiselect = new FormControl();
	selectedItems = [];

	@HostBinding('class.selected-domain-id') @Input()
	bindValues = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindPlaceholder = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindTarget = '';
	@HostBinding('class.selected-domain-id') @Input()
	bindUniqueID = '';

	// passing values up
	@Output() newValue: EventEmitter<any> = new EventEmitter();

	constructor() {
	}

	ngOnInit() {
	}

	changeSelected() {
		this.newValue.emit(
			{
				value: this.selectedItems,
				target: this.bindTarget,
				uniqueID: this.bindUniqueID,
				formControl: this.controlMultiselect
			}
		);
	}
}
