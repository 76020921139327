import {
  Component,
  OnInit,
  HostBinding,
  Input,
  ViewEncapsulation,
} from "@angular/core";
import { LexicoService } from "../../../_services";

@Component({
  selector: "event-view",
  templateUrl: "./event-view.component.html",
  styleUrls: ["./event-view.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class EventViewComponent implements OnInit {
  ticketDetails = null;
  remediationEnabled = false;

  @HostBinding("class.my-domain-data")
  @Input()
  bindAlertShow = null;
  @HostBinding("class.my-domain-data")
  @Input()
  bindTicket = null;
  @HostBinding("class.my-domain-data")
  @Input()
  bindTagsList = null;
  @HostBinding("class.my-domain-data")
  @Input()
  bindSoteriaUser = null;

  constructor(private _lexicoService: LexicoService) {}

  ngOnInit() {}

  async ngOnChanges() {
    // Retrieve the ticket details from the bound ticket id
    if (this.ticketDetails === null) {
      this.loadTicketData();
    }
  }

  // Load and format ticket data from services
  async loadTicketData() {
    const data = await this._lexicoService.GetTicketDetails(this.bindTicket.id);
    if (data["error"]) {
      this.ticketDetails = {};
    } else {
      // Immediately enable remediation dropdown if client has previously set a remediation status
      if (data["ticket"].positivity) {
        this.remediationEnabled = true;
      }
      this.formatTicketDetails(data["ticket"]);
    }
  }

  // Handle timeline scroll to end by enabling remediation dropdown
  remediationEvent(enabled) {
    this.remediationEnabled = enabled;
  }

  // Handle comment creation update from timeline
  commentEvent() {
    this.loadTicketData();
  }

  // Format raw ticket detail data to better support display
  formatTicketDetails(ticketDetails) {
    let events = [];
    let networkEvents = [];
    let comments = [];
    let hostname = null;
    let username = null;
    let name = null;
    let tags = [];
    let tempEvents = [];

    if (ticketDetails) {
      tempEvents = ticketDetails.alerts.map((alert) => {

        // Reformat EDR events to support timeline view
        if (ticketDetails.source === "EDR") {
          // Try to pull up hostname and username to support summary view
          if (!username && alert.data.detect.event.USER_NAME) {
            username = alert.data.detect.event.USER_NAME;
          }

          if (!hostname && alert.data.routing.hostname) {
            hostname = alert.data.routing.hostname;
          }

          let temp = {
            id: alert.id,
            type: "EDR",
            createdAt: new Date(alert.created_at),
            commandLine: alert.data.detect.event.COMMAND_LINE,
            commandLineExpanded: false,
            annotations: alert.annotations,
            filePath: alert.data.detect.event.FILE_PATH,
            parentFilePath: '',
            fileIsSigned: alert.data.detect.event.FILE_IS_SIGNED,
            fileHash: alert.data.detect.event.HASH,
            parentFileHash: '',
          };

          // parent info may not exist
          try {
            temp.parentFilePath = alert.data.detect.event.PARENT.FILE_PATH;
          } catch (error) {
          }

          try {
            temp.parentFileHash = alert.data.detect.event.PARENT.HASH;
          } catch (error) {
          }

          return temp;
        }

        // Reformat Network events to support timeline view
        if (ticketDetails.source === "Network") {
          return {
            id: alert.id,
            type: "Network",
            createdAt: new Date(alert.created_at),
            annotations: alert.annotations,
            name: alert.name,
            destinationIp: alert.data.destination_ip,
            sourceIp: alert.data.source_ip,
            destinationPort: alert.data.destination_port,
            sourcePort: alert.data.source_port,
          };
        }
      });

      if (ticketDetails.source === "EDR") {
        events = tempEvents;
      } else {
        name = ticketDetails.name;
        networkEvents = tempEvents;
      }

      // Reformat comments to support timeline view
      comments = ticketDetails.comments.map((comment) => {
        return {
          id: comment.id,
          type: "Comment",
          body: comment.body,
          createdAt: new Date(comment.created_at),
          lastUpdated: new Date(comment.last_updated),
          user: comment.user,
        };
      });
      tags = ticketDetails.tags;
    }

    this.ticketDetails = {
      id: ticketDetails.id,
      events,
      networkEvents,
      comments,
      hostname,
      username,
      tags,
      name,
      positivity: ticketDetails.positivity,
      source: ticketDetails.source,
    };
  }
}
