import {Component, ElementRef, HostBinding, Input, OnInit, ViewChild} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
	selector: 'ui-chips',
	templateUrl: './ui-chips.component.html',
	styleUrls: ['./ui-chips.component.css']
})
export class UiChipsComponent implements OnInit {
	visible = true;
	selectable = true;
	removable = true;
	addOnBlur = true;
	duplicateChip = false;
	duplicateChipName = 'VALUE';
	readonly separatorKeysCodes: number[] = [ENTER, COMMA];
	filteredTags: Observable<string[]>;
	tagCtrl = new FormControl();
	allTagsNoDupes: string[] = [];
	saveStatus = null;

	@ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
	@ViewChild('auto') matAutocomplete;

	@HostBinding('class.my-domain-data') @Input()
	bindAllTags = null;
	@HostBinding('class.my-domain-data') @Input()
	bindAllTagsAlt = null;
	@HostBinding('class.my-domain-data') @Input()
	bindTags = null;
	@HostBinding('class.my-domain-data') @Input()
	bindMakeOwnTags = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindAdditionCallback = async (value) => { return value; };
	@HostBinding('class.selected-domain-id') @Input()
	bindRemovalCallback = async (value) => { return value; };

	@HostBinding('class.selected-domain-id') @Input()
	bindIdentifier = null;

	constructor() {
		this.filteredTags = this.tagCtrl.valueChanges.pipe(
			startWith(null),
			map((tag: string | null) => tag ? this._filter(tag) : this.allTagsNoDupes.slice())
		);
	}

	ngOnInit() {
	}

	ngOnChanges() {
		this.checkDupes();
	}

	// add chip
	add(event): void {
		// Add tag only when MatAutocomplete is not open
		// To make sure this does not conflict with OptionSelected Event
		if (!this.matAutocomplete.isOpen && this.bindMakeOwnTags) {
			const input = event.input;
			const value = String(event.value).toLowerCase();

			//has to look through list to see if this input is already there,
			//if already there doesn't duplicate
			//need to show warning?
			if (this.bindTags.indexOf(value) < 0) {
				// Add our tag
				if ((value || '').trim()) {
					this.bindTags.push(value.trim());
					this.emitChange(value, 'add');
				}
			} else {
				this.duplicateChip = true;
				this.duplicateChipName = value;
			}

			// Reset the input value
			if (input) {
				input.value = '';
			}

			this.tagCtrl.setValue(null);
		} else if (!this.matAutocomplete.isOpen && !this.bindMakeOwnTags) {
			const input = event.input;
			if (input) {
				input.value = '';
			}
		}
	}

	resetMessage() {
		if (this.duplicateChip) {
			this.duplicateChip = false;
		}
	}

	//remove chip
	remove(tag): void {
		const index = this.bindTags.indexOf(tag);

		if (index >= 0) {
			this.bindTags.splice(index, 1);
			this.checkDupes();
		}
		this.resetMessage();

		this.emitChange(tag, 'remove');
	}

	//select from autocomplete
	selected(event): void {
		let selectedValue = String(event.option.viewValue).toLowerCase();

		if (this.bindTags.indexOf(selectedValue) < 0) {
			this.bindTags.push(String(event.option.viewValue).toLowerCase());
			this.tagInput.nativeElement.value = '';
			this.tagCtrl.setValue(null);
			this.resetMessage();
		} else {
			this.duplicateChip = true;
			this.duplicateChipName = selectedValue;
		}
		this.emitChange(selectedValue, 'add');
	}

	openCheck() {
		this.checkDupes();
	}

	closeCheck() {
		this.checkDupes();
	}

	checkDupes() {
		this.allTagsNoDupes = [];
		if (this.bindTags == undefined) {
			this.bindTags = [];
		}

		for (let i = 0; i < this.bindAllTags.length; i++) {
			if (this.bindTags.indexOf(this.bindAllTags[i]) == -1) {
				this.allTagsNoDupes.push(this.bindAllTags[i]);
			}
		}

		this.tagCtrl.updateValueAndValidity({onlySelf: false, emitEvent: true});
	}

	async emitChange(value, action) {
		this.saveStatus = 'saving';

		// kills the input
		this.tagInput.nativeElement.style.display = 'none';

		let passValue = value;
		//if an alt exists finds the alt for the pass value
		if (this.bindAllTagsAlt !== null) {
			passValue = this.bindAllTagsAlt[this.bindAllTags.indexOf(value)];
		}

		let err;
		if (action == 'add') {
			err = await this.bindAdditionCallback(passValue);
		} else if (action == 'remove') {
			err = await this.bindRemovalCallback(passValue);
		} else {
			err = 'invalid change type';
		}

		if (err) {
			this.tagInput.nativeElement.style.display = 'block';
			this.saveStatus = 'nosave';
		} else {
			this.tagInput.nativeElement.style.display = 'block';
			this.saveStatus = 'saved';
		}
	}

	private _filter(value: string): string[] {
		this.resetMessage();

		const filterValue = String(value).toLowerCase();
		if (value == '') {
			return this.allTagsNoDupes;
		}
		return this.allTagsNoDupes.filter(option => String(option).toLowerCase().includes(filterValue));
	}
}
