import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http'
import {Client} from '../_models'
import {Moment} from 'moment';


@Injectable({
	providedIn: 'root'
})
export class ClientService {
	public client: Client;

	constructor(private http: HttpClient) {
		this.LoadClient("");
	}

	async GetClient(): Promise<Client> {
		if (!this.client) {
			return await this.LoadClient("")
		}
		return this.client;
	}

	async LoadClient(clientString: string): Promise<Client> {
		if (isDevMode()) {
			return await this.http.get<Client>('/assets/data/client.json').toPromise().then((result) => {
				this.client = result;
				return this.client;
			});
		} else {
			if(clientString === '' || clientString === undefined){
				return await this.http.get<Client>('/client').toPromise().then((result) => {
					this.client = result;
					return this.client;
				});
			}else{
				let params = new HttpParams()
					.set('client', clientString)
				return await this.http.get<Client>('/client', {params}).toPromise().then((result) => {
					this.client = result;
					return this.client;
				});
			}
		}
	}

	async GetClientInfo(clientString: string): Promise<Client> {
		if (isDevMode()) {
			return await this.http.get<Client>('/assets/data/client.json').toPromise().then((result) => {
				return result;
			});
		} else {
			if(clientString === '' || clientString === undefined){
				return await this.http.get<Client>('/client').toPromise().then((result) => {
					let client: Client = result;
					return client;
				});
			}else{
				let params = new HttpParams()
					.set('client', clientString)
				return await this.http.get<Client>('/client', {params}).toPromise().then((result) => {
					let client: Client = result;
					return client;
				});
			}
		}
	}

	async LoadClientList() {
		if (isDevMode()) {
			return await this.http.get('/assets/data/client_list.json').
			toPromise().then((result) => {
				return {'clients': result};
			});
		} else {
			return await this.http.get('/client/list').
			toPromise().then((result) => {
				return {'clients': result};
			}, (error) => {
				return {error: error};
			});
		}
	}

	async CreateClient(client: string) {
		if (isDevMode()){
			return {uuid: "ljalkjf23211lkaslkjalkjasdf1232312"}
		}else {
			let params = new HttpParams()
				.set('client', client)
			return await this.http.post('/client', null, {
				params,
			}).toPromise().then((result) => {
				return {uuid: result['uuid']};
			}).catch((error) => {
				return {error: error};
			});
		}
	}

	async DeleteClientProduct(id: number){
		if (isDevMode()){
			return null;
		}else{
			let params = new HttpParams()
				.set('id', id.toString())
			return await this.http.delete('/client/product',
				{params,}).toPromise().then(() => {
				return null;
			}).catch((error) => {
				return error;
			});
		}

	}

	async AddClientProduct(expiration: Moment, clientId: Number, productId: number){
		if(isDevMode()){
			return null;
		}else{
			let params = new HttpParams()
				.set('client', clientId.toString())
				.set('product', productId.toString())
				.set('expire', expiration.toString())
			return await this.http.post('/client/product',null,
				{params}).toPromise().then(() => {
				return null;
			}).catch((error) => {
				return error;
			});
		}

	}

	async LoadProducts() {
		if (isDevMode()) {
			return await this.http.get('/assets/data/product_list.json').
			toPromise().then((result) => {
				return {'products': result};
			});
		} else {
			return await this.http.get('/product/list').
			toPromise().then((result) => {
				return {'products': result};
			}, (error) => {
				return {error: error};
			});
		}

	}

	async UpdateClientName(clientUuid: string, newClientName: string) {
		if (isDevMode()) {
			return await this.http.get('/assets/data/client.json').
			toPromise().then((result) => {
				return {'client': result};
			});
		} else {
			let params = new HttpParams()
				.set('client', clientUuid)
				.set('name', newClientName);
			return await this.http.put('/client',null,
				{params}).toPromise().then((result) => {
				return {'client': result};
			}).catch((error) => {
				return {'error': error};
			});
		}
	}

}
