<div
		fxLayout="column" fxFlex="100%;" class="panel-container"
		[ngClass]="{'panel-show': bindPanelShow == true, 'panel-hide': bindPanelShow == false, 'panel-load': bindPanelShow == null}"
>
	<div fxLayout="row" id='panel-header' *ngIf='bindPanelShow'>
		<div id='text-id'>
			{{ bindCreate ? 'New Broadcaster' : 'Edit ' + presaveData.name }}
		</div>

		<div class='saving-status'>
			<div *ngIf='saveStatus == "unsaved"' id='status-unsaved'>unsaved</div>
			<div *ngIf='saveStatus == "saving"' id='status-saving'>saving</div>
			<div *ngIf='saveStatus == "saved"' id='status-saved'>saved</div>
			<div *ngIf='saveStatus == "nosave"' id='status-nosave'>did not save</div>
		</div>

		<div *ngIf='changesToApply' id='apply-cancel-combo'>
			<button id='cancel' (click)="cancelPanel()">Cancel</button>
			<button id='apply' (click)="applyPanel()">Apply Changes</button>
		</div>

		<mat-icon [inline]="true" id='icon-close' class="white" (click)="onPanelClose()">close</mat-icon>
	</div>

	<div class='panel-body' *ngIf='bindPanelShow'>
		<div class='input-row'>
			<div class='form-item form-width-half' *ngIf="bindCreate">
				<ui-input
						[bindTarget]="'name'"
						[bindPresetValue]='presaveData.name'
						[bindPlaceholder]="'Broadcaster Name'"
						[bindSaveAppear]='false'
						(newValue)="updatePresaved('name', $event)"
				></ui-input>
			</div>

			<div class='form-item'>
				<mat-form-field>
					<mat-label>Type</mat-label>
					<mat-select
							[(ngModel)]="presaveData.type"
                            (selectionChange)="checkChanges()"
					>
						<mat-option value="slack">Slack</mat-option>
						<mat-option value="email">Email</mat-option>
						<mat-option value="victorops">VictorOps</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

		<div class='input-row' *ngIf="presaveData.type == 'slack'">
			<div class='form-item'>
				<ui-input
						[bindTarget]="'api_key'"
						[bindPresetValue]='presaveData["api_key"]'
						[bindPlaceholder]="'Slack API Key'"
						[bindSaveAppear]='false'
						(newValue)="updatePresaved('api_key', $event)"
				></ui-input>
			</div>

			<div class='form-item' style="margin-left: 30px;">
				<ui-input
						[bindTarget]="'channel'"
						[bindPresetValue]='presaveData.channel'
						[bindPlaceholder]="'Slack Channel'"
						[bindSaveAppear]='false'
						(newValue)="updatePresaved('channel', $event)"
				></ui-input>
			</div>
		</div>

		<div class='input-row' *ngIf="presaveData.type == 'email'">
			<div class='form-item'>
				<ui-input
						[bindTarget]="'from'"
						[bindPresetValue]='presaveData.from'
						[bindPlaceholder]="'From'"
						[bindSaveAppear]='false'
						(newValue)="updatePresaved('from', $event)"
				></ui-input>
			</div>

			<div class='form-item' style="margin-left: 30px;">
				<ui-input
						[bindTarget]="'default_to'"
						[bindPresetValue]='presaveData["default_to"]'
						[bindPlaceholder]="'Default to'"
						[bindSaveAppear]='false'
						(newValue)="updatePresaved('default_to', $event)"
				></ui-input>
			</div>
		</div>

		<div class='input-row' *ngIf="presaveData.type == 'email'">
			<div class='form-item'>
				<ui-input
						[bindTarget]="'password'"
						[bindPresetValue]='presaveData.password'
						[bindPlaceholder]="'Password'"
						[bindSaveAppear]='false'
						(newValue)="updatePresaved('password', $event)"
				></ui-input>
			</div>

			<div class='form-item' style="margin-left: 30px;">
				<ui-input
						[bindTarget]="'auth_host'"
						[bindPresetValue]='presaveData["auth_host"]'
						[bindPlaceholder]="'Auth Host'"
						[bindSaveAppear]='false'
						(newValue)="updatePresaved('auth_host', $event)"
				></ui-input>
			</div>
		</div>

		<div class='input-row' *ngIf="presaveData.type == 'email'">
			<div class='form-item'>
				<ui-input
						[bindTarget]="'server_addr'"
						[bindPresetValue]='presaveData["server_addr"]'
						[bindPlaceholder]="'Server Address'"
						[bindSaveAppear]='false'
						(newValue)="updatePresaved('server_addr', $event)"
				></ui-input>
			</div>
		</div>

		<div class='input-row' *ngIf="presaveData.type == 'victorops'">
			<div class='form-item'>
				<ui-input
						[bindTarget]="'api_key'"
						[bindPresetValue]='presaveData["api_key"]'
						[bindPlaceholder]="'VictorOps API Key'"
						[bindSaveAppear]='false'
						(newValue)="updatePresaved('api_key', $event)"
				></ui-input>
			</div>

			<div class='form-item' style="margin-left: 30px;">
				<ui-input
						[bindTarget]="'routing_key'"
						[bindPresetValue]='presaveData["routing_key"]'
						[bindPlaceholder]="'Routing Key'"
						[bindSaveAppear]='false'
						(newValue)="updatePresaved('routing_key', $event)"
				></ui-input>
			</div>
		</div>
	</div>
</div>
