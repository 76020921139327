<div class='management-container' fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="30px" fxLayoutAlign="center" style="margin: 30px;">
	<div  class='management-cside' fxFlex="10%" fxFlex.xs="100%" *ngIf="_userservice.user">
		<h2>Username</h2>
		<p class="user-info">{{_userservice.user.Username}}</p>
		<h2>Email</h2>
		<p class="user-info">{{_userservice.user.Email}}</p>
	</div>
	<div  class='management-main' fxFlex="90%" fxFlex.xs="100%">
		<div fxLayout="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="30px" fxLayoutAlign="center" style="margin: 30px;">
			<div fxFlex="100%" fxFlex.xs="100%">
				<h2>User Info</h2>
                <mat-form-field>
					<input matInput type="text" [(ngModel)]="first" placeholder="First Name">
				</mat-form-field>
				<br>
				<mat-form-field>
					<input matInput type="text" [(ngModel)]="last" placeholder="Last Name">
				</mat-form-field>
				<br>
				<mat-form-field>
					<input matInput type="text" [(ngModel)]="primary" placeholder="Primary Number">
				</mat-form-field>
                <br>
				<mat-form-field>
					<input matInput type="text" [(ngModel)]="mobile" placeholder="Mobile Number">
				</mat-form-field>
				<br>
                <button id='button-save' mat-raised-button="primary" (click)="saveInfo()">Save info</button>
				<div class='form-header'>
						<div class='messaging'>
							<span *ngIf="updateStatus == 'saving'" class='saving'>saving</span>
							<span *ngIf="updateStatus == 'saved'" class='saved'>saved</span>
							<span *ngIf="updateStatus == 'fail'" class='nosave'>did not save</span>
						</div>
				</div>
			</div>
			<div fxFlex="100%" fxFlex.xs="100%" *ngIf="_userservice.user">
				<mat-divider></mat-divider>
				<h2>Reset Password</h2>
				<button id='button-reset' mat-raised-button="primary" (click)="resetPassword()">Send Password Reset Email</button>
				<div class='form-header'>
						<div class='messaging'>
							<span *ngIf="passStatus == 'saving'" class='saving'>saving</span>
							<span *ngIf="passStatus == 'saved'" class='saved'>saved</span>
							<span *ngIf="passStatus == 'fail'" class='nosave'>did not save</span>
						</div>
				</div>
			</div>
			<div fxFlex="100%" fxFlex.xs="100%" *ngIf="_userservice.user">
				<mat-divider></mat-divider>
				<h2>Two-Factor Authentication</h2>
                <button id='button-two-factor' mat-raised-button="primary" (click)="resetQr()" style="width: 200px;">Reset Multi-Factor</button>
				<div class='form-header'>
						<div class='messaging'>
							<span *ngIf="factorStatus == 'saving'" class='saving'>saving</span>
							<span *ngIf="factorStatus == 'saved'" class='saved'>saved</span>
							<span *ngIf="factorStatus == 'fail'" class='nosave'>did not save</span>
						</div>
				</div>
			</div>
		</div>
	</div>
</div>
