import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {LexicoService} from "../../_services";
import {FormControl} from "@angular/forms";

@Component({
	selector: 'layout-panel-notifiers',
	templateUrl: './layout-panel-notifiers.component.html',
	styleUrls: ['./layout-panel-notifiers.component.css'],
})
export class LayoutPanelNotifiersComponent implements OnInit {
	@HostBinding('class.my-clients-data') @Input()
	bindPanelShow = null;
	@HostBinding('class.my-clients-data') @Input()
	bindCreate = false;
	@Output() closePanel: EventEmitter<any> = new EventEmitter();
	@HostBinding('class.my-clients-data') @Input()
	bindData = null;

	presaveData: any = null;
	changesToApply = false;
	saveStatus = '';
	broadcasters = [];
	controlInput = new FormControl();
	dows = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

	constructor(private _lexicoService: LexicoService) {
	}

	@HostBinding('class.my-clients-data') @Input()
	bindCreateCallback = async (obj) => {
		return obj
	};

	@HostBinding('class.my-clients-data') @Input()
	bindUpdateCallback = async (obj) => {
		return obj
	};

	ngOnInit() {

	}

	async ngOnChanges() {
		if (this.bindData) {
			this.presaveData = {...this.bindData};
			if (!this.presaveData.to) {
				this.presaveData.to = [];
			}
			if (!this.presaveData.notify_dow) {
				// set the default (should include all days as keys)
				this.presaveData.notify_dow = {
					"Monday": false,
					"Tuesday": false,
					"Wednesday": false,
					"Thursday": false,
					"Friday": false,
					"Saturday": false,
					"Sunday": false,
				}
			}

			if (!this.presaveData.name){
				this.presaveData.name = null;
			}

			if (!this.presaveData.client){
				this.presaveData.client = null;
			}

			if (!this.presaveData.after){
				this.presaveData.after = null;
			}

			if (!this.presaveData.range_before){
				this.presaveData.range_before = null;
			}

			if (!this.presaveData.message){
				this.presaveData.message = null;
			}

			if (!this.presaveData.range_after){
				this.presaveData.range_after = null;
			}

			if (!this.presaveData.from_status){
				this.presaveData.from_status = null;
			}

			if (!this.presaveData.to_status){
				this.presaveData.to_status = null;
			}

			if (!this.presaveData.core_score_threshold){
				this.presaveData.core_score_threshold = null;
			}

			if (!this.presaveData.alert_threshold){
				this.presaveData.alert_threshold = null;
			}

			if (!this.presaveData.score_threshold){
				this.presaveData.score_threshold = null;
			}


			if(this.presaveData.status_change){
				let temp = this.presaveData.status_change.split("->")
				if(!this.presaveData.from_status){
					this.presaveData.from_status = temp[0].replace(/'/g, "").trim();

				}

				if(!this.presaveData.to_status){
					this.presaveData.to_status = temp[1].replace(/'/g, "").
					trim();
				}
			}


			// Fetch the broadcaster list
			let data = await this._lexicoService.GetBroadcasters();
			if (data['error']) {
				// TODO
			} else {
				this.broadcasters = data['broadcasters'];
			}

		}
	}

	clog($event) {
		console.log($event);
	}

	checkChanges() {
		this.changesToApply = JSON.stringify(this.presaveData) != JSON.stringify(this.bindData) // this.presaveData != this.bindData;
	}

	updatePresavedDOW(dow, $event) {
		this.presaveData.notify_dow[dow] = $event.checked
		this.checkChanges()
	}

	updatePresaved(key, $event, index=-1) {
		if (index > -1) {
			// Hacky workaround. If you don't do this, it updates both structures
			let tmp = Array.from(this.presaveData[key]);
			tmp[index] = $event.target.value;

			this.presaveData[key] = tmp;
			this.checkChanges()
            return;
		}

		if (key == 'name'){
			$event.value = String($event.value)
			this.presaveData['name'] = $event.value
		}

		if (key == 'client'){
			$event.value = String($event.value)
			this.presaveData['client'] = $event.value
		}

		if (key == 'after'){
			$event.value = String($event.value)
			this.presaveData['after'] = $event.value
		}

		if (key == 'range_before'){
			$event.value = String($event.value)
			this.presaveData['range_before'] = $event.value
		}

		if (key == 'message'){
			$event.value = String($event.value)
			this.presaveData['message'] = $event.value
		}

		if (key == 'range_after'){
			$event.value = String($event.value)
			this.presaveData['range_after'] = $event.value
		}

		if (key == 'core_score_threshold'){
			$event.value = Number($event.value)
			this.presaveData['core_score_threshold'] = $event.value
		}

		if (key == 'score_threshold'){
			$event.value = Number($event.value)
			this.presaveData['score_threshold'] = $event.value
		}

		if (key == 'alert_threshold'){
			$event.value = Number($event.value)
			this.presaveData['alert_threshold'] = $event.value
		}

		if (key == 'from_status'){
			$event.value = String($event.value)
			this.presaveData['from_status'] = $event.value
		}

		if (key == 'to_status'){
			$event.value = String($event.value)
			this.presaveData['to_status'] = $event.value
		}

		if (key == 'from_status' || key == 'to_status') {
			this.presaveData['status_change'] = "'"+this.presaveData['from_status']+"' -> '"+this.presaveData['to_status']+"'"
		}

		this.checkChanges()
	}

	updateAppend(key) {
		// Hacky workaround. If you don't do this, it updates both structures
		let tmp = Array.from(this.presaveData[key]);
		tmp.push("");

		this.presaveData[key] = tmp;
		this.checkChanges()
		return;
	}

	updateDelete(key, index) {
		// Hacky workaround. If you don't do this, it updates both structures
		let tmp = Array.from(this.presaveData[key]);
		tmp.splice(index, 1);

		this.presaveData[key] = tmp;
		this.checkChanges()
		return;
	}

	trackByFn(index: any, item: any) {
		return index;
	}

	// Closes panel
	onPanelClose() {
		this.changesToApply = false;
		this.saveStatus = '';
		this.closePanel.emit({nochange: true})
	}

	cancelPanel() {
		this.changesToApply = false;
		this.saveStatus = '';
		this.closePanel.emit({nochange: true});
	}

	async applyPanel() {
		let err;
		if (this.bindCreate) {
			err = await this.bindCreateCallback(this.presaveData);
		} else {
			err = await this.bindUpdateCallback(this.presaveData);
		}
		if (err) {
			this.saveStatus = 'nosave';
		} else {
			this.changesToApply = false;
			this.bindData = this.presaveData;
			this.saveStatus = '';
			this.closePanel.emit(
				{
					action: 'apply',
					newData: this.bindData
				}
			);
		}
	}
}
