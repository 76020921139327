export class Notification {
	public id: string;
	public title: string;
	public text: string;
	public link: string;
	public userEmail: string;
	public read: boolean;

	constructor() {
	}
}
