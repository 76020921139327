<div class="container-dropdown">
  <div class="saving-status">
    <div *ngIf="saveStatus == 'unsaved'" id="status-unsaved">unsaved</div>
    <div *ngIf="saveStatus == 'saving'" id="status-saving">saving</div>
    <div *ngIf="saveStatus == 'saved'" id="status-saved">saved</div>
    <div *ngIf="saveStatus == 'nosave'" id="status-nosave">did not save</div>
  </div>
  <mat-icon
    *ngIf="bindIcons && selectedValue !== null"
    class="extra-icon"
    svgIcon="{{ bindIcons[bindOptions.indexOf(selectedValue)] }}"
  ></mat-icon>
  <mat-form-field>
    <mat-label>{{ bindPlaceholder }}</mat-label>
    <mat-select
      [disabled]="disabled"
      panelClass="mat-select-dropdown"
      [(ngModel)]="selectedValue"
      [formControl]="controlAutocomplete"
      (selectionChange)="newValue($event)"
      [attr.data-iconup]="bindIcons && selectedValue !== null"
    >
      <mat-option
        *ngFor="let option of bindOptions; let i = index"
        [value]="option"
      >
        <mat-icon
          *ngIf="bindIcons"
          svgIcon="{{ bindIcons[bindOptions.indexOf(option)] }}"
        ></mat-icon>
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
