<!--The content below is only a placeholder and can be replaced.-->
<div class="appContainer">
  <div class="contentMenu">
    <img
      style="
        float: left;
        padding-left: 30px;
        margin-top: 12px;
        width: 140px;
        cursor: pointer;
      "
      src="/assets/img/soteria.png"
      routerLink="/home/lexico/tickets"
    />
    <menu-main></menu-main>
    <nav>
      <div class="menuOptions">
        <button
          *ngIf="hasUserManagement"
          mat-button
          routerLink="/administration"
          class="nav-button"
          [color]="
            router.url.indexOf('/administration') !== -1 ? 'primary' : 'basic'
          "
        >
          <h2>Administration</h2>
        </button>
        <button mat-button class="nav-button" (click)="openDialog()">
          <h2>Feedback</h2>
        </button>
      </div>

      <!-- <button mat&#45;button class="userDropdownNotifications" -->
      <!-- 	[matMenuTriggerFor]="notificationMenu"> -->
      <!-- 	<mat&#45;icon *ngIf="hasUnreadNotifications">notifications_active</mat&#45;icon> -->
      <!-- 	<mat&#45;icon *ngIf="!hasUnreadNotifications">notifications</mat&#45;icon> -->
      <!-- </button> -->
      <!-- <mat&#45;menu -->
      <!-- 	#notificationMenu="matMenu" -->
      <!-- 	class="notification&#45;panel" -->
      <!-- 	(click)="$event.stopPropagation();" -->
      <!-- > -->
      <!-- 	<notifications -->
      <!-- 		class="notification&#45;panel" -->
      <!-- 		(setUnread)="setHasUnreadNotifications($event)" -->
      <!-- 		(click)="$event.stopPropagation();" -->
      <!-- 	> -->
      <!-- 	</notifications> -->
      <!-- </mat&#45;menu> -->

      <button
        *ngIf="_userservice.user"
        class="userDropdown"
        style="font-size: 18px; font-weight: normal"
        mat-button
        [matMenuTriggerFor]="menu"
      >
        <mat-icon>account_circle</mat-icon>
        {{ _userservice.user.Username }}
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/settings">Settings</button>
        <button mat-menu-item (click)="destroySession()">Logout</button>
      </mat-menu>
    </nav>
  </div>
  <div
    class="menu-sub"
    *ngIf="router.url.indexOf('/lexico') !== -1 && soteriaUser"
  >
    <menu-sub [bindList]="lexicoSubheaders"> </menu-sub>
  </div>
  <div
    class="menu-sub"
    *ngIf="router.url.indexOf('/dnsense') !== -1 && soteriaUser"
  >
    <menu-sub [bindList]="dnsenseSubheaders"> </menu-sub>
  </div>
  <div *ngIf="!soteriaUser" class="menu-placeholder"></div>
  <div
    class="menu-sub"
    *ngIf="router.url.indexOf('/dnsense') !== -1 && !soteriaUser"
  >
    <menu-sub [bindList]="dnsenseSubheadersNotSoterian"> </menu-sub>
  </div>
  <div class="contentArea">
    <router-outlet *ngIf="_userservice.user"></router-outlet>
  </div>
</div>
