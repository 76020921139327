<div class="notification-header">
	<h3 class="notification-header-title">Notifications</h3>
	<p
		*ngIf="notificationStatus == 'fail'"
		class='notification-error'
	>error</p>
	<a
		class="markallread"
		(click)="markAllRead()"
	>
		Mark All as Read
	</a>
</div>
<hr>
<div *ngIf="notificationPullStatus">
	<p class="no-notifications">{{notificationPullStatus}}</p>
</div>
<div class="notification"
	*ngFor="let n of notifications; first as isFirst; last as isLast"
	[ngClass]="{'notification-read': n.read}"
>
	<div class="notification-message">
		<h4
			class="notification-short"
			[ngClass]="{'notification-short-link': n.link}"
			(click)="gotoLink(n)"
		>
			{{n.title}}<span *ngIf="!n.read" class="dot"></span>
		</h4>
		<p
			class="notification-long"
		>
			{{n.text}}
		</p>
	</div>
	<div class="notification-options">
		<mat-icon
			class="markread"
			(click)="markDontShow(n)"
		>
			clear
		</mat-icon>
	</div>
</div>
