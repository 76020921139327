<div *ngIf="bindPanelShow" class="panel-container">
    <div class="header" fxLayout="row" id="text-header">
        <h3 style='flex: 1' >Domain not found</h3>
        <mat-icon [inline]="true" id='icon-close' class="white" (click)="closePanel()">close</mat-icon>
    </div>
    <div class="panel-body">
        <div *ngIf="!loading">
            <div class="domain-form">
                <mat-form-field>
						<textarea cdkTextareaAutosize
                                  matInput
                                  #inputName="matInput"
                                  placeholder="New Domain"
                                  [formControl]="newDomainForm"
                                  autocomplete="off"
                                  [(ngModel)]="newDomain"
                        ></textarea>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Keyword</mat-label>
                    <mat-select
                            [(ngModel)]="keywordId"
                            [formControl]="keywordIdForm"
                    >
                        <mat-option
                                *ngFor="let keyword of keywordList"
                                [value]="keyword"
                        >
                            {{keyword.keyword}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="domain-confirm-create-button">
                <p  class="success messaging add-domain-error" *ngIf="successMessage !==''">{{successMessage}}</p>
                <p  class="error messaging add-domain-error" *ngIf="errorMessage !==''">{{errorMessage}}</p>

                <button
                        class="cancel"
                        (click)="cancelCreateDomain()"
                >
                    Cancel
                </button>
                <button *ngIf="createDomainEmpty()"
                        class="create-domain-empty"
                >Create</button>
                <button *ngIf="!createDomainEmpty()"
                        class="create-domain-ready"
                        (click)="AddDomain()">Create
                </button>

            </div>
        </div>

        <div class='loading-container' *ngIf="loading">
            <mat-spinner class='spinner' mode="indeterminate"></mat-spinner>
        </div>

    </div>
</div>

