import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material";
import {HttpClient} from '@angular/common/http'
import {LexicoService} from "../../_services";
import {MediaObserver} from '@angular/flex-layout';

@Component({
	selector: 'lexico-tags',
	templateUrl: './lexico-tags.component.html',
	styleUrls: ['./lexico-tags.component.css']
})
export class LexicoTagsComponent implements OnInit {
	// basic static vars that would appear in multiple views
	panelShow = null;
	selectedItemData = null;
	selectedItemIndex = null;
	tableMessage = null;
	// first array is columns
	// feature array inside is individual filter
	// that go down the column
	filterBoxFeatures = [
		{
			"filterList": [
				{
					type: "input",
					filterTarget: "all",
					default: "",
					placeholder: "Text Filter (on any column)",
					uniqueID: 'text filter'
				}
			]
		},
		{
			"filterList": []
		}
	];
	filterBoxTitle = "Tags List";
	filterBoxButton = "+ New Tag";
	filterBoxAllFields = ["name", "description"];
	// for the table, config is how it's arranged
	// sample data is temp until we can get some
	tableConfig = {
		"columns": [
			{
				"header": "Tag",
				"field": "name",
				"maxWidth": 200
			},
			{
				"header": "Description",
				"field": "description",
				"maxWidth": null
			}
		],
		"delete": true,
		"testing": false
	};
	data = null;
	//for the panel that comes out
	// array of rows, each feature is a row
	// this is like filter box except these are rows of inputs instead of columns
	panelTitle = "";
	panelSaveWording = "";
	panelFeatures = [
		{
			"panelList": [
				{
					type: "input",
					dataProp: "name",
					placeholder: "Tag",
					apiURL: "apiLink"
				},
				{}
			]
		},
		{
			"panelList": [
				{
					type: "textarea",
					dataProp: "description",
					placeholder: "Tag Description",
					apiURL: "apiLink"
				}
			]
		}
	];
	identifierProp: 'id';

	constructor(private http: HttpClient, public _lexicoService: LexicoService, public media: MediaObserver) {
	}

	async ngOnInit() {
		let data = await this._lexicoService.GetTags();
		if (data['error']) {
			this.tableMessage = 'error loading data';
		} else if (!data['tags'] || data['tags'].length == 0) {
			this.tableMessage = 'no data in this time range';
		} else {
			this.tableMessage = null;
			this.data = new MatTableDataSource(data['tags']);
		}
	}


	// for closing the panel, always closes but does API things
	// this would appaer in my different views
	async closePanelSaveData($event) {
		const closeAction = $event.action;
		if (closeAction === "apply") {
			let data = await this._lexicoService.UpdateTags();
			if (data['error']) {
				this.tableMessage = 'error loading data';
			} else if (!data['tags'] || data['tags'].length == 0) {
				this.tableMessage = 'no data in this time range';
			} else {
				this.tableMessage = null;
				this.data = new MatTableDataSource(data['tags']);
			}
		}

		// does the closing action and resets things
		this.selectedItemData = null;
		this.selectedItemIndex = null;
		this.panelShow = false;

	}

	// opens panel to make a new item
	// this would appear in many different views
	newItem() {
		this.selectedItemIndex = null;
		this.panelShow = true;
		this.panelTitle = "New Tag";
		this.panelSaveWording = "Save New Tag";

		// temp tries making new alias
		this.selectedItemData = {
			'name': '',
			'description': ''
		};
	}

	// for getting a selected table row
	// this would appear in many different views
	selectTableRow($event) {
		const match = $event.emitMatch;
		const index = $event.emitIndex;

		// if row already selected, closes it
		if (index == this.selectedItemIndex) {
			this.selectedItemData = null;
			this.selectedItemIndex = null;
			this.panelShow = false;
		} else {
			this.selectedItemData = match;
			this.selectedItemIndex = index;
			this.panelShow = true;
			this.panelTitle = "Edit Tag";
			this.panelSaveWording = "Apply Changes";
		}
	}

	rTag = this.removeTag(this._lexicoService);
	removeTag(lexicoService) {
		return async (identifier) => {
			return await lexicoService.DeleteTag(identifier);
		}
	}

	// Hacky work-around to get the update to stop breaking within the panel.
	// For some reason, if you pass a function call rather than a function reference
	// the panel update craps out.
	ctagCallback = this.createTag(this._lexicoService);
	createTag(lexicoService) {
		return async (obj) => {
			return await lexicoService.CreateTag(obj);
		}
	}

/* Uncomment if we decide to allow tag updates in the future
	updateTag(lexicoService) {
		return async (obj) => {
			return await lexicoService.UpdateTag(obj);
		}
	}
*/
}
