<div class="event-view-container">
  <event-summary
    [bindTicket]="bindTicket"
    [ticketDetails]="ticketDetails"
    [remediationEnabled]="remediationEnabled"
    (remediationEvent)="remediationEvent($event)"
    (commentEvent)="commentEvent()"
  ></event-summary>
  <event-timeline
    [ticketDetails]="ticketDetails"
    (remediationEvent)="remediationEvent($event)"
  ></event-timeline>
</div>
