import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http'
import {User} from '../_models'
import {Router} from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	public user: User;

	constructor(private http: HttpClient, public router: Router) {
		this.LoadUser();
	}

	async LoadUser(): Promise<User> {
		if (isDevMode()) {
			return await this.http.get<User>('/assets/data/user.json').toPromise().then((result) => {
				this.user = result;
				return this.user;
			});
		} else {
			return await this.http.get<User>('/session').toPromise().then((result) => {
				this.user = result;
				return this.user;
			});
		}
	}

	async GetUser(): Promise<User> {
		if (!this.user) {
			return this.LoadUser();
		}
		return this.user;
	}

	async UserIsSoterian(): Promise<boolean> {
		let user = await this.GetUser();
		let soteriaUUID = '8d97a7d4df9761a5326fe7a2f0a90ba121a372499bcab480a818ad40cb5076a2';
		return user.ClientUUID == soteriaUUID;
	}

	async UserHasPermission(permission: string): Promise<boolean> {
		let user = await this.GetUser();
		return user.Permissions.indexOf(permission) >= 0;
	}

	async CreateUser(email: string, first: string, last: string, clientUuid: string): Promise<any> {
		if (isDevMode()) {
			return {userId: 1}
		}

		let somerandomstring = Math.random().toString(36).substring(2, 15)
			+ Math.random().toString(36).substring(2, 15);
		let params = new HttpParams()
			.set('user', email)
			.set('pass', somerandomstring+'!aAa')
			.set('email', email)
			.set('first_name', first)
			.set('last_name', last)
			.set('client_id', clientUuid)
		return await this.http.post('/user', null, {
			params,
		}).toPromise().then((result) => {
			return {userId: result['user_id']};
		}).catch((error) => {
			return {error: error};
		});
	}

	async DeleteUser(user_id): Promise<any> {
		if (isDevMode()) {
			return null;
		}

		let params = new HttpParams().set('user', user_id)
		return await this.http.delete('/user', {
			params,
		}).toPromise().then(() => {
			return null;
		}).catch((error) => {
			return error;
		});
	}

	async ListUsers(client = ''): Promise<any> {
		if (isDevMode()) {
			return await this.http.get('/assets/data/user_list.json').toPromise()
			.then((result) => {
				return {list: result};
			})
		}
		let params = null;

		if (client !== '') {
			params = new HttpParams().set('client', client)
		}
		return await this.http.get('/users', {
			params,
		}).toPromise().then((result) => {
			return {list: result};
		}).catch((error) => {
			return {error: error};
		});
	}

	async GrantUserRole(UserID: Number, RoleID: Number){
		if (isDevMode()){
			return null;
		}
		let params = new HttpParams().set('role', RoleID.toString())
		return await this.http.post('/user/role/'+ UserID.toString(), {}, {
			params,
		}).toPromise().then(() =>{
			return null;
		}).catch((error) => {
			return {error: error};
		});
	}

	async DeleteUserRole(UserID: Number, RoleID: Number){
		if (isDevMode()){
			return null;
		}

		let params = new HttpParams().set('role', RoleID.toString());
		return await this.http.delete('/user/role/'+UserID.toString(), {
			params,
		}).toPromise().then(() =>{
			return null;
		}).catch((error) => {
			return {error: error};
		});
	}

	//list roles that a the current user can assign
	async ListRoles(){
		if (isDevMode()){
			return await this.http.get('/assets/data/role_list.json').toPromise()
			.then((result) => {
				return {list: result};
			})
		}

		return await this.http.get('/role/list')
		.toPromise().then((result) =>{
			return {list: result};
		}).catch( (error) => {
			return {error: error};
		} ) ;

	}

	//list roles selected user already has assigned
	async ListUserRoles(UserID: Number){
		if (isDevMode()){
			return await this.http.get('/assets/data/user_role_list.json').toPromise()
				.then((result) => {
					return {list: result};
				})
		}

		let params = new HttpParams().set('user', UserID.toString());
		return await this.http.get('/user/role/list', {
			params
		}).toPromise().then((result) =>{
			return {list: result};
		}).catch( (error) => {
			return {error: error};
		}) ;
	}

	// create a role
	async CreateRole(Name: string){
		if(isDevMode()){
			return {name: Name, role_id: 12}
		}

		let body = {
			name: Name
		};

		return await this.http.post('/role', body, {}).
		toPromise().then((result) =>{
			return result;
		}).catch( (error) => {
			return {error: error};
		}) ;
	}

	//	delete a role
	async DeleteRole(id: Number){
		if(isDevMode()){
			return null;
		}

		return await this.http.delete('/role/' + id.toString(), {}).
		toPromise().then((result) =>{
			return null;
		}).catch( (error) => {
			return error;
		}) ;
	}

	async GetRolePerm(RoleID: Number){
		if (isDevMode()){
			return await this.http.get('/assets/data/role_perm_list.json').toPromise()
				.then((result) => {
					return {list: result};
				})
		}

		let params = new HttpParams().set('role_id', RoleID.toString());
		return await this.http.get('/role/perm/list', {
			params
		}).toPromise().then((result) =>{
			return {list: result};
		}).catch( (error) => {
			return {error: error};
		}) ;
	}

	// create a role perm
	async CreateRolePerm(perm: string, id: Number){
		if(isDevMode()){
			return null;
		}

		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' })
		};

		let body = {
			perm: perm
		};

		return await this.http.post('/role/perm/' + id.toString(), body, httpOptions).
		toPromise().then(() =>{
			return null;
		}).catch( (error) => {
			return error;
		}) ;
	}

	// delete a role perm
	async DeleteRolePerm(perm: string, id: Number){
		if(isDevMode()){
			return null;
		}

		const httpOptions = {
			headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: {perm: perm}
		};

		return await this.http.delete('/role/perm/' + id.toString(), httpOptions).
		toPromise().then(() =>{
			return null;
		}).catch( (error) => {
			return error;
		}) ;
	}
}

