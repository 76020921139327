import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {NotificationsService} from "../../_services";
import {Notification} from "../../_models"

@Component({
	selector: 'notifications',
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
	notifications = [];
	notificationStatus = '';
	notificationPullStatus = null;
	@Output() setUnread: EventEmitter<any> = new EventEmitter();
	constructor(
		public _notifcationsservice: NotificationsService,
	) {}

	async ngOnInit() {
		let noti = await this._notifcationsservice.GetNotifications()
		if (noti['error']){
			this.notificationPullStatus = 'Failed to load notifications...';
			return;
		}else{
			this.notifications = noti['notifications'];
		}
		// if there are one or more notifications that are unread
		if (this.notifications.filter((noti) => !noti.read).length > 0) {
			this.setUnread.emit({unread: true});
		}

		if (this.notifications.length === 0) {
			this.notificationPullStatus = 'No new notifications...';
		}
	}

	async markAllRead() {
		// tell api that notification has been read
		let err = await this._notifcationsservice.MarkAllRead();
		if (err){
			this.notificationStatus = 'fail'; 
			return;
		}else{
			for (let i = 0; i < this.notifications.length; i++) {
				this.notifications[i].read = true;
			}

			this.notificationStatus = '';
			this.setUnread.emit({unread: false});
		}
	}

	async markRead(noti) {
		// tell api that notification has been read
		let err = await this._notifcationsservice.MarkSingleRead(noti.ID.value);
		if (err){
			this.notificationStatus = 'fail';
			return;
		}

		this.notifications[this.notifications.indexOf(noti)].read = true;
		// sort by read
		this.notifications = this.notifications.sort(
			(a,b) => (a.read > b.read) ? 1 : ((b.read > a.read) ? -1 : 0)
		);

		// if all are read, emit to parent that all are read
		if (this.notifications.filter((n) => !n.read).length == 0) {
			this.setUnread.emit({unread: false});
		}
		this.notificationStatus = '';
	}

	async markDontShow(noti) {
		// tell api that notification has been marked as don't show
		let err = await this._notifcationsservice.HideNotification(noti.ID.value);
		if (err){
			this.notificationStatus = 'fail';
			return;
		}

		this.notifications = this.notifications.filter((n) => n !== noti)

		// if all are read, emit to parent that all are read
		if (this.notifications.filter((n) => !n.read).length == 0) {
			this.setUnread.emit({unread: false});
		}
		this.notificationStatus = '';
		if (this.notifications.length === 0) {
			this.notificationPullStatus = 'No new notifications...';
		}
	}

	gotoLink(noti: Notification) {
		this.markRead(noti)
		if (noti.link) {
			document.location.href = noti.link;
		}
	}
}
