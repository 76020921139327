import {Component, HostBinding, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatCheckbox} from '@angular/material';
import {HttpClient, HttpParams} from '@angular/common/http'
import {LexicoService} from "../../_services";

@Component({
	selector: 'layout-tickets-edit',
	templateUrl: './layout-tickets-edit.component.html',
	styleUrls: ['./layout-tickets-edit.component.css']
})
export class LayoutTicketsEditComponent implements OnInit, OnDestroy {
	tags = [];
	annotationFields = [
		{
			"type": "autoDate",
			"placeholder": "Date",
			"width": ""
		},
		{
			"type": "autoUser",
			"placeholder": "User",
			"width": ""
		},
		{
			"type": "input",
			"placeholder": "Note",
			"width": ""
		}
	];
	apiRisk = {
		'link': '/ticket/ticket/suspicion',
		'identifierProp': 'id',
		'valueProp': 'suspicion'
	};
	apiPriority = {
		'link': '/ticket/ticket/priority',
		'identifierProp': 'id',
		'valueProp': 'priority'
	};

	apiPositivity = {
		'link': '/ticket/ticket/positivity',
		'identifierProp': 'id',
		'valueProp': 'positivity',
		'mainIdentifier': null
	};

	apiAssignee = {
		'link': '/ticket/ticket/assign',
		'identifierProp': 'id',
		'valueProp': 'assignee'
	};
	apiDescription = {
		'link': '/ticket/ticket/description',
		'identifierProp': 'id',
		'valueProp': 'description'
	};
	apiComments = {
		'link': '/ticket/ticket/comment',
		'identifierProp': 'ticket_id',
		'valueProp': 'comment',
		'mainIdentifier': null
	};
	apiNotes = {
		'link': '/ticket/ticket/note',
		'identifierProp': 'ticket_id',
		'valueProp': 'note',
		'mainIdentifier': null
	};

	clientViewableStatus = null;
	optionsRisk = [
		"Malicious", "Suspicious", "Potentially Unwanted Program (PUP)", "Not Suspicious"
	];
	optionsPriority = [
		"High", "Medium", "Low", "Informational"
	];
	positivityStatus =[
		"False Positive/Misidentified Activity",
		"False Positive/Approved or authorized activity",
		"True Positive"
	];
	iconsRisk = [
		"icon-risk-high", "icon-risk-medium", "icon-risk-suspicious", "icon-risk-informational"
	];
	iconsPriority = [
		"icon-priority-high", "icon-priority-medium", "icon-priority-low", "icon-priority-informational"
	];

	@HostBinding('class.my-domain-data') @Input()
	bindData = null;
	@HostBinding('class.my-domain-data') @Input()
	bindTagsList = null;
	@HostBinding('class.my-domain-data') @Input()
	bindTagsListID = null;
	@HostBinding('class.my-domain-data') @Input()
	bindAssigneesList = null;
	@HostBinding('class.my-domain-data') @Input()
	bindSoteriaUser = null;


	@ViewChild('myCheckbox') private myCheckbox: MatCheckbox;

	constructor(private http: HttpClient, private _lexicoService: LexicoService) {
	}

	ngOnInit() {
	}

	ngOnDestroy() {
		this.bindData = null;
	}

	addTag(lexService, ticket=this.bindData.id) {
		return async (value) => {
			return await lexService.AddTag(ticket, value);
		};
	}

	removeTag(lexService, ticket=this.bindData.id) {
		return async (value) => {
			return await lexService.RemoveTag(ticket, value);
		}
	}

	ngOnChanges() {
		//has to add higher level ID to comments and notes
		this.apiComments.mainIdentifier = this.bindData.id;
		this.apiNotes.mainIdentifier = this.bindData.id;
	}

	clientViewableCheck($event) {
		const state = $event.checked;
		this.clientViewableStatus = null;

		let params = new HttpParams().set('ticket_id', this.bindData.id).set('viewable', String(state));
		this.http.put('ticket/ticket/viewable', null, {params, responseType: 'text'}).toPromise().then(() => {
			// has to update the data outside this
			this.bindData.client_viewable = this.myCheckbox.checked;

		}).catch(() => {
			this.clientViewableStatus = 'nosave';
			this.myCheckbox.checked = this.bindData.client_viewable;
		});
	}
}
