<div class="filter-container">
  <div class="filter-title-row">
    <div class="filter-title">
      {{ bindTitle }}

      <div class="main-search" *ngIf="bindMainSearch">
        <input
          class="main-search-input"
          placeholder="{{ mainSearchPlaceholder }}"
          [(ngModel)]="mainSearchText"
          (keyup)="onMainSearchKeyUp($event)"
        />
        <button class="search-button" (click)="onMainSearch()">Search</button>
      </div>
    </div>

    <div *ngIf="bindError !== ''" class="filter-error">{{ bindError }}</div>
    <div class="filter-button-group">
      <button
        class="refresh-button"
        (click)="refresh()"
        *ngIf="bindRefreshButton"
      >
        <mat-icon svgIcon="icon-reset-dark"></mat-icon>
        <span>Refresh table data</span>
      </button>
      <button
        class="reset-button"
        (click)="resetFilters(); nilDefaults()"
        *ngIf="showReset"
      >
        <mat-icon svgIcon="icon-reset-dark"></mat-icon>
        <span>Reset Filters</span>
      </button>

      <button
        *ngIf="bindButtonInclude"
        class="filter-button"
        (click)="buttonAction()"
      >
        {{ bindButton }}
      </button>
      <div
        *ngIf="bindButtonAltText && !bindButtonInclude"
        class="filter-button-alt-text"
      >
        {{ bindButtonAltText }}
      </div>
    </div>
  </div>
  <div class="filter-options">
    <div *ngFor="let featureCol of bindFeatures" class="filter-column">
      <div *ngFor="let feature of featureCol['filterList']" class="filter-item">
        <div *ngIf="feature.type === 'input'" class="uiType">
          <ui-input
            [bindTarget]="feature.filterTarget"
            [bindPlaceholder]="feature.placeholder"
            [bindUniqueID]="feature.uniqueID"
            (newValue)="filterArrayUpdate($event)"
          ></ui-input>
        </div>

        <div *ngIf="feature.type === 'ui-dropdown'" class="uiType">
          <ui-dropdown
            [bindTarget]="feature.filterTarget"
            [bindPlaceholder]="feature.placeholder"
            [bindOptions]="feature.values"
            [bindIcons]="feature.icons"
            [bindUniqueID]="feature.uniqueID"
            [bindPresetValue]="feature.default"
            (sendValue)="filterArrayUpdate($event)"
          ></ui-dropdown>
        </div>

        <div *ngIf="feature.type === 'ui-autocomplete'" class="uiType">
          <ui-autocomplete
            [bindTarget]="feature.filterTarget"
            [bindPlaceholder]="feature.placeholder"
            [bindUniqueID]="feature.uniqueID"
            [bindValues]="feature.values"
            [bindIcons]="feature.icons"
            (newValue)="filterArrayUpdate($event)"
          ></ui-autocomplete>
        </div>

        <div *ngIf="feature.type === 'ui-multiselect'" class="uiType">
          <ui-multiselect
            [bindTarget]="feature.filterTarget"
            [bindPlaceholder]="feature.placeholder"
            [bindUniqueID]="feature.uniqueID"
            [bindValues]="feature.values"
            (newValue)="filterArrayUpdate($event)"
          ></ui-multiselect>
        </div>

        <div *ngIf="feature.type === 'date'" class="uiType">
          <mat-form-field class="datepicker">
            <input
              matInput
              [max]="maxDate"
              [min]="minDate"
              [matDatepicker]="picker"
              placeholder="Month and Year"
              [formControl]="bindDate"
              (keyup)="updateDate()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #picker
              startView="multi-year"
              (yearSelected)="chosenYearHandler($event)"
              (monthSelected)="chosenMonthHandler($event, picker)"
              panelClass="month-picker"
            >
            </mat-datepicker>
          </mat-form-field>
        </div>

        <div *ngIf="feature.type === 'ui-search-bar'" class="uiType">
          <div fxLayout="row" class="search-bar">
            <mat-form-field>
              <input
                matInput
                [(ngModel)]="searchItem"
                placeholder="{{ feature.placeholder }}"
              />
            </mat-form-field>
            <button class="search-button" (click)="searchAction()">
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
