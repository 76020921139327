import { Component, OnInit, isDevMode } from '@angular/core';
import { UserService} from "../../_services";
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import { QrComponent } from "./qr/qr.component";
import { PasswordComponent } from "./password/password.component";

@Component({
	selector: 'app-management',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
    development = false;
    linkPicture = '/secret/qr';
	timeStamp = (new Date()).getTime();
	newp = '';
	old = '';
	confirmed = '';
	
	first = '';
	last = '';
	primary = '';
	mobile = '';

	updateStatus = '';
	passStatus = '';
	factorStatus = '';

	constructor(
		public _userservice: UserService,
		private http: HttpClient,
		public _Router: Router,
		public dialog: MatDialog
	) {
		this.development = isDevMode();
	}

	ngOnInit(){
		if (isDevMode()) {
			this.http.get('/assets/data/user_info.json').toPromise().then((result) => {
				this.first = result['profile']['firstName'];
				this.last = result['profile']['lastName'];
				this.primary = result['profile']['primaryPhone'];
				this.mobile = result['profile']['mobilePhone'];
			}, () => {
				console.error('failure to pull user info')
			});
		} else {
			this.http.get('/session/user').toPromise().then((result) => {
                this.first = result['profile']['firstName'];
				this.last = result['profile']['lastName'];
				this.primary = result['profile']['primaryPhone'];
				this.mobile = result['profile']['mobilePhone'];
			}, () => {
				console.error('failure to pull user info')
			});
		}
	}
	
	saveInfo() {
		this.updateStatus = 'saving';
		this.http.post('/user/update', {firstName: this.first, lastName: this.last, primaryPhone: this.primary, mobilePhone: this.mobile}).toPromise()
			.then(() => {
				this.updateStatus = 'saved';
			}, () => {
				this.updateStatus = 'fail';
			});
	}

	resetQr(): void {
		this.factorStatus = 'saving';
		const dialogRef = this.dialog.open(QrComponent, {
			width: '400px',
			data: {test: 'test'}
		});

		dialogRef.afterClosed().subscribe(result => {
		    if (result) {
				this.http.put('/secret/reset', null).toPromise().then(() => {
					this.factorStatus = 'saved';
				}, () => {
					this.factorStatus = 'fail';
				});
			}
		});
	}

	resetPassword(){
		this.passStatus = 'saving';
		const dialogRef = this.dialog.open(PasswordComponent, {
			width: '400px',
			data: {test: 'test'}
		});

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.http.put('/user/password', {}).toPromise().then(() => {
					this.passStatus = 'saved';
				}, () => {
					this.passStatus = 'fail';
				});
			}
		});
	}
}
