import {Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatSort, MatTable} from "@angular/material";
import {MatPaginator} from '@angular/material/paginator';
import {HttpClient} from '@angular/common/http'

@Component({
	selector: 'content-table',
	templateUrl: './content-table.component.html',
	styleUrls: ['./content-table.component.css']
})
export class ContentTableComponent implements OnInit {
	displayedColumns = [];
	deleteIndex = null;
	deleteIdentifier = null;
	deleteFailed = null;
	loading = true;

	@HostBinding('class.my-domain-data') @Input()
	bindData = null;
	@HostBinding('class.my-domain-data') @Input()
	bindTableConfig = null;
	@HostBinding('class.my-domain-data') @Input()
	bindSelectedItemIndex = null;
	@HostBinding('class.my-domain-data') @Input()
	bindSort = 'none';
	@HostBinding('class.my-domain-data') @Input()
	bindSortDirection = 'asc';
	@HostBinding('class.my-domain-data') @Input()
	bindEditable = true;
	@HostBinding('class.my-domain-data') @Input()
	bindMessage = null;
	@HostBinding('class.my-domain-data') @Input()
	bindBulkSelected = null;
	@HostBinding('class.my-domain-data') @Input()
	bindBulkIdentifier = '';
	@HostBinding('class.my-domain-data') @Input()
	bindRemovalCallback = async (identifier) => { return identifier; };
	@HostBinding('class.my-domain-data') @Input()
	bindIdentifierProp = null;

	// passing values up
	@Output() setRow: EventEmitter<any> = new EventEmitter();
	@Output() deletedRow: EventEmitter<any> = new EventEmitter();

	@ViewChild(MatTable) table: MatTable<any>;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;

	constructor() {
	}

	toggleSelected($event, id) {
		if ($event.checked) {
			this.bindBulkSelected.push(id)
		} else {
			const index = this.bindBulkSelected.indexOf(id);
			if (index > -1) {
				this.bindBulkSelected.splice(index, 1);
			}
		}
	}

	ngOnInit() {
		this.displayedColumns = [];
		// sets up the display list for the table
		for (let i = 0; i < this.bindTableConfig.columns.length; i++) {
			this.displayedColumns.push(this.bindTableConfig.columns[i].field);
		}

		if (this.bindTableConfig.delete) {
			this.displayedColumns.push('delete');
		}
		// this isn't handled consistently, should be in higher level table config...although
		// there's a good bit of stuff that should be
		if (this.bindEditable) {
			this.displayedColumns.push('arrow');
		}
	}

	ngOnChanges() {
		this.displayedColumns = [];

		if (this.bindBulkSelected != null) {
			this.displayedColumns.push('bulk-select');
		}

		// sets up the display list for the table
		for (let i = 0; i < this.bindTableConfig.columns.length; i++) {
			this.displayedColumns.push(this.bindTableConfig.columns[i].field);
		}

		if (this.bindTableConfig.delete) {
			this.displayedColumns.push('delete');
		}
		// this isn't handled consistently, should be in higher level table config...although
		// there's a good bit of stuff that should be
		if (this.bindEditable) {
			this.displayedColumns.push('arrow');
		}

		if (this.bindData !== null || this.bindMessage !== null) {
			this.loading = false;
		}
		if (this.bindData !== null) {
			this.bindData.sort = this.sort;
		}
		if (this.bindData !== null) {
			this.bindData.paginator = this.paginator;
		}
	}

	updateSort() {
		this.bindData.sort = this.sort;
	}

	selectRow($event, match: any, index: Number) {
		//clears any possible delete style
		if (this.deleteIndex !== null) {
			this.deleteIndex = null;
			this.deleteFailed = null;
			this.deleteIdentifier = null;
		}

		//emits second two up to parent
		this.setRow.emit(
			{
				emitMatch: match,
				emitIndex: index
			}
		);
	}

	deselectRowDelete() {
		this.deleteIndex = null;
		this.deleteFailed = null;
		this.deleteIdentifier = null;
	}

	deleteItem($event, cellData: any, index: Number) {
		$event.stopPropagation();
		if (this.deleteIndex === index) {
			this.deleteIndex = null;
			this.deleteFailed = null;
			this.deleteIdentifier = null;
		} else {
			this.deleteIndex = index;
			this.deleteIdentifier = cellData[this.bindIdentifierProp]
		}
	}

	async applyDelete() {
		const saveIndexForDeleteFail = this.deleteIndex;
		const saveDeleteIdentifier = this.deleteIdentifier;
		let err = await this.bindRemovalCallback(this.deleteIdentifier);
		if (err) {
			this.deleteFailed = saveIndexForDeleteFail;
			this.deleteIndex = null;
			this.deleteIdentifier = null;
		} else {
			const indexResult = this.bindData.data.map(e => e[this.bindIdentifierProp]).indexOf(saveDeleteIdentifier);
			if (indexResult !== -1) {
				const splicedData = this.bindData.data.slice();
				splicedData.splice(indexResult, 1);
				this.bindData.data = splicedData.slice();
				//render rows not working
				this.table.renderRows()
			}
			this.deleteIndex = null;
			this.deleteFailed = null;
			this.deleteIdentifier = null;

			//emits second two up to parent
			this.deletedRow.emit(
				{
					emitLength: this.bindData.data.length
				}
			);
		}
	}

	formatValue(inputValue, field) {
		let formatValue = inputValue;

		//if this is an array makes sure there are spaces
		if (Array.isArray(formatValue)) {
			formatValue = formatValue.join(', ');
		}
		// handles date formatting
		else if (field === "created_at" && (new Date(inputValue)).getTime() > 1000000000) {
			const date = new Date(inputValue);
			let stringMonth = String(date.getMonth() + 1);
			if (stringMonth.length == 1) {
				stringMonth = "0" + stringMonth;
			}
			let stringDate = String(date.getDate());
			if (stringDate.length == 1) {
				stringDate = "0" + stringDate;
			}
			const stringYear = String(date.getFullYear());
			formatValue = stringMonth + "/" + stringDate + "/" + stringYear;
		}
		return formatValue;
	}
}
