import {Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatInput} from '@angular/material';

@Component({
	selector: 'ui-input',
	templateUrl: './ui-input.component.html',
	styleUrls: ['./ui-input.component.css']
})
export class UiInputComponent implements OnInit {
	controlInput = new FormControl();
	textDifferent = false;
	textCurrent = '';
	savedSelection = '';
	saveForAPIErrorValue = null;
	saveStatus = null;

	// passed in values
	@HostBinding('class.selected-domain-id') @Input()
	bindPlaceholder = '';
	@HostBinding('class.selected-domain-id') @Input()
	bindTarget = '';
	@HostBinding('class.selected-domain-id') @Input()
	bindUniqueID = '';
	@HostBinding('class.selected-domain-id') @Input()
	bindPresetValue = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindSaveAppear = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindAPI = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindFocusOnInit = false;
	@HostBinding('class.selected-domain-id') @Input()
	bindValidataion = null;

	// passing values up
	@Output() newValue: EventEmitter<any> = new EventEmitter();

	@ViewChild('inputName') nameInput: MatInput;

	constructor() {
	}

	ngOnInit() {
		if (this.bindFocusOnInit) {
			this.nameInput.focus();
		}
	}

	ngOnChanges() {
		// makes some default changes
		if (this.bindPresetValue !== null) {
			this.controlInput.setValue(this.bindPresetValue);
			this.savedSelection = this.bindPresetValue;
		}
	}

	changeInputText($event) {
		const myValue = $event.target.value;
		this.textDifferent = myValue !== this.savedSelection;
		this.textCurrent = myValue;

		// this is for the list,
		// list maybe should not get this
		this.newValue.emit(
			{
				value: myValue,
				target: this.bindTarget,
				uniqueID: this.bindUniqueID,
				formControl: this.controlInput,
				charCode: $event.code,
				formType: 'input'
			}
		);

		//if code == enter then does the save
		if ($event.code == 'Enter' && this.bindSaveAppear) {
			this.saveItem();
		}
	}

	cancelItem() {
		this.controlInput.setValue(this.savedSelection);
		this.textCurrent = this.savedSelection;
		this.textDifferent = false;
	}

	saveItem() {
		this.saveForAPIErrorValue = this.savedSelection;
		this.savedSelection = this.textCurrent;
		this.textDifferent = false;
	}
}
