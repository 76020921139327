export class User {
	public SID: string;
	public ID: number;
	public Username: string;
	public Email: string;
	public ClientUUID: string;
	public Permissions: string[];

	constructor() {
	}
}
