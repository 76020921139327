import { Component, OnInit, ViewChild } from '@angular/core';
import {ClientService, UserService} from "../../_services";
import {FormControl} from '@angular/forms';
import {MatInput} from '@angular/material';
import {Moment} from "moment";
import * as moment from "moment";


@Component({
	selector: 'app-management',
	templateUrl: './administration.component.html',
	styleUrls: ['./administration.component.css']
})
export class AdministrationComponent implements OnInit {
	soteriaUser = false;
	createForm = new FormControl();
	createFNForm = new FormControl();
	createLNForm = new FormControl();
	createEmailForm = new FormControl();
	createRoleForm = new FormControl();
	editFNForm = new FormControl();
	editLNForm = new FormControl();
	editEmailForm = new FormControl();
	editRoleForm = new FormControl();
	filterForm = new FormControl();

	@ViewChild('inputName') nameInput: MatInput;

	pageMessage = [];
	cuserMessage = ''; // error for creating user
	euserMessage = ''; // error for editing a user
	euserMessageStyle = '';

	create_first = '';
	create_last = '';
	create_email = '';
	create_roles = [];
	create_client: any;

	filterStr = '';

	user_list = [];
	filtered_user_list = [];
	selectedUser = null;
	selectedUserRoles = [];
	role_list = [];
	selectedRole = null;
	showDeleteUser = false;
	showCreateUser = false;

	clientList = []

	// client variables
	showCreateClient = false;
	createClientName = '';
	createClientForm = new FormControl();
	cClientError = '';
	selectClient = null;
	clientError = '';
	clientUpdateBool = false;
	clientUpdateName = null;
	updateClientForm = new FormControl();
	updateClient = null;
	cClientUpdateError = '';



	//create product variables
	createProductExpireForm = new FormControl();
	selectClientProductForm = new FormControl();
	createProductExpire = null;
	showCreateClientProduct = false;
	selectClientProduct = null;
	clientProducts = []

	// role vars
	showCreateRole = false;
	createNewRoleForm = new FormControl();
	newRole = '';
	selectedRoleToView: any;
	selectedRolePermList = [];
	showCreatePerm = false;
	newPermObject = '';
	newPermObjectForm = new FormControl();
	newPermVerb = '';
	newPermVerbForm = new FormControl();
	newPermAction = '';
	newPermActionForm = new FormControl();
	permActions = ['create', 'read', 'update', 'delete', 'crud'];
	roleError = '';
	deleteRole = false;


	displayedColumns = ['Name', 'Email', 'Roles'];

	constructor(public _userservice: UserService, public _clientService: ClientService) {}

	async ngOnInit(){
		this.soteriaUser = await this._userservice.UserIsSoterian();
		let result = await this._userservice.ListUsers()
		if (result['error']) {
			this.user_list = [];
			this.pageMessage.push('Error fetching list of users.')
		} else {
			this.user_list = result['list']
			this.updateFilter('');
		}

		result = await this._userservice.ListRoles();
		if(result['error']){
			this.role_list = [];
			this.pageMessage.push('Error fetching list of roles.')
		}else {
			this.role_list = result['list'];
		}

		if (this.soteriaUser){

			// get client list
			let result = await this._clientService.LoadClientList();
			if (result['error']){
				this.clientList = [];
				this.pageMessage.push(('Error fetching client list.'))
			}else{
				this.clientList = result['clients'];
			}

			// get client product list
			let products = await this._clientService.LoadProducts();
			if (products['error']){
				this.clientProducts = [];
				this.pageMessage.push(('Error fetching client products.'))
			}else {
				this.clientProducts = products['products'];
			}
		}
	}

	updateFilter(filterStr) {
		this.filtered_user_list = this.user_list.filter((user) => {
			return user.first_name.toLowerCase().includes(filterStr.toLowerCase()) ||
				user.last_name.toLowerCase().includes(filterStr.toLowerCase()) ||
				user.email.toLowerCase().includes(filterStr.toLowerCase()) ||
				user.roles.filter((role) => role.name.toLowerCase().
													includes(filterStr.toLowerCase())).length !== 0
		})
	}

	confirmCreateUser(){
		this.create_first = '';
		this.create_last = '';
		this.create_email = '';
		this.create_roles = [];
		this.create_client = null;
		this.showCreateUser = true;
		this.cancelDeleteUser()
	}

	cancelCreateUser(){
		this.showCreateUser = false;
		this.cuserMessage = '';
	}

	createFieldsEmpty(){
		if (this.create_first !== '' && this.create_last !== '' && this.create_email
			 && this.create_roles.length != 0){
				 return false;
			 }
		return true;
	}

	async submitCreateUser() {
		if (this.createFieldsEmpty()){
			return;
		}
		if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.
				test(this.create_email)) {
					this.cuserMessage = 'Email address is invalid'
					return
		}
		let result = await this._userservice.CreateUser(
			this.create_email, this.create_first, this.create_last,
			this.create_client.client_uuid)
		if (result['error']) {
			this.cuserMessage = 'Failed to create user'
		} else {
			this.filterStr = '';

			this.user_list.unshift({userID: result['userId'],
													email: this.create_email, roles: this.create_roles,
													first_name: this.create_first,
													last_name: this.create_last})
			this.selectedUser = this.user_list[0];
			this.selectedUserRoles = this.user_list[0].roles;

			let report = false;
			for (let i=0; i < this.create_roles.length; i++){
				let error = await this.AddRoleToUser(result['userId'], this.create_roles[i]);
				if (error) {
					report = true
				}
			}

			if (report) {
				this.euserMessage = 'Failed to add role.'
				this.euserMessageStyle = 'error'
			}
			this.updateFilter(this.filterStr);
			this.create_email = '';
			this.cuserMessage = '';
			this.showCreateUser = false;
		}
	}

	confirmDeleteUser(user){
		this.showDeleteUser = true;
		this.selectedUser = user;
		this.cancelCreateUser()
	}

	cancelDeleteUser() {
		this.showDeleteUser = false;
		this.selectedUser = null;
	}

	async submitDeleteUser(){
		this.euserMessage = 'Saving...'
		let result = await this._userservice.DeleteUser(this.selectedUser.userID);
		if (result){
			this.euserMessage = 'Failed to delete user.'
		}else {
			let index = this.user_list.indexOf(this.selectedUser);
			if (index !== -1) {
				this.user_list.splice(index, 1);
			}
			this.updateFilter(this.filterStr)
			this.selectedUser = null;
			this.euserMessage = 'Success'
		}
		this.showDeleteUser = false;
	}

	// created for the use of role list drop down
	// without this currently selected roles are not prepopulated
	compareFn (r1, r2) {
		return r1 && r2 ? r1.role_id == r2.role_id : r1 === r2
	}

	async selectUser(user) {
		this.cancelCreateUser()
		this.cancelDeleteUser()

		// must be done so that the error/success message doesn't persist through
		// changing users
		this.euserMessage = '';
		this.euserMessageStyle = '';

		this.selectedUser = user;
		this.selectedUserRoles = user.roles;
	}

	async deselectUser() {
		this.cancelDeleteUser();
		this.selectedUser = null;
		this.selectedUserRoles = [];
	}

	async UpdateUserRoles($event) {
		// aitem is the item currently being changed
		let aitem = await $event.source._keyManager.activeItem
		if (aitem._selected) {
			this.AddRoleToUser(this.selectedUser.userID, aitem.value)
		} else {
			this.DeleteRoleFromUser(this.selectedUser.userID, aitem.value)
		}
	}

	async AddRoleToUser(userID, role){
		this.euserMessage = 'Saving...'
		let result = await this._userservice.GrantUserRole(userID, role.role_id);
		if (result){
			this.euserMessage = 'Failed to add role.'
			this.euserMessageStyle = 'error'
			return 'error'
		}else{
			this.selectedUser.roles = this.selectedUserRoles;
			this.euserMessage = 'Success'
			this.euserMessageStyle = 'success'
		}
	}

	async DeleteRoleFromUser(userID, role){
		this.euserMessage = 'Saving...'
		let result = await this._userservice.DeleteUserRole(userID, role.role_id);
		if (result){
			this.euserMessage = 'Failed to delete role.'
			this.euserMessageStyle = 'error'
		}else{
			this.selectedUser.roles = this.selectedUserRoles;
			this.euserMessage = 'Success'
			this.euserMessageStyle = 'success'
		}
	}


	// role methods
	confirmCreateRole(){
		this.roleError = '';
		this.newRole = '';
		this.showCreateRole = true;
	}

	cancelCreateRole(){
		this.showCreateRole = false;
		this.roleError = '';
	}

	async submitCreateRole() {
		let result = await this._userservice.CreateRole(this.newRole);
		if (result['error']) {
			this.roleError = 'Error creating role'
		} else {
			//adds this as the first role in the role list
			this.role_list.unshift({name: result['name'], role_id: result['role_id']})
			this.newRole = ''
			this.showCreateRole = false;
			this.selectedRoleToView = this.role_list[0];
			this.selectedRolePermList = [];
			this.roleError = '';
		}
	}

	createRoleFieldEmpty(){
		if(this.newRole !== ''){
			return true;
		}
		return false;
	}

	confirmDeleteRole(){
		this.deleteRole = true;
	}

	cancelDeleteRole(){
		this.deleteRole = false;
	}

	async submitDeleteRole() {
		let result = await this._userservice.DeleteRole(this.selectedRoleToView.role_id)
		if (result){
			// TODO: handle error
			this.roleError = 'Error deleting role';
		}else {
			let index = this.role_list.indexOf(this.selectedRoleToView);
			if (index !== -1) {
				this.role_list.splice(index, 1);
			}
			this.selectedRoleToView = null;
			this.roleError = '';
		}
	}

	async getRolePerms(){
		let result = await this._userservice.GetRolePerm(this.selectedRoleToView.role_id)
		if (result['error']){
			this.roleError = 'Error getting permissions list for the selected role'
		}else{
			this.selectedRolePermList = result['list']
			this.roleError = '';
		}
	}

	confirmCreatePerm(){
		this.newPermAction = '';
		this.newPermObject = '';
		this.newPermVerb = '';
		this.roleError = '';
		this.showCreatePerm = true;
	}

	async deletePerm(perm: any){
		let result = await this._userservice.DeleteRolePerm(perm.perm, this.selectedRoleToView.role_id)
		if (result){
			this.roleError = 'Error deleting permission from role';
		}else{
			this.selectedRolePermList.splice(this.selectedRolePermList.indexOf(perm), 1);
			this.roleError = '';
		}
	}

	async submitCreatePerm() {
		let newPerm = this.newPermObject + '.' + this.newPermVerb + '.' + this.newPermAction
		let result = await this._userservice.CreateRolePerm(newPerm, this.selectedRoleToView.role_id)
		if (result){
			this.roleError = 'Error creating role permission'
		}else {
			// when pulling the role permission list from authapi, it can return null.
			// this check is to prevent a null error
			if (!this.selectedRolePermList){
				this.selectedRolePermList = []
			}
			this.selectedRolePermList.push({perm: newPerm})
			this.newPermAction = '';
			this.newPermObject = '';
			this.newPermVerb = '';
			this.showCreatePerm = false;
			this.roleError = '';
		}
	}

	createPermFieldEmpty(){
		if ((this.newPermAction !== '' || this.newPermObject !== '' || this.newPermAction !== '')
			&& !this.checkPermExisit()){
			return true;
		}
		return false;
	}

	checkPermExisit(){
		let newPerm = this.newPermObject + '.' + this.newPermVerb + '.' + this.newPermAction
		if(this.selectedRolePermList){
			for(let i = 0; i < this.selectedRolePermList.length; i++){
				if (this.selectedRolePermList[i].perm === newPerm){
					return true;
				}
			}
		}
		return false;
	}

	cancelCreatePerm(){
		this.newPermAction = '';
		this.newPermObject = '';
		this.newPermVerb = '';
		this.roleError = '';
		this.showCreatePerm = false;
	}

	confirmCreateClient(){
		this.createClientName = '';
		this.showCreateClient = true;
		// this.cancelDeleteUser()
	}

	cancelCreateClient(){
		this.showCreateClient = false;
		this.clientError = '';
	}

	createClientFieldEmpty(){
		if (this.createClientName !== ''){
			return false;
		}
		return true;
	}

	async submitCreateClient() {
		if (this.createClientFieldEmpty()) {
			return;
		}

		let result = await this._clientService.CreateClient(this.createClientName)
		if (result['error']){
			this.clientError = 'Error creating client';
		}else{
			//add the new client to the list
			this.clientList.unshift({client_name: this.createClientName, client_uuid: result['uuid']})
			//set the selected client and load their info
			this.selectClient = this.clientList[0];
			this.GetClient()
			this.createClientName = '';
			this.cClientError = '';
			this.showCreateClient = false;
		}
	}

	async GetClient(){
		let client = await this._clientService.GetClientInfo(this.selectClient.client_uuid);
		// if not already loaded, setting client products is not required.
		// when loading /client/list client products are not already set
		// and have to be set this way
		if (!this.selectClient.ClientProducts){
			this.selectClient.ClientProducts = client.ClientProducts;
		}
		//if already loaded setting an ID is not required
		if(!this.selectClient.ID){
			this.selectClient.ID = client.ID;
		}
	}

	async deleteClientProduct(product: any){
		let result = await this._clientService.DeleteClientProduct(product.Product.ID)
		if (result){
			this.clientError = 'Error deleting client product'
		}else{
			this.selectClient.ClientProducts.splice(this.selectClient.ClientProducts.indexOf(product), 1);
		}
	}

	confirmCreateClientProduct(){
		this.showCreateClientProduct = true;
	}

	async submitCreateClientProduct() {
		let tempExpire = moment(this.createProductExpire)
		let result = await this._clientService.AddClientProduct(
			tempExpire, this.selectClient.ID, this.selectClientProduct.ID)
		if (result){
			this.clientError = 'Error creating client product'
		}else{
			this.selectClient.ClientProducts.push({Expires: tempExpire, Product: this.selectClientProduct})
			this.selectClientProduct = null;
			this.showCreateClientProduct = false;
		}
	}

	cancelCreateClientProduct(){
		this.showCreateClientProduct = false;
		this.selectClientProduct = null;
		this.createProductExpire = null;
		this.clientError = '';
	}

	createClientProductFieldEmpty() {
		if (this.selectClientProduct && !this.clientDoesNotHaveProduct()){
			return false;
		}
		return true;
	}

	clientDoesNotHaveProduct(){
		if (this.selectClientProduct){
			for (let i = 0; i < this.selectClient.ClientProducts.length; i++ ){
				if (this.selectClient.ClientProducts[i].Product.product_name === this.selectClientProduct.product_name){
					return true;
				}
			}
		}
		return false;
	}

	showUpdateClientName() {
		this.clientUpdateBool = true;
		this.updateClient = this.selectClient;
	}


	cancelClientUpdate() {
		this.clientUpdateBool = false;
		this.clientUpdateName = '';
		this.cClientUpdateError = '';
	}

	async submitClientNameUpdate() {
		let result = await this._clientService.UpdateClientName(this.selectClient.client_uuid, this.clientUpdateName);

		if (result['error']){
			this.cClientUpdateError = 'error';
		} else {
			// get the index of select client so it can be updated
			let updatedClientIndex = this.clientList.indexOf(this.selectClient);
			// update the client in the list
			this.clientList[updatedClientIndex] = result['client'];
			// update select client to reflect the change
			this.selectClient = this.clientList[updatedClientIndex];

			// reset the values for updating a client
			this.clientUpdateBool = false;
			this.clientUpdateName = '';
		}
	}






}
