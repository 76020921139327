<div
  class="lexico_tickets_container"
  [attr.ticket-view]="alertShow"
  [attr.user-view]="soteriaUser"
>
  <div class="layout-viz">
    <div class="layout-donuts">
      <div [ngClass]="{ 'non-solo-donut': soteriaUser, 'viz-holder': true }">
        <content-donut
          [bindID]="idDonut1"
          [bindData]="donutDataPriorityPrep"
          [bindTitle]="'Ticket Priority'"
        ></content-donut>
      </div>
      <div *ngIf="soteriaUser" class="viz-holder">
        <content-donut
          [bindID]="idDonut2"
          [bindData]="donutDataStatusPrep"
          [bindTitle]="'Ticket Status'"
        ></content-donut>
      </div>
    </div>
    <div class="layout-bars">
      <div class="viz-holder">
        <content-line-graph
          [bindID]="idLine1"
          [bindData]="lineDataSensorsOverTime"
          [bindTitle]="'Sensors over time'"
        ></content-line-graph>
      </div>
      <div class="viz-holder">
        <content-line-graph
          [bindID]="idLine2"
          [bindData]="lineDataEventsOverTime"
          [bindTitle]="'Events over time'"
        ></content-line-graph>
      </div>
    </div>
  </div>
  <div class="layout-table">
    <layout-filter-box
      *ngIf="!(bulkSelectedTickets && bulkSelectedTickets.length > 0)"
      [bindDate]="date"
      (setDate)="updateDate()"
      [bindTitle]="filterBoxTitle"
      [bindButton]="filterBoxButton"
      [bindFeatures]="filterBoxFeatures"
      [bindFields]="filterBoxAllFields"
      (buttonClick)="nextTicket()"
      [bindData]="data"
      [bindButtonInclude]="filterBoxButtonInclude"
      [bindButtonAltText]="filterBoxAltMessage"
      [bindRefreshButton]="true"
      (refreshData)="updateDate()"
    >
    </layout-filter-box>

    <!--
			TODO: style this or move it so that it looks better/ fits into the app
				better
		-->
    <div class="saving-status">
      <div *ngIf="TicketLoadStatus == 'fail'" id="status-nosave">
        failed to load ticket
      </div>
    </div>

    <div
      class="filter-container"
      *ngIf="bulkSelectedTickets && bulkSelectedTickets.length > 0"
    >
      <div class="filter-title-row">
        <div class="bulk-select-title">Bulk Select Options</div>
        <div class="saving-status">
          <div *ngIf="bulkSaveStatus == 'unsaved'" id="status-unsaved">
            unsaved
          </div>
          <div *ngIf="bulkSaveStatus == 'saving'" id="status-saving">
            saving
          </div>
          <div *ngIf="bulkSaveStatus == 'saved'" id="status-saved">saved</div>
          <div *ngIf="bulkSaveStatus == 'nosave'" id="status-nosave">
            did not save
          </div>
        </div>
        <div class="filter-button-group">
          <button
            (click)="bulkIrrelevant($event)"
            id="button_closed_irrevelent"
          >
            Close (irrevelent)
          </button>
          <button (click)="clearBulk()" id="button_deselect">
            Deselect All
          </button>
        </div>
      </div>

      <div class="filter-options">
        <div class="filter-column">
          <div class="filter-item">
            <ui-dropdown
              [bindTarget]="{}"
              [bindPlaceholder]="'Risk'"
              [bindOptions]="optionsRisk"
              [bindIcons]="iconsRisk"
              [bindUniqueID]="'risk'"
              (sendValue)="bulkRisk($event)"
            ></ui-dropdown>
          </div>
        </div>
        <div class="filter-column">
          <div class="filter-item">
            <ui-dropdown
              [bindTarget]="{}"
              [bindPlaceholder]="'Priority'"
              [bindOptions]="optionsPriority"
              [bindIcons]="iconsPriority"
              [bindUniqueID]="'priority'"
              (sendValue)="bulkPriority($event)"
            ></ui-dropdown>
          </div>
        </div>
        <div class="filter-column">
          <div class="filter-item"></div>
        </div>
      </div>

      <ui-textarea
        [bindData]="{}"
        [bindDataField]="'description'"
        [bindTarget]="null"
        [bindPlaceholder]="'Description'"
        [bindIdentifier]="''"
        [bindSaveAppear]="true"
        (newValue)="bulkDescription($event)"
      ></ui-textarea>
    </div>

    <content-table
      [bindSort]="'created_at'"
      [bindSortDirection]="'desc'"
      [bindData]="data"
      [bindTableConfig]="tableConfig"
      [bindSelectedItemIndex]="selectedItemIndex"
      [bindMessage]="tableMessage"
      [bindBulkSelected]="bulkSelectedTickets"
      [bindBulkIdentifier]="'id'"
      (setRow)="selectTableRow($event)"
    >
    </content-table>
  </div>
  <event-view
    *ngIf="selectedItemData && !soteriaUser"
    [bindTicket]="selectedItemData"
    [bindSoteriaUser]="soteriaUser"
    [bindTagsList]="tagsList"
  ></event-view>
  <lexico-tickets-edit
    *ngIf="selectedItemData && soteriaUser"
    [bindTicket]="selectedItemData"
    [bindAlertShow]="alertShow"
    [bindTagsList]="tagsList"
    [bindTagsListID]="tagsListID"
    [bindAssigneesList]="filterListAssignee"
    [bindSoteriaUser]="soteriaUser"
    [bindClientList]="clientlist"
    (closeAlert)="closeAlertSaveData()"
    (nextTicket)="nextTicket()"
  ></lexico-tickets-edit>
</div>
