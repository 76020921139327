import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http'

@Component({
	selector: 'ui-list',
	templateUrl: './ui-list.component.html',
	styleUrls: ['./ui-list.component.css']
})
export class UiListComponent implements OnInit {
	showInstructions = true;
	showNew = false;
	showNewButton = true;
	showList = false;
	indexDelete = null;
	indexInvalid = false;
	itemList = [];
	// this is currently being set a string 'both' 'true' or 'false'
	// this is kind of a lazy cheat by Jim
	newItemSave = 'false';
	currentNewValue = "";
	deleteDisable = false;
	hoverOver = false;
	screenshotMessage = 'Drag and Drop Screenshot Here';
	errorMessage = '';
	screenshotName = '';
	saveStatus = null;

	@HostBinding('class.selected-domain-id') @Input()
	bindPlaceholder = '';
	@HostBinding('class.selected-domain-id') @Input()
	bindTarget = '';
	@HostBinding('class.selected-domain-id') @Input()
	bindPresetValue = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindTitle = "";
	@HostBinding('class.selected-domain-id') @Input()
	bindType = "input";
	@HostBinding('class.selected-domain-id') @Input()
	bindValidataion = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindAPI = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindNewInclude = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindInputList = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindEditableField = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindDisplayFields = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindEmptyText = 'This list is empty. Use the "new" button above to add an item.';
	@HostBinding('class.selected-domain-id') @Input()
	bindNewText = "+ New item";
	@HostBinding('class.selected-domain-id') @Input()
	bindDelete = false;

	// passing values up
	@Output() newValue: EventEmitter<any> = new EventEmitter();

	constructor(private http: HttpClient) {
	}

	ngOnInit() {
	}

	ngOnChanges() {
		if (typeof this.bindPresetValue == "string") {
			this.itemList = this.bindPresetValue.split(',');
		} else if (typeof this.bindPresetValue == "object") {
			this.itemList = [];
			for (let i = 0; i < this.bindPresetValue.length; i++) {
				this.itemList.push(this.bindPresetValue[i].body);
			}
		} else {
			this.bindPresetValue = [];
		}

		if (this.itemList !== null && this.itemList[0] === "") {
			this.itemList = [];
		}

		if (this.itemList !== null && this.itemList.length <= 0) {
			this.showInstructions = true;
			this.showList = false;
		} else {
			this.showInstructions = false;
			this.showList = true;
		}

		this.showNew = false;
		this.showNewButton = true;
		this.indexDelete = null;
		this.indexInvalid = false;
		this.errorMessage = '';
		this.currentNewValue = "";
		this.newItemSave = 'false';
		this.saveStatus = null;
	}

	addNew() {
		this.showNew = true;
		this.showNewButton = false;
		this.showInstructions = false;
		this.saveStatus = null;
	}

	editItem($event) {
		this.currentNewValue = $event.value;
		this.indexInvalid = false;
		this.errorMessage = '';
		if ($event.value !== "") {
			this.newItemSave = 'true';
		} else {
			this.newItemSave = 'false';
		}

		if ($event.charCode == 'Enter') {
			this.saveItem();
		}
	}

	cancelItem() {
		if (this.itemList.length === 0) {
			this.showInstructions = true;
			this.showList = false;
			this.currentNewValue = "";
		}
		this.showNew = false;
		this.showNewButton = true;
		this.newItemSave = 'false';
		this.indexInvalid = false;
		this.errorMessage = '';
		this.indexDelete = null;
		this.saveStatus = null;
	}

	saveItem() {
		// validates value first
		// assumes INVALID, has to prove it is valid
		// this regex is from https://emailregex.com/
		let myValid =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.currentNewValue);

		let myDupe = false;
		if (this.itemList.indexOf(this.currentNewValue) !== -1) {
			myDupe = true;
		}

		// IF INVALID
		if (this.bindValidataion == 'email' && !myValid) {
			this.newItemSave = 'false';
			this.indexInvalid = true;
			this.errorMessage = 'This is not a valid email address.';
		}
		// looks for duplicate
		else if (this.bindValidataion == 'email' && myDupe) {
			this.newItemSave = 'false';
			this.indexInvalid = true;
			this.errorMessage = 'This email already exists.';
		}
		// IF VALID
		else {
			if (this.bindAPI !== null) {
				this.saveStatus = 'saving';
				this.newItemSave = 'both';
				let params = new HttpParams().set(this.bindAPI.identifierProp, this.bindAPI.mainIdentifier).set(this.bindAPI.valueProp, this.currentNewValue);
				this.http.post(this.bindAPI.link, {}, {params}).toPromise().then((result) => {
					const newObj = {
						"body": this.currentNewValue
					};
					if (this.bindDisplayFields !== null) {
						for (let i = 0; i < this.bindDisplayFields.length; i++) {
							newObj[this.bindDisplayFields[i]] = result[this.bindDisplayFields[i]];
						}
					}
					this.bindPresetValue.push(newObj);

					this.itemList.push(this.currentNewValue);
					this.resetAfterSave()
				}).catch(() => {
					this.saveStatus = 'nosave';
					this.newItemSave = 'true';
				});
			} else {
				this.itemList.push(this.currentNewValue);
				this.newValue.emit(
					{
						value: this.itemList,
						target: this.bindTarget
					}
				);
				this.resetAfterSave();
			}

			this.errorMessage = '';
			this.indexInvalid = false;
			this.indexDelete = null;
		}
	}

	deleteItem(index: Number) {
		this.indexDelete = index;
		this.showNewButton = false;
	}

	cancelDelete() {
		this.showNew = false;
		this.showNewButton = true;
		this.indexDelete = null;
		this.indexInvalid = false;
		this.errorMessage = '';
		this.currentNewValue = "";
		this.newItemSave = 'false';
		this.saveStatus = null;
	}

	// delete currently only works with a global delete, doesn't do it one by one
	// all those delete instances were removed
	applyDelete() {
		this.itemList.splice(this.indexDelete, 1);
		this.newValue.emit(
			{
				value: this.itemList,
				target: this.bindTarget
			}
		);

		this.resetAfterSave();
	}

	resetAfterSave() {
		this.saveStatus = null;
		this.showNew = false;
		this.showNewButton = true;
		this.newItemSave = 'false';
		this.currentNewValue = "";
		this.indexDelete = null;
		this.deleteDisable = false;
		if (this.itemList.length == 0) {
			this.showInstructions = true
		} else {
			this.showInstructions = false;
			this.showList = true;
		}
	}

	formatValue(inputValue) {
		let formatValue = inputValue;
		if ((new Date(inputValue)).getTime() > 0) {
			const date = new Date(inputValue);
			let stringMonth = String(date.getMonth() + 1);
			if (stringMonth.length == 1) {
				stringMonth = "0" + stringMonth;
			}
			let stringDate = String(date.getDate());
			if (stringDate.length == 1) {
				stringDate = "0" + stringDate;
			}
			const stringYear = String(date.getFullYear());
			formatValue = stringMonth + "/" + stringDate + "/" + stringYear;
		}
		return formatValue;
	}

	dragEnter($event) {
		$event.preventDefault();
		$event.stopPropagation();
		if (!this.hoverOver) {
			this.hoverOver = true;
		}
		if (this.errorMessage !== '') {
			this.errorMessage = '';
		}
	}

	dragLeave($event) {
		$event.preventDefault();
		$event.stopPropagation();
		if (this.hoverOver) {
			this.hoverOver = false;
		}
	}

	dragDrop($event) {
		$event.preventDefault();
		$event.stopPropagation();
		if (this.hoverOver) {
			this.hoverOver = false;
		}

		let dt = $event.dataTransfer;
		let files = dt.files;
		const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
		const fileIsImage = files[0] && acceptedImageTypes.includes(files[0]['type']);
		this.screenshotName = files[0]['name'];

		if (files.length > 1) {
			this.errorMessage = 'Upoad only 1 file at a time.';
		}
		// check it is an image file
		else if (!fileIsImage) {
			this.errorMessage = this.screenshotName + ' is not a valid image file.';
		}
		// if so does data transfer
		else {
			//does data transfer
			this.errorMessage = '';

			// changes messaging to uploading
			this.screenshotMessage = 'Uploading Screenshot';

			//after uploading done shows screenshot

			//has to handle error uploading

			// while uploading needs to shut down interactivity on the drop area so
			// that more files aren't sent at the same time
		}
	}
}
