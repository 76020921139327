<div class='alert-item'
     [attr.data-type]="bindType"
>
    <div class='alert-row1'>
        <div class='alert-row1a'>
            <div id='alert-title' class='alert-col1a'>{{bindType}}</div>
            <div *ngIf="soteriaUser">
                <mat-checkbox
					[checked]="bindData.irrelevant"
					(change)="markIrrelevant($event)"
					matTooltip="Marks alert client-unreadable"
				>
							  Irrelevant
				</mat-checkbox>
            </div>
            <div class='saving-status'>
                <div *ngIf='saveStatus == "unsaved"' id='status-unsaved'>unsaved</div>
                <div *ngIf='saveStatus == "saving"' id='status-saving'>saving</div>
                <div *ngIf='saveStatus == "saved"' id='status-saved'>saved</div>
                <div *ngIf='saveStatus == "nosave"' id='status-nosave'>did not save</div>
            </div>
            <div id='alert-timestamp' class='alert-col1b'>{{timestamp}}</div>
            <div  *ngIf='soteriaUser' id='alert-details'
                 (click)="selectRow();"
            >
                <span>details</span>
                <mat-icon  [inline]="true" class="right-arrow">arrow_forward_ios</mat-icon>
            </div>
        </div>
        <div class='alert-row1b'>
            <div class='alert-col1'>
                <div class='alert-row1b-1'>
                    <div class='alert-col1a alert-name'>score</div>
                    <div class='alert-col1b'>{{bindData.score ? bindData.score : 'n/a'}}</div>
                </div>
                <div class='alert-row1b-2'>
                    <div class='alert-col1a alert-name'>hostname</div>
                    <div *ngIf='bindType == "Network"'
                         class='alert-col1b'>{{bindData && bindData.data && bindData.data.host ? bindData.data.host : 'n/a'}}</div>
                    <div *ngIf='bindType == "EDR"'
                         class='alert-col1b'>{{bindData && bindData.data && bindData.data.routing && bindData.data.routing.hostname ? bindData.data.routing.hostname : 'n/a'}}</div>
                </div>
                <div class='alert-row1b-3'>
                    <div class='alert-col1a alert-name'>Time</div>
                    <div class='alert-col1b'>{{bindData.created_at ? bindData.created_at : 'n/a'}}</div>
                </div>
            </div>
            <div class='alert-col2'>
                <div *ngIf='bindType == "EDR"' class='alert-row1b-1'>
                    <div class='alert-col2a alert-name'>file is signed</div>
                    <div class='alert-col2b'>{{event['FILE_IS_SIGNED'] ? event['FILE_IS_SIGNED'] : 'n/a'}}</div>
                </div>

                <div *ngIf='bindType == "EDR"' class='alert-row1b-2'>
                    <div class='alert-col2a alert-name'>username</div>
                    <div class='alert-col2b'>{{event['USER_NAME'] ? event['USER_NAME'] : 'n/a'}}</div>
                </div>

                <div *ngIf='bindType == "Network"' class='alert-row1b-3'>
                    <div class='alert-callout-block alert-callout-shift'>
                        <div class='box-row'>
                            <div class='alert-name box-1-col-1'>src IP</div>
                            <div class='box-1-col-2'>{{bindData && bindData.data && bindData.data.source_ip ? bindData.data.source_ip : 'n/a'}}</div>
                            <div class='alert-name box-1-col-3'>src PORT</div>
                            <div class='box-1-col-2'>{{bindData && bindData.data && bindData.data.source_port ? bindData.data.source_port : 'n/a'}}</div>
                        </div>
                        <div class='box-row'>
                            <div class='alert-name box-1-col-1'>dest IP</div>
                            <div class='box-1-col-2'>{{bindData && bindData.data && bindData.data.destination_ip ? bindData.data.destination_ip : 'n/a'}}</div>
                            <div class='alert-name box-1-col-3'>dest PORT</div>
                            <div class='box-1-col-2'>{{bindData && bindData.data && bindData.data.destination_port ? bindData.data.destination_port : 'n/a'}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='alert-col3'>
                <div *ngIf='bindType == "EDR"' class='alert-row1b-1'>
                    <div class='alert-col3a alert-name alert-name'>commandline</div>
                    <div class='alert-col3b'>{{event['COMMAND_LINE'] ? event['COMMAND_LINE'] : 'n/a'}}</div>
                </div>
                <div *ngIf='bindType == "Network"' class='alert-row1b-2'>
                    <div class='alert-col3a alert-name'>protocol</div>
                    <div class='box-1-col-2'>{{bindData && bindData.data && bindData.data.protocol ? bindData.data.protocol : 'n/a'}}</div>
                </div>
                <div class='alert-row1b-3'>
                    <div class='alert-col3a alert-name'>rule name</div>
                    <div *ngIf='bindType == "Network"'
                         class='alert-col3b'>{{bindData && bindData.data && bindData.data.alert ? bindData.data.alert : 'n/a'}}</div>
                    <div *ngIf='bindType == "EDR"'
                         class='alert-col3b'>{{bindData && bindData.data && bindData.data.cat ? bindData.data.cat : 'n/a'}}</div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf='bindData.data.note || bindType == "EDR" || bindData.data.subnote' class='alert-row2'>
        <div *ngIf='bindType == "Network"' class='alert-row2a'>
            <div class="alert-row-100 alert-long-cell alert-row2a-1"
                 *ngIf='bindData && bindData.data && bindData.data.note'>
                <div class='alert-col1a alert-name'>note</div>
                <div class='alert-long-paragraph'>{{bindData && bindData.data && bindData.data.note ? bindData.data.note : 'n/a'}}</div>
            </div>
            <div class="alert-row-100 alert-long-cell" *ngIf='bindData && bindData.data && bindData.data.subnote'>
                <div class='alert-col1a alert-name'>subnote</div>
                <div class='alert-long-paragraph'>{{bindData && bindData.data && bindData.data.subnote ? bindData.data.subnote : 'n/a'}}</div>
            </div>
        </div>
        <div *ngIf='bindType == "EDR"' class='alert-row2b'>
            <div class='alert-long-cell alert-callout-block'>
                <div class='box-row'>
                    <div class='alert-name alert-col1a'>file path</div>
                    <div class='box-2-col-2'>{{event['FILE_PATH'] ? event['FILE_PATH'] : 'n/a'}}</div>
                    <div class='alert-name alert-col1a'>file hash</div>
                    <div class='box-2-col-4'>{{event['HASH'] ? event['HASH'] : 'n/a'}}</div>
                </div>
                <div class='box-row' *ngIf='event["PARENT"]'>
                    <div class='alert-name alert-col1a'>parent file path</div>
                    <div class='box-2-col-2'>{{event['PARENT']['FILE_PATH'] ? event['PARENT']['FILE_PATH'] : 'n/a'}}</div>
                    <div class='alert-name alert-col1a'>parent file hash</div>
                    <div class='box-2-col-4'>{{event['PARENT']['HASH'] ? event['PARENT']['HASH'] : 'n/a'}}</div>
                </div>
            </div>
            <div *ngIf='bindType == "EDR" && soteriaUser' class='btn-group'>
				        <button matTooltip="LimaCharlie Timeline" type="button" class="btn btn-primary"
                                (click)="goToLiveView(bindData.data.routing.sid, bindData.data.routing.oid,
                                bindData.data.routing.event_time, bindData.data.routing.this )">
					          <mat-icon>restore</mat-icon>
				        </button>
			      </div>
        </div>
    </div>
    <div class="alert-annotations" *ngIf="bindData.annotations">
        <ui-list
                *ngIf='bindData && !soteriaUser'
                [bindTitle]="'Annotations'"
                [bindPlaceholder]="'Add annotation'"
                [bindEmptyText]=""
                [bindNewText]="'+ New Annotation'"
                [bindNewInclude]="soteriaUser"
                [bindType]="'textArea'"
                [bindInputList]="annotationFields"
                [bindPresetValue]="bindData.annotations"
                [bindEditableField]="'body'"
                [bindDisplayFields]="['user','created_at']"
                [bindAPI]="selectedAPI"
        ></ui-list>
    </div>
</div>

