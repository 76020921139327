<!-- Template for recursive object viewing -->
<ng-template #nodeTemplateRef let-node>
	<div class="container-columns">

		<div class="container-main">
			<div class='container-item' *ngFor="let item of node | keyvalue">
				<div *ngIf="!isObject(item.value)" class='panel-row'>
					<div class='endpoint-col-1-a'>{{item.key}}</div>
					<div class='panel-content'>{{item.value}}</div>
				</div>
			</div>
		</div>

		<div class="container-sub">
			<div *ngFor="let item of node | keyvalue">
				<div *ngIf="isObject(item.value)" class="panel-column">
					<hr>
					<div *ngIf="isObject(item.value)" class='panel-sub-head'>{{item.key}}</div>
					<div class="panel-row">
						<ng-template
								[ngTemplateOutlet]="nodeTemplateRef"
								[ngTemplateOutletContext]="{ $implicit: item.value }">
						</ng-template>
					</div>
				</div>
			</div>
		</div>

	</div>
</ng-template>



<div
		class='panel-container'
		[ngClass]="{'panel-show': bindPanelShow == true, 'panel-hide': bindPanelShow == false, 'panel-load': bindPanelShow == null}"
>
	<div class='panel-header'>
		<div id='panel-name'>{{bindAlertsType}}</div>
		<div id='panel-timestamp'>{{this.timestamp}}</div>
		<mat-icon [inline]="true" id='icon-close' class="white" (click)="cancelPanel()">close</mat-icon>
	</div>
	<div class='panel-body'>
		<ng-template
				[ngTemplateOutlet]="nodeTemplateRef"
				[ngTemplateOutletContext]="{ $implicit: (this.bindData ? this.bindData.data : null) }">
		</ng-template>
		<hr>
		<div>
			<ui-list
					*ngIf='bindData && bindSoteriaUser'
					[bindTitle]="'Annotations (client viewable)'"
					[bindPlaceholder]="'Add annotation'"
					[bindEmptyText]="'No current annotations.'"
					[bindNewText]="'+ New Annotation'"
					[bindNewInclude]="bindSoteriaUser"
					[bindType]="'textArea'"
					[bindInputList]="annotationFields"
					[bindPresetValue]="bindData.annotations"
					[bindEditableField]="'body'"
					[bindDisplayFields]="['user','created_at']"
					[bindAPI]="selectedAPI"
			></ui-list>
			<ui-list
					*ngIf='bindData && !bindSoteriaUser'
					[bindTitle]="'Annotations'"
					[bindPlaceholder]="'Add annotation'"
					[bindEmptyText]="'No current annotations.'"
					[bindNewText]="'+ New Annotation'"
					[bindNewInclude]="bindSoteriaUser"
					[bindType]="'textArea'"
					[bindInputList]="annotationFields"
					[bindPresetValue]="bindData.annotations"
					[bindEditableField]="'body'"
					[bindDisplayFields]="['user','created_at']"
					[bindAPI]="selectedAPI"
			></ui-list>
		</div>
	</div>
</div>
