<div class='line-container'>
	<div class='line-title'>{{bindTitle}}</div>
	<div class='loading-container' *ngIf="loading && !error">
		<mat-spinner mode="indeterminate"></mat-spinner>
	</div>
	<div class='error-container' *ngIf="error">
		<div>There was an error loading the data.</div>
	</div>
	<div *ngIf="!error" class='viz-container-row'>
		<div (window:resize)="onResize()" class='line-viz'>
			<canvas #tref id="{{bindID}}"></canvas>
		</div>
		<!-- <div #legendref class='line-legend'></div> -->
	</div>
</div>
