<div
		class='lexico_broadcaster_container'
>
	<layout-panel-broadcasters
			[bindPanelShow]="panelShow"
			[bindData]="selectedItemData"
            [bindCreate]="newBroadcaster"
			[bindCreateCallback]="cbroadcastCallback"
			[bindUpdateCallback]="ubroadcastCallback"
			(closePanel)="closePanelSaveData($event)"
	>
	</layout-panel-broadcasters>
	<div class='table-holder'>
		<layout-filter-box
				[bindTitle]="filterBoxTitle"
				[bindButton]="filterBoxButton"
				[bindFeatures]="filterBoxFeatures"
				[bindButtonInclude]=true
				[bindFields]="filterBoxAllFields"
				(buttonClick)="newItem()"
				[bindData]="data"
		>
		</layout-filter-box>
		<content-table
				[bindSort]='"name"'
				[bindSortDirection]='"asc"'
				[bindData]="data"
				[bindTableConfig]="tableConfig"
				[bindSelectedItemIndex]="selectedItemIndex"
				[bindMessage]="tableMessage"
				(setRow)="selectTableRow($event)"
				(deletedRow)="deleteTableRow($event)"
				[bindIdentifierProp]="'id'"
				[bindRemovalCallback]="rBroadcaster"
		>
		</content-table>
	</div>
</div>
