import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http'
import {Notification} from '../_models'
// @ts-ignore
const LosslessJSON = require('lossless-json');

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
	public notifications: [];

	constructor(private http: HttpClient) {
	}

	async GetNotifications(): Promise<any> {
		if (this.notifications) {
			return {notifications: this.notifications};
		}
		let n = await this.LoadNotifications();
		if (n['error']) {
			return n
		}

		n['notifications'] = n['notifications'].sort(
			(a,b) => (a.read > b.read) ? 1 : ((b.read > a.read) ? -1 : 0)
		);
		this.notifications = n['notifications']
		// sort by read
		return n;
	}

	async LoadNotifications(): Promise<any> {
		if (isDevMode()) {
			return await this.http
			.get('/assets/data/notifications/notifications.json', {responseType: 'text'})
			.toPromise().then((result) => {
				result = LosslessJSON.parse(result);
				return {notifications: result}
			});
		}

		return await this.http.get('notification/notification/list', {responseType: 'text'})
		.toPromise().then((result) => {
			result = LosslessJSON.parse(result);
			return {notifications: result}
		}).catch(() => {
			return {error: 'error getting notifications'};
		});

		// non dev route will need to be put here
	}

	async MarkAllRead() {
		if (isDevMode()) {
			return null;
		}

		return await this.http.post('/notification/notification/mark/all', {}).toPromise()
		.then(() => {
			return null;
		})
		.catch(() => {
			return 'error marking all notifications as read';
		})

	}

	async MarkSingleRead(id: string) {
		if (isDevMode()) {
			return null;
		}

		let params = new HttpParams().set('notification', id);
		return await this.http.post('/notification/notification/mark', {}, {params}).toPromise()
		.then(() => {
			return null;
		})
		.catch(() => {
			return 'error maring notification as read';
		})
	}

	async HideNotification(id: string) {
		if (isDevMode()) {
			return null;
		}

		let params = new HttpParams().set('notification', id);
		return await this.http.post('/notification/notification/hide', {}, {params}).toPromise()
		.then(() => {
			return null;
		})
		.catch(() => {
			return 'error removing notifications';
		})
	}
}
