<div class='container-multiselect'>
	<mat-form-field>
		<mat-label>{{bindPlaceholder}}</mat-label>
		<mat-select
				multiple
				[(ngModel)]="selectedItems"
				(selectionChange)="changeSelected()"
				[formControl]="controlMultiselect"
		>
			<mat-option
					*ngFor="let values of bindValues"
					[value]="values"
			>
				{{values}}
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>
