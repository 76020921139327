import {Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Chart} from 'chart.js';

@Component({
	selector: 'content-bar-chart',
	templateUrl: './content-bar-chart.component.html',
	styleUrls: ['./content-bar-chart.component.css']
})
export class ContentBarChartComponent implements OnInit {
	@ViewChild("tref", {read: ElementRef}) tref: ElementRef;
	@ViewChild("legendref", {read: ElementRef}) legendref: ElementRef;

	@HostBinding('class.my-domain-data') @Input()
	bindID = null;
	@HostBinding('class.my-domain-data') @Input()
	bindData = null;
	@HostBinding('class.my-domain-data') @Input()
	bindTitle = "";
	@HostBinding('class.my-domain-data') @Input()
	bindLegend = true;

	// Event for sending new domain up
	@Output() sendDataUp: EventEmitter<any> = new EventEmitter();

	chartProps = {};
	chart = null;
	loading = true;
	error = false;

	constructor() {
	}

	ngOnInit() {
	}

	ngOnChanges() {
		if (this.bindData !== null) {
			if (this.tref !== undefined) {
				this.tref.nativeElement.width = this.tref.nativeElement.parentNode.offsetWidth * 2;
				this.tref.nativeElement.height = this.tref.nativeElement.parentNode.offsetHeight * 2;
				this.tref.nativeElement.style.width = this.tref.nativeElement.parentNode.offsetWidth + "px";
				this.tref.nativeElement.style.height = this.tref.nativeElement.parentNode.offsetHeight + "px";
			}

			this.loading = false;
			this.createGraph()
		}
	}

	createGraph() {
		//this for inside callback
		const refMain = this;

		if (typeof this.bindData === 'string') {
			this.error = true
		} else if (this.tref !== undefined) {
			this.chartProps = {
				type: 'bar',
				maintainAspectRatio: false,
				data: this.bindData,
				options: {
					legendCallback: function (chart) {
						if (refMain.bindLegend) {
							var legendSet = [];
							for (var i = chart.data.datasets.length - 1; i >= 0; i--) {
								if (chart.data.icons !== undefined) {
									legendSet.push('<div class="legend-item" style="width:100%; display:flex; flex-direction:row; margin-top:0px;">');
									legendSet.push('<img style="margin-right:3px; display:inline-block;" src="/assets/img/' + chart.data.icons[i] + '.svg">');
									legendSet.push('<span style="display:inline-block; align-self:center; font-size:11px;">' + chart.data.datasets[i].label + '</span>');
									legendSet.push('</div>');
								} else {
									legendSet.push('<div class="legend-item" style="width:100%; margin-bottom:2px;">');
									legendSet.push('<div class="legendBox" style="float:left; margin-right:4px; width:13px; height:13px; background-color:' + chart.data.datasets[i].backgroundColor + '"></div>');
									legendSet.push('<span style="display:inline-block; font-size:11px;">' + chart.data.datasets[i].label + '</span>');
									legendSet.push('</div>');
								}
							}
							return legendSet.join("");
						}
					},
					title: {
						display: false,
						text: 'Chart.js Bar Chart - Stacked'
					},
					tooltips: {
						mode: 'index',
						intersect: false
					},
					responsive: true,
					legend: {
						display: false,
					},
					animation: false,
					scales: {
						xAxes: [{
							stacked: true,
							gridLines: {
								color: "rgba(0, 0, 0, 0)",
							},
						}],
						yAxes: [{
							stacked: true,
							ticks: {
								beginAtZero: true,
								precision: 0,
							}
						}]
					},
					onClick: this.sendData.bind(this)
				}
			};

			this.chart = new Chart(this.bindID, this.chartProps);

			if (this.legendref !== undefined) {
				this.legendref.nativeElement.innerHTML = this.chart.generateLegend();
			}
		}
	}

	sendData(event) {
		let clickedElement = this.chart.getElementAtEvent(event);
		this.sendDataUp.emit(clickedElement[0]._model)
	}

	onResize() {
		this.chart.destroy();

		this.tref.nativeElement.width = 0;
		this.tref.nativeElement.height = 0;
		this.tref.nativeElement.style.width = 0 + "px";
		this.tref.nativeElement.style.height = 0 + "px";

		this.tref.nativeElement.width = this.tref.nativeElement.parentNode.offsetWidth * 2;
		this.tref.nativeElement.height = this.tref.nativeElement.parentNode.offsetHeight * 2;
		this.tref.nativeElement.style.width = this.tref.nativeElement.parentNode.offsetWidth + "px";
		this.tref.nativeElement.style.height = this.tref.nativeElement.parentNode.offsetHeight + "px";

		this.chart = new Chart(this.bindID, this.chartProps);
	}
}
