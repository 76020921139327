import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { UserService } from "../_services";
import { User } from "../_models";
import { FeedbackComponent } from "./feedback/feedback.component";
import { HttpClient, HttpParams } from "@angular/common/http";
import { DnsenseDomainsComponent } from "../views/dnsense-domains/dnsense-domains.component";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  @ViewChild(DnsenseDomainsComponent)
  private dnsenseDomainsComponent: DnsenseDomainsComponent;

  title = "Home";
  opened = true;
  over = "side";
  backdrop = false;
  watcher: Subscription;
  user: User = new User();
  client = null;
  hasUserManagement = false;
  soteriaUser = false;
  hasUnreadNotifications = false;
  lexicoSubheaders = [
    {
      label: "Tickets",
      router: "/home/lexico/tickets",
    },
    {
      label: "Aliases",
      router: "/home/lexico/aliases",
    },
    {
      label: "Assignees",
      router: "/home/lexico/assignees",
    },
    {
      label: "Tags",
      router: "/home/lexico/tags",
    },
    {
      label: "Broadcasters",
      router: "/home/lexico/broadcasters",
    },
    {
      label: "Notifiers",
      router: "/home/lexico/notifiers",
    },
  ];
  dnsenseSubheaders = [
    {
      label: "Domains",
      router: "/home/dnsense/domains",
    },
    {
      label: "Clients",
      router: "/home/dnsense/clients",
    },
    {
      label: "Takedowns",
      router: "/home/dnsense/takedowns",
    },
  ];

  dnsenseSubheadersNotSoterian = [
    {
      label: "Domains",
      router: "/home/dnsense/domains",
    },
    {
      label: "Takedowns",
      router: "/home/dnsense/takedowns",
    },
  ];

  constructor(
    public media: MediaObserver,
    public router: Router,
    public _userservice: UserService,
    public dialog: MatDialog,
    private http: HttpClient
  ) {
    this.watcher = media.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias === "sm" || change.mqAlias === "xs") {
        this.opened = false;
        this.over = "over";
        this.backdrop = true;
      } else {
        this.opened = true;
        this.over = "side";
        this.backdrop = false;
      }
    });

    router.events.subscribe((event: any) => {
      if (event.url === "/home") {
        this.title = "Home";
      }
      if (event.url === "/faq") {
        this.title = "FAQ";
      }
      if (event.url === "/management") {
        this.title = "User Management";
      }
      if (event.url === "login") {
        this.title = "Login";
      }
      if (event.url === "notifications") {
        this.title = "Notifications";
      }
    });
  }

  async ngOnInit() {
    // determines if this is an internal user or not
    this.soteriaUser = await this._userservice.UserIsSoterian();
    this.hasUserManagement = await this._userservice.UserHasPermission(
      "auth.clientrole.update"
    );
  }

  destroySession() {
    window.location.href = "/logout";
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(FeedbackComponent, {
      width: "400px",
      data: { test: "test" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      let feedback = result;
      if (feedback) {
        let params = new HttpParams().set("message", feedback);
        this.http.get("/feedback", { params }).subscribe(() => {});
      }
    });
  }

  setHasUnreadNotifications($event) {
    this.hasUnreadNotifications = $event.unread;
  }
}
