import {Component, OnInit} from '@angular/core';
import {DNSenseService, UserService} from "../../_services";
import {MatTableDataSource} from "@angular/material";
import {Alias} from "../../_models";

@Component({
	selector: 'dnsense-clients',
	templateUrl: './dnsense-clients.component.html',
	styleUrls: ['./dnsense-clients.component.css']
})
export class DnsenseClientsComponent implements OnInit {
	soteriaUser = false;
	data = new MatTableDataSource([]);
	tableMessage = null;
	tableConfig = {
		"columns": [
			{
				"header": "Client",
				"field": "owner",
				"maxWidth": 200
			},
			{
				"header": "Paying",
				"field": "paying",
				"maxWidth": null
			}
		],
		"delete": true,
		"testing": false
	};
	selectedItemIndex = null;
	selectedItemData = null;
	filterBoxTitle = "Client List";
	filterBoxButton = "+ Client";
	filterBoxFeatures = [
		{
			"filterList": [
				{
					type: "input",
					filterTarget: "all",
					default: "",
					placeholder: "Text Filter (on any column)",
					uniqueID: 'text filter'
				}
			]
		},
		{
			"filterList": []
		},
		{
			"filterList": []
		}
	];
	filterBoxAllFields = ["owner", "paying"];
	panelTitle = "";
	panelSaveWording = "";
	panelFeatures = [
		{
			"panelList": [
				{
					type: "input",
					dataProp: "owner",
					placeholder: "Client Name"
				},
				{}
			]
		},
		{
			"panelList": [
				{
					type: "dropdown",
					dataProp: "paying",
					placeholder: "Paying",
					values: [true, false],
				},
				{}
			]
		}
	];
	panelShow = false;
	identifierProp = 'owner_id';
	newClient = false;

	constructor(private _userservice: UserService, public _dnsenseservice: DNSenseService) {
	}

	updateClients(data: Alias[]) {
		if (data !== null && data.length == 0) {
			this.tableMessage = 'no data found';
		} else if (data !== null) {
			this.tableMessage = null;
			this.data = new MatTableDataSource(data);
		}
	}

	async ngOnInit() {
		// determines if this is an internal user or not
		this.soteriaUser = await this._userservice.UserIsSoterian();
		let data = await this._dnsenseservice.LoadClientList();
		if (data['error']) {
			this.tableMessage = 'error loading data';
		} else if (!data['clients']) {
			this.tableMessage = 'no data found';
		} else {
			this.updateClients(<any[]>data['clients']);
		}
	}

	// Callback sent to table for removing a client
	rClient = this.removeClient(this._dnsenseservice);
	removeClient(dnsService) {
		return async (identifier) => {
			return await dnsService.DeleteClient(identifier);
		}
	}

	closePanelSaveData($event) {
		if (this.newClient && $event && $event.newData) {
			this.data.data.push($event.newData);
			this.data = new MatTableDataSource(this.data.data);
		}

		// does the closing action and resets things
		this.selectedItemData = null;
		this.selectedItemIndex = null;
		this.panelShow = false;
	}

	// opens panel to make a new item
	// this would appear in many different views
	newItem() {
		this.selectedItemIndex = null;
		this.newClient = true;
		this.panelShow = true;
		this.panelTitle = "New Client";
		this.panelSaveWording = "Save New Client";

		// temp tries making new alias
		this.selectedItemData = {
			'owner': '',
			'paying': null
		};
	}

	// for getting a selected table row
	// this would appear in many different views
	selectTableRow($event) {
		const match = $event.emitMatch;
		const index = $event.emitIndex;
		this.newClient = false;

		// if row already selected, closes it
		if (index == this.selectedItemIndex) {
			this.selectedItemData = null;
			this.selectedItemIndex = null;
			this.panelShow = false;
		} else {
			this.selectedItemData = match;
			this.selectedItemIndex = index;
			this.panelShow = true;
			this.panelTitle = "Edit Client";
			this.panelSaveWording = "Apply Changes";
		}
	}



	deleteTableRow($event) {
		if ($event.emitLength == 0) {
			this.tableMessage = 'no data in this time range';
			this.data = null;
		}
	}

	// Hacky work-around to get the update to stop breaking within the panel.
	// For some reason, if you pass a function call rather than a function reference
	// the panel update craps out.
	cclientCallback = this.createClient(this._dnsenseservice);
	createClient(dnsService) {
		return async (obj) => {
			let result;

			if (obj && obj['owner']) {
				result = await dnsService.CreateClient(obj['owner']);
			} else {
				return 'invalid request'
			}

			if (result['error']) {
				return result['error'];
			}

			obj['owner_id'] = result.client['owner_id'];

			if (typeof obj['paying'] === "boolean") {
				return await dnsService.UpdateClientPayment(obj['owner_id']['value'], obj['paying']);
			}

			return null;
		}
	}

	// Hacky work-around to get the update to stop breaking within the panel.
	// For some reason, if you pass a function call rather than a function reference
	// the panel update craps out.
	uclientCallback = this.updateClient(this._dnsenseservice);
	updateClient(dnsService) {
		return async (obj) => {
			if (obj && typeof obj['paying'] === "boolean") {
				return await dnsService.UpdateClientPayment(obj['owner_id']['value'], obj['paying']);
			} else {
				return 'invalid request'
			}
		}
	}
}
