<div class='container-autocomplete'>
	<div class='saving-status'>
		<div *ngIf='saveStatus == "unsaved"' id='status-unsaved'>unsaved</div>
		<div *ngIf='saveStatus == "saving"' id='status-saving'>saving</div>
		<div *ngIf='saveStatus == "saved"' id='status-saved'>saved</div>
		<div *ngIf='saveStatus == "nosave"' id='status-nosave'>did not save</div>
	</div>
	<mat-icon
			*ngIf="bindIcons && selection !== ''"
			class='extra-icon'
			svgIcon="{{bindIcons[bindValues.indexOf(selection)]}}"
	></mat-icon>
	<mat-form-field>
		<input type="text"
			   placeholder="{{bindPlaceholder}}"
			   aria-label="Number"
			   matInput
			   [attr.data-iconup]="bindIcons && selection !== ''"
			   [formControl]="controlAutocomplete"
			   [(ngModel)]="selection"
			   autocomplete="off"
			   [matAutocomplete]="auto"
		>
		<mat-autocomplete
				(closed)="closeCheck()"
				(optionSelected)="changeSelected($event.option.value)"
                #auto="matAutocomplete"
		>
			<mat-option
					*ngFor="let option of (filteredOptions | async); let i=index"
					[value]="option"
			>
				<mat-icon
						*ngIf="bindIcons"
						svgIcon="{{bindIcons[bindValues.indexOf(option)]}}"
				></mat-icon>
				{{option}}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
</div>
