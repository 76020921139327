import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
	selector: 'ui-autocomplete',
	templateUrl: './ui-autocomplete.component.html',
	styleUrls: ['./ui-autocomplete.component.css']
})

export class UiAutocompleteComponent implements OnInit {
	controlAutocomplete = new FormControl();
	selection = '';
	savedValue = '';
	saveStatus = null;
	filteredOptions: Observable<string[]>;

	@HostBinding('class.selected-domain-id') @Input()
	bindPlaceholder = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindTarget = '';
	myFilterResult = {"column": this.bindTarget, "text": ""};
	@HostBinding('class.selected-domain-id') @Input()
	bindUniqueID = '';
	@HostBinding('class.selected-domain-id') @Input()
	bindValues = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindPresetValue = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindIcons = null;
	@HostBinding('class.selected-domain-id') @Input()
	bindAPI = null;

	// sends event for adding thing to filter array
	@Output() newValue: EventEmitter<any> = new EventEmitter();

	constructor() {
		//this does filtering
		this.filteredOptions = this.controlAutocomplete.valueChanges
			.pipe(
				startWith(''),
				map(value => this._filterOptions(value))
			);

		let refMain = this;
		this.controlAutocomplete.valueChanges.subscribe(val => {
			if (val == '') {
				refMain.selection = '';
				refMain.savedValue = '';
			}
		})
	}

	ngOnInit() {
		this.myFilterResult.column = this.bindTarget;
	}

	ngOnChanges() {
		//if there's a change, it's coming to the data matches, so goes through create filter list again
		this.selection = this.bindPresetValue;
		this.savedValue = this.bindPresetValue;
	}

	closeCheck() {
		//if filters individually are null checks if in filter array
		if (this.controlAutocomplete.value == '' || this.controlAutocomplete.value == null) {
			this.selection = '';
			this.savedValue = '';
			this.newValue.emit(
				{
					value: "",
					target: this.bindTarget,
					uniqueID: this.bindUniqueID,
					formControl: this.controlAutocomplete
				}
			);
		}
	}

	changeSelected(filterValue) {
		if (this.savedValue !== filterValue) {
			this.savedValue = filterValue;

			//sets current value
			this.selection = filterValue;
			//emits current value
			this.newValue.emit(
				{
					value: filterValue,
					target: this.bindTarget,
					uniqueID: this.bindUniqueID,
					formControl: this.controlAutocomplete
				}
			);

			if (this.bindAPI !== null) {
				this.saveStatus = 'saving';
				this.bindAPI(filterValue, this.callbackStatus, this);
			}
		}
	}

	callbackStatus(status, passThis) {
		passThis.saveStatus = status;
	}

	private _filterOptions(value: string): string[] {
		const filterValue = String(value).toLowerCase();
		if (value == '') {
			return this.bindValues;
		}
		return this.bindValues.filter(option => String(option).toLowerCase().includes(filterValue));
	}
}
