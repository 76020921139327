import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import * as moment from "moment";
import { FormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material";
import { DNSenseService, UserService } from "../../_services";
import { Match } from "../../_models";
import { Moment } from "moment";

@Component({
  selector: "dnsense-domains",
  templateUrl: "./dnsense-domains.component.html",
  styleUrls: ["./dnsense-domains.component.css"],
})
export class DnsenseDomainsComponent implements OnInit {
  soteriaUser = false;

  minDate = moment("2000-01-01");
  maxDate = moment();
  date = new FormControl(moment());

  tableMessage = null;
  data = null;
  panelShow = null;
  panelShowAdd = null;

  selectedDataRows = [];
  selectedIDRows = [];
  selectedItemIndex = null;

  filterBoxAltMessage = null;
  filterListStatus = [
    "Confirmed Malicious",
    "Suspicious",
    "New",
    "Infringement",
    "Client Owned",
    "Unrelated",
  ];
  iconListStatus = [
    "icon-risk-high",
    "icon-risk-suspicious",
    "icon-risk-medium",
    "icon-status-infringement",
    "icon-risk-client-owned",
    "icon-risk-informational",
  ];
  filterListTakedown = [
    "uninitiated",
    "open",
    "successful",
    "unsuccessful",
    "N/A",
  ];
  iconListTakedown = [
    "icon-status-open",
    "icon-status-client-review",
    "icon-status-in-progress",
    "icon-takedown-unsuccessful",
    "icon-status-unassigned",
  ];
  filterListPaying = ["true", "false"];
  filterListDomain = [];
  filterListOwner = [];
  filterListKeyword = [];

  filterBoxTitle = "";
  filterBoxButton = "Download Report";
  filterBoxButtonInclude = true;
  filterBoxAllFields = [
    "status",
    "final_score",
    "offending_dtld",
    "takedown",
    "owner",
    "paying",
    "keyword",
    "day",
    "found_on",
  ];
  filterBoxFeatures = [
    {
      filterList: [
        {
          type: "input",
          filterTarget: "all",
          default: "",
          placeholder: "Text Filter (on any column)",
          uniqueID: "text filter",
        },
        {
          type: "ui-dropdown",
          filterTarget: "status",
          default: "New",
          placeholder: "Status",
          uniqueID: "status filter",
          values: this.filterListStatus,
          icons: this.iconListStatus,
        },
      ],
    },
    {
      filterList: [
        {
          type: "ui-dropdown",
          filterTarget: "takedown",
          default: "",
          placeholder: "Takedown",
          uniqueID: "takedown filter",
          values: this.filterListTakedown,
          icons: this.iconListTakedown,
        },

        {
          type: "ui-dropdown",
          filterTarget: "paying",
          default: "",
          placeholder: "Paying",
          uniqueID: "paying filter",
          values: this.filterListPaying,
        },
      ],
    },
    {
      filterList: [
        {
          type: "ui-dropdown",
          filterTarget: "owner",
          default: "",
          placeholder: "Owner",
          uniqueID: "owner filter",
          values: null,
          refreshable: true,
        },
        {
          type: "date",
          filterTarget: "found_on",
          default: "",
          placeholder: "Date Range",
          uniqueID: "date filter",
        },
      ],
    },
    {
      filterList: [
        {
          type: "ui-dropdown",
          filterTarget: "offending_dtld",
          default: "",
          placeholder: "Domain",
          uniqueID: "domain filter",
          values: null,
          refreshable: true,
        },
        {
          type: "ui-dropdown",
          filterTarget: "keyword",
          default: "",
          placeholder: "Keyword",
          uniqueID: "keyword filter",
          values: null,
          refreshable: true,
        },
      ],
    },
  ];

  /* stuff for table */
  tableConfig = {
    columns: [
      {
        header: "Status",
        field: "status",
        maxWidth: 150,
      },
      {
        header: "Score",
        // "field": "final_score",
        field: "priority",
        maxWidth: null,
      },
      {
        header: "Domain",
        field: "offending_dtld",
        maxWidth: null,
      },
      {
        header: "Takedown",
        field: "takedown",
        maxWidth: 130,
      },
      {
        header: "Owner",
        field: "owner",
        maxWidth: null,
      },
      {
        header: "Paying",
        field: "paying",
        maxWidth: 75,
      },
      {
        header: "Keyword Hit",
        field: "keyword",
        maxWidth: 150,
      },
      {
        header: "Day",
        field: "day",
        maxWidth: 100,
      },
      {
        header: "First Found",
        field: "found_on",
        maxWidth: 100,
      },
    ],
    delete: false,
    testing: false,
  };

  barDataMalicious = null;
  barDataSuspicious = null;

  bindError = "";
  bindFilter = "";
  bindFilterStatus = "";

  constructor(
    private http: HttpClient,
    private _dnsenseService: DNSenseService,
    public _userservice: UserService
  ) {}

  async ngOnInit() {
    // determines if this is an internal user or not
    this.soteriaUser = await this._userservice.UserIsSoterian();

    //set error message to empty when the page is initlaized
    this.bindError = "";

    // set bind filter to empty  when page is intilaized
    this.bindFilter = "";

    //sets some things based on whether a user is a soterian
    if (!this.soteriaUser) {
      this.filterBoxButtonInclude = true;
      this.filterBoxFeatures = [
        {
          filterList: [
            {
              type: "input",
              filterTarget: "all",
              default: "",
              placeholder: "Text Filter (on any column)",
              uniqueID: "text filter",
            },
            {
              type: "ui-dropdown",
              filterTarget: "status",
              default: "",
              placeholder: "Status",
              uniqueID: "status filter",
              values: this.filterListStatus,
              icons: this.iconListStatus,
            },
          ],
        },
        {
          filterList: [
            {
              type: "date",
              filterTarget: "found_on",
              default: "",
              placeholder: "Date Range",
              uniqueID: "date filter",
            },
            {
              type: "ui-dropdown",
              filterTarget: "takedown",
              default: "",
              placeholder: "Takedown",
              uniqueID: "takedown filter",
              values: this.filterListTakedown,
              icons: this.iconListTakedown,
            },
          ],
        },
      ];
      this.tableConfig = {
        columns: [
          {
            header: "Status",
            field: "status",
            maxWidth: 150,
          },
          {
            header: "Domain",
            field: "offending_dtld",
            maxWidth: null,
          },
          {
            header: "First Found",
            field: "found_on",
            maxWidth: 100,
          },
        ],
        delete: false,
        testing: false,
      };
    }

    this._dnsenseService.GetMaliciousStats().then(
      (Result) => {
        // basics of data, needs to match format from line chart below so match up
        let result = <any[]>Result;
        this.barDataMalicious = {
          labels: [],
          datasets: [],
        };
        let labels = [];
        let data = [];
        for (let i = 14; i > 0; i--) {
          let graphDate = moment().startOf("day").subtract(i, "day");
          labels.push(graphDate.format("MMM D"));

          let index = data.push(0) - 1;
          for (let j = 0; j < result.length; j++) {
            let searchDate = moment.utc(result[j]["date"]);
            if (
              searchDate.year() == graphDate.year() &&
              searchDate.month() == graphDate.month() &&
              searchDate.date() == graphDate.date()
            ) {
              data[index] = result[j]["count"];
            }
          }
        }

        let malData = {
          label: "High Risk Domains",
          backgroundColor: "#FA6262",
          data: data,
        };

        this.barDataMalicious.labels = labels;
        this.barDataMalicious.datasets = [malData];
      },
      () => {
        this.barDataMalicious = "error";
      }
    );

    this._dnsenseService.GetSuspiciousStats().then(
      (Result) => {
        let result = <any[]>Result;
        // basics of data, needs to match format from line chart below so match up
        this.barDataSuspicious = {
          labels: [],
          datasets: [],
        };
        let labels = [];
        let data = [];
        for (let i = 14; i > 0; i--) {
          let graphDate = moment().startOf("day").subtract(i, "day");
          labels.push(graphDate.format("MMM D"));

          let index = data.push(0) - 1;
          for (let j = 0; j < result.length; j++) {
            let searchDate = moment.utc(result[j]["date"]);
            if (
              searchDate.year() == graphDate.year() &&
              searchDate.month() == graphDate.month() &&
              searchDate.date() == graphDate.date()
            ) {
              data[index] = result[j]["count"];
            }
          }
        }

        let suspData = {
          label: "Low Risk Domains",
          backgroundColor: "#FED535",
          data: data,
        };

        this.barDataSuspicious.labels = labels;
        this.barDataSuspicious.datasets = [suspData];
      },
      () => {
        this.barDataSuspicious = "error";
      }
    );

    let data = await this._dnsenseService.GetMatches();
    if (data["error"]) {
      this.tableMessage = "error loading data";
      return;
    } else if (!data["matches"]) {
      this.tableMessage = "no data in this time range";
    } else if (data !== null) {
      this.updateMatches(data["matches"]);
    }
  }

  updateMatches(data: Match[]) {
    if (data !== null && data.length == 0) {
      this.tableMessage = "no data in this time range";
    } else if (data !== null) {
      this.tableMessage = null;
      this.data = new MatTableDataSource(data);

      for (let match of data) {
        //confirmed malicious to just malicious
        if (match.status === "Confirmed Malicious") {
          match.status = "Confirmed Malicious";
        }
        // makes owner list
        if (this.filterListOwner.indexOf(match.owner) == -1) {
          this.filterListOwner.push(match.owner);
        }
        // makes domain list
        if (this.filterListDomain.indexOf(match.offending_dtld) == -1) {
          this.filterListDomain.push(match.offending_dtld);
        }
        // makes keyword list
        if (this.filterListKeyword.indexOf(match.keyword) == -1) {
          this.filterListKeyword.push(match.keyword);
        }
        // Cast paying bool to string for filter
        if (match["paying"] != null) {
          match["paying"] = match["paying"].toString();
        }
      }
      let iconStatusMatch = {
        Client_Owned: "icon-risk-client-owned",
        Infringement: "icon-status-infringement",
        Confirmed_Malicious: "icon-risk-high",
        New: "icon-risk-medium",
        Suspicious: "icon-risk-suspicious",
        Unrelated: "icon-risk-informational",
      };
      let iconTakedownMatch = {
        N_A: "icon-status-unassigned",
        open: "icon-status-client-review",
        successful: "icon-status-in-progress",
        uninitiated: "icon-status-open",
        unsuccessful: "icon-takedown-unsuccessful",
      };

      for (let i = 0; i < this.data.data.length; i++) {
        let statusText = "";
        if (this.data.data[i].status) {
          statusText = this.data.data[i].status.split(" ").join("_");
        }

        let takedownText = "";
        if (this.data.data[i].takedown) {
          takedownText = this.data.data[i].takedown.split("/").join("_");
        }
        this.data.data[i].status_icon = iconStatusMatch[statusText];
        this.data.data[i].takedown_icon = iconTakedownMatch[takedownText];
        this.data.data[i].found_on = moment(
          this.data.data[i].found_on,
          "YYYY-M-DDTHH:mm:ss.SSS"
        );
        this.data.data[i].day = moment(
          this.data.data[i].day,
          "YYYY-M-DDTHH:mm:ss.SSS"
        );
      }
    }
    this.filterListOwner.sort();
    this.filterListDomain.sort();
    this.filterListKeyword.sort();
  }

  async updateDate() {
    if (
      this.date.value.isAfter(this.minDate) &&
      this.date.value.startOf("month").isBefore(this.maxDate)
    ) {
      this.data = new MatTableDataSource([]);
      let data = await this._dnsenseService.SetDaterange(
        moment(this.date.value).startOf("month")
      );
      if (data["error"]) {
        this.tableMessage = "error loading data";
        return;
      } else if (!data["matches"]) {
        this.tableMessage = "no data in this time range";
      } else if (data !== null) {
        this.updateMatches(data["matches"]);
      }
    }
  }

  checkDataEmpty(){
    if (this.data === null) {
      let list = <Match[]>[];
      this.updateMatches(list);
    }
  }

  async searchDomain(searchItem) {
    if (searchItem !== "") {

      searchItem = searchItem.toUpperCase();

      let foundMatch = false;
      if (this.data !== null) {
        for (let i = 0; i < this.data.data.length; i++) {
          if (this.data.data[i].offending_dtld.toUpperCase() === searchItem) {
            // if their are multiple domains, we want to just break to not continuely open the domains
            await this.selectTableRow({
              emitMatch: this.data.data[i],
              emitIndex: i,
            });
            foundMatch = true;
            break;
          }
        }
      }


      // if match not found in current list of domains
      if (!foundMatch) {
        let result = await this._dnsenseService.SearchMatch(searchItem);
        if (result["match"]) {
          // result could come back with no match
          if (result["match"].length > 0) {
            if (this.data === null){
              let list = <Match[]>[];
              list.push(result["match"][0]);
              let index = list.indexOf(result["match"][0]);
              this.updateMatches(list);
              if (index !== -1) {
                this.selectTableRow({
                  emitMatch: this.data.data[index],
                  emitIndex: index,
                });
              }
            } else {
              this.data.data.push(result["match"][0]);
              let index = this.data.data.indexOf(result["match"][0]);
              if (index !== -1) {
                this.selectTableRow({
                  emitMatch: this.data.data[index],
                  emitIndex: index,
                });
              }
            }
          } else {
            // ask to create match if not found in the historical data
            if (this.data === null) {
              this.data = new MatTableDataSource(<Match[]>[]);
            }
            this.panelShowAdd = true;
          }
        } else {
          this.bindError = "error searching for match";
        }
      }
    }
  }

  downloadReport(filterArray) {
    let owner = "";
    for (let i = 0; i < filterArray.length; i++) {
      if (filterArray[i].target === "owner") {
        owner = filterArray[i].value;
        break;
      }
    }

    let params;
    if (owner == "") {
      params = new HttpParams()
        .set("start", moment(this.date.value).startOf("month").format())
        .set("stop", moment(this.date.value).endOf("month").format());
    } else {
      params = new HttpParams()
        .set("start", moment(this.date.value).startOf("month").format())
        .set("stop", moment(this.date.value).endOf("month").format())
        .set("owner", owner);
    }

    this.http
      .get("/dnsense/report/dnsense", { params, responseType: "blob" })
      .subscribe((response) => {
        let mediaType = "application/pdf";
        let blob = new Blob([response], { type: mediaType });
        let a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = "DNSense Report.pdf";
        a.click();
      });
  }

  async selectTableRow($event) {
    // this was all built for the future ability to select mulitple rows at once
    // none of the other panels work this way, these arrays are for the multiple selected
    // rows and multiple indexes to be shown as selected
    if (this.selectedDataRows.length > 0) {
      this.selectedDataRows.splice(0, 1);
      this.selectedIDRows.splice(0, 1);
    }
    if (Object.entries($event.emitMatch).length != 0) {
      const match = $event.emitMatch;

      // Show the panel before trying to lad the info so that feedback to the user is instant.
      this.panelShow = true;
      this.selectedItemIndex = $event.emitIndex;
      this.selectedDataRows.push(match);
      this.selectedIDRows.push(this.selectedItemIndex);
      this.selectedDataRows = this.selectedDataRows.splice(0);
      this.selectedIDRows = this.selectedIDRows.splice(0);

      // Potentially move all data loading code into the panel if panel open is still slow
      this.data.data[this.data.data.indexOf(match)][
        "summary"
      ] = await this._dnsenseService.GetMatchSummary(match);
    } else {
      this.panelShow = false;
      this.selectedItemIndex = null;
      // in the future will have to search array to remove
    }
  }

  hidePanel() {
    this.bindError = "";
    this.panelShow = false;
    this.selectedItemIndex = null;
    this.selectedIDRows.splice(0, 1);
  }

  hidePanelAdd() {
    this.bindError = "";
    this.updateMatches(this.data.data);
    this.panelShowAdd = false;
  }

  async filterDay(day: Moment, malicious: string) {
    this.data = new MatTableDataSource([]);
    let data = await this._dnsenseService.SetDay(day, malicious);
    if (data["error"]) {
      this.tableMessage = "error loading data";
      return;
    } else if (!data["matches"]) {
      this.tableMessage = "no data in this time range";
    } else if (data !== null) {
      this.updateMatches(data["matches"]);
    }
  }

  async filterMalicious($event) {
    let date = new Date($event.label + " " + moment().year().toString());
    let momentDate = moment(date);
    await this.filterDay(momentDate, "true");
  }

  async filterSuspicious($event) {
    let date = new Date($event.label + " " + moment().year().toString());
    let momentDate = moment(date);
    await this.filterDay(momentDate, "false");
  }

}
