<div class='container-textarea'>
	<div class='saving-status'>
		<div *ngIf='saveStatus == "unsaved"' id='status-unsaved'>unsaved</div>
		<div *ngIf='saveStatus == "saving"' id='status-saving'>saving</div>
		<div *ngIf='saveStatus == "saved"' id='status-saved'>saved</div>
		<div *ngIf='saveStatus == "nosave"' id='status-nosave'>did not save</div>
	</div>
	<mat-form-field>
    <textarea
			matInput
            #textName="matInput"
			(keyup)="changeTextareaText($event)"
			placeholder={{bindPlaceholder}}
			[formControl]="controlTextArea"
	></textarea>
	</mat-form-field>
	<div
			[attr.data-visible]="textDifferent"
			class='list-item-applysave'
			*ngIf='bindSaveAppear == true'
	>
		<div
				class="button-row"
				[ngClass]="{'cancel-save': textDifferent, 'cancel-save-disabled' : !textDifferent}"
		>
			<button (click)="cancelItem()" class='button-cancel'>Cancel</button>
			<button (click)="saveItem()" class='button-save'>Save</button>
		</div>
	</div>
</div>
