import {Moment} from 'moment';

export class Alias {
	public title: string;
	public description: string;
	public severity: number;
	public status: string;
	public summary: string;
	public createdAt: Moment;
	public caseId: number;
	public customFields: any;
	public name: string;
	public to: any;

	constructor() {
	}
}
