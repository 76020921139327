import {HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";

@Injectable()
export class InterceptorService implements HttpInterceptor {
    constructor(private http: HttpClient) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):   Observable<HttpEvent<any>> {
        // All HTTP requests are going to go through this method
        return next.handle(req)
            .pipe(
                tap(_ => {}, error => {
                    if (error.error.includes('not logged in') && error.status == 401){
                        window.location.href = "/logout";
                    }
                })
            )
        return
    };
}
