import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
	selector: 'app-password',
	templateUrl: './password.component.html',
	styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<PasswordComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	ngOnInit() {
	}
}
