<div
		fxLayout="column" fxFlex="100%;" class="panel-container"
		[ngClass]="{'panel-show': bindPanelShow == true, 'panel-hide': bindPanelShow == false, 'panel-load': bindPanelShow == null}"
>
	<div fxLayout="row" id='panel-header' *ngIf='bindPanelShow'>
		<div id='text-id'>
			{{ bindCreate ? 'New Notifier' : 'Edit ' + presaveData.name }}
		</div>

		<div class='saving-status'>
			<div *ngIf='saveStatus == "unsaved"' id='status-unsaved'>unsaved</div>
			<div *ngIf='saveStatus == "saving"' id='status-saving'>saving</div>
			<div *ngIf='saveStatus == "saved"' id='status-saved'>saved</div>
			<div *ngIf='saveStatus == "nosave"' id='status-nosave'>did not save</div>
		</div>

		<div *ngIf='changesToApply' id='apply-cancel-combo'>
			<button id='cancel' (click)="cancelPanel()">Cancel</button>
			<button id='apply' (click)="applyPanel()">Apply Changes</button>
		</div>

		<mat-icon [inline]="true" id='icon-close' class="white" (click)="onPanelClose()">close</mat-icon>
	</div>
	<div class='panel-body' *ngIf='bindPanelShow'>
		<div class='input-row'>
			<div class='form-item form-width-half' *ngIf="bindCreate">
				<ui-input
						[bindTarget]="'name'"
						[bindPresetValue]='presaveData.name'
						[bindPlaceholder]="'Notifier Name'"
						[bindSaveAppear]='false'
						(newValue)="updatePresaved('name', $event)"
				></ui-input>
			</div>

			<div class='form-item'>
				<mat-form-field>
					<mat-label>Type</mat-label>
					<mat-select
							[(ngModel)]="presaveData.type"
							(selectionChange)="checkChanges()"
					>
						<mat-option value="new_alert">New Alert</mat-option>
						<mat-option value="status_change">Status Change</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

		<div class="input-row" *ngIf="presaveData.type != ''">
			<div class='form-item'>
				<mat-form-field>
					<mat-label>Broadcaster</mat-label>
					<mat-select
							[(ngModel)]="presaveData.broadcaster"
							(selectionChange)="checkChanges()"
					>
						<mat-option *ngFor="let broadcaster of broadcasters" [value]="broadcaster.name">{{broadcaster.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<ui-input
					[bindTarget]="'client'"
					[bindPresetValue]='presaveData.client'
					[bindPlaceholder]="'Client'"
					[bindSaveAppear]='false'
					(newValue)="updatePresaved('client', $event)"
			></ui-input>
		</div>
		<div class="input-row" *ngIf="presaveData.type != ''">
			<!-- TODO: update ui-input to allow for numbers as well as text inputs -->
			<ui-input
					[bindTarget]="'score_threshold'"
					[bindPresetValue]='presaveData.score_threshold'
					[bindPlaceholder]="'Score Threshold'"
					[bindSaveAppear]='false'
					(newValue)="updatePresaved('score_threshold', $event)"
			></ui-input>
			<ui-input
					[bindTarget]="'alert_threshold'"
					[bindPresetValue]='presaveData.alert_threshold'
					[bindPlaceholder]="'Alert Threshold'"
					[bindSaveAppear]='false'
					(newValue)="updatePresaved('alert_threshold', $event)"
			></ui-input>
			<ui-input
					[bindTarget]="'core_score_threshold'"
					[bindPresetValue]='presaveData.core_score_threshold'
					[bindPlaceholder]="'Core Score Threshold'"
					[bindSaveAppear]='false'
					(newValue)="updatePresaved('core_score_threshold', $event)"
			></ui-input>
		</div>
		<div class="input-row" *ngIf="presaveData.type != ''">
            <div>
				<label>To:</label>
				<div *ngFor="let user of presaveData.to; let i = index; trackBy:trackByFn">
                    <mat-form-field>
						<input matInput [formControl]="controlInput" type="text" [value]="user" (keyup)="updatePresaved('to', $event, i)">
					</mat-form-field>
                    <button (click)="updateDelete('to', i)">Delete</button>
				</div>
				<button (click)="updateAppend('to')">New</button>
			</div>
		</div>
		<div class="input-row" *ngIf="presaveData.type != ''">
			<ui-input
					[bindTarget]="'message'"
					[bindPresetValue]='presaveData.message'
					[bindPlaceholder]="'Message'"
					[bindSaveAppear]='false'
					(newValue)="updatePresaved('message', $event)"
			></ui-input>
		</div>
		<div class="input-row" *ngIf="presaveData.type == 'new_alert'">
			<ui-input
					[bindTarget]="'after'"
					[bindPresetValue]='presaveData.after'
					[bindPlaceholder]="'After'"
					[bindSaveAppear]='false'
					(newValue)="updatePresaved('after', $event)"
			></ui-input>
			<ui-input
					[bindTarget]="'range_before'"
					[bindPresetValue]='presaveData.range_before'
					[bindPlaceholder]="'Range Before'"
					[bindSaveAppear]='false'
					(newValue)="updatePresaved('range_before', $event)"
			></ui-input>
			<ui-input
					[bindTarget]="'range_after'"
					[bindPresetValue]='presaveData.range_after'
					[bindPlaceholder]="'Range After'"
					[bindSaveAppear]='false'
					(newValue)="updatePresaved('range_after', $event)"
			></ui-input>
		</div>
		<div class="input-row" *ngIf="presaveData.type == 'status_change'">
			<mat-form-field>
				<mat-label>From Status</mat-label>
				<mat-select
						[(value)]="presaveData.from_status"
						[(ngModel)]="presaveData.from_status"
						(selectionChange)="updatePresaved('from_status', $event)"
				>
					<mat-option value="Open">Open</mat-option>
					<mat-option value="In Progress">In Progress</mat-option>
					<mat-option value="In Review">In Review</mat-option>
					<mat-option value="Client Review">Client Review</mat-option>
					<mat-option value="Closed">Closed</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field>
				<mat-label>To Status</mat-label>
				<mat-select
						[(value)]="presaveData.to_status"
						[(ngModel)]="presaveData.to_status"
						(selectionChange)="updatePresaved('to_status', $event)"
				>
					<mat-option value="Open">Open</mat-option>
					<mat-option value="In Progress">In Progress</mat-option>
					<mat-option value="In Review">In Review</mat-option>
					<mat-option value="Client Review">Client Review</mat-option>
					<mat-option value="Closed">Closed</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<!-- Create a checkbox for all days of the week -->
		<div class="input-row" *ngIf="presaveData.type != ''">
			<mat-checkbox *ngFor="let dow of dows" [checked]="presaveData.notify_dow[dow]" (change)="updatePresavedDOW(dow, $event)">{{dow}}</mat-checkbox>
		</div>

	</div>
</div>
