import {Moment} from 'moment';

export class Match {
	public day: Moment;
	public final_score: number;
	public found_on: Moment;
	public keyword: string;
	public offending_dtld: string;
	public takedown: string;
	public owner: string;
	public priority: number;
	public status: string;
	public summary: string;
	public whois: string;
	public screenshot: string;
	public screenshotError: string;

	constructor() {

	}
}
