import {Component, EventEmitter, HostBinding, Input, OnInit, Output, isDevMode} from '@angular/core';
import {DNSenseService} from "../../_services";

@Component({
	selector: 'layout-panel-clients',
	templateUrl: './layout-panel-clients.component.html',
	styleUrls: ['./layout-panel-clients.component.css'],
})
export class LayoutPanelClientsComponent implements OnInit {
	@HostBinding('class.my-clients-data') @Input()
	bindPanelShow = null;
	@HostBinding('class.my-clients-data') @Input()
	bindCreate = false;
	@Output() closePanel: EventEmitter<any> = new EventEmitter();
	@HostBinding('class.my-clients-data') @Input()
	bindData = {owner_id: '', owner: '', paying: null};

	presaveData = {owner_id: '', owner: '', paying: null};
	changesToApply = false;
	saveStatus = '';
	keywordsData = [];
	domainsData = [];
	clientLoadError = '';


	/*for keyword*/
	keywordDeleteIndex = null;
	addingNewKeyword = false;
	addKeywordStatus = '';
  	inputKeyword = '';
	keywordChangesToApply = false;

	/*for exclusions*/
	exclusionChangesToApply = false;
	keywordForExclusion = null;
	newExclusion = '';
	exclusionDeleteIndex = null;

	/* for domains*/
	domainChangesToApply = false;
	addNewDomain = false;
	domainStatus = '';
	conflictDomain = false;
	deleteDomainIndex = null;
	inputDomain = '';
	savedDomain = '';
	domainSaveStatus = '';



	constructor(private _dnsenseService: DNSenseService) {
	}

	@HostBinding('class.my-clients-data') @Input()
	bindCreateCallback = async (obj) => {
		return obj
	};

	@HostBinding('class.my-clients-data') @Input()
	bindUpdateCallback = async (obj) => {
		return obj
	};

	ngOnInit() {
	}

	async ngOnChanges() {
		if (this.bindData) {
			this.presaveData = {...this.bindData};
			if (!this.bindCreate) {
				let data = await this._dnsenseService.LoadClientInfo(this.bindData.owner_id);
				if (data['error']) {
					this.clientLoadError = 'There was an error loading the client domains and keywords';
				} else {
					this.keywordsData = data['info']['keywords'];
					this.domainsData = data['info']['domains'];
					// TODO: display domains
					//console.log(this.domainsData);
				}
			}
		}
	}

	showKeywordExclusion(keyword) {
		this.keywordForExclusion = keyword;
		this.newExclusion = '';
		this.exclusionDeleteIndex = null;
		this.keywordDeleteIndex = null;
	}

	cancelKeywordExclusion() {
		this.keywordForExclusion = null;
		this.newExclusion = '';
	}

	async saveKeywordExclusion(keyword) {
		this.saveStatus = 'saving';
		if (isDevMode()){
			if(keyword.exclusions == undefined){
				keyword.exclusions = [];
			}
			keyword.exclusions.push({
					word: this.newExclusion
				}
			);
			this.sleep(1000).then(() => {
				this.saveStatus = 'saved';
			});
		}else {
			let err = await this._dnsenseService.CreateKeywordExclusion(this.newExclusion, this.keywordForExclusion.keyword_id.value);
			if (err) {
				this.saveStatus = 'nosave';
			} else {
				this.saveStatus = 'saved';
				this.keywordForExclusion = null;
				this.newExclusion = '';

				//HERE IS WHERE IT NEEDS TO UPDATE SAVED NEW EXCLUSIONS

				//this is the delete one
				// keyword.exclusions = keyword.exclusions.filter(function(value, index, arr){ return value !== exclusion; });

				//this first line just refreshes everything
				let data = await this._dnsenseService.LoadClientInfo(this.bindData.owner_id);
				if (data['info']) {
					let keywords = data['info']['keywords']
					keywords = keywords.filter((kword) => {
						return kword.keyword === keyword.keyword
					})
					keyword.exclusions = keywords[0].exclusions
				} else {
					// TODO: some type of error
				}

				// this.keywordsData = keywords['info']['keywords'];
			}
		}
	}

	async updateKeyword(keyword, type: string) {
		this.saveStatus = 'saving';
		let err = await this._dnsenseService.UpdateClientKeyword(keyword.keyword_id, type, keyword[type]);
		if (err) {
			keyword[type] = !keyword[type];
			this.saveStatus = 'nosave';
		} else {
			this.saveStatus = 'saved';
		}
	}


	/*keyword stuff*/
	deleteKeyword(index) {
		this.keywordDeleteIndex = index;
		this.exclusionDeleteIndex = null;
		this.keywordForExclusion=''
	}
	cancelKeywordDelete(){
		this.keywordDeleteIndex = null;
	}
	async confirmKeywordDelete(keyword){
		this.saveStatus = 'saving';
		let err = await this._dnsenseService.DeleteClientKeyword(keyword.keyword_id);
		if (err) {
			this.saveStatus = 'nosave';
		} else {
			this.keywordsData = this.keywordsData.filter(function(value){ return value !== keyword; });
			this.saveStatus = 'saved';
		}
	}



	deleteExclusion(index) {
		this.exclusionDeleteIndex = index;
		this.keywordDeleteIndex = null;
		this.keywordForExclusion=''
	}
	cancelExclusionDelete() {
		this.exclusionDeleteIndex = null;
	}
	async confirmExclusionDelete(keyword, exclusion) {
		this.saveStatus = 'saving';
		let err = await this._dnsenseService.DeleteKeywordExclusion(exclusion.word_id);
		if (err) {
			this.saveStatus = 'nosave';
		} else {
			keyword.exclusions = keyword.exclusions.filter(function(value, index, arr){ return value !== exclusion; });
			this.saveStatus = 'saved';
			this.exclusionDeleteIndex = null;
		}
	}

	addNewKeywordPrompt(){
		this.addingNewKeyword = true;
	}

	cancelKeyword() {
		this.addKeywordStatus = '';
		this.inputKeyword = '';
		this.keywordChangesToApply = false;
		this.addingNewKeyword = false;
	}

	async saveKeyword() {
		this.addKeywordStatus = 'saving';

		if( isDevMode()) {
			this.keywordsData.push({
				keyword: this.inputKeyword,
				created_on: 'xx/xx/xxxx',
				username: 'developer',
				node_id: '1234'
			});

			this.sleep(1000).then(() => {
				this.addKeywordStatus = 'saved';
			});
		} else {

			let err = await this._dnsenseService.CreateClientKeyword(this.bindData.owner_id, this.inputKeyword);
			if(err){
				this.addKeywordStatus = 'nosave';
			} else {
				this.addKeywordStatus = 'saved';
				this.keywordChangesToApply = false;
				this.addingNewKeyword = false;
				this.inputKeyword = '';

				let keywords = await this._dnsenseService.LoadClientInfo(this.bindData.owner_id);
				this.keywordsData = keywords['info']['keywords'];
			}
		}
	}

	changeKeyword() {
		if(this.inputKeyword !== ""){
			this.keywordChangesToApply = true;
		} else {
			this.keywordChangesToApply = false;
		}
	}


	clog($event) {
		console.log($event);
	}

	// Closes panel
	onPanelClose() {
		this.changesToApply = false;
		this.saveStatus = '';
		this.closePanel.emit()
	}

	updateName($event) {
		this.presaveData.owner = $event.value;
		this.changesToApply = true;
	}

	async updatePaying($event) {
		this.presaveData.paying = $event.checked;
		if (this.bindCreate) {
			this.changesToApply = true;
		} else {
			this.saveStatus = 'saving';
			let err = await this.bindUpdateCallback({owner_id: this.bindData.owner_id, paying: this.presaveData.paying});
			if (err) {
				this.saveStatus = 'nosave';
				this.presaveData.paying = this.bindData.paying;
			} else {
				this.bindData.paying = this.presaveData.paying;
				this.saveStatus = 'saved';
			}
		}
	}

	cancelPanel() {
		this.changesToApply = false;
		this.saveStatus = '';
		this.closePanel.emit();
	}





	/*exclusions*/
	changeExclusion() {
		if(this.newExclusion !== ""){
			this.exclusionChangesToApply = true;
		} else {
			this.exclusionChangesToApply = false;
		}
	}
	exclusionChange() {
		this.changeExclusion();
	}






	// handles all domain functions
	// making a new domain
	newDomain() {
		this.addNewDomain = true;
	}
	// deletes a domain
	deleteDomainPrompt(i) {
		this.deleteDomainIndex = i;
	}
	cancelDomainDelete() {
		this.deleteDomainIndex = null;
	}
	changeDomain() {
		if(this.inputDomain !== ""){
			this.domainChangesToApply = true;
		} else {
			this.domainChangesToApply = false;
		}
	}
	domainChange() {
		this.changeDomain();
	}

	async confirmDomainDelete() {

		this.saveStatus = 'saving';

		if (isDevMode()) {
			/*
			this.internalNotes.splice(this.deleteInternalIndex, 1);
			this.deleteInternalIndex = null;
			this.sleep(1000).then(() => {
				this.internalStatus = 'saved';
			});*/
		} else {
			let err = await this._dnsenseService.DeleteClientDomain( this.domainsData[this.deleteDomainIndex].domain_id )
			if(err){
				this.saveStatus = 'nosave';
			} else {
				this.saveStatus = 'saved';
				let domains = await this._dnsenseService.LoadClientInfo(this.bindData.owner_id);
				this.domainsData = domains['info']['domains'];
				this.deleteDomainIndex = null;
			}
		}
	}

	cancelDomain() {
		this.saveStatus = '';
		this.inputDomain = '';
		this.domainChangesToApply = false;
		this.addNewDomain = false;
	}

	async saveDomain() {
		this.saveStatus = 'saving';

		if( isDevMode()) {
			/*this.internalNotes.push({
				note: this.inputInternal,
				time: 'xx/xx/xxxx',
				username: 'developer',
				node_id: '1234'
			});

			this.sleep(1000).then(() => {
				this.internalStatus = 'saved';
			});*/
		} else {

			let err = await this._dnsenseService.CreateClientDomain(this.bindData.owner_id, this.inputDomain)
			if(err){
				this.saveStatus = 'nosave';
			} else {
				this.saveStatus = 'saved';
				this.domainChangesToApply = false;
				this.addNewDomain = false;
				this.inputDomain = '';

				let domains = await this._dnsenseService.LoadClientInfo(this.bindData.owner_id);
				this.domainsData = domains['info']['domains'];
			}
		}
	}

	async applyPanel() {
		let err = await this.bindCreateCallback(this.presaveData);
		if (err) {
			this.saveStatus = 'nosave';
		} else {
			this.changesToApply = false;
			this.bindData = this.presaveData;
			this.saveStatus = '';
			this.closePanel.emit(
				{
					action: 'apply',
					newData: this.bindData
				}
			);
		}
	}

	sleep = (milliseconds) => {
		return new Promise(resolve => setTimeout(resolve, milliseconds));
	};
}
