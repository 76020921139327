import {Component, EventEmitter, HostBinding, Input, OnInit, Output, OnChanges, SimpleChanges} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ClientService, DNSenseService, UserService} from "../../_services";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'layout-panel-domain-add',
  templateUrl: './layout-panel-domain-add.component.html',
  styleUrls: ['./layout-panel-domain-add.component.css']
})
export class LayoutPanelDomainAddComponent implements OnInit {

  soteriaUser = false;
  loading = false;
  newDomain = '';
  newDomainForm = new FormControl();
  keywordId = null;
  keywordIdForm = new FormControl();
  keywordList = [];
  clientList = [];

  errorMessage = '';
  successMessage = ''

  @HostBinding('class.my-domain-data') @Input()
  bindDomainRows = null;
  @HostBinding('class.my-domain-data') @Input()
  bindPanelShow = null;
  @HostBinding('class.my-domain-data') @Input()
  bindData = null;

  // Event for closing panel.
  // emit any for if we want to emit this.data.data back up
  @Output() hidePanel: EventEmitter<any> = new EventEmitter();
  // Event for sending new domain up
  @Output() sendDomain: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private _dnsenseService: DNSenseService,
              private _clientServices: ClientService,  public _userservice: UserService) { }

  async ngOnInit() {
    this.soteriaUser = await this._userservice.UserIsSoterian();
    let data = await this._dnsenseService.ListKeywords();
    if (data['error']){
      this.errorMessage = "error";
    } else {
      this.keywordList = data['keywords'];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let _ = changes;
  }

  closePanel() {
    //resets the loading variables
    this.loading = false;
    this.keywordId = null;
    this.newDomain = '';
    this.successMessage = '';
    this.errorMessage = '';
    this.hidePanel.emit();
  }

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  async AddDomain(){
    let result = await this._dnsenseService.AddMatch(this.newDomain, this.keywordId.keyword_id)
    if (result['match']) {
      this.successMessage = 'success';
      this.bindData.data.push(result['match'][0]);
      let index = this.bindData.data.indexOf(result['match'][0]);
      if (index !== -1) {
        this.sendDomain.emit({emitMatch: this.bindData.data[index], emitIndex: index});
      }
      this.sleep(1000).then(() => {});
      this.loading = true;
      this.sleep(2000).then(() => {
        this.closePanel();
      });
    } else {
      this.loading = false;
      this.errorMessage = 'error';
    }

  }

  cancelCreateDomain() {
    this.keywordId = null;
    this.newDomain = '';
    this.successMessage = '';
    this.errorMessage = '';
    this.closePanel();
  }

  createDomainEmpty() {
    if (this.newDomain !== '' && this.keywordId !== ''){
      return false ;
    }
    return true ;
  }

}
