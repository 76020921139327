import {
  Component,
  OnInit,
  Input,
  HostListener,
  Output,
  EventEmitter,
  HostBinding,
} from "@angular/core";

const EDR = "EDR",
  NETWORK = "Network",
  COMMENT = "Comment",
  ALL = "All";
const ALL_EVENT_TYPES = [
  {
    label: ALL,
    icon: null,
  },
  {
    label: COMMENT,
    icon: "icon-comment-solid",
  },
];

const EDR_EVENT_TYPE = {
  label: EDR,
  icon: "icon-code-branch-solid",
};

const NETWORK_EVENT_TYPE = {
  label: NETWORK,
  icon: "icon-code-branch-solid",
};

@Component({
  selector: "event-timeline",
  templateUrl: "./event-timeline.component.html",
  styleUrls: ["../event-view.component.css", "./event-timeline.component.css"],
})
export class EventTimelineComponent implements OnInit {
  @HostBinding("class.my-domain-data")
  @Input()
  ticketDetails;

  mergedTimelineItems = null; // Combined events and comments for display
  filteredTimelineItems = null; // Filtered version of merged timeline items

  searchText = ""; // Current search text applied to the timeline

  eventTypes = []; // Type filter options
  selectedType = null; // Current item type filter applied to the timeline

  @Output() remediationEvent = new EventEmitter<boolean>();

  constructor() {}

  @HostListener("scroll", ["$event"])
  onTimelineScroll(event) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      this.remediationEvent.emit(true);
    }
  }

  ngOnInit() {}

  ngOnChanges() {
    if (this.mergedTimelineItems === null && this.ticketDetails) {
      // Initialize timeline when data become available
      this.setupEventTypes();
      this.mergeTimelineItems();
    } else if (
      this.mergedTimelineItems &&
      this.ticketDetails &&
      this.mergeTimelineItems.length !== this.ticketDetails.length
    ) {
      // Update merged timeline when parent adds a comment
      this.mergeTimelineItems();

      // Reapply filters
      this.filterTimelineItems();
    }
  }

  getEventTypeIcon(eventType) {
    switch (eventType) {
      case EDR:
        return "icon-code-branch-solid";
      case NETWORK:
        return "icon-code-branch-solid";
      case COMMENT:
        return "icon-comment-solid";
      default:
        return "";
    }
  }

  setupEventTypes() {
    this.eventTypes = ALL_EVENT_TYPES.slice();
    switch (this.ticketDetails.source) {
      case EDR:
        this.eventTypes.push(EDR_EVENT_TYPE);
        break;
      case NETWORK:
        this.eventTypes.push(NETWORK_EVENT_TYPE);
        break;
    }
  }

  /** Combine and sort items to display in timeline */
  mergeTimelineItems() {
    const mergedItems = this.ticketDetails.comments
      .concat(this.ticketDetails.events)
      .concat(this.ticketDetails.networkEvents)
      .sort((a, b) => {
        return a.createdAt - b.createdAt;
      });

    this.mergedTimelineItems = mergedItems;
  }

  /** Handle expanded change for command line */
  expandCommandLine(id, expanded) {
    // If filtering, set expanded filtered item
    // Otherwise, set expanded on full item list
    const items = (this.filteredTimelineItems === null
      ? this.mergedTimelineItems
      : this.filteredTimelineItems
    ).map((item) => {
      if (item.id === id) {
        item.expanded = expanded;
      }
      return item;
    });

    if (this.filterTimelineItems === null) {
      this.mergeTimelineItems = items;
    } else {
      this.filterTimelineItems = items;
    }
  }

  /** Handler for event type dropdown change */
  setTypeFilter(value) {
    this.selectedType = value;
    this.filterTimelineItems();
  }

  /** Handler for event search text change */
  setSearchText(searchText) {
    this.searchText = searchText;
    this.filterTimelineItems();
  }

  /** Manage filtered item list when filters have changed */
  filterTimelineItems() {
    const filterApplied =
      (this.selectedType && this.selectedType.label !== ALL) ||
      this.searchText !== ""; // Track whether filters are currently being applied

    if (!filterApplied) {
      // If filters haven't been applied, clear the filtered list
      this.filteredTimelineItems = null;
    } else {
      // Filters have been applied
      let filteredTimelineItems = this.mergedTimelineItems;

      filteredTimelineItems = filteredTimelineItems.filter((timelineItem) => {
        let includeItem = true;

        if (this.selectedType && this.selectedType.label !== ALL) {
          // Apply type filter
          if (timelineItem.type !== this.selectedType.label) {
            includeItem = false;
          }
        }

        if (this.searchText !== "") {
          const lowerCaseSearchText = this.searchText.toLowerCase();
          // Apply search text to all fields of all item types
          const values = Object.values(timelineItem);
          let matchingValue = false;
          // Try to find a value that matches the text filter
          values.forEach((value) => {
            if (
              value &&
              JSON.stringify(value).toLowerCase().includes(lowerCaseSearchText)
            ) {
              matchingValue = true;
            }
          });
          // Include the item if any value matched
          if (!matchingValue) {
            includeItem = false;
          }
        }

        return includeItem;
      });

      this.filteredTimelineItems = filteredTimelineItems;
    }
  }
}
