import { Component } from "@angular/core";
import { UserService } from "./_services";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  constructor(
    private _userService: UserService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      "icon-code-branch-solid",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_code_branch_solid.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-comment-solid",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_comment_solid.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-high",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_high.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-medium",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_medium.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-client",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_client.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-infringe",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_infringe.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-download",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_download.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-reset",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_reset.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-reset-dark",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_reset_dark.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-delete",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_delete.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-edit",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_edit.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-priority-high",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_priority_high.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-priority-medium",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_priority_medium.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-priority-low",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_priority_low.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-priority-informational",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_priority_informational.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-risk-high",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_risk_high.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-risk-medium",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_risk_medium.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-risk-low",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_risk_low.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-risk-informational",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_risk_informational.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-status-open",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_status_open.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-status-in-review",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_status_in_review.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-status-client-review",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_status_client_review.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-status-closed",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_status_closed.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-status-in-progress",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_status_in_progress.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-status-unassigned",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_status_unassigned.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-status-infringement",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_status_infringement.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-status-client-owned",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_status_client_owned.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-client-owned",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_client_owned.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-suspicious",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_suspicious.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-takedown-unsuccessful",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_takedown_unsuccessful.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-risk-client-owned",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_risk_client_owned.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-risk-suspicious",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_risk_suspicious.svg"
      )
    );
    this.matIconRegistry.addSvgIcon(
      "icon-help-outline",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "/assets/img/icon_help_outline.svg"
      )
    );
  }
}
