<div class="product-switch">
	<button mat-stroked-button routerLink="/home/lexico/tickets"
			[color]="router.url.indexOf('/lexico') !== -1 ? 'primary' : 'basic'"
			*ngIf="showLexico"
	>Lexico
	</button>
	<button mat-stroked-button routerLink="/home/dnsense/domains"
			[color]="router.url.indexOf('/dnsense') !== -1 ? 'primary' : 'basic'"
			*ngIf="showDnsense"
	>DNSense
	</button>
</div>
