import {Moment} from 'moment';

export class Ticket {
	public id: string;
	public title: string;
	public description: string;
	public severity: number;
	public priority: string;
	public status: string;
	public suspicion: string;
	public priority_icon: string;
	public status_icon: string;
	public suspicion_icon: string;
	public summary: string;
	public client: string;
	public client_name: string;
	public created_at: Moment;
	public caseId: number;
	public queue_score: any;
	public customFields: any;
	public notes: any;
	public comments: any;

	constructor() {
	}
}
