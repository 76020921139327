import {Moment} from 'moment';

export class Client {
	public ID: number;
	public client_name: string;
	public client_uuid: string;
	public ClientProducts: ClientProduct[];

	constructor() {

	}
}

export class ClientProduct {
	public Product: Product;
	public Expires: Moment;
}

export class Product {
	public product_name: string;
}
