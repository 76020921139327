<div class='lexico-tickets-edit-container'
     [ngClass]="{'alert-show': bindAlertShow == true, 'alert-hide': bindAlertShow == false, 'alert-load': bindAlertShow == null}"
     [attr.alert-view]='alertsOpen'
>
    <mde-popover
        #appPopover="mdePopover"
        [mdePopoverOverlapTrigger]="false"
        [mdePopoverCloseOnClick]
    >
        <mat-card style="max-width: 300px">
            <mat-card-content>
                <div class='popover-main'>
                    Are you sure you want to force push this to the customer?
                </div>
                <div class='popover-button-row'>
                    <button id='popover-cancel'>Cancel</button>
                    <button (click)="confirmForcePush($event)" id='popover-delete'>Confirm</button>
                </div>
            </mat-card-content>
        </mat-card>
    </mde-popover>
    <div class='lexico-tickets-edit-header'>
        <div
                class='lexico-tickets-format'
        >
            <!--
            <div (click)="downloadHTML()">HTML</div>
            <div (click)="downloadJSON()">JSON</div>
            <div (click)="downloadCSV()">CSV</div>
            <div (click)="downloadText()">Plaintext</div>
            -->
        </div>
        <div *ngIf="bindSoteriaUser" class='lexico-tickets-actions'>
            <div>Client: <b>{{bindTicket.client_name}}</b></div>
        </div>
        <div *ngIf="bindSoteriaUser" class='lexico-tickets-actions'>
            <div>Current Status: <b>{{bindTicket.status}}</b></div>
        </div>
        <div [hidden]="!bindSoteriaUser || bindTicket.status == 'Client Review' || bindTicket.status == 'Closed'"
             class='lexico-tickets-actions'
        >
            <button (click)="closePanel($event, 'closed irrevelent')" id='button_closed_irrevelent'>Close (irrevelent)
            </button>
            <button [hidden]="bindTicket.status == 'In Review'" [mdePopoverTriggerFor]="appPopover"
                    mdePopoverTriggerOn="click" #popoverTrigger="mdePopoverTrigger"
                    id='button_closed_ignore'>Force push to customer
            </button>
			<button [hidden]="bindTicket.status == 'In Review'" (click)="closePanel($event, 'send to review')"
                    id='button_review'>Send to Review
            </button>
            <button [hidden]="bindTicket.status != 'In Review'" (click)="closePanel($event, 'confirm review')"
                    id='button_confirm'>Confirm Review
            </button>

            <div *ngIf='statusMessage !== ""' id='status-error'>{{statusMessage}}</div>
        </div>
        <mat-icon *ngIf='bindSoteriaUser' (click)="closePanel($event, 'cancel')">close</mat-icon>
    </div>
    <div class='lexico-tickets-edit-body'>
        <div
                *ngIf='!loading && bindTicket'
                class='lexico-tickets-left-edit'
        >
            <layout-tickets-edit
                    [bindData]="bindTicket"
                    [bindTagsList]="bindTagsList"
                    [bindTagsListID]="bindTagsListID"
                    [bindAssigneesList]="bindAssigneesList"
                    [bindSoteriaUser]="bindSoteriaUser"
            ></layout-tickets-edit>
        </div>
        <div
                *ngIf='!loading && bindTicket'
                class='lexico-tickets-right-table'
        >
            <layout-tickets-alerts
                    *ngIf="bindTicket"
                    [bindPanelAlerts]=bindTicket.alerts
                    [bindPanelEvents]=bindTicket.events
                    [bindPanelSource]=bindTicket.source
                    [bindSoteriaUser]="bindSoteriaUser"
                    [bindTicketID]=bindTicket.id
                    (setRow)="selectTableRow($event)"
            >
            </layout-tickets-alerts>
        </div>
    </div>
    <layout-panel-alerts
            [bindData]="alertsData"
            [bindPanelShow]="alertsOpen"
            [bindAlertsType]="alertsType"
            [bindSoteriaUser]="bindSoteriaUser"
            (closeAlertPanel)="closePanelSaveData()"
    ></layout-panel-alerts>
</div>
