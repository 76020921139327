<div
		class='panel-container'
		[ngClass]="{'panel-show': bindPanelShow == true, 'panel-hide': bindPanelShow == false, 'panel-load': bindPanelShow == null}"
>
	<div
			class='panel-header'
			*ngIf='bindPanelShow'
	>
		<div id='panel-name'>
			{{bindTitle}}
		</div>
		<mat-icon *ngIf='!changesToApply' [inline]="true" id='icon-close' class="white" (click)="cancelPanel()">close
		</mat-icon>
		<div *ngIf='changesToApply' id='apply-cancel-combo'>
			<div *ngIf='error' id='status-nosave'>did not save</div>
			<button id='cancel' (click)="cancelPanel()">Cancel</button>
			<button id='apply' (click)="applyPanel()">Apply Changes</button>
		</div>
	</div>
	<div
			class='panel-body'
			*ngIf='bindPanelShow'
	>
		<div *ngFor="let featureRow of bindFeatures" class='panel-row'>
			<div *ngFor="let feature of featureRow['panelList']" class='panel-item'>
				<div *ngIf="feature.type === 'input'" class='uiType'>
					<ui-input
							bindTarget={{feature.dataProp}}
							bindPresetValue='{{bindSelectedData ? bindSelectedData[feature.dataProp] : ""}}'
							bindPlaceholder={{feature.placeholder}}
							bindSaveAppear='false'
							(newValue)="panelArrayUpdate($event)"
					></ui-input>
				</div>
				<div *ngIf="feature.type === 'textarea'" class='uiType'>
					<ui-textarea
							bindTarget={{feature.dataProp}}
							bindPresetValue='{{bindSelectedData ? bindSelectedData[feature.dataProp] : ""}}'
							bindPlaceholder={{feature.placeholder}}
							bindSaveAppear='false'
							(newValue)="panelArrayUpdate($event)"
					></ui-textarea>
				</div>
				<div *ngIf="feature.type === 'list'" class='uiType'>
					<ui-list
							bindTitle={{feature.listTitle}}
							bindTarget={{feature.dataProp}}
							bindPresetValue='{{bindSelectedData ? bindSelectedData[feature.dataProp] : []}}'
							bindPlaceholder={{feature.placeholder}}
							bindValidataion='email'
							bindDelete='true'
							bindNewInclude=true
							(newValue)="panelArrayUpdate($event)"
					></ui-list>
				</div>
				<div *ngIf="feature.type === 'dropdown'" class='uiType'>
					<ui-dropdown
							[bindTarget]="feature.dataProp"
							[bindPlaceholder]="feature.placeholder"
							[bindOptions]="feature.values"
							[bindPresetValue]='bindSelectedData ? bindSelectedData[feature.dataProp]: null'
							(sendValue)="panelArrayUpdate($event)"
					></ui-dropdown>
				</div>
			</div>
		</div>
	</div>
</div>
