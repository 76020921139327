import {
  Component,
  HostBinding,
  Input,
  OnInit,
  EventEmitter,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "menu-sub",
  templateUrl: "./menu-sub.component.html",
  styleUrls: ["./menu-sub.component.css"],
})
export class MenuSubComponent implements OnInit {
  @HostBinding("class.my-domain-data")
  @Input()
  bindList = null;

  constructor(public router: Router) {}

  ngOnInit() {}
}
