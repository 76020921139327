<div class='lexico-tickets-header'>
	<div class='lexico-tickets-title'>Ticket Information</div>
	<div class='lexico-tickets-client-viewable'>
		<div class='saving-status'>
			<div *ngIf='clientViewableStatus == "nosave"' id='status-nosave'>did not save</div>
		</div>
		<mat-checkbox
				*ngIf="bindData && bindSoteriaUser"
                #myCheckbox
				[checked]="bindData.client_viewable"
				(change)="clientViewableCheck($event)"
		>
			Client Viewable
		</mat-checkbox>
	</div>
</div>
<div class='lexico-tickets-id'>ID: {{bindData.id}}<br>{{bindData.created_at}}</div>
<div>
	<div class='lexico-tickets-edit-row row-size-half'>
		<div>
			<ui-dropdown
					*ngIf="bindData && bindSoteriaUser"
					[bindData]="bindData"
					[bindDataField]="'suspicion'"
					[bindPlaceholder]="'Risk'"
					[bindOptions]='optionsRisk'
					[bindIcons]='iconsRisk'
					[bindIdentifier]="bindData.id"
					[bindPresetValue]="bindData.suspicion"
					[bindAPI]="apiRisk"
			>
			</ui-dropdown>
			<div class='lexico-tickets-nonedit' *ngIf="bindData && !bindSoteriaUser">
				<mat-icon
						[svgIcon]="iconsRisk[optionsRisk.indexOf(bindData.suspicion)]"
				></mat-icon>
				{{bindData.suspicion}}
			</div>
		</div>
		<div>
			<ui-dropdown
					*ngIf="bindData && bindSoteriaUser"
					[bindData]="bindData"
					[bindDataField]="'priority'"
					[bindPlaceholder]="'Priority'"
					[bindOptions]='optionsPriority'
					[bindIcons]='iconsPriority'
					[bindIdentifier]="bindData.id"
					[bindPresetValue]="bindData.priority"
					[bindAPI]="apiPriority"
			>
			</ui-dropdown>
			<div class='lexico-tickets-nonedit' *ngIf="bindData && !bindSoteriaUser">
				<mat-icon
						[svgIcon]="iconsPriority[optionsPriority.indexOf(bindData.priority)]"
				></mat-icon>
				{{bindData.priority}}
			</div>
		</div>
	</div>
	<div class='lexico-tickets-edit-row extra-margin-shift  row-size-half'>
		<div>
			<ui-dropdown
					*ngIf="bindData && bindSoteriaUser"
					[bindData]="bindData"
					[bindDataField]="'assignee'"
					[bindPlaceholder]="'Assignee'"
					[bindOptions]='bindAssigneesList'
					[bindIdentifier]="bindData.id"
					[bindPresetValue]="bindData.assignee"
					[bindAPI]="apiAssignee"
			>
			</ui-dropdown>
		</div>
		<div>
			<ui-dropdown
					*ngIf="bindData"
					[bindData]="bindData"
					[bindDataField]="'positivity'"
					[bindPlaceholder]="'Remediation Status'"
					[bindOptions]='positivityStatus'
					[bindIdentifier]="bindData.id"
					[bindPresetValue]="bindData.positivity"
					[bindAPI]="apiPositivity"
			>
			</ui-dropdown>
		</div>
	</div>

	<div class='lexico-tickets-edit-row '>
		<div class='lexico-tickets-edit-subheader'>Description</div>
		<ui-textarea
				*ngIf="bindData && bindSoteriaUser"
				[bindData]="bindData"
				[bindDataField]="'description'"
				[bindTarget]="'description'"
				[bindPlaceholder]="'Description'"
				[bindIdentifier]="bindData.id"
				[bindPresetValue]="bindData.description"
				[bindAPI]="apiDescription"
				[bindSaveAppear]="true"
		></ui-textarea>
		<p *ngIf="!bindSoteriaUser">{{bindData.description}}</p>
	</div>
	<div *ngIf="bindSoteriaUser" class='lexico-tickets-edit-row extra-margin-shift'>
		Tags
		<ui-chips
				*ngIf="bindData"
				[bindTags]="bindData.tags"
				[bindAllTags]="bindTagsList"
				[bindAllTagsAlt]="bindTagsListID"
				[bindMakeOwnTags]=false
				[bindIdentifier]="bindData.id"
				[bindAdditionCallback]="addTag(_lexicoService)"
				[bindRemovalCallback]="removeTag(_lexicoService)"
		></ui-chips>
	</div>
	<div *ngIf="bindSoteriaUser" class='lexico-tickets-edit-row'>
		<ui-list
				*ngIf="bindData.notes"
				[bindTitle]="'Notes'"
				[bindPlaceholder]="'Note on ticket'"
				[bindEmptyText]="'No current notes.'"
				[bindNewText]="'+ New Note'"
				[bindNewInclude]="true"
				[bindType]="'textArea'"
				[bindPresetValue]="bindData.notes"
				[bindEditableField]="'body'"
				[bindDisplayFields]="['user','created_at']"
				[bindAPI]="apiNotes"
		></ui-list>
	</div>
	<div class='lexico-tickets-edit-row'>
		<ui-list
				*ngIf="bindData.comments && bindSoteriaUser"
				[bindTitle]="'Comments (client viewable)'"
				[bindPlaceholder]="'Comment on ticket'"
				[bindEmptyText]="'No current comments.'"
				[bindNewText]="'+ New Comment'"
				[bindType]="'textArea'"
				[bindPresetValue]="bindData.comments"
				[bindEditableField]="'body'"
				[bindDisplayFields]="['user','created_at']"
				[bindAPI]="apiComments"
				[bindNewInclude]="bindSoteriaUser"
		></ui-list>
		<ui-list
				*ngIf="bindData.comments && !bindSoteriaUser"
				[bindTitle]="'Comments'"
				[bindPlaceholder]="'Comment on ticket'"
				[bindEmptyText]="'No current comments.'"
				[bindNewText]="'+ New Comment'"
				[bindNewInclude]="!bindSoteriaUser"
				[bindType]="'textArea'"
				[bindPresetValue]="bindData.comments"
				[bindEditableField]="'body'"
				[bindDisplayFields]="['user','created_at']"
				[bindAPI]="apiComments"
		></ui-list>
	</div>
</div>
