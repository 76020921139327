<div class='container-tags'>
	<div class='saving-status'>
		<div *ngIf='saveStatus == "unsaved"' id='status-unsaved'>unsaved</div>
		<div *ngIf='saveStatus == "saving"' id='status-saving'>saving</div>
		<div *ngIf='saveStatus == "saved"' id='status-saved'>saved</div>
		<div *ngIf='saveStatus == "nosave"' id='status-nosave'>did not save</div>
	</div>
	<mat-form-field class='form-width-full' class="example-chip-list">
		<div *ngIf='duplicateChip' class='input-message input-warning'>"{{duplicateChipName}}" is already in the tag
			list and was not added.
		</div>
		<mat-chip-list #chipList>
			<mat-chip
					*ngFor="let tag of bindTags"
					[selectable]="selectable"
					[removable]="removable"
					(removed)="remove(tag)"
			>
				{{tag}}
				<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
			</mat-chip>
			<input [matChipInputFor]="chipList"
                   #tagInput
				   [formControl]="tagCtrl"
				   [matAutocomplete]="auto"
				   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
				   [matChipInputAddOnBlur]="addOnBlur"
				   (matChipInputTokenEnd)="add($event)">
		</mat-chip-list>
		<mat-autocomplete (opened)="openCheck()" (closed)="closeCheck()" #auto="matAutocomplete"
						  (optionSelected)="selected($event)">
			<mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
				{{tag}}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
</div>
