import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router'
import {ClientService, UserService} from "../../_services";


@Component({
	selector: 'menu-main',
	templateUrl: './menu-main.component.html',
	styleUrls: ['./menu-main.component.css']
})
export class MenuMainComponent implements OnInit {
	soteriaUser = false;
	showLexico = true;
	showDnsense = true;
	constructor(public router: Router, private _clientServices: ClientService, private _userServices: UserService) {
	}

	async ngOnInit() {
		this.soteriaUser = await this._userServices.UserIsSoterian();

		if (!this.soteriaUser){
			this.showLexico = await this.doesClientHaveProduct('lexico');
			this.showDnsense = await this.doesClientHaveProduct('dnsense')
		}
	}

	async doesClientHaveProduct(product: string): Promise<boolean> {
		let client = await this._clientServices.GetClient();
		for (let i = 0; i < client.ClientProducts.length; i++) {
			if (product.toLowerCase() === client.ClientProducts[i]["Product"]["product_name"].toLowerCase()){
				return true
			}
		}
		return false
	}
}
