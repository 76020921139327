<div class = 'dnsense_takedowns_container'>
  <div class="layout-table">
    <layout-filter-box
            [bindTitle]="filterBoxTitle"
            [bindButton]="filterBoxButton"
            [bindFeatures]="filterBoxFeatures"
            [bindButtonInclude]=false
            [bindFields]="filterBoxAllFields"
            [bindData]="data"
    >
    </layout-filter-box>
    <content-table
            [bindSort]='"offending_dtld"'
            [bindSortDirection]='"asc"'
            [bindData]="data"
            [bindTableConfig]="tableConfig"
            [bindSelectedItemIndex]="selectedItemIndex"
            [bindMessage]="tableMessage"
            [bindIdentifierProp]="'offending_dtld'"
            [bindEditable]=false
    >
    </content-table>
  </div>
</div>
