<div class='lexico-events-header'>
	<div class='lexico-tickets-title'>Events</div>
</div>
<div class='lexico-events-body'>
	<cdk-virtual-scroll-viewport itemSize="rowHeight" class="scrolling-viewport">
		<div *cdkVirtualFor="let alert of combinedEventsAlerts; let i=index ">
			<layout-alert-row
					[bindType]="bindPanelSource"
					[bindIndex]=i
					[bindTicketID]=bindTicketID
					[bindData]=alert
					(setRow)="selectTableRow($event)"
			></layout-alert-row>
		</div>
	</cdk-virtual-scroll-viewport>
</div>
