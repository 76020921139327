<div
		class='lexico_aliases_container'
>
	<layout-panel
			[bindTitle]="panelTitle"
			[bindSaveWording]="panelSaveWording"
			[bindFeatures]="panelFeatures"
			[bindPanelShow]="panelShow"
			[bindSelectedData]="selectedItemData"
			[bindIdentifierProp]="identifierProp"
			[bindCreateCallback]="caliasCallback"
			[bindUpdateCallback]="ualiasCallback"
			(closePanel)="closePanelSaveData($event)"
	>
	</layout-panel>
	<div class='table-holder'>
		<layout-filter-box
				[bindTitle]="filterBoxTitle"
				[bindButton]="filterBoxButton"
				[bindFeatures]="filterBoxFeatures"
				[bindButtonInclude]=true
				[bindFields]="filterBoxAllFields"
				(buttonClick)="newItem()"
				[bindData]="data"
		>
		</layout-filter-box>
		<content-table
				[bindSort]='"name"'
				[bindSortDirection]='"asc"'
				[bindData]="data"
				[bindTableConfig]="tableConfig"
				[bindSelectedItemIndex]="selectedItemIndex"
				[bindMessage]="tableMessage"
				(setRow)="selectTableRow($event)"
				(deletedRow)="deleteTableRow($event)"
				[bindIdentifierProp]="'id'"
				[bindRemovalCallback]="rAlias"
		>
		</content-table>
	</div>
</div>
