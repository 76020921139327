import {Component, EventEmitter, HostBinding, Input, isDevMode, OnInit, Output} from '@angular/core';
import {MediaObserver} from '@angular/flex-layout';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http'
import {DNSenseService, UserService} from "../../_services";
import * as moment from 'moment';

@Component({
	selector: 'layout-panel-domain',
	templateUrl: './layout-panel-domain.component.html',
	styleUrls: ['./layout-panel-domain.component.css'],
})
export class LayoutPanelDomainComponent implements OnInit {
	loadingWhois = true;
	loadingDnsRecord = true;
	loadingImage = true;

	internalChangesToApply = false;
	externalChangesToApply = false;
	commentChangesToApply = false;
	commentPullError = false;

	internalAddNewNote = false;
	internalNotePullError = null;
	externalAddNewNote = false;
	externalNotePullError = null;
	deleteInternalIndex = null;
	deleteExternalIndex = null;

	addNewComment = false;
	deleteCommentIndex = null;

	conflictClassification = false;
	conflictTakedown = false;
	conflictTags = false;
	conflictInternal = false;
	conflictExternal = false;

	tagMessage = "Enter any custom tag or select from the list. Tags will be converted to lower case.";
	tagMessageLimited = 'Select a tag from the list.';
	currentDomain = '';

	classificationStatus = '';
	takedownStatus = '';
	tagStatus = '';
	internalStatus = '';
	externalStatus = '';
	monitorStatus = '';
	commentStatus = '';

	/* classification values*/
	selectedClassification = null;
	savedClassification = null;
	classifications = [
		{value: 'Confirmed Malicious', viewValue: 'Confirmed Malicious'},
		{value: 'Suspicious', viewValue: 'Suspicious'},
		{value: 'Infringement', viewValue: 'Infringement'},
		{value: 'Unrelated', viewValue: 'Unrelated'},
		{value: 'New', viewValue: 'New'},
		{value: 'Client Owned', viewValue: 'Client Owned'}
	];

	// Takedown values
	selectedTakedown = null;
	savedTakedown = null;
	takedowns = [
		// {value: 'N/A', viewValue: 'N/A'},
		{value: 'uninitiated', viewValue: 'Uninitiated'},
		{value: 'open', viewValue: 'Open'},
		{value: 'successful', viewValue: 'Successful'},
		{value: 'unsuccessful', viewValue: 'Unsuccessful'}
	];

	internalNotes = [];
	externalNotes = [];
	comments = [];

	// Tag chips
	tags: string[] = [];
	savedTags: string[] = [];
	allTags: string[] = [];
	tagpullError = null;

	// Internal notes
	inputInternal = '';
	savedInternal = '';

	// External notes
	inputExternal = '';
	savedExternal = '';

	//comments
	inputComment = '';
	savedCommet = '';

	// Default is to make tags
	canMakeTags = true;
	soteriaUser = false;
	soteriaAdmin = false;
	userPermissionTakedown = false;
	domainDate = moment().format('M/D/YYYY');
	dnsRecords = [];
	dnsRecordEmpty = false;
	dnsRecordPullError = null;

	@HostBinding('class.my-domain-data') @Input()
	bindDomainRows = null;
	@HostBinding('class.my-domain-data') @Input()
	bindPanelShow = null;

	// Event for closing panel
	@Output() hidePanel: EventEmitter<null> = new EventEmitter();

	constructor(private media: MediaObserver,
				private http: HttpClient,
				public _sanitizer: DomSanitizer,
				private _dnsenseService: DNSenseService,
				private _userservice: UserService
	) {}

	async ngOnInit() {
		this.soteriaUser = await this._userservice.UserIsSoterian();
		this.soteriaAdmin = await this._userservice.UserHasPermission("dnsense.tag.create");
		this.userPermissionTakedown = await this._userservice.UserHasPermission("dnsense.takedown.update");
		if (this.soteriaUser) {
			this.allTags = await this._dnsenseService.GetTagList();
			if (!this.allTags) {
				// todo: handle issues with having no tags in the list
			}
		}
	}

	async ngOnChanges(changes) {
		if (changes.bindPanelShow && changes.bindPanelShow.previousValue && !changes.bindPanelShow.currentValue) {
			return;
		}

		//THIS DATA WILL EVENTUALLY COME IN AS AN ARRAY OF DOMAINS, NOT A SINGLE DOMAIN
		if (this.bindDomainRows !== undefined && this.bindDomainRows[0] !== undefined) {
			this.domainDate = moment(this.bindDomainRows[0].found_on).format('M/D/YYYY');
			this.currentDomain = this.bindDomainRows[0].offending_dtld;
			// needs to take existing data and set to local variables so that the visuals inside the panel can change without
			// changing the actual data object, that can't change until we hit "apply"
			this.selectedClassification = this.bindDomainRows[0].status;
			this.savedClassification = this.bindDomainRows[0].status;
			this.classificationStatus = '';
			this.selectedTakedown = this.bindDomainRows[0].takedown;
			this.savedTakedown = this.bindDomainRows[0].takedown;
			this.takedownStatus = '';
			this.savedInternal = this.inputInternal;
			this.internalStatus = '';
			this.savedExternal = this.inputExternal;
			this.externalStatus = '';
			this.commentStatus = '';
			this.monitorStatus = '';
			this.savedTags = Array.from(this.tags);
			this.tagStatus = '';
			if (this.soteriaUser) {
				// pull tag list for domain
				let tags = await this._dnsenseService.DomainTags(this.bindDomainRows[0].offending_dtld);
				if (tags['error']) {
					this.tagpullError = true;
				} else {
					this.tagpullError = false;
					this.tags = tags['tags'];
					this.savedTags = tags['tags'];
				}

				let inotes = await this._dnsenseService.DomainInternalNotes(this.bindDomainRows[0].offending_dtld);
				if (inotes['error']) {
					this.internalNotePullError = true;
				} else {
					this.internalNotePullError = false;
					this.internalNotes = inotes['notes'];
				}

				let enotes = await this._dnsenseService.DomainExternalNotes(this.bindDomainRows[0].offending_dtld);
				if (enotes['error']) {
					this.externalNotePullError = true;
				} else {
					this.externalNotePullError = false;
					this.externalNotes = enotes['notes'];
				}
			}

			let comment = await this._dnsenseService.DomainComment(this.bindDomainRows[0].offending_dtld);
			if (comment['error']) {
				this.commentPullError = true;
			} else {
				this.commentPullError = false;
				this.comments = comment['comments'];
			}

			let whois = await this._dnsenseService.GetWhois(this.bindDomainRows[0]);
			if (whois['error']){
				this.loadingWhois = false;
				this.bindDomainRows[0]['whois'] = whois['error'];
			}else{
				this.loadingWhois = false;
				this.bindDomainRows[0]['whois'] = whois['result'];
			}

			let records = await this._dnsenseService.GetDNSRecords(this.bindDomainRows[0].offending_dtld);
			if (records['error']) {
				this.loadingDnsRecord = false;
				this.dnsRecordEmpty = false;
				this.dnsRecordPullError = true;
      } else {
				this.dnsRecordEmpty = false;
				this.dnsRecordPullError = false;
				this.loadingDnsRecord = false;
				this.dnsRecords = records['records'];
				if (!this.dnsRecords || (this.dnsRecords && this.dnsRecords.length == 0)) {
					this.dnsRecordEmpty = true;
				}
			}

			let screenshot = await this._dnsenseService.GetScreenshot(this.bindDomainRows[0].offending_dtld);
			if (screenshot['error']) {
				this.loadingImage = false;
				this.bindDomainRows[0]['screenshotError'] = screenshot['error'];
			} else {
				this.loadingImage = false;
				this.bindDomainRows[0]['screenshot'] = screenshot['screenshot'];
			}

			let enotes = await this._dnsenseService.DomainExternalNotes(this.bindDomainRows[0].offending_dtld);
			if (enotes['error']) {
				this.externalNotePullError = true;
			} else {
				this.externalNotePullError = false;
				this.externalNotes = enotes['notes'];
			}
		}

		//if they can't make tags
		this.canMakeTags = this.soteriaAdmin && this.soteriaUser;
	}

	// Closes panel
	closePanel() {
		//resets the loading variables
		this.loadingWhois = true;
		this.loadingDnsRecord = true;
		this.loadingImage = true;
		this.tags = Array.from([]);
		this.hidePanel.emit();
	}

	// Functions for handling internal notes
	changeInternal() {
		this.internalChangesToApply = true;
	}

	changeComment() {
		this.commentChangesToApply = true;
	}

	async saveInternal() {
		this.internalStatus = 'saving';

		if (isDevMode()) {
			this.internalNotes.push({
				note: this.inputInternal,
				time: 'xx/xx/xxxx',
				username: 'developer',
				node_id: '1234'
			});

			this.sleep(1000).then(() => {
				this.internalStatus = 'saved';
			});
		} else {
			let err = await this._dnsenseService.CreateDomainInternalNote(this.inputInternal, this.bindDomainRows[0].offending_dtld);
			if (err) {
				this.internalStatus = 'fail';
				return;
			}

			this.internalStatus = 'saved';

			let inotes = await this._dnsenseService.DomainInternalNotes(this.bindDomainRows[0].offending_dtld);
			if (inotes['error']) {
				this.internalNotePullError = true;
			} else {
				this.internalNotes = inotes['notes'];
			}
		}
		this.cancelInternal();
	}

	cancelInternal() {
		this.savedInternal = '';
		this.inputInternal = '';
		this.internalChangesToApply = false;
		this.internalAddNewNote = false;
	}

	// making a new internal note
	newInternalNote() {
		this.internalAddNewNote = true;
	}

	//make a new note
	newComment() {
		this.addNewComment = true;
	}

	cancelComment() {
		this.savedCommet = '';
		this.inputComment = '';
		this.commentChangesToApply = false;
		this.addNewComment = false;
	}

	deleteCommentPrompt(i) {
		this.deleteCommentIndex = i;
	}

	// deletes a note, first brings up messaging
	deleteInternalPrompt(i) {
		this.deleteInternalIndex = i;
	}

	cancelInternalDelete() {
		this.deleteInternalIndex = null;
	}


	async confirmCommentDelete() {
		if (isDevMode()) {
			this.commentStatus = 'saving';
			this.comments.splice(this.deleteCommentIndex, 1);
			this.deleteCommentIndex = null;
			this.sleep(1000).then(() => {
				this.commentStatus = 'saved';
			});
		} else {
			let err = await this._dnsenseService.DeleteDomainComment(this.comments[this.deleteCommentIndex].comment_id);
			if (err) {
				this.commentStatus = 'fail';
			} else {
				this.commentStatus = 'saved';
				this.comments.splice(this.deleteCommentIndex, 1);
				this.deleteCommentIndex = null;
			}
		}
	}

	async saveComment() {
		this.commentStatus = 'saving';

		if (isDevMode()) {
			this.comments.push({
				comment: this.inputComment,
				time: 'xx/xx/xxxx',
				username: 'developer',
				comment_id: '1234'
			});

			this.sleep(1000).then(() => {
				this.commentStatus = 'saved';
			});
		} else {
			let err = await this._dnsenseService.CreateDomainComment(this.inputComment, this.bindDomainRows[0].offending_dtld);
			if (err) {
				this.commentStatus = 'fail';
				return;
			}

			this.commentStatus = 'saved';

			let commentList = await this._dnsenseService.DomainComment(this.bindDomainRows[0].offending_dtld);
			if (commentList['error']) {
				this.commentPullError = true;
			} else {
				this.commentPullError = false;
				this.comments = commentList['comments'];
			}
		}
		this.cancelComment();
	}

	async confirmInternalDelete() {
		if (isDevMode()) {
			this.internalStatus = 'saving';
			this.internalNotes.splice(this.deleteInternalIndex, 1);
			this.deleteInternalIndex = null;
			this.sleep(1000).then(() => {
				this.internalStatus = 'saved';
			});
		} else {
			let err = await this._dnsenseService.DeleteDomainInternalNote(this.internalNotes[this.deleteInternalIndex].note_id);
			if (err) {
				this.internalStatus = 'fail';
			} else {
				this.internalStatus = 'saved';
				this.internalNotes.splice(this.deleteInternalIndex, 1);
				this.deleteInternalIndex = null;
			}
		}
	}

	// Functions for handling external notes
	changeExternal() {
		this.externalChangesToApply = true;
	}

	async saveExternal() {
		this.externalStatus = 'saving';

		if (isDevMode()) {
			this.externalNotes.push({
				note: this.inputExternal,
				time: 'xx/xx/xxxx',
				username: 'developer',
				node_id: '1234'
			});

			this.sleep(1000).then(() => {
				this.externalStatus = 'saved';
			});
		} else {
			let err = await this._dnsenseService.CreateDomainExternalNote(this.inputExternal, this.bindDomainRows[0].offending_dtld);
			if (err) {
				this.externalStatus = 'fail';
				return;
			}

			this.externalStatus = 'saved';

			let enotes = await this._dnsenseService.DomainExternalNotes(this.bindDomainRows[0].offending_dtld);
			if (enotes['error']) {
				this.externalNotePullError = true;
			} else {
				this.externalNotePullError = false;
				this.externalNotes = enotes['notes'];
			}
		}
		this.cancelExternal();
	}

	cancelExternal() {
		this.savedExternal = '';
		this.inputExternal = '';
		this.externalChangesToApply = false;
		this.externalChangesToApply = false;
		this.externalAddNewNote = false;
	}

	// making a new internal note
	newExternalNote() {
		this.externalAddNewNote = true;
	}

	// deletes a note, first brings up messaging
	deleteExternalPrompt(i) {
		this.deleteExternalIndex = i;
	}

	cancelExternalDelete() {
		this.deleteExternalIndex = null;
	}

	async confirmExternalDelete() {
		if (isDevMode()) {
			this.externalStatus = 'saving';
			this.externalNotes.splice(this.deleteExternalIndex, 1);
			this.deleteExternalIndex = null;
			this.sleep(1000).then(() => {
				this.externalStatus = 'saved';
			});
		} else {
			let err = await this._dnsenseService.DeleteDomainExternalNote(this.externalNotes[this.deleteExternalIndex].note_id);
			if (err) {
				this.externalStatus = 'fail';
			} else {
				this.externalStatus = 'saved';
				this.externalNotes.splice(this.deleteExternalIndex, 1);
				this.deleteExternalIndex = null;
			}
		}
	}

	addTag(dnsService, domain=this.bindDomainRows[0].offending_dtld, canCreate=this.soteriaAdmin) {
		return async (value) => {
			return await dnsService.AddTag(domain, value, canCreate);
		};
	}

	removeTag(dnsService, domain=this.bindDomainRows[0].offending_dtld) {
		return async (value) => {
			return await dnsService.RemoveTag(domain, value);
		}
	}

	sleep = (milliseconds) => {
		return new Promise(resolve => setTimeout(resolve, milliseconds));
	};

	// Changes data based on change (Event handlers)
	async selectionChangeClassification($event) {
		this.selectedClassification = $event.value;
		if (
			this.selectedClassification == 'Confirmed Malicious' ||
			this.selectedClassification == 'Suspicious'
		){
			let err = await this._dnsenseService.Monitoring(this.bindDomainRows[0].offending_dtld, true);
			if (err){
				this.monitorStatus = 'fail';
				this.bindDomainRows[0].monitor = true;
			} else{
				this.monitorStatus = 'saved';
				this.bindDomainRows[0].monitor = true;
			}

		}
		let err = await this._dnsenseService.UpdateDomainStatus(this.bindDomainRows[0].offending_dtld, $event.value);
		if (err) {
			this.selectedClassification = this.bindDomainRows[0].status;
			this.classificationStatus = 'fail';
		} else {
			this.bindDomainRows[0].status = $event.value;
			let iconStatusMatch = {
				Client_Owned: "icon-risk-client-owned",
				Infringement: "icon-status-infringement",
				Confirmed_Malicious: "icon-risk-high",
				New: "icon-risk-medium",
				Suspicious: "icon-risk-suspicious",
				Unrelated: "icon-risk-informational"
			};
			let statusText = '';
			if (this.bindDomainRows[0].status) {
				statusText = this.bindDomainRows[0].status.split(' ').join('_');
			}
			this.bindDomainRows[0].status_icon = iconStatusMatch[statusText];
			this.classificationStatus = 'saved';
		}
	}

	// takes in: {value: '...'}
	async selectionChangeTakedown($event) {
		if (isDevMode()) {
			this.takedownStatus = 'saving';
			this.sleep(1000).then(() => {
				this.bindDomainRows[0].takedown = $event.value;
				this.selectedTakedown = $event.value;
				this.takedownStatus = 'saved';
			});
		} else {
			let err = await this._dnsenseService.UpdateDomainTakedown(this.bindDomainRows[0].offending_dtld, $event.value.toLowerCase());
			if (err) {
				this.selectedTakedown = this.bindDomainRows[0].takedown;
				this.takedownStatus = 'fail';
			} else {
				this.bindDomainRows[0].takedown = $event.value;
				this.selectedTakedown = $event.value;
        let iconTakedownMatch = {
				  N_A: "icon-status-unassigned",
			  	open: "icon-status-client-review",
			  	successful: "icon-status-in-progress",
			  	uninitiated: "icon-status-open",
			  	unsuccessful: "icon-takedown-unsuccessful",
		  	};
		  	let takedownText = '';
		  	if (this.bindDomainRows[0].takedown) {
		  		takedownText = this.bindDomainRows[0].takedown.split('/').join('_');
		  	}
		  	this.bindDomainRows[0].takedown_icon = iconTakedownMatch[takedownText];
				this.takedownStatus = 'saved';
			}
		}
	}

	async monitor($event){
		if (isDevMode()) {
			this.monitorStatus = 'saving';
			this.sleep(1000).then(() => {
				this.bindDomainRows[0].monitor = $event.checked;
				this.monitorStatus = 'saved';
			});
			this.bindDomainRows[0].monitor = $event.checked;
		}else{
			let err = await this._dnsenseService.Monitoring(this.bindDomainRows[0].offending_dtld, $event.checked);
			if (err){
				this.monitorStatus = 'fail';
				this.bindDomainRows[0].monitor = $event.checked;
			} else{
				this.monitorStatus = 'saved';
				this.bindDomainRows[0].monitor = $event.checked;
			}
		}
	}

	async takedownRequest($event){
		this.takedownStatus = 'saving';
		if (isDevMode()){
			this.sleep(1000).then(() => {
				this.selectionChangeTakedown({value: 'open'});
			});
		}else{
			let err = await this._dnsenseService.DomainTakedown(this.bindDomainRows[0].offending_dtld)
			if (err){
				this.takedownStatus = 'fail';
			}else {
				// change takedown status to open
				await this.selectionChangeTakedown({value: 'open'});

			}

		}
	}


	inputChangeInternal() {
		this.changeInternal();
	}

	inputChangeExternal() {
		this.changeExternal();
	}

	inputChangeComment() {
		this.changeComment();
	}

	imageClick(url, domain) {
		let newTab = window.open();
		newTab.document.title = domain + ' screenshot';
		newTab.document.body.innerHTML = '<img src="' + url + '" style="max-width: 100%;max-height: 100%" >';
	}

}
