import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output} from '@angular/core';
import {LexicoService, UserService} from "../../_services";

@Component({
	selector: 'layout-alert-row',
	templateUrl: './layout-alert-row.component.html',
	styleUrls: ['./layout-alert-row.component.css']
})
export class LayoutAlertRowComponent implements OnInit, OnChanges {
	// For quick-actions on alerts
	// hot1Selected = false;
	// hot2Selected = false;
	// hot3Selected = false;
	// hot4Selected = false;
	// hot5Selected = false;

	annotationFields = [
		{
			"type": "autoDate",
			"placeholder": "Date",
			"width": ""
		},
		{
			"type": "autoUser",
			"placeholder": "User",
			"width": ""
		},
		{
			"type": "input",
			"placeholder": "Note",
			"width": ""
		}
	];
	apiAlerts = {
		'link': '/ticket/ticket/annotation',
		'identifierProp': 'alert_id',
		'valueProp': 'annotation',
		'mainIdentifier': null
	};
	apiEvents = {
		'link': '/ticket/ticket/event/annotation',
		'identifierProp': 'event_id',
		'valueProp': 'annotation',
		'mainIdentifier': null
	};
	selectedAPI = null;

	timestamp = null;
	event = {};
	saveStatus = '';

	soteriaUser = false;

	@HostBinding('class.my-domain-data') @Input()
	bindType = null;
	@HostBinding('class.my-domain-data') @Input()
	bindIndex = null;
	@HostBinding('class.my-domain-data') @Input()
	bindData = null;
	@HostBinding('class.my-domain-data') @Input()
	bindTicketID = '';

	@Output() setRow: EventEmitter<any> = new EventEmitter();

	constructor(private _lexicoService: LexicoService, private _userservice: UserService) {
	}

	async ngOnInit() {
		// determines if this is an internal user or not
		this.soteriaUser = await this._userservice.UserIsSoterian();

		this.timestamp = this.bindData["@timestamp"];

		if (this.bindData.data.event !== undefined) {
			this.event = this.bindData.data.event;
		} else if (this.bindData.data.detect !== undefined) {
			this.event = this.bindData.data.detect.event;
		}
	}

	ngOnChanges(){
		if (this.bindData !== null) {
			this.timestamp = this.bindData["@timestamp"];
			if (this.bindData.rowType == 'event') {
				this.selectedAPI = this.apiEvents;
			} else {
				this.selectedAPI = this.apiAlerts;
			}
			this.selectedAPI.mainIdentifier = this.bindData.id;
		}
	}

	async markIrrelevant($event) {
		this.saveStatus = 'saving';
	    let err = await this._lexicoService.UpdateAlertRelevancy(this.bindTicketID, this.bindData.id, $event.checked);
	    if (err) {
	    	this.saveStatus = 'nosave'
			this.bindData.irrelevant = !$event.checked
		} else {
			this.saveStatus = 'saved'
			this.bindData.irrelevant = $event.checked
		}
	}

	selectRow() {
		//emits details up to parent
		this.setRow.emit(
			{
				emitType: this.bindType,
				emitIndex: this.bindIndex,
				emitData: this.bindData
			}
		);
	}

	// the timestamp should only be a length of 10
	goToLiveView(sid: string, oid: string, timestamp: string, this_event: string){
		let url = "https://app.limacharlie.io/explore/" + oid + "/" + sid + "?fullscreen=true&run=true&time=" +
			this.truncate(timestamp, 10) + "&selected=" + this_event;
		window.open(url, "_blank");
	}

	truncate(string: string, num: number){
		if (string.length <= num){
			return string
		}
		return string.toString().slice(0, num)
	}
}
