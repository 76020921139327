<div
        class='lexico_notifier_container'
>
    <layout-panel-notifiers
            [bindPanelShow]="panelShow"
            [bindData]="selectedItemData"
            [bindCreate]="newNotifier"
            [bindCreateCallback]="cnotifierCallback"
            [bindUpdateCallback]="unotifierCallback"
            (closePanel)="closePanelSaveData($event)"
    >
    </layout-panel-notifiers>
    <div class='table-holder'>
        <layout-filter-box
                [bindTitle]="filterBoxTitle"
                [bindButton]="filterBoxButton"
                [bindFeatures]="filterBoxFeatures"
                [bindButtonInclude]=true
                [bindFields]="filterBoxAllFields"
                (buttonClick)="newItem()"
                [bindData]="data"
        >
        </layout-filter-box>
        <content-table
                [bindSort]='"name"'
                [bindSortDirection]='"asc"'
                [bindData]="data"
                [bindTableConfig]="tableConfig"
                [bindSelectedItemIndex]="selectedItemIndex"
                [bindMessage]="tableMessage"
                (setRow)="selectTableRow($event)"
                (deletedRow)="deleteTableRow($event)"
                [bindIdentifierProp]="'id'"
                [bindRemovalCallback]="rNotifier"
        >
        </content-table>
    </div>
</div>
