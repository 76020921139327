<div class="section-switch">
  <nav>
    <button
      *ngFor="let link of bindList"
      mat-stroked-button
      routerLink="{{ link.router }}"
      [color]="router.url.indexOf(link.router) !== -1 ? 'primary' : 'basic'"
    >
      {{ link.label }}
    </button>
  </nav>
</div>
