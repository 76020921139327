import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';

@Component({
	selector: 'layout-panel-alerts',
	templateUrl: './layout-panel-alerts.component.html',
	styleUrls: ['./layout-panel-alerts.component.css']
})
export class LayoutPanelAlertsComponent implements OnInit {
	version = null;
	timestamp = null;
	event = null;
	panelOpenState = false;
	syslog = {
		"syslog_facility": "",
		"syslog_host": "",
		"syslog_host_from": "",
		"syslog_legacy_msghdr": "",
		"syslog_priority": "",
		"syslog_sourceip": "",
		"syslog_tags": ""
	};
	annotationFields = [
		{
			"type": "autoDate",
			"placeholder": "Date",
			"width": ""
		},
		{
			"type": "autoUser",
			"placeholder": "User",
			"width": ""
		},
		{
			"type": "input",
			"placeholder": "Note",
			"width": ""
		}
	];
	apiAlerts = {
		'link': '/ticket/ticket/annotation',
		'identifierProp': 'alert_id',
		'valueProp': 'annotation',
		'mainIdentifier': null
	};
	apiEvents = {
		'link': '/ticket/ticket/event/annotation',
		'identifierProp': 'event_id',
		'valueProp': 'annotation',
		'mainIdentifier': null
	};
	selectedAPI = null;
	ossecObject = false;

	@HostBinding('class.my-domain-data') @Input()
	bindPanelShow = null;
	@HostBinding('class.my-domain-data') @Input()
	bindAlertsType = null;
	@HostBinding('class.my-domain-data') @Input()
	bindData = null;
	@HostBinding('class.my-domain-data') @Input()
	bindSoteriaUser = null;

	@Output() closeAlertPanel: EventEmitter<any> = new EventEmitter();

	constructor() {
	}

	ngOnInit() {
	}

	ngOnChanges() {
		this.panelOpenState = false;
		if (this.bindData !== null) {
			this.timestamp = this.bindData["@timestamp"];
			if (this.bindData.rowType == 'event') {
				this.selectedAPI = this.apiEvents;
			} else {
				this.selectedAPI = this.apiAlerts;
			}
			this.selectedAPI.mainIdentifier = this.bindData.id;
		}

		if (this.bindAlertsType === "Network" && this.bindData && this.bindData.data) {
			this.version = this.bindData.data["@version"];
			this.syslog.syslog_facility = this.bindData.data["syslog-facility"];
			this.syslog.syslog_host = this.bindData.data["syslog-host"];
			this.syslog.syslog_host_from = this.bindData.data["syslog-host_from"];
			this.syslog.syslog_legacy_msghdr = this.bindData.data["syslog-legacy_msghdr"];
			this.syslog.syslog_priority = this.bindData.data["syslog-priority"];
			this.syslog.syslog_sourceip = this.bindData.data["syslog-sourceip"];
			this.syslog.syslog_tags = this.bindData.data["syslog-tags"];
		} else if (this.bindData !== null) {
			//if not network then endpoint
			// gets the "event" stuff into a common place
			if (this.bindData.data.detect !== undefined) {
				this.event = this.bindData.data.detect.event;
			} else {
				this.event = this.bindData.data.event;
			}
		}

		if (this.bindData) {
			this.ossecObject = typeof (this.bindData.data.message) === 'object';
		}
	}

	isObject(val) {
		return typeof val === 'object';
	}

	// this will close the panel but not reset anything so that the guts
	// don't get jarring as you're fading out
	// reset is done on the panel getting new data
	cancelPanel() {
		this.closeAlertPanel.emit(
			{
				action: 'cancel'
			}
		);
	}
}
