import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LexicoService } from "../../_services";
import { MediaObserver } from "@angular/flex-layout";
import { Ticket } from "../../_models";

@Component({
  selector: "lexico-tickets-edit",
  templateUrl: "./lexico-tickets-edit.component.html",
  styleUrls: ["./lexico-tickets-edit.component.css"],
})
export class LexicoTicketsEditComponent implements OnInit {
  alertsOpen = null;
  alertsType = "endpoint";
  alertsData = null;
  statusMessage = "";
  loading = true;
  error = false;
  //icon lists
  iconPriorityMatch = {
    High: "icon-priority-high",
    Medium: "icon-priority-medium",
    Low: "icon-priority-low",
    Informational: "icon-priority-informational",
  };
  iconStatusMatch = {
    Open: "icon-status-open",
    In_Review: "icon-status-in-review",
    In_Progress: "icon-status-in-progress",
    Client_Review: "icon-status-client-review",
    Closed: "icon-status-closed",
    Unassigned: "icon-status-unassigned",
  };
  iconRiskMatch = {
    Malicious: "icon-risk-high",
    Suspicious: "icon-risk-medium",
    Potentially_Unwanted_Program_PUP: "icon-risk-suspicious",
    Not_Suspicious: "icon-risk-informational",
  };

  @HostBinding("class.my-domain-data")
  @Input()
  bindAlertShow = null;
  @HostBinding("class.my-domain-data")
  @Input()
  bindTicket = null;
  @HostBinding("class.my-domain-data")
  @Input()
  bindTagsList = null;
  @HostBinding("class.my-domain-data")
  @Input()
  bindTagsListID = null;
  @HostBinding("class.my-domain-data")
  @Input()
  bindAssigneesList = null;
  @HostBinding("class.my-domain-data")
  @Input()
  bindSoteriaUser = null;
  @HostBinding("class.my-domain-data")
  @Input()
  bindClientList = [];

  @Output() closeAlert: EventEmitter<any> = new EventEmitter();
  @Output() nextTicket: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private _lexicoService: LexicoService,
    public media: MediaObserver
  ) {}

  ngOnInit() {}

  updateBind(data: Ticket) {
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        this.bindTicket[property] = data[property];
      }
    }
  }

  updateTicket(data: Ticket) {
    if (data !== null) {
      // Enforce that something exists for these values
      if (!data.status) {
        data.status = "";
      }
      if (!data.priority) {
        data.priority = "";
      }
      if (!data.suspicion) {
        data.suspicion = "";
      }

      // matches up columns with icons
      let statusText = data.status.split(" ").join("_");
      let priorityText = data.priority.split(" ").join("_");
      let suspiciousText = data.suspicion.split(" ").join("_");
      suspiciousText = suspiciousText.replace('(', '');
      suspiciousText = suspiciousText.replace(')', '');
      data.priority_icon = this.iconPriorityMatch[priorityText];
      data.status_icon = this.iconStatusMatch[statusText];
      data.suspicion_icon = this.iconRiskMatch[suspiciousText];

      // Push data back into table
      if (this.bindTicket && data && this.bindTicket.id === data.id) {
        this.updateBind(data);
      }
      this.loading = false;

      // Set the client name on page if user is a soterian
      if (this.bindSoteriaUser && this.bindTicket) {
        for (let i = 0; i < this.bindClientList.length; i++) {
          if (this.bindClientList[i]["client_uuid"] == this.bindTicket.client) {
            this.bindTicket.client_name = this.bindClientList[i]["client_name"];
          }
        }
      }
    }
  }

  // TODO: add error loading ticket and ticket not found to ticket-edit
  async ngOnChanges() {
    // If the ticket isn't empty, set it.
    if (this.bindTicket !== null) {
      let data = await this._lexicoService.GetTicketDetails(this.bindTicket.id);
      if (data["error"]) {
        // console.log(data['error']);
      } else if (!data["ticket"]) {
        // console.log('error no ticket');
      } else {
        this.updateTicket(<Ticket>data["ticket"]);
      }
    }
  }

  confirmForcePush($event) {
    this.closePanel($event, "closed ignore");
  }

  async closePanel($event, action) {
    let fail;
    // API pieces can go here or they can go up a level higher and 'action' can get passed up
    if (action == "cancel") {
      // anything for cancel
    } else if (action == "send to review") {
      // anything for send to review
      let err = await this._lexicoService.SetStatus(
        this.bindTicket.id,
        "In Review"
      );
      if (err) {
        if (err.status == 400) {
          fail = true;
          this.statusMessage =
            "priority, suspicion, and description must be set";
        } else {
          fail = true;
          this.statusMessage = "did not save status";
        }
      } else {
        fail = false;
        this.bindTicket.status = "In Review";
        let statusText = this.bindTicket.status.split(" ").join("_");
        this.bindTicket.status_icon = this.iconStatusMatch[statusText];
      }
    } else if (action == "closed ignore") {
      // anything for closed (ignore)
      let err = await this._lexicoService.SetStatus(
        this.bindTicket.id,
        "Client Review"
      );
      if (err) {
        if (err.status == 400) {
          fail = true;
          this.statusMessage = "must be after hours";
        } else {
          fail = true;
          this.statusMessage = "did not save status";
        }
      } else {
        fail = false;
        this.bindTicket.status = "Client Review";
        let statusText = this.bindTicket.status.split(" ").join("_");
        this.bindTicket.status_icon = this.iconStatusMatch[statusText];
      }
    } else if (action == "confirm review") {
      // anything for confirm review
      let err = await this._lexicoService.SetStatus(
        this.bindTicket.id,
        "Client Review"
      );
      if (err) {
        if (err.status == 400) {
          fail = true;
          this.statusMessage = "ticket must be client-viewable";
        } else {
          fail = true;
          this.statusMessage = "did not save status";
        }
      } else {
        fail = false;
        this.bindTicket.status = "Client Review";
        let statusText = this.bindTicket.status.split(" ").join("_");
        this.bindTicket.status_icon = this.iconStatusMatch[statusText];
      }
    } else if (action == "closed irrevelent") {
      // anything for closed (irrevelent)
      let err = await this._lexicoService.CloseIrrelevant(this.bindTicket.id);
      if (err) {
        fail = true;
        this.statusMessage = "did not save status";
      } else {
        fail = false;
        this.bindTicket.status = "Closed";
        let statusText = this.bindTicket.status.split(" ").join("_");
        this.bindTicket.status_icon = this.iconStatusMatch[statusText];
        this.bindTicket.client_viewable = false;
      }
    }

    if (!fail) {
      this.bindTicket = null;
      this.closeAlert.emit({
        action: "cancel",
      });
      if (action != "cancel") {
        this.nextTicket.emit({});
      }
    }
    // this will close the panel but not reset anything so that the guts
    // don't get jarring as you're fading out
    // reset is done on the panel getting new data
  }

  closePanelSaveData() {
    this.alertsOpen = false;
    this.alertsType = null;
    this.alertsData = null;
  }

  selectTableRow($event) {
    this.alertsOpen = true;
    this.alertsType = $event.emitType;
    this.alertsData = $event.emitData;
  }

  /*
		downloadHTML() {
			console.log('download HTML');
			console.log(this.bindMatch);
			// download the HTML version, can use this.bindMatch to find the right thing
			// guessing maybe 'id' or does some other identifier need to be in this view?
		}

		downloadJSON() {
			console.log('download JSON');
			console.log(this.bindMatch);
			// download the JSON version, can use this.bindMatch to find the right thing
			// guessing maybe 'id' or does some other identifier need to be in this view?
		}

		downloadCSV() {
			console.log('download CSV');
			console.log(this.bindMatch);
			// download the CSV version, can use this.bindMatch to find the right thing
			// guessing maybe 'id' or does some other identifier need to be in this view?
		}

		downloadText() {
			console.log('download TEXT');
			console.log(this.bindMatch);
			// download the TEXT version, can use this.bindMatch to find the right thing
			// guessing maybe 'id' or does some other identifier need to be in this view?
		}
	*/
}
