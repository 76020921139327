import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material";
import {LexicoService} from "../../_services";

@Component({
	selector: 'app-lexico-broadcasters',
	templateUrl: './lexico-broadcasters.component.html',
	styleUrls: ['./lexico-broadcasters.component.css']
})
export class LexicoBroadcastersComponent implements OnInit {
    data = null;
	tableMessage = null;
	selectedItemData = null;
	selectedItemIndex = null;
	panelShow = null;
	tableConfig = {
		"columns": [
			{
				"header": "Name",
				"field": "name",
				"maxWidth": 200
			},
			{
				"header": "Type",
				"field": "type",
				"maxWidth": null
			}
		],
		"delete": true,
		"testing": false
	};
	filterBoxTitle = "Broadcaster List";
	filterBoxButton = "+ Broadcaster";
	filterBoxFeatures = [
		{
			"filterList": [
				{
					type: "input",
					filterTarget: "all",
					default: "",
					placeholder: "Text Filter (on any column)",
					uniqueID: 'text filter'
				}
			]
		},
		{
			"filterList": []
		},
		{
			"filterList": []
		}
	];
	filterBoxAllFields = ["name", "type"];
	newBroadcaster = false;

	constructor(private _lexicoService: LexicoService) {
	}

	updateBroadcasters(data: any[]) {
		if (data !== null && data.length == 0) {
			this.tableMessage = 'no data in this time range';
		} else if (data !== null) {
			this.tableMessage = null;
			this.data = new MatTableDataSource(data);
		}
	}

	async ngOnInit() {
		let data = await this._lexicoService.GetBroadcasters();
		if (data['error']) {
			this.tableMessage = 'error loading data';
		} else if (!data['broadcasters']) {
			this.tableMessage = 'no data in this time range';
		} else {
			this.updateBroadcasters(data['broadcasters']);
		}
	}

	// for getting a selected table row
	// this would appear in many different views
	selectTableRow($event) {
		const match = $event.emitMatch;
		const index = $event.emitIndex;

		// if row already selected, closes it
		if (index == this.selectedItemIndex) {
			this.selectedItemData = null;
			this.selectedItemIndex = null;
			this.panelShow = false;
		} else {
			this.selectedItemData = match;
			this.selectedItemIndex = index;
			this.panelShow = true;
			this.newBroadcaster = false;
		}
	}

	deleteTableRow($event) {
		if ($event.emitLength == 0) {
			this.tableMessage = 'no data in this time range';
			this.data = null;
		}
	}

	rBroadcaster = this.removeBroadcaster(this._lexicoService);
	removeBroadcaster(lexicoService) {
		return async (identifier) => {
			return await lexicoService.DeleteBroadcaster(identifier);
		}
	}

	// opens panel to make a new item
	// this would appear in many different views
	newItem() {
		this.selectedItemIndex = null;
		this.newBroadcaster = true;
		this.panelShow = true;

		// temp tries making new alias
		this.selectedItemData = {
			'name': '',
			'type': null
		};
	}

	async closePanelSaveData($event) {
		if ($event && $event.nochange) {
			// does the closing action and resets things
			this.selectedItemData = null;
			this.selectedItemIndex = null;
			this.panelShow = false;
			return;
		}

		let data = await this._lexicoService.UpdateBroadcastersList();
		if (data['error']) {
			this.tableMessage = 'error loading data';
		} else if (!data['broadcasters']) {
			this.tableMessage = 'no data in this time range';
		} else {
			this.updateBroadcasters(data['broadcasters']);
		}

		// does the closing action and resets things
		this.selectedItemData = null;
		this.selectedItemIndex = null;
		this.panelShow = false;
	}

	// Hacky work-around to get the update to stop breaking within the panel.
	// For some reason, if you pass a function call rather than a function reference
	// the panel update craps out.
	cbroadcastCallback = this.createBroadcaster(this._lexicoService);
	createBroadcaster(lexicoService) {
		return async (obj) => {
			return await lexicoService.CreateBroadcaster(obj);
		}
	}

	// Hacky work-around to get the update to stop breaking within the panel.
	// For some reason, if you pass a function call rather than a function reference
	// the panel update craps out.
	ubroadcastCallback = this.updateBroadcaster(this._lexicoService);
	updateBroadcaster(lexicoService) {
		return async (obj) => {
			return await lexicoService.UpdateBroadcaster(obj);
		}
	}
}
