<h1 mat-dialog-title>Submit a Suggestion</h1>
<div mat-dialog-content>
	<p>We would love to hear what you think of the Soteria portal. Any suggestions are welcome.</p>
	<mat-form-field style="width: 100%;">
		<input matInput [(ngModel)]="data.feedback" (keyup.enter)="onEnter()">
	</mat-form-field>
</div>
<div mat-dialog-actions>
	<button mat-button id="cancel" (click)="onNoClick()">Cancel</button>
	<button mat-button type="button" id="submit" [mat-dialog-close]="data.feedback" cdkFocusInitial>Submit</button>
</div>
