import {
  Component,
  OnInit,
  HostBinding,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { LexicoService } from "../../../../_services";

@Component({
  selector: "event-summary",
  templateUrl: "./event-summary.component.html",
  styleUrls: ["../event-view.component.css", "./event-summary.component.css"],
})
export class EventSummaryComponent implements OnInit {
  // API metadata to support remediation status updates
  apiPositivity = {
    link: "/ticket/ticket/positivity",
    identifierProp: "id",
    valueProp: "positivity",
    mainIdentifier: null,
  };

  // Remediation status options
  positivityStatus = [
    "False Positive/Misidentified Activity",
    "False Positive/Approved or authorized activity",
    "True Positive",
  ];

  @HostBinding("class.my-domain-data")
  @Input()
  bindTicket = null;
  @HostBinding("class.my-domain-data")
  @Input()
  ticketDetails = null;
  @Input()
  remediationEnabled: any;

  commentText = ""; // User entry text
  commentError = false; // Notification for user on failure to save

  @Output() remediationEvent = new EventEmitter<boolean>();
  @Output() commentEvent = new EventEmitter<any>();

  constructor(private _lexicoService: LexicoService) {}

  ngOnInit() {}

  getSeverityText(severity) {
    switch (severity) {
      case "Not Suspicious":
        return "???";
      case "Suspicious":
        return "Activity that is abnormal and cannot be attributed to a known tradecraft/malware family or which an analyst cannot attribute to malicious activity with a high confidence due a lack of environmental or contextual knowledge.";
      case "Malicious":
        return "Broad term for activity carried out by a human actor or software with the intent purpose to degrade, disrupt, deny, or destroy confidentiality, integrity, or availability of a system or data on a system.";
      default:
        return "???";
    }
  }

  getClassificationText(classification) {
    switch (classification) {
      case "Low":
        return "Potential Unwanted or Risky Applications category and activities that introduce risk or potential control gaps.";
      case "Medium":
        return "Action should be taken with urgency but does not require a drop everything or all hands response.";
      case "High":
        return "Immediate attention and action is required.";
      default:
        return "???";
    }
  }

  // Handle text coloration for severity
  mapSeverityToColor(severity) {
    switch (severity) {
      case "Suspicious":
        return "yellow";
      case "Malicious":
        return "red";
      default:
        return "";
    }
  }

  // Handle text coloration for severity
  mapClassificationToColor(classification) {
    switch (classification) {
      case "Low":
        return "green";
      case "Medium":
        return "yellow";
      case "High":
        return "red";
      default:
        return "";
    }
  }

  /** Handle comment text change */
  onCommentTextKeyup(comment) {
    this.commentText = comment;
  }

  // Save comment on service side
  async onCommentSave() {
    const { id } = this.ticketDetails;
    try {
      await this._lexicoService.AddTicketComment(id, this.commentText);
      // Notify parent to refresh / add comment to timeline
      this.commentEvent.emit();
      // Clear comment text and error
      this.commentText = "";
      this.commentError = false;
    } catch (error) {
      // Notify user of failure to save
      this.commentError = true;
    }
  }
}
