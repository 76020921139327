<div class="event-timeline-container">
  <div class="header-bar">
    <div class="main-header">Event Timeline</div>

    <div class="timeline-filters">
      <div class="filter">
        <input
          class="search-input"
          placeholder="Search..."
          matInput
          value="{{ searchText }}"
          (input)="setSearchText($event.target.value)"
        />
        <mat-icon class="search">search</mat-icon>
      </div>

      <mat-select
        class="filter"
        placeholder="Event Type"
        (selectionChange)="setTypeFilter($event.value)"
        [(value)]="selectedType"
      >
        <mat-select-trigger *ngIf="selectedType">
          <mat-icon
            *ngIf="selectedType.icon"
            svgIcon="{{ selectedType.icon }}"
            height="18px"
          ></mat-icon>
          {{ selectedType.label }}
        </mat-select-trigger>

        <mat-option *ngFor="let type of eventTypes" [value]="type">
          <mat-icon *ngIf="type.icon" svgIcon="{{ type.icon }}"></mat-icon>
          {{ type.label }}
        </mat-option>
      </mat-select>
    </div>
  </div>

  <div
    class="timeline-items"
    *ngIf="mergedTimelineItems"
    (scroll)="onTimelineScroll($event)"
  >
    <div
      class="timeline-item-container"
      *ngFor="
        let item of filteredTimelineItems === null
          ? mergedTimelineItems
          : filteredTimelineItems
      "
    >
      <div class="datetime">
        <mat-icon
          class="{{
            item.type !== 'Comment' ? 'header-icon code-branch' : 'header-icon'
          }}"
          svgIcon="{{ getEventTypeIcon(item.type) }}"
        ></mat-icon>
        {{ item.createdAt.toLocaleString() }}
      </div>
      <!-- Template for comments -->
      <div class="timeline-item comment-item" *ngIf="item.type === 'Comment'">
        <div class="item-header">{{ item.user }} commented</div>

        <div class="val-group">
          <div class="value">
            {{ item.body }}
          </div>
        </div>
      </div>

      <!-- Template for EDR events -->
      <div class="timeline-item event-item" *ngIf="item.type === 'EDR'">
        <div class="item-header">EDR Event Occurred</div>

        <div class="val-group">
          <div class="label">Command Line</div>
          <div
            class="value command-line-container {{
              item.expanded ? 'expanded' : ''
            }}"
            (click)="expandCommandLine(item.id, !item.expanded)"
          >
            {{ item.commandLine }}
          </div>
        </div>

        <div class="val-group" *ngFor="let annotation of item.annotations">
          <div class="label annotation-label">
            <mat-icon
              class="annotation-icon"
              svgIcon="icon-comment-solid"
            ></mat-icon>
            {{ annotation.user }}
          </div>
          <div class="value">
            {{ annotation.body }}
          </div>
        </div>

        <!-- Event details pane -->
        <mat-expansion-panel class="event-details-panel" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon [inline]="true" class="right-arrow"
                >arrow_forward_ios</mat-icon
              >
              Event Details
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="val-group">
            <div class="label">File Path</div>
            <div class="value">
              {{ item.filePath }}
            </div>
          </div>

          <div class="val-group">
            <div class="label">Parent File Path</div>
            <div class="value">
              {{ item.parentFilePath }}
            </div>
          </div>

          <div class="val-group">
            <div class="label">File Is Signed</div>
            <div class="value">
              {{ item.fileIsSigned }}
            </div>
          </div>

          <div class="val-group">
            <div class="label">File Hash</div>
            <div class="value">
              {{ item.fileHash }}
            </div>
          </div>

          <div class="val-group">
            <div class="label">Parent File Hash</div>
            <div class="value">
              {{ item.parentFileHash }}
            </div>
          </div>
        </mat-expansion-panel>
      </div>

      <!-- Template for Network events -->
      <div class="timeline-item event-item" *ngIf="item.type === 'Network'">
        <div class="item-header">Network Event Occurred</div>
        <div class="inline-val-group">
          <div class="val-col">
            <div class="val-group">
              <div class="label">Destination Port</div>
              <div class="value">
                {{ item.destinationPort }}
              </div>
            </div>

            <div class="val-group">
              <div class="label">Destination IP</div>
              <div class="value">
                {{ item.destinationIp }}
              </div>
            </div>
          </div>

          <div class="val-col">
            <div class="val-group">
              <div class="label">Source Port</div>
              <div class="value">
                {{ item.sourcePort }}
              </div>
            </div>

            <div class="val-group">
              <div class="label">Source IP</div>
              <div class="value">
                {{ item.sourceIp }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="empty-timeline"
    *ngIf="
      !mergedTimelineItems ||
      mergedTimelineItems.length === 0 ||
      (filteredTimelineItems && filteredTimelineItems.length === 0)
    "
  >
    No Timeline Items Found
  </div>
</div>
