import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDividerModule } from "@angular/material/divider";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatExpansionModule } from "@angular/material/expansion";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatIconModule } from "@angular/material/icon";
import { MatIconRegistry } from "@angular/material/";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import {
  MatAutocompleteModule,
  MatCheckboxModule,
  MatInputModule,
  MatMenuModule,
  MatOptionModule,
  MatSelectModule,
} from "@angular/material";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { CookieService } from "ngx-cookie-service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatChipsModule } from "@angular/material/chips";

import {
  AppRoutingModule,
  ClientGuard,
  SoteriaGuard,
} from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NotificationsComponent } from "./dashboard/notifications/notifications.component";
import { AdministrationComponent } from "./dashboard/administration/administration.component";
import { SettingsComponent } from "./dashboard/settings/settings.component";
import { FeedbackComponent } from "./dashboard/feedback/feedback.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DashboardComponent } from "./dashboard/dashboard.component";
import {
  ClientService,
  DNSenseService,
  LexicoService,
  UserService,
  NotificationsService,
  InterceptorService,
} from "./_services";
import { QrComponent } from "./dashboard/settings/qr/qr.component";
import { PasswordComponent } from "./dashboard/settings/password/password.component";
import { UiAutocompleteComponent } from "./ui/ui-autocomplete/ui-autocomplete.component";
import { UiAutocompleteComponentDNSense } from "./ui/ui-autocomplete-dnsense/ui-autocomplete-dnsense.component";
import { UiChipsComponent } from "./ui/ui-chips/ui-chips.component";
import { MenuMainComponent } from "./menu/menu-main/menu-main.component";
import { MenuSubComponent } from "./menu/menu-sub/menu-sub.component";
import { LexicoTicketsComponent } from "./views/lexico-tickets/lexico-tickets.component";
import { LexicoAliasesComponent } from "./views/lexico-aliases/lexico-aliases.component";
import { LexicoAssigneesComponent } from "./views/lexico-assignees/lexico-assignees.component";
import { LexicoBroadcastersComponent } from "./views/lexico-broadcasters/lexico-broadcasters.component";
import { LexicoNotifiersComponent } from "./views/lexico-notifiers/lexico-notifiers.component";
import { LexicoTagsComponent } from "./views/lexico-tags/lexico-tags.component";
import { DnsenseClientsComponent } from "./views/dnsense-clients/dnsense-clients.component";
import { DnsenseDomainsComponent } from "./views/dnsense-domains/dnsense-domains.component";
import { ContentTableComponent } from "./content/content-table/content-table.component";
import { LayoutFilterBoxComponent } from "./layout/layout-filter-box/layout-filter-box.component";
import { LayoutPanelComponent } from "./layout/layout-panel/layout-panel.component";
import { UiApplyCancelComponent } from "./ui/ui-apply-cancel/ui-apply-cancel.component";
import { UiInputComponent } from "./ui/ui-input/ui-input.component";
import { UiMultiselectComponent } from "./ui/ui-multiselect/ui-multiselect.component";
import { UiTextareaComponent } from "./ui/ui-textarea/ui-textarea.component";
import { UiListComponent } from "./ui/ui-list/ui-list.component";
import { MdePopoverModule } from "@material-extended/mde";
import { ContentDonutComponent } from "./content/content-donut/content-donut.component";
import { ContentBarChartComponent } from "./content/content-bar-chart/content-bar-chart.component";
import { ContentLineGraphComponent } from "./content/content-line-graph/content-line-graph.component";
import { LexicoTicketsEditComponent } from "./views/lexico-tickets-edit/lexico-tickets-edit.component";
import { UiDropdownComponent } from "./ui/ui-dropdown/ui-dropdown.component";
import { MatRadioModule } from "@angular/material/radio";
import { LayoutTicketsEditComponent } from "./layout/layout-tickets-edit/layout-tickets-edit.component";
import { LayoutTicketsAlertsComponent } from "./layout/layout-tickets-alerts/layout-tickets-alerts.component";
import { LayoutAlertRowComponent } from "./layout/layout-alert-row/layout-alert-row.component";
import { LayoutPanelAlertsComponent } from "./layout/layout-panel-alerts/layout-panel-alerts.component";
import { LayoutPanelDomainComponent } from "./layout/layout-panel-domain/layout-panel-domain.component";
import { LayoutPanelClientsComponent } from "./layout/layout-panel-clients/layout-panel-clients.component";
import { LayoutPanelBroadcastersComponent } from "./layout/layout-panel-broadcasters/layout-panel-broadcasters.component";
import { LayoutPanelNotifiersComponent } from "./layout/layout-panel-notifiers/layout-panel-notifiers.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { LandingPageComponent } from "./views/landing-page/landing-page.component";
import { LayoutPanelDomainAddComponent } from "./layout/layout-panel-domain-add/layout-panel-domain-add.component";
import { DnsenseTakedownComponent } from "./views/dnsense-takedowns/dnsense-takedown.component";
import { EventViewComponent } from "./views/lexico-client-view/event-view/event-view.component";
import { EventSummaryComponent } from "./views/lexico-client-view/event-view/event-summary/event-summary.component";
import { EventTimelineComponent } from './views/lexico-client-view/event-view/event-timeline/event-timeline.component';

@NgModule({
  declarations: [
    AppComponent,
    NotificationsComponent,
    AdministrationComponent,
    SettingsComponent,
    FeedbackComponent,
    DashboardComponent,
    QrComponent,
    PasswordComponent,
    UiAutocompleteComponent,
    UiAutocompleteComponentDNSense,
    UiChipsComponent,
    MenuMainComponent,
    MenuSubComponent,
    LexicoTicketsComponent,
    LexicoAliasesComponent,
    LexicoAssigneesComponent,
    LexicoBroadcastersComponent,
    LexicoNotifiersComponent,
    LexicoTagsComponent,
    EventViewComponent,
    DnsenseClientsComponent,
    DnsenseDomainsComponent,
    ContentTableComponent,
    LayoutFilterBoxComponent,
    LayoutPanelComponent,
    UiApplyCancelComponent,
    UiInputComponent,
    UiMultiselectComponent,
    UiTextareaComponent,
    UiListComponent,
    ContentDonutComponent,
    ContentBarChartComponent,
    ContentLineGraphComponent,
    LexicoTicketsEditComponent,
    UiDropdownComponent,
    LayoutTicketsEditComponent,
    LayoutTicketsAlertsComponent,
    LayoutAlertRowComponent,
    LayoutPanelAlertsComponent,
    LayoutPanelDomainComponent,
    LayoutPanelClientsComponent,
    LayoutPanelBroadcastersComponent,
    LayoutPanelNotifiersComponent,
    LandingPageComponent,
    LayoutPanelDomainAddComponent,
    DnsenseTakedownComponent,
    EventSummaryComponent,
    EventTimelineComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatDividerModule,
    MatButtonModule,
    MatCardModule,
    MatGridListModule,
    MatToolbarModule,
    MatExpansionModule,
    HttpClientModule,
    MatTableModule,
    MatTabsModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatMenuModule,
    MatMomentDateModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    FlexLayoutModule,
    MatChipsModule,
    MatDialogModule,
    MatTooltipModule,
    PdfViewerModule,
    MatProgressSpinnerModule,
    MdePopoverModule,
    MatCheckboxModule,
    MatRadioModule,
    ScrollingModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    ClientService,
    MatIconRegistry,
    UserService,
    NotificationsService,
    CookieService,
    LexicoService,
    DNSenseService,
    SoteriaGuard,
  ],
  bootstrap: [AppComponent],
  entryComponents: [FeedbackComponent, QrComponent, PasswordComponent],
})
export class AppModule {}
