import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material";
import {LexicoService} from "../../_services";

@Component({
	selector: 'app-lexico-notifiers',
	templateUrl: './lexico-notifiers.component.html',
	styleUrls: ['./lexico-notifiers.component.css']
})
export class LexicoNotifiersComponent implements OnInit {
	data = null;
	tableMessage = null;
	selectedItemData = null;
	selectedItemIndex = null;
	panelShow = null;
	tableConfig = {
		"columns": [
			{
				"header": "Name",
				"field": "name",
				"maxWidth": 200
			},
			{
				"header": "Type",
				"field": "type",
				"maxWidth": null
			}
		],
		"delete": true,
		"testing": false
	};
	filterBoxTitle = "Notifier List";
	filterBoxButton = "+ Notifier";
	filterBoxFeatures = [
		{
			"filterList": [
				{
					type: "input",
					filterTarget: "all",
					default: "",
					placeholder: "Text Filter (on any column)",
					uniqueID: 'text filter'
				}
			]
		},
		{
			"filterList": []
		},
		{
			"filterList": []
		}
	];
	filterBoxAllFields = ["name", "type"];
	newNotifier = false;

	constructor(private _lexicoService: LexicoService) {
	}

	updateNotifiers(data: any[]) {
		if (data !== null && data.length == 0) {
			this.tableMessage = 'no data in this time range';
		} else if (data !== null) {
			this.tableMessage = null;
			this.data = new MatTableDataSource(data);
		}
	}

	async ngOnInit() {
		let data = await this._lexicoService.GetNotifiers();
		if (data['error']) {
			this.tableMessage = 'error loading data';
		} else if (!data['notifiers']) {
			this.tableMessage = 'no data in this time range';
		} else {
			this.updateNotifiers(data['notifiers']);
		}
	}

	// for getting a selected table row
	// this would appear in many different views
	selectTableRow($event) {
		const match = $event.emitMatch;
		const index = $event.emitIndex;

		// if row already selected, closes it
		if (index == this.selectedItemIndex) {
			this.selectedItemData = null;
			this.selectedItemIndex = null;
			this.panelShow = false;
		} else {
			this.selectedItemData = match;
			this.selectedItemIndex = index;
			this.panelShow = true;
			this.newNotifier = false;
		}
	}

	deleteTableRow($event) {
		if ($event.emitLength == 0) {
			this.tableMessage = 'no data in this time range';
			this.data = null;
		}
	}

	// opens panel to make a new item
	// this would appear in many different views
	newItem() {
		this.selectedItemIndex = null;
		this.newNotifier = true;
		this.panelShow = true;

		// temp tries making new alias
		this.selectedItemData = {
			'name': '',
			'type': null
		};
	}

	// Hacky work-around to get the update to stop breaking within the panel.
	// For some reason, if you pass a function call rather than a function reference
	// the panel update craps out.
	cnotifierCallback = this.createNotifier(this._lexicoService);
	createNotifier(lexicoService) {
		return async (obj) => {
			return await lexicoService.CreateNotifier(obj);
		}
	}

	// Hacky work-around to get the update to stop breaking within the panel.
	// For some reason, if you pass a function call rather than a function reference
	// the panel update craps out.
	unotifierCallback = this.updateNotifier(this._lexicoService);
	updateNotifier(lexicoService) {
		return async (obj) => {
			return await lexicoService.UpdateNotifier(obj);
		}
	}

	rNotifier = this.removeNotifier(this._lexicoService);
	removeNotifier(lexicoService) {
		return async (identifier) => {
			return await lexicoService.DeleteNotifier(identifier);
		}
	}

	async closePanelSaveData($event) {
		if ($event && $event.nochange) {
			// does the closing action and resets things
			this.selectedItemData = null;
			this.selectedItemIndex = null;
			this.panelShow = false;
			return;
		}

		let data = await this._lexicoService.UpdateNotifiersList();
		if (data['error']) {
			this.tableMessage = 'error loading data';
		} else if (!data['notifiers']) {
			this.tableMessage = 'no data in this time range';
		} else {
			this.updateNotifiers(data['notifiers']);
		}

		// does the closing action and resets things
		this.selectedItemData = null;
		this.selectedItemIndex = null;
		this.panelShow = false;
	}
}
